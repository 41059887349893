import React, { useRef, useEffect, useState } from "react";
import { Map as Leaflet, Circle, GeoJSON } from "react-leaflet";
import "../Map/map.scss";
import MapLayers from "../Map/MapLayers";
import wkt from "terraformer-wkt-parser";
import L from "leaflet";

const ManageCreationMethodsAreas = ({ geometry }) => {
  const geoJsonLayer = React.createRef();
  const mapRef = useRef(null);

  useEffect(() => {
    if (geometry) {
      let geoData = wkt.parse(geometry);
      let pol = geoData.coordinates[0].map(
        (point) => new L.latLng(point[1], point[0])
      );
      const bounds = L.latLngBounds(pol);
      geoJsonLayer.current.leafletElement.clearLayers().addData(geoData);
      mapRef.current.leafletElement.flyToBounds(bounds);
    }
  }, [geometry]);

  useEffect(() => {
    mapRef.current.leafletElement.flyTo({ lng: 36.2384, lat: 30.5852 }, 7);
  }, []);

  return (
    <Leaflet
      ref={mapRef}
      center={{ lng: 36.2384, lat: 30.5852 }}
      zoom={6}
      className="main-map"
    >
      <MapLayers />
      {geometry && <GeoJSON ref={geoJsonLayer} data={wkt.parse(geometry)} />}
    </Leaflet>
  );
};

export default ManageCreationMethodsAreas;
