import React, { useState, useEffect } from "react";
import { Grid, Segment, Form, Button, Message } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";

export default function ManageClient({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [client, setClient] = useState();
  const [clientID, setClientID] = useState();
  const router = useHistory();

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (!Boolean(location && location.state && location.state.clientID)) {
        await serviceApi
          .service("client")
          .create(values)
          .then((res) => setClientID(res.clientId));
      } else {
        await serviceApi
          .service("client")
          .update(+location.state.clientID, values);
        setClientID(values.clientId);
      }
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === 400) {
        setErrorMessage(resources.pages.newUser.errors.duplicateUser);
      }
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.clientID)) {
      getClientData();
    }
  }, [location]);

  const getClientData = async () => {
    try {
      const res = await serviceApi
        .service("client")
        .get(+location.state.clientID);
      if (res) setClient(res);
    } catch (error) {
      console.log("error get client", error);
    }
  };
  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    router.push("/ClientBranches/ManageBranch", {
      clientID: clientID,
    });
  };

  return (
    <Grid className="form-container">
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <Grid.Column>
        <PageHeaderTitle
          content={
            Boolean(location && location.state && location.state.clientID)
              ? resources.pages.clients.editClient
              : resources.pages.clients.addClient
          }
          icon="building"
        />
        <Segment>
          <FinalForm
            initialValues={client}
            onSubmit={onSubmit}
            validate={(values) => {
              if (!values.clientName)
                return {
                  clientName: resources.common.errors.requiredField,
                };
            }}
            render={({ handleSubmit }) => (
              <Form
                className="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                loading={isSubmitting}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="8">
                      <p>{resources.pages.clients.clientName}</p>
                      <Field name="clientName">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="user"
                            placeholder={resources.pages.clients.clientName}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                  {/* <Grid.Row>
                    <Grid.Column width="8">
                      <p>{resources.pages.clients.clientSituation}</p>
                      <Field name="clientSituation">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="tag"
                            placeholder={
                              resources.pages.clients.clientSituation
                            }
                            autoComplete="no"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row> */}
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <p>{resources.common.notes}</p>
                      <Field name="notes">
                        {({ input, meta }) => (
                          <Form.TextArea
                            {...input}
                            error={meta.touched && meta.error}
                            icon="sticky note"
                            placeholder={resources.common.notes}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Column width={8}>
                    <div style={{ display: "flex" }}>
                      <Field name="vip">
                        {({ input, meta }) => (
                          <Form.Checkbox
                            onChange={(e, v) => input.onChange(v.checked)}
                            checked={Boolean(input.value)}
                            name={input.name}
                          />
                        )}
                      </Field>
                      <label style={{ margin: "0px 5px" }}>
                        {resources.pages.clients.vip}
                      </label>
                    </div>
                  </Grid.Column>
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  className="save-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {resources.common.save}
                </Button>
                <Button
                  size="large"
                  type="button"
                  onClick={() =>
                    router.replace("/ClientBranches/ClientBranchesList")
                  }
                >
                  {resources.common.back}
                </Button>
                {errorVisible.visible && (
                  <Message
                    negative
                    compact
                    className="error-message"
                    content={
                      errorMessage || resources.common.errors.genericServerError
                    }
                  />
                )}
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
