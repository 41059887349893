import React, { useState, useEffect } from "react";
import "./NotDeservedContractsPrintOut.scss";
import PrintOutHeader from "../PrintOutHeader/PrintOutHeader";
import { useHistory, useLocation } from "react-router-dom";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import qs from "qs";

const NotDeservedContractsPrintOut = () => {
  const [sum, setSum] = useState(0);
  const [printOutInfo, setPrintOutOnfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          type: 12,
        },
      });

      let totalSum = 0;
      console.log(res);
      const data = res.map((row) => {
        totalSum += row.PaymentAmount;
        return {
          ...row,
          PaymentDate: moment(row.PaymentDate).format("DD/MM/YYYY"),
          paymentAmount: row.PaymentAmount ? row.PaymentAmount : null,
          ContractNumber: row.ContractNumber ? row.ContractNumber : null,
          ContractValue: row.ContractValue ? row.ContractValue : null,
          ClientName: row.ClientName ? row.ClientName : null,
        };
      });
      setSum(totalSum);
      setPrintOutOnfo(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setPrintOutOnfo([]);
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="not-deserved-contract-printout-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(printOutInfo.length) ? (
        <>
          <PrintOutHeader
            printOutTitle={"تقرير بالعقود و مدة المطالبة (غير مدفوع)"}
            dateTitle={"تاريخ الدفعة "}
            endDate={to}
            startDate={from}
            hasDate={true}
          />
          <div className="not-deserved-contract-printout-table-container">
            <table className="not-deserved-contract-printout-table">
              <thead>
                <tr>
                  <th> اسم العميل</th>
                  <th>رقم العقد</th>
                  <th>قيمة العقد</th>
                  <th> تاريخ الدفعة</th>
                  <th>قيمة الدفعة</th>
                </tr>
              </thead>
              <tbody>
                {printOutInfo.map((info, idx) => (
                  <tr key={idx}>
                    <td className="not-deserved-contract-printout-table">
                      {info.ClientName}
                    </td>
                    <td className="not-deserved-contract-printout-table">
                      {info.ContractNumber}
                    </td>
                    <td className="not-deserved-contract-printout-table">
                      {info.ContractValue}
                    </td>
                    <td className="not-deserved-contract-printout-table">
                      {info.PaymentDate}
                    </td>
                    <td className="not-deserved-contract-printout-table">
                      {info.PaymentAmount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="not-deserved-contract-printout-sum-lbl-container">
              <label>{`المجموع: ${sum}`}</label>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default NotDeservedContractsPrintOut;
