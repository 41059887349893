import React, { useState, useEffect } from "react";
import "./PeriodEndedContractsPrintOut.scss";
import serviceApi from "../../api";
import PrintOutHeader from "../PrintOutHeader/PrintOutHeader";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import Perm, { isAuthorized } from "../helpers/Permissions";
import { useSelector } from "react-redux";

const PeriodEndedContractsPrintOut = () => {
  const router = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const { from, to, TechnicianID } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const currentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          to: to,
          type: 3,
          from: from,
          technicianId: TechnicianID,
        },
      });
      const { Contracts, Machines } = res;

      if (Boolean(Contracts.length && Machines.length)) {
        Contracts.map((row) => {
          return {
            ...row,
            ContractNumber: row.ContractNumber ? row.ContractNumber : null,
            EndDate: row.EndDate
              ? moment(row.EndDate).format("DD/MM/YYYY")
              : null,
            ClientName: row.ClientName ? row.ClientName : null,
            ContractValue: row.ContractValue ? row.ContractValue : null,
          };
        });

        const obj = {};
        Contracts.forEach((row) => {
          obj[row.ContractID] = {
            header: {
              ...row,
              ContractNumber: row.ContractNumber ? row.ContractNumber : null,
              EndDate: row.EndDate
                ? moment(row.EndDate).format("DD/MM/YYYY")
                : null,
              ClientName: row.ClientName ? row.ClientName : null,
              ContractValue: row.ContractValue ? row.ContractValue : null,
            },
            machines: [],
          };
        });

        Machines.forEach((row) => {
          if (obj[row.ContractID]) {
            obj[row.ContractID].machines.push({
              ...row,
              ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
              SerialNumber: row.SerialNumber ? row.SerialNumber : null,
              type: row.type ? row.type : null,
              model: row.model ? row.model : null,
              BranchName: row.BranchName ? row.BranchName : null,
              AreaName: row.AreaName ? row.AreaName : null,
              Fax: row.Fax ? row.Fax : null,
              Phone: row.Phone ? row.Phone : null,
              ContractNumber: row.ContractNumber ? row.ContractNumber : null,
            });
          }
        });

        if (Machines.length > 0) {
          setTableData(obj);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setTableData(undefined);
      }
    } catch (err) {
      //TODO: show error message
      setIsLoading(false);
      console.log(err);
      setTableData(undefined);
    }
  };

  useEffect(() => {
    if (
      isAuthorized([Perm.viewAllReports]) ||
      +TechnicianID === currentTechnicianID
    ) {
      fetchData();
    }
  }, [currentTechnicianID]);

  const contacts = tableData
    ? Object.entries(tableData).filter(([key, { header, machines }]) => {
        return machines.length > 0;
      })
    : undefined;

  return (
    <div className="period-ended-contract-printout-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(tableData) ? (
        <div>
          <PrintOutHeader
            printOutTitle={"تقرير بالعقود التي ستنتهي خلال فتره محددة"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
          />
          {contacts.map(([_, { header, machines }], idx) => (
            <div key={idx}>
              <div className="period-ended-contract-printout-first-table-container">
                <table className="period-ended-contract-printout-first-table">
                  <thead>
                    <tr className="period-ended-contract-printout-first-table-header">
                      <th>رقم العقد</th>
                      <th>تاريخ الانتهاء</th>
                      <th>اسم العميل</th>
                      <th>قيمة العقد</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="period-ended-contract-printout-first-table-data"
                      key={idx}
                    >
                      <td> {header.ContractNumber}</td>
                      <td> {header.EndDate}</td>
                      <td> {header.ClientName}</td>
                      <td> {header.ContractValue}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="period-ended-contract-printout-second-table-container">
                <table className="period-ended-contract-printout-second-table">
                  <thead>
                    <tr>
                      <th> رقم الكمبيوتر</th>
                      <th> الرقم التسلسلي</th>
                      <th> نوع الالة</th>
                      <th> موديل الالة</th>
                      <th>الفرع</th>
                      <th>المنطقة</th>
                      <th>الهاتف</th>
                      <th>فاكس</th>
                      <th className="period-ended-contract-printout-second-table-last-header">
                        رقم العقد
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {machines.map((machine, idx) => (
                      <tr key={idx}>
                        <td>{machine.ComputerNumber}</td>
                        <td>{machine.SerialNumber}</td>
                        <td>{machine.type}</td>
                        <td>{machine.model}</td>
                        <td>{machine.BranchName}</td>
                        <td>{machine.AreaName}</td>
                        <td>{machine.Fax}</td>
                        <td>{machine.Phone}</td>
                        <td>{machine.ContractNumber}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default PeriodEndedContractsPrintOut;
