import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Segment, Label } from "semantic-ui-react";
import app from "../../../api";
import { resources } from "../../../assets/LocalizationResources";
import SuccessModal from "../../../components/SuccessModal";
import "./EditInvoiceModal.scss";

export const EditInvoiceModal = ({
  open,
  total,
  invoiceId,
  lastInvoiceCounter,
  currentCounter,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceedModalShown, setIsSucceedModalShown] = useState(false);
  const [updateInvoiceForm, setUpdateInvoiceForm] = useState({
    total,
    currentCounter,
    lastInvoiceCounter,
  });

  const onConfirmUpdateInvoice = async () => {
    try {
      setIsLoading(true);
      await app.service("queries-text").update(invoiceId, {
        type: "update-invoice",
        ...updateInvoiceForm,
      });
      setIsSucceedModalShown(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setUpdateInvoiceForm({
      total,
      currentCounter,
      lastInvoiceCounter,
    });
  }, [total, currentCounter, lastInvoiceCounter]);

  const onCloseEditInvoiceModal = () => {
    onClose({
      isInvoiceUpdated: isSucceedModalShown, //! refetch
    });
    setIsLoading(false);
    setIsSucceedModalShown(false);
  };

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <SuccessModal
        visible={isSucceedModalShown}
        header={resources.common.savedSuccessfully}
        onConfirm={onCloseEditInvoiceModal}
      />
      <Modal.Header>
        {resources.pages.maintenanceReport.editInvoice}
      </Modal.Header>
      <Modal.Content>
        <Input
          label={resources.pages.maintenanceReport.invoiceAmount}
          className="edit-page-input-style"
          fluid
          type="number"
          onChange={(e) => {
            e.persist();
            setUpdateInvoiceForm((prev) => {
              return { ...prev, total: e?.target?.value };
            });
          }}
          value={`${updateInvoiceForm.total}`}
          autoComplete="off"
        />
        <br />
        <Input
          fluid
          className="edit-page-input-style"
          label={resources.pages.maintenanceReport.previousCounterRead}
          type="number"
          onChange={(e) => {
            e.persist();
            setUpdateInvoiceForm((prev) => {
              return { ...prev, lastInvoiceCounter: e?.target?.value };
            });
          }}
          value={`${updateInvoiceForm.lastInvoiceCounter}`}
          autoComplete="off"
        />
        <br />
        <Input
          fluid
          className="edit-page-input-style"
          label={resources.pages.maintenanceReport.currentCounterRead}
          type="number"
          onChange={(e) => {
            e.persist();
            setUpdateInvoiceForm((prev) => {
              return { ...prev, currentCounter: e?.target?.value };
            });
          }}
          value={`${updateInvoiceForm.currentCounter}`}
          autoComplete="off"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="edit"
          labelPosition="right"
          content={resources.common.confirm}
          onClick={onConfirmUpdateInvoice}
          loading={isLoading}
          disabled={isLoading}
        />
        <Button
          icon="share square outline"
          labelPosition="right"
          content={resources.common.cancel}
          onClick={onCloseEditInvoiceModal}
        />
      </Modal.Actions>
    </Modal>
  );
};
