import React, { useEffect, useState } from "react";
import {
  Card,
  Menu,
  Responsive,
  Segment,
  Popup,
  Icon,
  Checkbox,
  Loader,
  Pagination,
  Modal,
  Button,
} from "semantic-ui-react";
import serviceApi from "../../api";
import { resources } from "../../assets/LocalizationResources";
import Img from "../../assets/images/routing/route-end.png";
import showMap from "../../assets/images/show-on-map.svg";
import routingImg from "../../assets/images/routing.svg";
import reportImg from "../../assets/images/report.svg";
import googleImg from "../../assets/images/routing/google-routes.svg";
import contactPersonsListImg from "../../assets/images/contactPersonsList.svg";
import ContactPersonsListModal from "./contactPersonsListModal.js";
import { useHistory } from "react-router-dom";
import RoutingServiceApi from "../../api/router-service.api";
import locatorImg from "../../assets/images/locator.svg";
import SearchBy from "../../components/SearchBy/SearchBy";
import GeoLocationModal from "./GeoLocationModal";
import { useSelector } from "react-redux";
import moment from "moment";

export default function TechVisits() {
  const [machines, setMachines] = useState([]);
  const [showLateDate, setShowLateDate] = useState(false);
  const [orderByDistance, setOrderByDistance] = useState(false);
  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );
  const [searching, setSearching] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({});
  const router = useHistory();
  const [openContactPersonsListModal, setOpenContactPersonsListModal] =
    useState(false);

  const [contactPersons, setContactPersons] = useState([]);

  const [openGeoLocationModal, setOpenGeoLocationModal] = useState(false);
  const [branchID, setBranchID] = useState(null);

  const [pagination, setPagination] = useState({ pageSize: 9, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const [openNoCoordsAlertModal, setOpenNoCoordsAlertModal] = useState(false);

  const fillSearchData = async () => {
    setSearchData({
      ComputerNumber: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
      ClientName: {
        type: "text",
        label: resources.pages.clients.clientName,
      },
      TechnicianName: {
        type: "text",
        label: resources.pages.technicians.technicianName,
      },
      ContractNumber: {
        type: "text",
        label: resources.pages.addContract.contractNumber,
      },
      AreaName: { type: "text", label: resources.pages.areaList.areaName },
    });
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  useEffect(() => {
    if (CurrentUserID && activePage && searching) loadVisits();
  }, [CurrentUserID, showLateDate, searching, orderByDistance, activePage]);

  const onPaging = (page) => setActivePage(page);

  const getDistance = async (machines) => {
    let ClientBranches = {};
    const userLocation = await getUserLastLocation();

    if (userLocation) {
      for (let i = 0; i < machines.length; i++) {
        if (
          ClientBranches.length > 0 &&
          Object(ClientBranches).keys((k) => machines[i].BranchID == k)
        ) {
          machines[i] = {
            ...machines,
            distance: ClientBranches[[machines[i].BranchID]].distance,
          };
        } else if (Boolean(machines[i].longitude && machines[i].latitude)) {
          ClientBranches = {
            ...ClientBranches,
            [machines[i].BranchID]: 0,
          };

          let points = [
            userLocation,
            {
              longitude: machines[i].longitude,
              latitude: machines[i].latitude,
            },
          ];

          const res = await RoutingServiceApi.route(points);
          if (res) {
            machines[i] = {
              ...machines[i],
              distance: res.data.paths[0].distance,
            };
            ClientBranches[[machines[i].BranchID]] = res.data.paths[0].distance;
          }
        } else continue;
      }
    }

    if (showLateDate) {
      const data = [];
      machines.forEach((row) => {
        if (row.nextVisist)
          if (new Date().getTime() > new Date(row.nextVisist).getTime()) {
            data.push({
              ...row,
              TechnicianName: row.TechnicianName,
              lateForNextVisit: resources.pages.ticket.late,
            });
          }
      });

      data.sort((a, b) => a.distance - b.distance);
      setMachines(data);
    } else {
      const data = machines
        .map((row) => {
          return {
            ...row,
            TechnicianName: row.TechnicianName,
            lateForNextVisit: row.nextVisist
              ? new Date().getTime() > new Date(row.nextVisist).getTime()
                ? resources.pages.ticket.late
                : resources.pages.ticket.notLate
              : resources.common.notSpecified,
          };
        })
        .sort((a, b) => a.distance - b.distance);
      setMachines(data);
    }
    setIsLoading(false);
  };

  const loadVisits = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          userID: CurrentUserID,
          technicianID: CurrentTechnicianID,
          type: 2,
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),

          where:
            searching && searching.searchBy
              ? searching.searchBy === "ComputerNumber"
                ? `and M.ComputerNumber like '%${searching.term}%'`
                : `and ${
                    searching.searchBy === "ContractNumber"
                      ? "C"
                      : searching.searchBy === "TechnicianName"
                      ? "T"
                      : searching.searchBy === "ClientName"
                      ? "CI"
                      : "A"
                  }.${searching.searchBy} like N'%${searching.term}%'`
              : "",
        },
      });

      if (Boolean(res && res.data && res.data.length > 0)) {
        if (showLateDate && orderByDistance) {
          getDistance(res.data);
        } else if (showLateDate) {
          const data = [];
          res.data.forEach((row) => {
            if (row.nextVisist)
              if (new Date().getTime() > new Date(row.nextVisist).getTime()) {
                data.push({
                  ...row,
                  TechnicianName: row.TechnicianName,
                  lateForNextVisit: resources.pages.ticket.late,
                });
              }
          });
          setMachines(data);
          setIsLoading(false);
        } else if (orderByDistance) {
          getDistance(res.data);
        } else {
          const data = res.data.map((row) => {
            return {
              ...row,
              TechnicianName: row.TechnicianName,
              lateForNextVisit: row.nextVisist
                ? new Date().getTime() > new Date(row.nextVisist).getTime()
                  ? resources.pages.ticket.late
                  : resources.pages.ticket.notLate
                : resources.common.notSpecified,
            };
          });

          setMachines(data);
          setPagination({
            ...pagination,
            total: res.totalNumOfData,
          });
          if (Math.ceil(res.totalNumOfData / 10) < activePage) setActivePage(1);
          setIsLoading(false);
        }
      } else {
        setMachines([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // TODO: Show error message
      console.log(error);
    }
  };

  const routingTo = async (ClientBranch) => {
    if ((ClientBranch && ClientBranch.longitude, ClientBranch.latitude)) {
      const userLocation = await getUserLastLocation();
      if (userLocation && userLocation.longitude && userLocation.latitude)
        router.push("/Tech/Home", {
          isRouting: true,
          points: [
            userLocation,
            {
              longitude: ClientBranch.longitude,
              latitude: ClientBranch.latitude,
            },
          ],
        });
    } else setOpenNoCoordsAlertModal(true);
  };

  const getUserLastLocation = async () => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Users",
          attributes: ["fullName"],
          include: [
            {
              model: "Locations",
              attributes: ["longitude", "latitude"],
            },
          ],
          where: { UserId: CurrentUserID },
        },
      });
      if (res && res.data && res.data[0].Location)
        return {
          longitude: res.data[0].Location.longitude,
          latitude: res.data[0].Location.latitude,
        };
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  const routingToGoogle = async (ClientBranch) => {
    if ((ClientBranch && ClientBranch.longitude, ClientBranch.latitude)) {
      const to = `${ClientBranch.latitude} , ${ClientBranch.longitude}`;

      const qs = `saddr=daddr=${to}&amp;ll=`;
      if (
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPod") != -1 ||
        navigator.platform.indexOf("iPad") != -1
      )
        window.open(`maps://maps.google.com/maps?${qs}`);
      else window.open(`http://maps.google.com/maps?${qs}`);
    } else setOpenNoCoordsAlertModal(true);
  };

  const getInterval = (d2) => {
    const date1 = moment();
    const date2 = moment(d2);
    const duration = date1.diff(date2);
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
      days = Math.floor(duration / (1000 * 60 * 60 * 24));

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return days + "." + hours + ":" + minutes;
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  const createReport = (computerNumber) => {
    router.push("/maintenanceReports", {
      computerNumber: computerNumber,
    });
  };

  const showOnMap = (machine) => {
    if (machine && machine.longitude && machine.latitude) {
      router.push("/Tech/Home", {
        showOnMap: true,
        machine: machine,
      });
    } else setOpenNoCoordsAlertModal(true);
  };

  const getCards = (isTablet) => {
    return (
      <>
        {isLoading ? (
          <Loader active inline="centered" />
        ) : (
          <>
            {machines.length > 0 ? (
              <Card.Group className="tickets-list-container">
                {machines.map((machine, idx) => (
                  <Card
                    key={idx}
                    className={isTablet ? "ticket-card-tablet" : " ticket-card"}
                  >
                    <div
                      className="time-ticket"
                      style={{
                        color:
                          machine.lateForNextVisit ===
                          resources.pages.ticket.late
                            ? "red"
                            : "green",
                      }}
                    >
                      <div>
                        <Icon
                          size="large"
                          name="calendar alternate outline"
                          color={
                            machine.lateForNextVisit ===
                            resources.pages.ticket.late
                              ? "red"
                              : "green"
                          }
                        />
                        <label>({machine.lateForNextVisit})</label>
                      </div>
                      <label>
                        {machine.lateForNextVisit ===
                        resources.pages.ticket.late
                          ? getInterval(machine.nextVisist)
                          : ""}
                      </label>
                    </div>
                    <Card.Content>
                      <img src={Img} className="ticket-icon" />
                      <Card.Header>
                        {`${
                          machine.BranchName
                            ? machine.BranchName
                            : resources.pages.ticket.notSpecifiedBranch
                        }/${machine.ContractNumber}`}
                      </Card.Header>
                      <Card.Meta>
                        {`${resources.pages.branch.branchPhoneNumber} : ${
                          machine.ContactPhone
                            ? machine.ContactPhone
                            : resources.common.notSpecified
                        }`}
                      </Card.Meta>
                      <Card.Description className="cardDescriptions-container ">
                        {/* //<span> رقم العقد : {machine.ContractNumber}</span> */}
                        <span className="text-redColor">
                          {`${resources.pages.machines.computerNumber} : ${
                            machine.ComputerNumber
                              ? machine.ComputerNumber
                              : resources.common.notSpecified
                          }`}
                        </span>
                        <span>
                          {`${resources.pages.technicians.technicianName} : ${
                            machine.TechnicianName
                              ? machine.TechnicianName
                              : resources.common.notSpecified
                          }`}
                        </span>
                        {Boolean(machine.ClientName) ? (
                          machine.ClientName.length > 33 ? (
                            <Popup
                              content={machine.ClientName}
                              trigger={
                                <span>
                                  {`${
                                    resources.pages.ticket.clientName
                                  } : ${machine.ClientName.substring(
                                    0,
                                    31
                                  )} ...`}
                                </span>
                              }
                              position="top center"
                              size="small"
                            />
                          ) : (
                            <span>
                              {`${resources.pages.ticket.clientName} : ${machine.ClientName}`}
                            </span>
                          )
                        ) : (
                          <span>
                            {`${resources.pages.ticket.clientName} : ${resources.pages.ticket.notSpecifiedClient}`}
                          </span>
                        )}
                        {Boolean(machine.AreaName) ? (
                          machine.AreaName.length > 33 ? (
                            <Popup
                              content={machine.AreaName}
                              trigger={
                                <span>
                                  {`${
                                    resources.pages.areaList.areaName
                                  } : ${machine.AreaName.substring(0, 31)} ...`}
                                </span>
                              }
                              position="top center"
                              size="small"
                            />
                          ) : (
                            <span>
                              {`${resources.pages.areaList.areaName}  : ${machine.AreaName}`}
                            </span>
                          )
                        ) : (
                          <span>
                            {`${resources.pages.areaList.areaName}  : ${resources.common.notSpecified}`}
                          </span>
                        )}
                        <span>
                          {`${resources.pages.ticket.NextVisit} : ${
                            machine.nextVisist
                              ? machine.nextVisist
                              : resources.common.notSpecified
                          }`}
                        </span>
                        <span>
                          {`${resources.pages.ticket.lastVisit} ${
                            machine.LastVisitDate
                              ? machine.LastVisitDate
                              : resources.common.notSpecified
                          }`}
                        </span>
                        <span>
                          {Boolean(orderByDistance) &&
                            (machine.distance
                              ? `${
                                  resources.pages.ticket.distance
                                } : ${Math.ceil(machine.distance / 1000)} ${
                                  resources.pages.ticket.km
                                }`
                              : "")}
                        </span>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content
                      extra
                      className={
                        isTablet ? "ticket-action-tablet" : "ticket-action"
                      }
                    >
                      {machine.statusID !== 99 && (
                        <Menu>
                          <Menu.Item onClick={() => showOnMap(machine)}>
                            <Popup
                              content={resources.pages.ticket.showOnMap}
                              position="bottom right"
                              trigger={
                                <img src={showMap} className="img-icon" />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                          <Menu.Item onClick={() => routingTo(machine)}>
                            <Popup
                              content={
                                resources.pages.ticket.determineDirections
                              }
                              position="bottom right"
                              trigger={
                                <img src={routingImg} className="img-icon" />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                          <Menu.Item onClick={() => routingToGoogle(machine)}>
                            <Popup
                              content={
                                resources.pages.ticket
                                  .determineDirectionsUsingGoogle
                              }
                              position="bottom right"
                              trigger={
                                <img src={googleImg} className="img-icon" />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => createReport(machine.ComputerNumber)}
                          >
                            <Popup
                              content={
                                resources.pages.createMaintenanceReport
                                  .createMaintenanceReport
                              }
                              position="bottom right"
                              trigger={
                                <img src={reportImg} className="img-icon" />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              setContactPersons(
                                JSON.parse(machine.contactName)
                              );
                              setOpenContactPersonsListModal(true);
                            }}
                          >
                            <Popup
                              content={resources.pages.branch.contacts}
                              position="bottom right"
                              trigger={
                                <img
                                  src={contactPersonsListImg}
                                  className="img-icon"
                                />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              setBranchID(machine.BranchID);
                              setOpenGeoLocationModal(true);
                            }}
                          >
                            <Popup
                              content={
                                resources.pages.branch.updateBranchCoords
                              }
                              position="bottom right"
                              trigger={
                                <img src={locatorImg} className="img-icon" />
                              }
                              color="blue"
                            />
                          </Menu.Item>
                        </Menu>
                      )}
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            ) : (
              <div className="noResult-message">{resources.common.noData}</div>
            )}
          </>
        )}
      </>
    );
  };

  const SearchBoxes = ({ label, onChange, stateKey }) => {
    return (
      <div
        style={{
          padding: "3px 10px",
        }}
      >
        <Checkbox label={label} checked={stateKey} onChange={onChange} />
      </div>
    );
  };

  return (
    <div className="tickets-list-main-container">
      <ContactPersonsListModal
        isOpen={openContactPersonsListModal}
        contactPersons={contactPersons}
        closeContactPersonListModal={() =>
          setOpenContactPersonsListModal(false)
        }
      />
      <GeoLocationModal
        isOpen={openGeoLocationModal}
        branchID={branchID}
        closeGeoLocationModal={(reLoadVisits) => {
          setBranchID(null);
          setOpenGeoLocationModal(false);
          if (reLoadVisits) loadVisits();
        }}
      />
      <SearchBy
        searchTypes={searchData}
        getSearchResults={getSearchResults}
        extraFields={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <SearchBoxes
                label={resources.pages.ticket.showLate}
                onChange={(evt, elm) => {
                  setShowLateDate(Boolean(elm.checked));
                }}
                stateKey={showLateDate}
              />
              <SearchBoxes
                label={resources.pages.ticket.orderByDistance}
                onChange={(evt, elm) => {
                  setOrderByDistance(Boolean(elm.checked));
                }}
                stateKey={orderByDistance}
              />
            </div>
          </>
        }
      />
      <Responsive maxWidth={800}>
        {getCards(true)}
        <div className="pagination-component-container">
          {Boolean(
            pagination && machines && pagination.total > pagination.pageSize
          ) && (
            <Pagination
              activePage={activePage}
              totalPages={Math.ceil(pagination.total / pagination.pageSize)}
              onPageChange={(e, v) => onPaging(v.activePage)}
              boundaryRange={1}
            />
          )}
        </div>
      </Responsive>
      <Responsive minWidth={801}>
        <Segment>
          {getCards(false)}
          <div className="pagination-component-container">
            {Boolean(
              pagination && machines && pagination.total > pagination.pageSize
            ) && (
              <Pagination
                activePage={activePage}
                totalPages={Math.ceil(pagination.total / pagination.pageSize)}
                onPageChange={(e, v) => onPaging(v.activePage)}
                boundaryRange={1}
                className="pagination-component"
              />
            )}
          </div>
        </Segment>
      </Responsive>
      <Modal size="mini" open={openNoCoordsAlertModal}>
        <Modal.Header
          content={resources.common.warning}
          className="no-coords-header"
        />
        <Modal.Content content={resources.common.noCoords} />
        <Modal.Actions>
          <Button
            content={resources.common.close}
            onClick={() => setOpenNoCoordsAlertModal(false)}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}
