import React, { useState, useEffect } from "react";
import tescoNewLogo from "../../../assets/images/tescoNewLogo.png";
import serviceApi from "../../../api";
import { Button, Icon, Segment, Table } from "semantic-ui-react";
import "../MaintenanceReport.scss";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { resources } from "../../../assets/LocalizationResources";
import moment from "moment";
import { EditInvoiceModal } from "./EditInvoiceModal";
import Perm, { isAuthorized } from "../../../components/helpers/Permissions";
import { generateInvoiceNumber } from "../MaintenanceReportsList";

export default function MaintenanceInvoice() {
  const [maintenanceReportInfo, setMaintenanceReportInfo] = useState(null);
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isEditInvoiceOpen, setIsEditInvoiceOpened] = useState(false);
  const getFraction = (price) => {
    return (+price).toFixed(3).split(".")[1];
  };

  const getWhole = (price) => {
    return Math.floor(Math.abs(price));
  };

  const getMaintenanceReportInfo = async (maintenanceReportID) => {
    try {
      const jsonInclude = JSON.stringify([
        {
          model: "MaintenanceReport",
          attributes: [
            "maintenanceReportId",
            "createdat",
            "counter1",
            "counter2",
            "lastCounter1",
            "lastCounter2",
            "contractMachineID",
          ],
          include: [
            {
              model: "ContractMachine",
              attributes: [
                "ContractMachineID",
                "MonthlyFees",
                "copyPrice",
                "copyPriceColorful",
              ],
              include: [
                {
                  model: "Machine",
                  attributes: [
                    "machineId",
                    "serialNumber",
                    "machineTypeID",
                    "machineModelID",
                    "computerNumber",
                  ],
                  include: [
                    {
                      model: "Lookup",
                      as: "MachineType",
                      attributes: ["LookupName"],
                    },
                    {
                      model: "Lookup",
                      as: "MachineModel",
                      attributes: ["LookupName"],
                    },
                  ],
                },
                {
                  model: "ClientBranch",
                  attributes: ["BranchName", "ClientID", "Phone", "Address"],
                  include: [
                    {
                      model: "Client",
                      attributes: ["ClientName"],
                    },
                    {
                      model: "Area",
                      attributes: ["AreaName"],
                    },
                  ],
                },
                {
                  model: "Contract",
                  attributes: ["contractNumber"],
                },
              ],
            },
          ],
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReportInvoice",
          attributes: [
            "InvoiceNumber",
            "MaintenanceReportInvoiceID",
            "LastInvoiceCounter",
            "currentCounter",
            "total",
            "copyPrice",
          ],
          jsonInclude: jsonInclude,
          where: { maintenanceReportId: maintenanceReportID },
        },
      });

      const technician = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReportTechnician",
          attributes: ["TechnicianID"],
          jsonInclude: JSON.stringify([
            {
              model: "Technicians",
              attributes: ["TechnicianName"],
            },
          ]),
          where: { maintenanceReportId: maintenanceReportID },
        },
      });

      const flattenedData = res.data.map((row) => ({
        MachineType:
          row.MaintenanceReport.ContractMachine.Machine.MachineType.LookupName,
        MachineModel:
          row.MaintenanceReport.ContractMachine.Machine.MachineModel.LookupName,
        SerialNumber:
          row.MaintenanceReport.ContractMachine.Machine.serialNumber,
        ClientName:
          row.MaintenanceReport.ContractMachine.ClientBranch.Client.ClientName,
        AreaName:
          row.MaintenanceReport.ContractMachine.ClientBranch.Area.AreaName,
        BranchName:
          row.MaintenanceReport.ContractMachine.ClientBranch.BranchName,
        Address: row.MaintenanceReport.ContractMachine.ClientBranch.Address,
        Phone: row.MaintenanceReport.ContractMachine.ClientBranch.Phone,
        MaintenanceReportInvoiceID: generateInvoiceNumber(
          row.createdAt,
          row.InvoiceNumber
        ),
        computerNumber:
          row.MaintenanceReport.ContractMachine.Machine.computerNumber,
        MaintenanceReportId: row.MaintenanceReport.maintenanceReportId,
        previousCounters: row.LastInvoiceCounter,
        currentCounters: row.currentCounter,
        CopyPriceFromInvoice: row.copyPrice,
        CreatedAt: moment(row.MaintenanceReport.createdat).format("DD/MM/YYYY"),
        ContractMachineID: row.MaintenanceReport.contractMachineID,
        AchievedCopies: row.currentCounter - row.LastInvoiceCounter,
        total: row.total,
        contractNumber:
          row.MaintenanceReport.ContractMachine.Contract.contractNumber,
        technicianName: technician.data[0]?.Technician?.TechnicianName,
      }));
      setMaintenanceReportInfo(flattenedData[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMaintenanceReportInfo(query.id);
  }, []);

  const printReport = async () => {
    window.print();
  };

  const onCloseEditInvoiceModal = ({ isInvoiceUpdated = false }) => {
    if (isInvoiceUpdated) {
      getMaintenanceReportInfo(query.id);
    }
    setIsEditInvoiceOpened(false);
  };

  return (
    <Segment.Group className="main-container">
      <EditInvoiceModal
        open={isEditInvoiceOpen}
        currentCounter={maintenanceReportInfo?.currentCounters}
        invoiceId={maintenanceReportInfo?.MaintenanceReportInvoiceID}
        lastInvoiceCounter={maintenanceReportInfo?.previousCounters}
        total={maintenanceReportInfo?.total}
        onClose={onCloseEditInvoiceModal}
      />
      <Segment className="maintenance-logo">
        <img src={tescoNewLogo}></img>
        {isAuthorized(Perm.editInvoice) && (
          <div className="no-print">
            <Button
              color="blue"
              size="small"
              content={resources.pages.maintenanceReport.editInvoice}
              onClick={() => setIsEditInvoiceOpened(true)}
            />
          </div>
        )}

        <div
          id="printIcon"
          onClick={printReport}
          className="print-label-icon-container no-print"
        >
          <label className="print-label">
            {resources.pages.maintenanceReport.printMaintenanceInvoice}
          </label>
          <Icon name="print" className="print-icon" color="blue" />
        </div>

        <label className="maintenance-left-label textSize9">
          <div>الرقم الضريبي</div>
          <span> 4077334</span>
        </label>
      </Segment>
      <Segment.Group className="maintenance-segment">
        <Segment className="maintenance-head">
          <label>فاتورة مبيعات صيانة الذمم</label>
        </Segment>
        <Segment.Group
          horizontal
          className="main-invoice-info borderLightwight"
        >
          <Segment basic className="left-border segment-fifty">
            <label className="textSize9">
              رقم العقد :
              {maintenanceReportInfo && maintenanceReportInfo.contractNumber
                ? maintenanceReportInfo.contractNumber
                : "-"}
            </label>
          </Segment>
          <Segment basic className="segment-fifty">
            <label className="textSize9">
              رقم الفاتورة:
              {maintenanceReportInfo &&
              maintenanceReportInfo.MaintenanceReportInvoiceID
                ? maintenanceReportInfo.MaintenanceReportInvoiceID
                : "-"}
            </label>
          </Segment>
          <Segment basic className="segment-fifty">
            <label className="textSize9">
              التاريخ:
              {maintenanceReportInfo && maintenanceReportInfo.CreatedAt
                ? maintenanceReportInfo.CreatedAt
                : "-"}
            </label>
          </Segment>
        </Segment.Group>

        <div className="tableContainer">
          <Table basic celled className="textSize9" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.Cell textAlign="center" className="left-border">
                  رقم الكمبيوتر
                </Table.Cell>
                <Table.Cell textAlign="center">نوع الآلة</Table.Cell>
                <Table.Cell textAlign="center">موديل الآلة</Table.Cell>
                <Table.Cell textAlign="center">الرقم المتسلسل</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row style={{ borderColor: "black" }}>
                <Table.Cell textAlign="center" className="left-border">
                  {maintenanceReportInfo && maintenanceReportInfo.computerNumber
                    ? maintenanceReportInfo.computerNumber
                    : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {maintenanceReportInfo && maintenanceReportInfo.MachineType
                    ? maintenanceReportInfo.MachineType
                    : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {maintenanceReportInfo && maintenanceReportInfo.MachineModel
                    ? maintenanceReportInfo.MachineModel
                    : "-"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {maintenanceReportInfo && maintenanceReportInfo.SerialNumber
                    ? maintenanceReportInfo.SerialNumber
                    : "-"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div
          style={{
            borderColor: "black",
          }}
          className="tableContainer"
        >
          <Table celled className="textSize9" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.Cell textAlign="center" className="left-border">
                  قراءة العداد السابق
                </Table.Cell>
                <Table.Cell textAlign="center">قراءة العداد الحالي</Table.Cell>
                <Table.Cell textAlign="center">عدد النسخ المنجزة</Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center" className="left-border">
                  {maintenanceReportInfo &&
                  maintenanceReportInfo.previousCounters
                    ? maintenanceReportInfo.previousCounters
                    : "0"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {maintenanceReportInfo &&
                  maintenanceReportInfo.currentCounters
                    ? maintenanceReportInfo.currentCounters
                    : "0"}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {maintenanceReportInfo && maintenanceReportInfo.AchievedCopies
                    ? maintenanceReportInfo.AchievedCopies
                    : "-"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <Segment className="padding5 borderLightwight borderBottomZero">
          <label className="client-name-row">
            المطلوب من :
            {maintenanceReportInfo && maintenanceReportInfo.ClientName
              ? maintenanceReportInfo.ClientName
              : "-"}
          </label>
        </Segment>

        <Segment.Group horizontal className="borderLightwight">
          <Segment className="left-border segment-fifty">
            <label className="textSize9">
              العنوان :
              {maintenanceReportInfo && maintenanceReportInfo.Address
                ? maintenanceReportInfo.Address
                : "-"}
            </label>
          </Segment>
          <Segment className="segment-fifty">
            <label className="textSize9">
              رقم الهاتف :
              {maintenanceReportInfo && maintenanceReportInfo.Phone
                ? maintenanceReportInfo.Phone
                : "-"}
            </label>
          </Segment>
        </Segment.Group>

        <div className="tableContainer">
          <Table celled unstackable>
            <Table.Header>
              <Table.Row className="table-row-height">
                <Table.HeaderCell
                  textAlign="center"
                  className="left-border textSize9 width10"
                >
                  فلس
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  className="textSize9 padding5 width10"
                >
                  دينار
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  className="textSize9 padding5 width10"
                >
                  سعر النسخة
                </Table.HeaderCell>
                {/* <Table.HeaderCell
                  textAlign="center"
                  className="textSize9 padding5 width10"
                >
                  اجرة شهرية
                </Table.HeaderCell> */}
                <Table.HeaderCell
                  textAlign="center"
                  className="textSize9 padding5"
                  colSpan="2"
                >
                  عدد النسخ المنجزة
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" className="textSize9">
                  البيان
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row textAlign="center" className="table-rows-height">
                <Table.Cell className="left-border textSize9 ">
                  {maintenanceReportInfo && maintenanceReportInfo.total
                    ? getFraction(maintenanceReportInfo.total)
                    : 0}
                </Table.Cell>
                <Table.Cell className="padding5 ">
                  <label className="textSize9 ">
                    {maintenanceReportInfo && maintenanceReportInfo.total
                      ? getWhole(maintenanceReportInfo.total)
                      : 0}
                  </label>
                </Table.Cell>
                <Table.Cell className="padding5 ">
                  <label className="textSize9">
                    {maintenanceReportInfo &&
                    maintenanceReportInfo.CopyPriceFromInvoice
                      ? maintenanceReportInfo.CopyPriceFromInvoice
                      : 0}
                  </label>
                </Table.Cell>
                {/* <Table.Cell className="padding5 width10">
                  <label className="textSize9">
                    {maintenanceReportInfo && maintenanceReportInfo.MonthlyFees
                      ? maintenanceReportInfo.MonthlyFees
                      : 0}
                  </label>
                </Table.Cell> */}
                <Table.Cell className="padding5 width10" colSpan="2">
                  <label className="textSize9">
                    {maintenanceReportInfo &&
                    maintenanceReportInfo.AchievedCopies
                      ? maintenanceReportInfo.AchievedCopies
                      : 0}
                  </label>
                </Table.Cell>
                <td rowSpan="3" className="borderLightwight"></td>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.HeaderCell className="padding5 textSize9" colSpan="2">
                  {(maintenanceReportInfo && maintenanceReportInfo.total
                    ? maintenanceReportInfo.total * 0.16
                    : 0
                  ).toFixed(3)}
                </Table.HeaderCell>
                <Table.HeaderCell className="textSize9 padding5">
                  ضريبة المبيعات
                </Table.HeaderCell>
                <Table.HeaderCell
                  colSpan="2"
                  rowSpan="2"
                  className="textSize9 padding5 width20"
                  textAlign="right"
                >
                  المجموع:
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.HeaderCell className="textSize9 padding5" colSpan="2">
                  {(maintenanceReportInfo && maintenanceReportInfo.total
                    ? maintenanceReportInfo.total * 1.16
                    : 0
                  ).toFixed(3)}
                </Table.HeaderCell>
                <Table.HeaderCell className="textSize9 padding5">
                  الإجمالي
                </Table.HeaderCell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className="tableContainer invoice-signature-table">
          <Table celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="left-border padding5"></Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  className="padding5 textSize9"
                >
                  الاسم
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  className="padding5 textSize9"
                >
                  التوقيع/الختم
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row className="table-row-height">
                <Table.Cell
                  className="left-border textSize9 "
                  // style={{ width: 15, height: 70 }}
                >
                  محرر الفاتورة
                </Table.Cell>
                <Table.Cell className="padding5 textSize9">
                  {maintenanceReportInfo && maintenanceReportInfo.technicianName
                    ? maintenanceReportInfo.technicianName
                    : ""}
                </Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
              </Table.Row>

              <Table.Row className="table-row-height">
                <Table.Cell
                  className="left-border textSize9 "
                  // style={{ height: 70 }}
                >
                  العميل
                </Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
              </Table.Row>
              <Table.Row className="table-row-height">
                <Table.Cell
                  className="left-border  textSize9 "
                  // style={{ height: 70 }}
                >
                  القسم المالي
                </Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
              </Table.Row>
              <Table.Row className="table-row-height">
                <Table.Cell
                  className="left-border  textSize9"
                  // style={{ height: 70 }}
                >
                  اللوازم
                </Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
              </Table.Row>
              <Table.Row className="table-row-height">
                <Table.Cell
                  className="left-border  textSize9"
                  // style={{ height: 70 }}
                >
                  المديرية
                </Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
                <Table.Cell className="padding5"></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <div className="tescoCompInfo-text" style={{ paddingRight: 20 }}>
          <div className="tesco-text">
            شركة المعدات والتجهيزات الفنية Technical Equipment And Supplies
            Company [Tesco]
          </div>
          <div className="tescoInfo-text">
            T +96265528147 | F +96265528154 | E support@tesco.com.jo |
            www.tesco.com.jo
          </div>
        </div>
      </Segment.Group>
    </Segment.Group>
  );
}
