import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Icon,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import TableTemplate from "../../components/Table/TableTemplate";
import DeleteModal from "../../components/DeleteModal";
import getDeleteError from "../../components/helpers/getDeleteError";
import useDebounce from "../../components/helpers/useDebounce";

export default function ManageMachineParts({ location }) {
  const router = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [parts, setParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [machineParts, setMachineParts] = useState([]);
  const [machineID, setMachineID] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [machinePartID, setMachinePartID] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [machineDetails, setMachineDetails] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [machinePartsIsLoading, setMachinePartsLoading] = useState(false);
  const headers = {
    partName: resources.pages.machines.partName,
    partNumber: resources.pages.machines.partNumber,
    quantity: resources.pages.machines.quantity,
    price: resources.pages.machines.price,
  };

  const [machinePartName, setMachinePartName] = useState("");
  const [machinePartNameIsLoading, setMachinePartNameIsLoading] =
    useState(false);
  const debouncedMachinePartNameSearch = useDebounce(machinePartName, 1000);

  const onPaging = (page) => setActivePage(page);

  const fillParts = async (partNameID = null) => {
    try {
      setMachinePartNameIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 61,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machinePartName}%` },
          ...(partNameID ? { LookupID: partNameID } : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setParts(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setParts([]);
      setMachinePartNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachinePartNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(machinePartName && machinePartName.length > 1)) fillParts();
  }, [debouncedMachinePartNameSearch]);

  const fillMachinesDetails = async (machineID) => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Machine",
          attributes: ["machineId", "serialNumber", "computerNumber"],
          where: { machineId: machineID },
          include: [
            { model: "Lookup", as: "MachineType", attributes: ["LookupName"] },
            { model: "Lookup", as: "MachineModel", attributes: ["LookupName"] },
            {
              model: "Lookup",
              as: "ManufacturingCompany",
              attributes: ["LookupName"],
            },
          ],
        },
      });
      if (res && res.data && res.data.length) {
        const machine = res.data[0];
        setMachineDetails([
          {
            label: resources.pages.machines.computerNumber,
            value: machine.computerNumber,
          },
          {
            label: resources.pages.machines.serialNumber,
            value: machine.serialNumber,
          },
          {
            label: resources.pages.machines.MachineModel,
            value: machine.MachineModel
              ? machine.MachineModel.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.MachineType,
            value: machine.MachineType
              ? machine.MachineType.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.ManufacturingCompany,
            value: machine.ManufacturingCompany
              ? machine.ManufacturingCompany.LookupName
              : resources.common.notSpecified,
          },
        ]);
      } else setMachineDetails([]);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillMachineParts = async () => {
    try {
      setMachinePartsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MachinePart",
          where: { machineId: machineID },
          include: [{ model: "Lookup", attributes: ["LookupName"] }],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });
      if (res && res.data && res.data.length) {
        setMachineParts(
          res.data.map((row) => {
            return { ...row, partName: row.Lookup.LookupName };
          })
        );
        setPagination({
          ...pagination,
          total: res.total,
        });
        if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      } else setMachineParts([]);

      setMachinePartsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachinePartsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.machineID)) {
      setMachineID(location.state.machineID);
      fillMachinesDetails(location.state.machineID);
    }
  }, [location]);

  useEffect(() => {
    if (machineID && activePage) fillMachineParts();
  }, [machineID, activePage]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (Boolean(machinePartID))
        await serviceApi.service("machinePart").patch(machinePartID, {
          ...values,
          MachineID: machineID,
        });
      else
        await serviceApi
          .service("machinePart")
          .create({ ...values, MachineID: machineID });
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    setInitialValues({});
    fillMachineParts();
    setMachinePartID(null);
    setSuccessVisible(false);
  };

  const onCellClick = (row, type) => {
    setMachinePartID(row.machinePartId);

    if (type === "edit") {
      fillParts(row.PartNameID);
      setInitialValues(row);
    } else if (type === "remove") {
      setIsDelete(true);
    }
  };

  const deleteMachine = async () => {
    try {
      const res = await serviceApi.service("machinePart").remove(machinePartID);

      if (res) {
        setIsDelete(false);
        fillMachineParts();
      }
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <DeleteModal
        onConfirm={() => deleteMachine()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.machines.MachineParts}
            icon="microchip"
          />
          <Message info>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {machineDetails.map((row, idx) => (
                <div key={idx}>
                  <Icon name="info circle" />
                  <label style={{ paddingRight: 2 }}>
                    {row.label} : {row.value}
                  </label>
                </div>
              ))}
            </div>
          </Message>
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={(v) => {
                if (!v.PartNameID)
                  return { PartNameID: resources.common.errors.requiredField };
              }}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={2}>
                    <Grid.Column>
                      <p>{resources.pages.machines.partName}</p>
                      <Field name="PartNameID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            options={parts}
                            placeholder={resources.pages.machines.partName}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setMachinePartName(e.target.value.trim())
                            }
                            loading={machinePartNameIsLoading}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            className={`${
                              Boolean(parts.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.partNumber}</p>
                      <Field name="partNumber">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="th"
                            placeholder={resources.pages.machines.partNumber}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.quantity}</p>
                      <Field name="quantity">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            type="number"
                            error={meta.touched && meta.error}
                            icon="credit card alternative"
                            placeholder={resources.pages.machines.quantity}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.price}</p>
                      <Field name="price">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="dollar sign"
                            type="number"
                            placeholder={resources.pages.machines.price}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Button
                      color="blue"
                      size="large"
                      className="save-btn"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {resources.common.save}
                    </Button>
                    <Button
                      color="red"
                      size="large"
                      className="save-btn"
                      onClick={() => router.push("/machines/MachinesList")}
                    >
                      {resources.common.cancel}
                    </Button>
                  </Grid>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
          <TableTemplate
            headers={headers}
            data={machineParts}
            edit
            remove
            onCellClick={onCellClick}
            paging={pagination}
            activePage={activePage}
            onPaging={onPaging}
            isLoading={machinePartsIsLoading}
          />
        </Grid.Column>
      </Grid>
    </>
  );
}
