import L from "leaflet";
import technicianIcon from "../../../assets/images/techIcon.svg";
import clientIcon from "../../../assets/images/technology.png";
import ticketIcon from "../../../assets/images/ticketImg.svg";
import pinUrl from "../../../assets/images/map/pin.png";
import markerUrl from "../../../assets/images/map/marker.png";
import routeEnd from "../../../assets/images/routing/route-end.png";
import routeStart from "../../../assets/images/routing/route-start.png";

const getIcon = (iconUrl) => {
  return new L.Icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    iconSize:
      iconUrl === ticketIcon
        ? [30, 30]
        : iconUrl === technicianIcon
        ? [40, 30]
        : iconUrl === markerUrl
        ? [32, 28]
        : [24, 30],
    iconAnchor: [12, 30],
    popupAnchor: [0, -30],
  });
};

const TechnicianIcon = getIcon(technicianIcon);
const ClientIcon = getIcon(routeEnd);
const PinUrl = getIcon(pinUrl);
const MarkerUrl = getIcon(markerUrl);
const RouteEnd = getIcon(routeEnd);
const RouteStart = getIcon(routeStart);
const TicketIcon = getIcon(ticketIcon);

export const icons = {
  TechnicianIcon,
  ClientIcon,
  PinUrl,
  MarkerUrl,
  RouteEnd,
  RouteStart,
  TicketIcon,
};
