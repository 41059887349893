import React, { useState, useEffect } from "react";
import { Grid, Segment, Form, Button, Message } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";

import { validatePassword } from "./user.validator";
export default function ChangePassword({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);
  const router = useHistory();

  const [comesFromUserMenuList, setComesFromUserMenuList] = useState(false);

  useEffect(() => {
    if (
      Boolean(
        location &&
          location.state &&
          location.state.userId &&
          location.state.fullName
      )
    ) {
      setUser({ name: location.state.fullName, id: location.state.userId });
      if (location.state.fromUserMenuList) setComesFromUserMenuList(true);
      else setComesFromUserMenuList(false);
    }
  }, [location]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (user.id) await serviceApi.service("users").patch(+user.id, values);
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === 400) {
        setErrorMessage(resources.pages.user.errors.duplicateUser);
      }
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    if (!comesFromUserMenuList) router.replace("/settings/users/usersList");
    else router.replace("/tech/tickets");
  };

  return (
    <Grid className="form-container">
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <Grid.Column>
        <PageHeadeTitle
          content={resources.pages.user.changePassword}
          icon="key"
        />
        <Segment>
          <FinalForm
            onSubmit={onSubmit}
            validate={validatePassword}
            render={({ handleSubmit }) => (
              <Form
                className="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                loading={isSubmitting}
              >
                <Grid columns={2} stackable>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <p>
                        {resources.pages.user.fullname} :{user ? user.name : ""}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <p>{resources.pages.user.newPassword}</p>
                      <Field name="password">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="lock"
                            type="password"
                            placeholder={resources.pages.user.newPassword}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width="6">
                      <p>{resources.pages.user.confirmNewPassword}</p>
                      <Field name="confirmPassword">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="lock"
                            type="password"
                            placeholder={
                              resources.pages.user.confirmNewPassword
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  className="save-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {resources.common.save}
                </Button>
                <Button size="large" type="button" onClick={handleConfirm}>
                  {resources.common.back}
                </Button>
                {errorVisible.visible && (
                  <Message
                    negative
                    compact
                    className="error-message"
                    content={
                      errorMessage || resources.common.errors.genericServerError
                    }
                  />
                )}
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
