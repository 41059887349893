import React, { useEffect, useState } from "react";
import {
  Card,
  Menu,
  Responsive,
  Segment,
  Popup,
  Icon,
  Checkbox,
  Loader,
  Pagination,
  Modal,
  Button,
} from "semantic-ui-react";
import serviceApi from "../../api";
import { resources } from "../../assets/LocalizationResources";
import callUrl from "../../assets/images/call.svg";
import showMap from "../../assets/images/show-on-map.svg";
import doneImg from "../../assets/images/done.svg";
import ticketStatusReportImg from "../../assets/images/ticketStatusHistory.svg";
import routingImg from "../../assets/images/routing.svg";
import reportImg from "../../assets/images/report.svg";
import googleImg from "../../assets/images/routing/google-routes.svg";
import locatorImg from "../../assets/images/locator.svg";
import RoutingServiceApi from "../../api/router-service.api";
import { useHistory } from "react-router-dom";
import SearchBy from "../../components/SearchBy/SearchBy";
import { useSelector } from "react-redux";
import Moment from "moment";
import TicketActionsModal from "../../components/Tickets/TicketActionsModal";
import TicketInfoModal from "../../components/Tickets/TicketInfoModal";
import TicketsMap from "./TicketsMap";
import GeoLocationModal from "./GeoLocationModal";

export default function TechTickets() {
  const [tickets, setTickets] = useState([]);
  const router = useHistory();
  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );
  const [showClosed, setShowClosed] = useState(false);
  const [showSLA, setShowSLA] = useState(false);
  const [showLateDate, setShowLateDate] = useState(false);
  const [orderByDistance, setOrderByDistance] = useState(false);
  const [time, setTime] = useState(Moment());
  const [searching, setSearching] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [changeTicketModal, setChangeTicketModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [ticketStatus, setTicketStatus] = useState([]);
  const [ticketID, setTicketID] = useState(null);
  const [showGeoMap, setShowGeoMap] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 9, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const [openNoCoordsAlertModal, setOpenNoCoordsAlertModal] = useState(false);

  const [openGeoLocationModal, setOpenGeoLocationModal] = useState(false);
  const [branchID, setBranchID] = useState(null);

  const onPaging = (page) => setActivePage(page);

  const getOptions = async (parentID) => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: parentID,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0) {
        if (parentID == 96) {
          setTicketStatus(
            res.data
              .filter((m) => m.LookupID !== 97)
              .map((c) => ({
                key: c.LookupID,
                text: c.LookupName,
                value: c.LookupID,
              }))
          );
        }
        return res.data.map((c) => {
          return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
        });
      } else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fillSearchData = async () => {
    setSearchData({
      ticketLevel: {
        type: "ddl",
        label: resources.pages.ticket.level,
        options: await getOptions(90),
      },
      ticketID: { type: "text", label: resources.pages.ticket.ticketID },
      BranchName: {
        type: "text",
        label: resources.pages.ticket.branchName,
      },
      computerNumber: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
      reason: {
        type: "ddl",
        label: resources.pages.ticket.reason,
        options: await getOptions(29),
      },
      status: {
        type: "ddl",
        label: resources.pages.ticket.ticketStatus,
        options: await getOptions(96),
      },
    });
  };

  useEffect(() => {
    fillSearchData();
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadTickets = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Tickets",
          include: [
            {
              ...(showSLA ? { innerJoin: true } : {}),
              model: "ContractMachine",
              attributes: ["machineID", "withSLA", "dueHours"],
              include: [
                {
                  innerJoin: true,
                  model: "Machine",
                  attributes: [
                    "machineId",
                    "LastMaintenaceDate",
                    "computerNumber",
                  ],
                  ...whereSearch("computerNumber"),
                },
              ],
              where: {
                ...(searching.searchBy === "computerNumber"
                  ? { contractMachineID: { not: null } }
                  : {}),
                ...(showSLA ? { withSLA: showSLA } : {}),
              },
            },
            {
              innerJoin: true,
              model: "ClientBranch",
              attributes: [
                "BranchID",
                "BranchName",
                "longitude",
                "latitude",
                "address",
              ],
              include: [
                {
                  innerJoin: true,
                  model: "Client",
                  attributes: ["ClientName"],
                },
              ],
              ...whereLikeSearch("BranchName"),
            },
            {
              innerJoin: true,
              model: "Lookup",
              as: "status",
              attributes: ["LookupName"],
              ...(Object.keys(whereSearch("status", "LookupID")).length
                ? whereSearch("status", "LookupID")
                : showClosed
                ? {
                    where: { LookupID: 99 },
                  }
                : { where: { LookupID: { ne: 99 } } }),
            },
            {
              innerJoin: true,
              model: "Lookup",
              as: "level",
              attributes: ["LookupName"],
              ...whereSearch("ticketLevel", "LookupID"),
            },
            {
              innerJoin: true,
              model: "Lookup",
              as: "ticketType",
              attributes: ["LookupName"],
              ...whereSearch("reason", "LookupID"),
            },
          ],

          ...(!Boolean(showLateDate || orderByDistance)
            ? { limit: pagination.pageSize }
            : {}),
          ...(!Boolean(showLateDate || orderByDistance)
            ? { skip: pagination.pageSize * (activePage - 1) }
            : {}),
          where: {
            ...(Boolean(CurrentUserID !== 1) && {
              TechnicianID: CurrentTechnicianID,
            }),
            ...(Boolean(searching.searchBy === "ticketID") && {
              ticketID: { like: `%${searching.term}%` },
            }),
          },
        },
      });

      if (showLateDate && orderByDistance) {
        getDistance(res.data);
      } else if (showLateDate) {
        const data = [];
        res.data.forEach((row) => {
          if (
            (Boolean(row.ContractMachine && row.ContractMachine.dueHours) &&
              new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() +
                    row.ContractMachine.dueHours
                )) ||
            (Boolean(row.ticketDueHours) &&
              new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() + row.ticketDueHours
                ))
          ) {
            data.push({ ...row, lateForDueHours: resources.pages.ticket.late });
          }
        });
        setTickets(data);
        setIsLoading(false);
      } else if (orderByDistance) {
        getDistance(res.data);
      } else {
        const data = res.data.map((row) => {
          return {
            ...row,
            lateForDueHours: Boolean(
              row.ContractMachine && row.ContractMachine.dueHours
            )
              ? new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() +
                    row.ContractMachine.dueHours
                )
                ? resources.pages.ticket.late
                : resources.pages.ticket.notLate
              : Boolean(row.ticketDueHours) &&
                new Date().getTime() >
                  new Date(row.createdAt).setHours(
                    new Date(row.createdAt).getHours() + row.ticketDueHours
                  )
              ? resources.pages.ticket.late
              : resources.pages.ticket.notLate,
          };
        });
        setTickets(data);
        setPagination({
          ...pagination,
          total: res.total,
        });
        if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // TODO: Show error message
      console.log(error);
    }
  };

  const showOnMap = (ticket) => {
    if (
      ticket &&
      ticket.ClientBranch &&
      ticket.ClientBranch.longitude &&
      ticket.ClientBranch.latitude
    ) {
      router.push("/Tech/Home", {
        showOnMap: true,
        ticket: ticket,
      });
    } else setOpenNoCoordsAlertModal(true);
  };

  const getUserLastLocation = async () => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Users",
          attributes: ["fullName"],
          include: [
            {
              model: "Locations",
              attributes: ["longitude", "latitude"],
            },
          ],
          where: { UserId: CurrentUserID },
        },
      });
      if (res && res.data && res.data[0].Location)
        return {
          longitude: res.data[0].Location.longitude,
          latitude: res.data[0].Location.latitude,
        };
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  const getDistance = async (tickets) => {
    let ClientBranches = {};
    const userLocation = await getUserLastLocation();
    if (userLocation) {
      for (let i = 0; i < tickets.length; i++) {
        if (
          ClientBranches.length > 0 &&
          Object(ClientBranches).keys(
            (k) => tickets[i].ClientBranch.BranchID == k
          )
        ) {
          tickets[i].ClientBranch = {
            ...tickets[i].ClientBranch,
            distance:
              ClientBranches[[tickets[i].ClientBranch.BranchID]].distance,
          };
        } else if (
          Boolean(
            tickets[i].ClientBranch.longitude &&
              tickets[i].ClientBranch.latitude
          )
        ) {
          ClientBranches = {
            ...ClientBranches,
            [tickets[i].ClientBranch.BranchID]: 0,
          };
          let points = [
            userLocation,
            {
              longitude: tickets[i].ClientBranch.longitude,
              latitude: tickets[i].ClientBranch.latitude,
            },
          ];
          const res = await RoutingServiceApi.route(points);
          if (res) {
            tickets[i].ClientBranch = {
              ...tickets[i].ClientBranch,
              distance: res.data.paths[0].distance,
            };
            ClientBranches[[tickets[i].ClientBranch.BranchID]] =
              res.data.paths[0].distance;
          }
        } else continue;
      }
    }

    if (showLateDate) {
      const data = [];
      tickets.forEach((row) => {
        if (
          (Boolean(row.ContractMachine && row.ContractMachine.dueHours) &&
            new Date().getTime() >
              new Date(row.createdAt).setHours(
                new Date(row.createdAt).getHours() +
                  row.ContractMachine.dueHours
              )) ||
          (Boolean(row.ticketDueHours) &&
            new Date().getTime() >
              new Date(row.createdAt).setHours(
                new Date(row.createdAt).getHours() + row.ticketDueHours
              ))
        ) {
          data.push({ ...row, lateForDueHours: resources.pages.ticket.late });
        }
      });
      data.sort((a, b) => a.ClientBranch.distance - b.ClientBranch.distance);
      setTickets(data);
    } else {
      const data = tickets
        .map((row) => {
          return {
            ...row,
            lateForDueHours: Boolean(
              row.ContractMachine && row.ContractMachine.dueHours
            )
              ? new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() +
                    row.ContractMachine.dueHours
                )
                ? resources.pages.ticket.late
                : resources.pages.ticket.notLate
              : Boolean(row.ticketDueHours) &&
                new Date().getTime() >
                  new Date(row.createdAt).setHours(
                    new Date(row.createdAt).getHours() + row.ticketDueHours
                  )
              ? resources.pages.ticket.late
              : resources.pages.ticket.notLate,
          };
        })
        .sort((a, b) => a.ClientBranch.distance - b.ClientBranch.distance);
      setTickets(data);
    }
    setIsLoading(false);
  };

  const routingTo = async (ClientBranch) => {
    if ((ClientBranch && ClientBranch.longitude, ClientBranch.latitude)) {
      const userLocation = await getUserLastLocation();
      if (userLocation && userLocation.longitude && userLocation.latitude)
        router.push("/Tech/Home", {
          isRouting: true,
          points: [
            userLocation,
            {
              longitude: ClientBranch.longitude,
              latitude: ClientBranch.latitude,
            },
          ],
        });
    } else setOpenNoCoordsAlertModal(true);
  };

  const routingToGoogle = async (ClientBranch) => {
    if ((ClientBranch && ClientBranch.longitude, ClientBranch.latitude)) {
      const to = ClientBranch.latitude + "," + ClientBranch.longitude;
      const qs = `daddr=${to}&amp;ll=`;
      if (
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPod") != -1 ||
        navigator.platform.indexOf("iPad") != -1
      )
        window.open(`maps://maps.google.com/maps?${qs}`);
      else window.open(`http://maps.google.com/maps?${qs}`);
    } else setOpenNoCoordsAlertModal(true);
  };
  const getInterval = (e, d2) => {
    const date1 = Moment();
    const date2 = Moment(d2);
    const duration = date1.diff(date2);
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
      days = Math.floor(duration / (1000 * 60 * 60 * 24));

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return days + "." + hours + ":" + minutes;
  };
  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTickets();
  }, [
    searching,
    activePage,
    showLateDate,
    showSLA,
    showClosed,
    orderByDistance,
  ]);

  const ticketReport = (ticketID, computerNumber) => {
    router.push("/maintenanceReports", {
      ticketID: ticketID,
      computerNumber: computerNumber,
    });
  };

  const getTicketsCards = (isTablet) => {
    return (
      <>
        {isLoading ? (
          <Loader active inline="centered" />
        ) : (
          <>
            {tickets.length > 0 ? (
              <>
                <div
                  className={
                    !isTablet && Boolean(showGeoMap)
                      ? "tickets-content-container"
                      : ""
                  }
                >
                  <div
                    className={
                      !isTablet && Boolean(showGeoMap) ? "tickets-content" : ""
                    }
                  >
                    <Card.Group
                      className={
                        !isTablet && Boolean(showGeoMap)
                          ? "tickets-list-container marginZero"
                          : "tickets-list-container"
                      }
                    >
                      {tickets.map((ticket, idx) => (
                        <Card
                          key={idx}
                          className={
                            isTablet
                              ? "ticket-card-tablet"
                              : showGeoMap
                              ? "geoDesign"
                              : "ticket-card"
                          }
                        >
                          <div
                            className="time-ticket"
                            style={{
                              color:
                                ticket.statusID === 99
                                  ? "grey"
                                  : ticket.levelID === 94
                                  ? "red"
                                  : ticket.levelID === 93
                                  ? "orange"
                                  : "green",
                            }}
                          >
                            <div>
                              {ticket.statusID !== 99 && (
                                <Icon
                                  size="large"
                                  name="exclamation triangle"
                                  color={
                                    ticket.levelID === 94
                                      ? "red"
                                      : ticket.levelID === 93
                                      ? "yellow"
                                      : "green"
                                  }
                                />
                              )}
                              <label>{ticket.level.LookupName}</label>
                              <label>( {ticket.status.LookupName})</label>
                            </div>
                            {ticket.statusID !== 99 && (
                              <>
                                <label>
                                  <span style={{ marginLeft: "10px" }}>
                                    {`(${ticket.lateForDueHours})`}
                                  </span>
                                  {getInterval(time, ticket.createdAt)}
                                </label>
                              </>
                            )}
                          </div>
                          <Card.Content>
                            <img src={callUrl} className="ticket-icon" />
                            <Card.Header>
                              {ticket.ClientBranch
                                ? ticket.ClientBranch.BranchName
                                : resources.pages.ticket.notSpecifiedBranch}
                              /{ticket.ticketID}
                            </Card.Header>
                            <Card.Meta>
                              (
                              {ticket.contactName
                                ? ticket.contactName
                                : resources.common.notSpecified}
                              -
                              {ticket.contactPhone
                                ? ticket.contactPhone
                                : resources.common.notSpecified}
                              )
                            </Card.Meta>
                            <Card.Description className="cardDescriptions-container ">
                              <span className="text-redColor">
                                {resources.pages.machines.computerNumber +
                                  " : " +
                                  (ticket.ContractMachine &&
                                  ticket.ContractMachine.Machine
                                    ? ticket.ContractMachine.Machine
                                        .computerNumber
                                    : resources.common.notSpecified)}
                              </span>

                              {ticket.ClientBranch &&
                              Boolean(ticket.ClientBranch.Client) ? (
                                ticket.ClientBranch.Client.ClientName.length >
                                33 ? (
                                  <Popup
                                    content={
                                      ticket.ClientBranch.Client.ClientName
                                    }
                                    trigger={
                                      <span>
                                        {resources.pages.ticket.clientName +
                                          " : " +
                                          ticket.ClientBranch.Client.ClientName.substring(
                                            0,
                                            31
                                          ) +
                                          "..."}
                                      </span>
                                    }
                                    position="top center"
                                    size="small"
                                  />
                                ) : (
                                  <span>
                                    {resources.pages.ticket.clientName +
                                      " : " +
                                      ticket.ClientBranch.Client.ClientName}
                                  </span>
                                )
                              ) : (
                                <span>
                                  {resources.pages.ticket.clientName +
                                    " : " +
                                    resources.pages.ticket.notSpecifiedClient}
                                </span>
                              )}

                              <span>
                                {resources.pages.ticket.reason +
                                  " : " +
                                  (ticket.ticketType
                                    ? ticket.ticketType.LookupName
                                    : resources.pages.ticket
                                        .notSpecifiedProblem)}
                              </span>
                              {Boolean(ticket.notes) ? (
                                ticket.notes.length > 33 ? (
                                  <Popup
                                    content={ticket.notes}
                                    trigger={
                                      <span>
                                        {resources.pages.ticket.notes +
                                          " : " +
                                          ticket.notes.substring(0, 31) +
                                          "..."}
                                      </span>
                                    }
                                    position="top center"
                                    size="small"
                                  />
                                ) : (
                                  <span>
                                    {resources.pages.ticket.notes +
                                      " : " +
                                      ticket.notes}
                                  </span>
                                )
                              ) : (
                                <span>
                                  {resources.pages.ticket.notes +
                                    " : " +
                                    resources.common.noNotes}
                                </span>
                              )}

                              {ticket.ClientBranch &&
                              Boolean(ticket.ClientBranch.address) ? (
                                ticket.ClientBranch.address.length > 33 ? (
                                  <Popup
                                    content={ticket.ClientBranch.address}
                                    trigger={
                                      <span>
                                        {resources.pages.branch.address +
                                          " : " +
                                          ticket.ClientBranch.address.substring(
                                            0,
                                            31
                                          ) +
                                          "..."}
                                      </span>
                                    }
                                    position="top center"
                                    size="small"
                                  />
                                ) : (
                                  <span>
                                    {resources.pages.branch.address +
                                      " : " +
                                      ticket.ClientBranch.address}
                                  </span>
                                )
                              ) : (
                                <span>
                                  {resources.pages.branch.address +
                                    " : " +
                                    resources.common.notSpecified}
                                </span>
                              )}

                              <span>
                                {Boolean(orderByDistance) &&
                                  (ticket.ClientBranch.distance
                                    ? resources.pages.ticket.distance +
                                      " : " +
                                      Math.ceil(
                                        ticket.ClientBranch.distance / 1000
                                      ) +
                                      resources.pages.ticket.km
                                    : "")}
                              </span>
                            </Card.Description>
                          </Card.Content>
                          <Card.Content
                            extra
                            className={
                              isTablet
                                ? "ticket-action-tablet"
                                : "ticket-action"
                            }
                          >
                            {ticket.statusID !== 99 && (
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    if (showGeoMap) setSelectedTicket(ticket);
                                    else showOnMap(ticket);
                                  }}
                                >
                                  <Popup
                                    content={resources.pages.ticket.showOnMap}
                                    position="bottom right"
                                    trigger={
                                      <img src={showMap} className="img-icon" />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => routingTo(ticket.ClientBranch)}
                                >
                                  <Popup
                                    content={
                                      resources.pages.ticket.determineDirections
                                    }
                                    position="bottom right"
                                    trigger={
                                      <img
                                        src={routingImg}
                                        className="img-icon"
                                      />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    routingToGoogle(ticket.ClientBranch)
                                  }
                                >
                                  <Popup
                                    content={
                                      resources.pages.ticket
                                        .determineDirectionsUsingGoogle
                                    }
                                    position="bottom right"
                                    trigger={
                                      <img
                                        src={googleImg}
                                        className="img-icon"
                                      />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() =>
                                    ticketReport(
                                      ticket.ticketID,
                                      ticket.ContractMachine
                                        ? ticket.ContractMachine.Machine
                                            .computerNumber
                                        : null
                                    )
                                  }
                                >
                                  <Popup
                                    content={
                                      resources.pages.createMaintenanceReport
                                        .createMaintenanceReport
                                    }
                                    position="bottom right"
                                    trigger={
                                      <img
                                        src={reportImg}
                                        className="img-icon"
                                      />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    setTicketID(ticket.ticketID);
                                    setChangeTicketModal(true);
                                  }}
                                >
                                  <Popup
                                    content={resources.pages.ticket.takeAction}
                                    position="bottom right"
                                    trigger={
                                      <img src={doneImg} className="img-icon" />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>

                                <Menu.Item
                                  onClick={() => {
                                    setTicketID(ticket.ticketID);
                                    setOpenInfoModal(true);
                                  }}
                                >
                                  <Popup
                                    content={
                                      resources.ticketInformation
                                        .ticketStatusRecord
                                    }
                                    position="bottom right"
                                    trigger={
                                      <img
                                        src={ticketStatusReportImg}
                                        className="img-icon"
                                      />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    setBranchID(ticket.branchID);
                                    setOpenGeoLocationModal(true);
                                  }}
                                >
                                  <Popup
                                    content={
                                      resources.pages.branch.updateBranchCoords
                                    }
                                    position="bottom right"
                                    trigger={
                                      <img
                                        src={locatorImg}
                                        className="img-icon"
                                      />
                                    }
                                    color="blue"
                                  />
                                </Menu.Item>
                              </Menu>
                            )}
                          </Card.Content>
                        </Card>
                      ))}
                    </Card.Group>
                    <TicketActionsModal
                      ticketStatus={ticketStatus}
                      ticketID={ticketID}
                      isOpen={changeTicketModal}
                      closeChangeTicketModal={(reLoadTickets) => {
                        setChangeTicketModal(false);
                        if (reLoadTickets) loadTickets();
                      }}
                    />

                    {openInfoModal && (
                      <TicketInfoModal
                        ticketID={ticketID}
                        isOpen={openInfoModal}
                        closeTicketInfoModal={() => {
                          setOpenInfoModal(false);
                        }}
                      />
                    )}
                  </div>
                  {!isTablet && Boolean(showGeoMap) && (
                    <TicketsMap
                      tickets={tickets}
                      selectedTicket={selectedTicket}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="noResult-message">{resources.common.noData}</div>
            )}
          </>
        )}
      </>
    );
  };

  const SearchBoxes = ({ label, onChange, stateKey }) => {
    return (
      <div
        style={{
          padding: "3px 10px",
        }}
      >
        <Checkbox label={label} checked={stateKey} onChange={onChange} />
      </div>
    );
  };

  return (
    <div className="tickets-list-main-container">
      <SearchBy
        searchTypes={searchData}
        getSearchResults={getSearchResults}
        extraFields={
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <SearchBoxes
                label={resources.pages.ticket.showSLA}
                onChange={(evt, elm) => setShowSLA(Boolean(elm.checked))}
                stateKey={showSLA}
              />
              <SearchBoxes
                label={resources.pages.ticket.showClosed}
                onChange={(evt, elm) => setShowClosed(Boolean(elm.checked))}
                stateKey={showClosed}
              />

              <SearchBoxes
                label={resources.pages.ticket.showLate}
                onChange={(evt, elm) => {
                  setShowLateDate(Boolean(elm.checked));
                }}
                stateKey={showLateDate}
              />
              <SearchBoxes
                label={resources.pages.ticket.orderByDistance}
                onChange={(evt, elm) => {
                  setOrderByDistance(Boolean(elm.checked));
                }}
                stateKey={orderByDistance}
              />
              <Responsive minWidth={801}>
                <SearchBoxes
                  label={resources.pages.ticket.showMap}
                  onChange={(evt, elm) => {
                    setShowGeoMap(Boolean(elm.checked));
                  }}
                  stateKey={showGeoMap}
                />
              </Responsive>
            </div>
          </>
        }
      />
      <Responsive maxWidth={800}>
        {getTicketsCards(true)}
        <div className="pagination-component-container">
          {!Boolean(showLateDate || orderByDistance) &&
            Boolean(
              pagination && tickets && pagination.total > pagination.pageSize
            ) && (
              <Pagination
                activePage={activePage}
                totalPages={Math.ceil(pagination.total / pagination.pageSize)}
                onPageChange={(e, v) => onPaging(v.activePage)}
                boundaryRange={1}
              />
            )}
        </div>
      </Responsive>
      <Responsive minWidth={801}>
        <Segment>
          {getTicketsCards(false)}
          <div className="pagination-component-container">
            {!Boolean(showLateDate || orderByDistance || showGeoMap) &&
              Boolean(
                pagination && tickets && pagination.total > pagination.pageSize
              ) && (
                <Pagination
                  activePage={activePage}
                  totalPages={Math.ceil(pagination.total / pagination.pageSize)}
                  onPageChange={(e, v) => onPaging(v.activePage)}
                  boundaryRange={1}
                  className="pagination-component"
                />
              )}
          </div>
        </Segment>
      </Responsive>
      <Modal size="mini" open={openNoCoordsAlertModal}>
        <Modal.Header
          content={resources.common.warning}
          className="no-coords-header"
        />
        <Modal.Content content={resources.common.noCoords} />
        <Modal.Actions>
          <Button
            content={resources.common.close}
            onClick={() => setOpenNoCoordsAlertModal(false)}
          />
        </Modal.Actions>
      </Modal>
      <GeoLocationModal
        isOpen={openGeoLocationModal}
        branchID={branchID}
        closeGeoLocationModal={(reLoadTickets) => {
          setBranchID(null);
          setOpenGeoLocationModal(false);
          if (reLoadTickets) loadTickets();
        }}
      />
    </div>
  );
}
