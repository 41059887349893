import React, { useState, useEffect } from "react";
import "./technician-areas.scss";
import { Grid, Segment, Select, Button, Icon, Modal } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import TechAreasMap from "./TechAreasMap";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory, useLocation } from "react-router-dom";

const TechnicianAreas = () => {
  const router = useHistory();
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [userLocked, setUserLocked] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [selectedArea, setSelectedArea] = useState({ value: null });
  const [technicianAreas, setTechnicianAreas] = useState([]);
  const [technicianId, setTechnicianId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [addedAreas, setAddedAreas] = useState([]);
  const [removedAreas, setRemovedAreas] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fillAreas();
    fillUsers();
  }, []);

  useEffect(() => {
    if (
      users.length &&
      Boolean(location && location.state && location.state.technicianID)
    ) {
      setTechnicianId(location.state.technicianID);
      setUserLocked(true);
    }
  }, [users]);

  const loadTechnicianAreas = async () => {
    try {
      const techAreas = await serviceApi.service("queries-text").find({
        query: {
          type: 21,
          technicianID: location.state.technicianID,
        },
      });
      if (techAreas && techAreas.length > 0) {
        setTechnicianAreas(
          techAreas.map((c) => ({
            key: c.AreaID,
            text: c.AreaName,
            value: c.AreaID,
            geom: c.geometry,
          }))
        );
      }
    } catch (error) {
      setLoadingAreas(false);
      console.log(error);
    }
  };

  const fillUsers = async () => {
    try {
      setLoadingUsers(true);
      const res = await serviceApi.service("Technicians").find({
        query: {
          $select: ["technicianId", "technicianName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setUsers(
          res.data.map((c) => ({
            key: c.technicianId,
            text: c.technicianName,
            value: c.technicianId,
          }))
        );

      setLoadingUsers(false);
    } catch (error) {
      // TODO: Show error message
      setLoadingUsers(false);
      console.log(error);
    }
  };

  const fillAreas = async () => {
    try {
      setLoadingAreas(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          type: 20,
        },
      });
      if (res && res.length > 0)
        setAreas(
          res.map((c) => ({
            key: c.areaID,
            text: c.areaName,
            value: c.areaID,
            geom: c.geometry,
          }))
        );

      if (Boolean(location && location.state && location.state.technicianID)) {
        loadTechnicianAreas();
      }
      setLoadingAreas(false);
    } catch (error) {
      // TODO: Show error message
      setLoadingAreas(false);
      console.log(error);
    }
  };

  const setUserId = (e, v) => {
    setTechnicianId(v.value);
  };

  const onAreaSelected = (e, v) => {
    setSelectedArea(areas.find((a) => a.key === v.value));
  };

  const onAddAreaClicked = () => {
    const areaIdx = removedAreas.indexOf(selectedArea);
    if (areaIdx !== -1) {
      setAddedAreas([...addedAreas, selectedArea]);
      setTechnicianAreas([...technicianAreas, selectedArea]);
      setAreas(areas.filter((item) => item !== selectedArea));
      setRemovedAreas(removedAreas.filter((item) => item !== selectedArea));
    } else {
      setAddedAreas([...addedAreas, selectedArea]);
      setTechnicianAreas([...technicianAreas, selectedArea]);
      setAreas(areas.filter((item) => item !== selectedArea));
    }
  };

  const onLayerRemoveClicked = (area) => () => {
    const areaIdx = addedAreas.indexOf(area);
    if (areaIdx !== -1) {
      setRemovedAreas([...removedAreas, area]);
      setTechnicianAreas(technicianAreas.filter((item) => item !== area));
      setAreas([...areas, area]);
      setAddedAreas(addedAreas.filter((item) => item !== area));
    } else {
      setRemovedAreas([...removedAreas, area]);
      setTechnicianAreas(technicianAreas.filter((item) => item !== area));
      setAreas([...areas, area]);
    }
  };

  const submit = async () => {
    try {
      setIsSubmitting(true);
      if (userLocked) {
        for (const area of removedAreas) {
          await serviceApi.service("TechnicianArea").remove(null, {
            query: {
              TechnicianID: technicianId,
              AreaID: area.value,
            },
          });
        }
      }

      for (const area of addedAreas) {
        await serviceApi.service("TechnicianArea").create({
          TechnicianID: technicianId,
          AreaID: area.value,
        });
      }
      setIsSubmitting(false);
      setOpenModal(false);
      router.goBack();
    } catch (err) {
      // TODO: Handle save errors
      setIsSubmitting(false);
      console.log(err);
    }
  };

  return (
    <Grid className="technician-areas-container">
      <SuccessModal
        visible={successVisible}
        onConfirm={() => {
          router.goBack();
        }}
        header={resources.common.savedSuccessfully}
      />

      <Modal
        size={"small"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>
          <label>{resources.pages.technicianAreas.addRemoveAreas}</label>
        </Modal.Header>
        <Modal.Content>
          {removedAreas.length > 0 && (
            <>
              <p>{resources.pages.technicianAreas.doYouWantToremove}</p>
              {removedAreas.map((item) => (
                <p key={item.key} style={{ color: "#dc3545" }}>
                  {item.text}
                </p>
              ))}
            </>
          )}
          {addedAreas.length > 0 && (
            <>
              <p>{resources.pages.technicianAreas.doYouWantToAdd} </p>
              {addedAreas.map((item) => (
                <p key={item.key} style={{ color: "#198754" }}>
                  {item.text}
                </p>
              ))}
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={submit} loading={isSubmitting}>
            {resources.common.confirm}
          </Button>
          <Button
            negative
            onClick={() => setOpenModal(false)}
            style={{ marginBottom: "1rem" }}
            loading={isSubmitting}
          >
            {resources.common.cancel}
          </Button>
          <br />
          <label style={{ color: "#dc3545" }}>
            *عند حذف/إضافة فني الى منطقه سيتم تعديل جميع الالات في المنطقة
            القديمة الى الفني الجديد
          </label>
        </Modal.Actions>
      </Modal>
      <Grid.Column>
        <PageHeaderTitle
          content={resources.pages.technicianAreas.pageTitle}
          icon="users"
        />
        <Segment>
          <div className="tech-areas-splitter">
            <div className="info-div">
              <label>{resources.pages.technicianAreas.technician}</label>
              <Select
                name="technicianId"
                loading={loadingUsers}
                options={users}
                fluid
                placeholder={
                  loadingUsers
                    ? resources.common.pleaseWait
                    : resources.common.pleaseSelect
                }
                noResultsMessage={resources.common.noData}
                search={users.length > 0}
                onChange={setUserId}
                value={location?.state?.technicianID ?? technicianId}
                disabled={userLocked}
              />
              <label>{resources.pages.technicianAreas.addArea}</label>
              <div className="area-select">
                <Select
                  loading={loadingAreas}
                  name="areaID"
                  options={areas}
                  placeholder={
                    loadingAreas
                      ? resources.common.pleaseWait
                      : resources.common.pleaseSelect
                  }
                  noResultsMessage={resources.common.noData}
                  search={areas.length > 0}
                  value={selectedArea.value}
                  onChange={onAreaSelected}
                />
                <Button
                  icon="plus"
                  color="blue"
                  onClick={onAddAreaClicked}
                  disabled={!Boolean(selectedArea.value)}
                />
              </div>
              {Boolean(technicianAreas.length) && (
                <h4>{resources.pages.technicianAreas.areas}</h4>
              )}
              <div className="areas-list">
                {technicianAreas.map((item) => (
                  <div key={item.key} className="area-list-item">
                    <div className="item-title">
                      <Icon name="map pin" />
                      <h4>{item.text}</h4>
                    </div>
                    <Icon
                      name="trash alternate"
                      color="red"
                      onClick={onLayerRemoveClicked(item)}
                    />
                  </div>
                ))}
              </div>
              <Button
                content={resources.common.save}
                color="blue"
                size="large"
                fluid
                className="areas-save-btn"
                disabled={technicianAreas.length === 0 || isSubmitting}
                loading={isSubmitting}
                onClick={() =>
                  removedAreas.length || addedAreas.length
                    ? setOpenModal(true)
                    : router.goBack()
                }
              />
            </div>
            <div className="map-div">
              <TechAreasMap
                areas={technicianAreas}
                selectedArea={selectedArea}
              />
            </div>
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default TechnicianAreas;
