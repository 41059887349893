import React, { useState, useEffect, useRef } from "react";
import "./AddContract.scss";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  GridColumn,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import DTPicker from "../../components/DTPicker/DTPicker";
import useDebounce from "../../components/helpers/useDebounce";
import axios from "axios";
import { host } from "../../api/index";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";

export default function AddContract({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [contractTypes, setContractTypes] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [contract, setContract] = useState({});
  const [clients, setClients] = useState([]);
  const [editedContractNumber, setEditedContractNumber] = useState("0");
  const [isOtherPayMethod, setIsOtherPayMethod] = useState(false);
  const [isPayedContract, setIsPayedContract] = useState(false);
  const [endDateValue, setEndDateValue] = useState(null);
  const [contractID, setContractID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const contractPdfFile = useRef(null);

  const [clientName, setClientName] = useState("");
  const [hasPDF, setHasPDF] = useState(false);
  const [clientNameIsLoading, setClientNameIsLoading] = useState(false);
  const debouncedClientNameSearch = useDebounce(clientName, 1000);

  const isUniqueContractNumber = async (contractNumber) => {
    try {
      if (editedContractNumber == contract.contractNumber) return true;
      const res = await serviceApi.service("contract").find({
        query: {
          contractNumber: contractNumber,
          $select: ["contractNumber"],
          $limit: 1,
        },
      });
      if (res && res.data && res.data.length > 0) {
        setIsSubmitting(false);
        setErrorMessage(resources.pages.addContract.duplicateContractNumber);
        setErrorVisible({
          visible: true,
          timeout: setTimeout(() => {
            setErrorVisible({ visible: false, timeout: null });
            setErrorMessage(null);
          }, 10000),
        });
        return false;
      } else return true;
    } catch (error) {
      setIsSubmitting(false);
      setErrorMessage(resources.common.errors.genericServerError);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 10000),
      });
      console.log(error);
      return false;
    }
  };

  const router = useHistory();

  const getClients = async (clientID = null) => {
    try {
      setClientNameIsLoading(true);
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["clientId", "clientName"],
          clientName: { $like: `%${clientName}%` },
          ...(clientID ? { clientId: clientID } : {}),
          $limit: 50,
        },
      });

      if (Boolean(res && res.data && res.data.length > 0))
        setClients(
          res.data.map((c) => {
            return { key: c.clientId, text: c.clientName, value: c.clientId };
          })
        );
      else setClients([]);

      setClientNameIsLoading(false);
    } catch (e) {
      setClientNameIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(clientName && clientName.length > 2)) getClients();
  }, [debouncedClientNameSearch]);

  const getContractTypes = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 38,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (Boolean(res && res.data && res.data.length > 0))
        setContractTypes(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setContractTypes([]);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 49,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (Boolean(res && res.data && res.data.length > 0)) {
        setPaymentMethods(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      } else setPaymentMethods([]);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getContractTypes();
    getPaymentMethods();
  }, []);

  const getContractInfo = async (contractID) => {
    try {
      const res = await serviceApi.service("contract").get(contractID);
      if (Boolean(res)) {
        if (res.PaymentMethodID === 59) setIsOtherPayMethod(true);
        if (Boolean(res.ContractTypeID === 40)) setIsPayedContract(true);
        getClients(res.ClientID);
        setContract({ ...res, contractId: contractID });
        setEditedContractNumber(res.contractNumber);
        setEndDateValue(res.endDate);
        setHasPDF(res.hasPDF);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (Boolean(location && location.state && location.state.contractID))
      getContractInfo(+location.state.contractID);
  }, [location]);

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!values.contractNumber) {
      errors.contractNumber = requiredField;
    }
    if (!values.ContractTypeID) {
      errors.ContractTypeID = requiredField;
    }
    if (!values.ClientID) {
      errors.ClientID = requiredField;
    }
    if (!values.contractValue && isPayedContract) {
      errors.contractValue = requiredField;
    }
    if (!values.PaymentMethodID && isPayedContract) {
      errors.PaymentMethodID = requiredField;
    }
    if (
      values.ContractTypeID === 40 &&
      values.PaymentMethodID === 59 &&
      !values.otherPayMethodNote
    ) {
      errors.otherPayMethodNote = requiredField;
    }
    // if (!values.dueHours) errors.dueHours = requiredField;
    return errors;
  };

  const uploadContractPdf = async (id) => {
    if (!!contractPdfFile.current) {
      const formData = new FormData();
      formData.append("attachements", contractPdfFile.current, `${id}.pdf`);
      await axios.post(`${host}/uploadFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    values.hasPDF = !!contractPdfFile.current;
    if (!isOtherPayMethod) values.otherPayMethodNote = null;
    try {
      if (await isUniqueContractNumber(values.contractNumber)) {
        values.endDate = endDateValue;
        if (Boolean(location && location.state && location.state.contractID)) {
          await serviceApi
            .service("contract")
            .update(+location.state.contractID, values);
          await uploadContractPdf(location.state.contractID);
          setContractID(+location.state.contractID);
        } else {
          let params = values;
          if (!values.startDate) params = { ...params, startDate: new Date() };
          if (!values.endDate)
            params = { ...params, endDate: new Date(Date.now() + 31449000000) };
          const res = await serviceApi.service("contract").create(params);

          if (res) {
            setContractID(res.contractId);
            await uploadContractPdf(res.contractId);
          }
        }
        setIsSubmitting(false);
        setSuccessVisible(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 100000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    router.push("/contracts/manageContractMachine", {
      contractId: contractID,
    });
  };

  const changeHandler = (e) => {
    const file = e.target.files[0];
    contractPdfFile.current = file;
  };

  const openContractPDF = () => {
    window.open(`${host}/uploads/${location.state.contractID}.pdf`);
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <Grid className="add-contract-page-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeadeTitle
            content={
              Boolean(location && location.state && location.state.contractID)
                ? resources.pages.editContract.pageTitle
                : resources.pages.addContract.pageTitle
            }
            icon="file alternate"
          />
          <Segment>
            <FinalForm
              initialValues={contract}
              validate={checkValidation}
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <Form
                  className="contract-form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={3} stackable>
                    <Grid.Column>
                      <p>{resources.pages.addContract.contractNumber}</p>
                      <Field name="contractNumber">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="sort numeric up"
                            placeholder={
                              resources.pages.addContract.contractNumber
                            }
                            disabled={
                              !isAuthorized(Perm.editContractNumberAndType) &&
                              Boolean(
                                location &&
                                  location.state &&
                                  location.state.contractID
                              )
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.addContract.contractType}</p>
                      <Field name="ContractTypeID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            name={input.name}
                            options={contractTypes}
                            placeholder={
                              resources.pages.addContract.contractType
                            }
                            noResultsMessage={resources.common.noData}
                            search={Boolean(contractTypes.length)}
                            onChange={(e, { value }) => {
                              input.onChange(value);
                              if (value === 40) setIsPayedContract(true);
                              else setIsPayedContract(false);
                            }}
                            error={meta.touched && meta.error}
                            disabled={
                              !isAuthorized(Perm.editContractNumberAndType) &&
                              Boolean(
                                location &&
                                  location.state &&
                                  location.state.contractID
                              )
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.addContract.client}</p>
                      <Field name="ClientID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            name={input.name}
                            options={clients}
                            placeholder={resources.pages.ticket.clientName}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setClientName(e.target.value.trim())
                            }
                            loading={clientNameIsLoading}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            disabled={Boolean(
                              location &&
                                location.state &&
                                location.state.contractID
                            )}
                            className={`${
                              Boolean(clients.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    {/* <Grid.Column>
                    <p>{resources.pages.addContract.contractDate}</p>
                    <Field name="contractDate">
                      {({ input, meta }) => (
                        <DTPicker
                          selectedValue={input.value}
                          isTrigger
                          setSelectedValue={(v) => input.onChange(v)}
                          format="date"
                        />
                      )}
                    </Field>
                  </Grid.Column> */}
                    <Grid.Column>
                      <p>{resources.pages.addContract.startDate}</p>
                      <Field name="startDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={input.value}
                            isTrigger
                            setSelectedValue={(v) => {
                              input.onChange(v);
                              setEndDateValue(
                                new Date(new Date(v).getTime() + 31449000000)
                              );
                            }}
                            format="date"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.addContract.endDate}</p>
                      <Field name="endDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={endDateValue}
                            isTrigger
                            setSelectedValue={(v) => {
                              input.onChange(v);
                              setEndDateValue(v);
                            }}
                            format="date"
                            defaultDate={new Date(Date.now() + 31449000000)} // 31449000000 = 364 days
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    {isPayedContract && (
                      <>
                        <Grid.Column>
                          <p>{resources.pages.addContract.contractValue}</p>
                          <Field name="contractValue">
                            {({ input, meta }) => (
                              <Form.Input
                                {...input}
                                error={meta.touched && meta.error}
                                fluid
                                icon="dollar sign"
                                type="number"
                                placeholder={
                                  resources.pages.addContract.contractValue
                                }
                              />
                            )}
                          </Field>
                        </Grid.Column>

                        <Grid.Column>
                          <p>{resources.pages.addContract.PaymentMethod}</p>
                          <Field name="PaymentMethodID">
                            {({ input, meta }) => (
                              <Form.Select
                                {...input}
                                name={input.name}
                                options={paymentMethods}
                                placeholder={
                                  resources.pages.addContract.PaymentMethod
                                }
                                noResultsMessage={resources.common.noData}
                                search={Boolean(paymentMethods.length)}
                                onChange={(e, { value }) => {
                                  input.onChange(value);
                                  if (value === 59) setIsOtherPayMethod(true);
                                  else setIsOtherPayMethod(false);
                                }}
                                error={meta.touched && meta.error}
                              />
                            )}
                          </Field>
                          {isOtherPayMethod && (
                            <Field name="otherPayMethodNote">
                              {({ input, meta }) => (
                                <Form.TextArea
                                  {...input}
                                  error={meta.touched && meta.error}
                                  placeholder={
                                    resources.pages.addContract
                                      .otherPayMethodInfo
                                  }
                                />
                              )}
                            </Field>
                          )}
                        </Grid.Column>
                      </>
                    )}
                    <Grid.Column>
                      <p>{resources.pages.addContract.notes}</p>
                      <Field name="notes">
                        {({ input, meta }) => (
                          <Form.Input
                            name={input.name}
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="sticky note"
                            placeholder={resources.pages.addContract.notes}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.common.contractPDFDownload}</p>
                      {hasPDF ? (
                        <>
                          <Field name="contractMachineImage">
                            {({ input, meta }) => (
                              <Form.Input
                                type="file"
                                onChange={changeHandler}
                              />
                            )}
                          </Field>
                          <div
                            style={{ color: "green", cursor: "pointer" }}
                            onClick={openContractPDF}
                          >
                            *يوجد صورة للعقد
                          </div>
                        </>
                      ) : (
                        <Field name="contractMachineImage">
                          {({ input, meta }) => (
                            <Form.Input type="file" onChange={changeHandler} />
                          )}
                        </Field>
                      )}
                    </Grid.Column>

                    {/* <Grid.Column>
                    <p>{`${resources.pages.addContract.dueHours} ( ${resources.common.inHours} )`}</p>
                    <Field name="dueHours">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          placeholder={resources.pages.addContract.dueHours}
                          type="number"
                        />
                      )}
                    </Field>
                  </Grid.Column> */}
                    {/* <Grid.Column>
                    <p>{resources.pages.addContract.SLA}</p>
                    <Field name="withSLA">
                      {({ input, meta }) => (
                        <Form.Checkbox
                          onChange={(e, v) => input.onChange(v.checked)}
                          checked={Boolean(input.value)}
                          name={input.name}
                        />
                      )}
                    </Field>
                  </Grid.Column> */}
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    style={{ marginTop: "10px" }}
                    className="contract-save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {Boolean(
                      location && location.state && location.state.contractID
                    )
                      ? resources.pages.editContract.edit
                      : resources.common.save}
                  </Button>
                  <Button
                    size="large"
                    type="button"
                    onClick={() => router.replace("/contracts")}
                  >
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
