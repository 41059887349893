import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import DTPicker from "../../components/DTPicker/DTPicker";
import { resources } from "../../assets/LocalizationResources";
import serviceApi from "../../api";
import moment from "moment";

const ManageContractPayments = ({
  isOpen,
  contractID,
  closeManageContractPayments,
  contractPaymentId,
  initialValues,
}) => {
  const [isDeserved, setIsDeserved] = useState(false);
  const [contractPaymentID, setContractPaymentID] = useState(-1);
  const [
    contractPaymentInitialValues,
    setContractPaymentInitialValues,
  ] = useState(null);

  const onSubmit = async (values) => {
    if (contractID) {
      if (contractPaymentID !== -1) {
        if (!values.deservedDate && Boolean(values.isDeserved))
          values = { ...values, deservedDate: moment() };
        await serviceApi.service("ContractPayments").patch(contractPaymentID, {
          ...values,
          ContractID: contractID,
        });
      } else {
        let params = values;
        if (!values.paymentDate) params = { ...params, paymentDate: moment() };
        if (!values.deservedDate && Boolean(values.isDeserved))
          params = { ...params, deservedDate: moment() };
        await serviceApi
          .service("ContractPayments")
          .create({ ...params, ContractID: contractID });
      }

      closeManageContractPayments(true);
    }
  };

  const validator = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!values.paymentAmount) errors.paymentAmount = requiredField;
    if (isDeserved && !values.invoiceNumber)
      errors.invoiceNumber = requiredField;
    return errors;
  };

  useEffect(() => {
    if (initialValues) {
      setContractPaymentID(contractPaymentId);
      setContractPaymentInitialValues(initialValues);
      setIsDeserved(initialValues.isDeserved);
    } else {
      setContractPaymentID(-1);
      setContractPaymentInitialValues(null);
    }
  }, [initialValues]);

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validator}
      initialValues={contractPaymentInitialValues}
      render={({ handleSubmit }) => (
        <Modal
          open={isOpen}
          size="tiny"
          className="manage-contract-payments-container"
        >
          <Modal.Header>
            {initialValues
              ? resources.pages.contractPayment.editPayment
              : resources.pages.contractPayment.addPayment}
          </Modal.Header>
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <Grid columns={2} stackable>
                <Grid.Column>
                  <p>{resources.pages.contractPayment.paymentDate}</p>
                  <Field name="paymentDate">
                    {({ input, meta }) => (
                      <DTPicker
                        selectedValue={input.value}
                        isTrigger
                        setSelectedValue={(v) => input.onChange(v)}
                        format="date"
                      />
                    )}
                  </Field>
                </Grid.Column>
                <Grid.Column>
                  <p>{resources.pages.contractPayment.paymentAmount}</p>
                  <Field name="paymentAmount">
                    {({ input, meta }) => (
                      <Form.Input
                        {...input}
                        error={meta.touched && meta.error}
                        fluid
                        icon="money"
                        type="number"
                        placeholder={
                          resources.pages.contractPayment.paymentAmount
                        }
                        autoComplete="off"
                      />
                    )}
                  </Field>
                </Grid.Column>
                {isDeserved ? (
                  <>
                    <Grid.Column>
                      <p>{resources.pages.contractPayment.deservedDate}</p>
                      <Field name="deservedDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={input.value}
                            isTrigger
                            setSelectedValue={(v) => input.onChange(v)}
                            format="date"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.contractPayment.invoiceNum}</p>
                      <Field name="invoiceNumber">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="money"
                            placeholder={
                              resources.pages.contractPayment.invoiceNum
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </>
                ) : null}
                <Grid.Column>
                  <Field name="isDeserved">
                    {({ input, meta }) => (
                      <Form.Checkbox
                        onChange={(e, v) => {
                          input.onChange(v.checked);
                          setIsDeserved(v.checked);
                        }}
                        checked={Boolean(input.value)}
                        label={resources.pages.contractPayment.isDeserved}
                        name={input.name}
                      />
                    )}
                  </Field>
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content={
                initialValues ? resources.common.edit : resources.common.add
              }
              positive
              onClick={handleSubmit}
            />
            <Button
              negative
              onClick={() => closeManageContractPayments(false)}
              content={resources.common.cancel}
            />
          </Modal.Actions>
        </Modal>
      )}
    />
  );
};

export default ManageContractPayments;
