import React, { useState, useRef, useEffect } from "react";
import { Button, Loader, Modal } from "semantic-ui-react";
import MapLayers from "../../components/Map/MapLayers";
import { Map, Marker } from "react-leaflet";
import { icons } from "../../components/Map/helpers/MapIcons";
import serviceApi from "../../api";
import { resources } from "../../assets/LocalizationResources";
import "./tech-home.scss";

const GeoLocationModal = ({ isOpen, branchID, closeGeoLocationModal }) => {
  const mapRef = useRef(null);
  const [
    updatingBranchLocationIsLoading,
    setUpdatingBranchLocationIsLoading,
  ] = useState(false);

  const [pointCoords, setPointsCoords] = useState({
    lat: null,
    lng: null,
  });

  const getBranchLongLat = () => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((p) =>
        setPointsCoords({
          lat: p.coords.latitude,
          lng: p.coords.longitude,
        })
      );
    else {
      alert("Geolocation is not supported by this browser.");
      closeGeoLocationModal();
    }
  };

  useEffect(() => {
    if (Boolean(branchID && isOpen)) getBranchLongLat();
  }, [branchID]);

  useEffect(() => {
    if (Boolean(pointCoords && mapRef && mapRef.current)) {
      mapRef.current.leafletElement.flyTo(
        {
          lat: pointCoords.lat,
          lng: pointCoords.lng,
        },
        16
      );
    }
  }, [pointCoords]);

  const updateBranchLongLat = async () => {
    try {
      setUpdatingBranchLocationIsLoading(true);
      await serviceApi.service("ClientBranch").patch(branchID, {
        longitude: pointCoords.lng,
        latitude: pointCoords.lat,
      });

      setUpdatingBranchLocationIsLoading(false);
      closeGeoLocationModal(true);
    } catch (e) {
      setUpdatingBranchLocationIsLoading(false);
      console.log(e);
    }
  };

  return (
    <Modal open={isOpen}>
      <Modal.Header>{resources.pages.branch.updateBranchCoords}</Modal.Header>
      <Modal.Content className="geo-locator-content">
        <Map
          animate
          ref={mapRef}
          center={[30.935654, 35.5565]}
          zoom={7}
          className="geo-locator-map"
        >
          <MapLayers />
          {Boolean(pointCoords && pointCoords.lng && pointCoords.lat) && (
            <Marker position={pointCoords} icon={icons.MarkerUrl} />
          )}
        </Map>
      </Modal.Content>
      <Modal.Actions>
        <div className="geo-locator-actions">
          {updatingBranchLocationIsLoading ? (
            <Loader
              active={updatingBranchLocationIsLoading}
              inverted
              inline="centered"
            />
          ) : (
            <Button
              content={resources.common.save}
              onClick={() => updateBranchLongLat()}
              color="blue"
            />
          )}
          <Button
            content={resources.common.cancel}
            onClick={() => {
              setPointsCoords({});
              closeGeoLocationModal();
            }}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default GeoLocationModal;
