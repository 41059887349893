import React from "react";
import "./PrintOutFilter.scss";

const PrintOutFilter = ({ model, client }) => {
  return (
    <div className="Print-out-filter-container">
      {Boolean(model) ? (
        <div>
          <p> موديل &emsp; {model} </p>
        </div>
      ) : (
        " "
      )}
      {Boolean(client) ? (
        <div>
          <p> العميل &emsp; {client} </p>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default PrintOutFilter;
