import React, { useState } from "react";
import tescoNewLogo from "./../../assets/images/tescoNewLogo.png";
import {
  Segment,
  Table,
  Button,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableHeader,
  Checkbox,
} from "semantic-ui-react";
import "../MaintenanceReport/MaintenanceReport.scss";
import { resources } from "../../assets/LocalizationResources";
import SuccessModal from "../../components/SuccessModal";
import "./MaintenanceReport.scss";
import "./MaintenanceReportZebraPrintView.scss";

export default function MaintenanceReportZebraPageView({
  maintenanceReportInfo,
  isITMaintenanceReport,
  setShowZebraMaintenanceReportPage,
}) {
  const [shouldHeaderRepeatedInEachPage, setShouldHeaderRepeatedInEachPage] =
    useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const printReport = async () => {
    window.print();
  };

  const TableHeaderContent = () => {
    return (
      <TableRow>
        <Table.Cell>
          <div className=" gap-1 flex-col maintenance-logo  margin-y-5">
            <div className="flex-1 flex-row algin-center justify-between ">
              <img src={tescoNewLogo} />
              {/* <Checkbox
                className="no-print"
                checked={shouldHeaderRepeatedInEachPage}
                onChange={(e) =>
                  setShouldHeaderRepeatedInEachPage(
                    !shouldHeaderRepeatedInEachPage
                  )
                }
                label="طباعة الترويسة مع كل صفحة"
              /> */}
              <Segment.Group className="flex-col gap-1 no-border no-box-shadow margin-y-5">
                <Button
                  color="blue"
                  icon="print"
                  className="print-btn no-print "
                  id="printIcon"
                  onClick={printReport}
                  labelPosition="right"
                  content={
                    resources.pages.maintenanceReport.printMaintenanceReport
                  }
                />
                <Button
                  icon="redo"
                  color="red"
                  className="no-print"
                  onClick={() => setShowZebraMaintenanceReportPage(false)}
                  labelPosition="right"
                  content={
                    resources.pages.maintenanceReport.goToA4MaintenanceReport
                  }
                />
              </Segment.Group>
            </div>

            <Segment className="maintenance-head">
              <label>
                {resources.pages.maintenanceReport.maintenanceReport}
              </label>
            </Segment>
          </div>
        </Table.Cell>
      </TableRow>
    );
  };

  return (
    <div className="zibra-container">
      <Table className="no-border no-box-shadow">
        <TableHeader>
          {shouldHeaderRepeatedInEachPage ? <TableHeaderContent /> : ""}
        </TableHeader>
        <TableBody>
          {!shouldHeaderRepeatedInEachPage ? <TableHeaderContent /> : ""}
          <TableRow>
            <TableCell>
              <div className="main-container">
                <Segment.Group className="maintenance-segment">
                  <Segment.Group horizontal className="borderLightwight">
                    <Segment className="left-border segment-fifty">
                      <label className="textSize9">
                        {`${resources.pages.maintenanceReport.reportNumber} : `}
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.MaintenanceReportID
                          ? maintenanceReportInfo.MaintenanceReportID
                          : " - "}
                      </label>
                    </Segment>
                    <Segment className="segment-fifty">
                      <label className="textSize9">
                        {`${resources.pages.maintenanceReport.date} : `}
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.createdAt
                          ? maintenanceReportInfo.createdAt
                          : " - "}
                      </label>
                    </Segment>
                  </Segment.Group>

                  <div className="tableContainer margin-y-5">
                    <Table
                      celled
                      className="textSize9 no-box-shadow "
                      unstackable
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.Cell
                            textAlign="center"
                            className="left-border"
                          >
                            {resources.pages.machines.computerNumber}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {resources.pages.machines.MachineType}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {resources.pages.machines.MachineModel}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {resources.pages.machines.serialNumber}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell
                            textAlign="center"
                            className="left-border"
                          >
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.ComputerNumber
                              ? maintenanceReportInfo.ComputerNumber
                              : " - "}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.MachineType
                              ? maintenanceReportInfo.MachineType
                              : " - "}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.MachineModel
                              ? maintenanceReportInfo.MachineModel
                              : " - "}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.SerialNumber
                              ? maintenanceReportInfo.SerialNumber
                              : " - "}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>

                  <Segment className="padding5 no-border no-box-shadow">
                    <label className="textSize9">
                      {`${resources.pages.clients.clientName} : `}
                      {maintenanceReportInfo && maintenanceReportInfo.ClientName
                        ? maintenanceReportInfo.ClientName
                        : " - "}
                    </label>
                  </Segment>
                  <Segment.Group
                    horizontal
                    className="no-border no-box-shadow pb-7px"
                  >
                    <Segment className="left-border segment-fifty no-box-shadow p-x-2px">
                      <label className="textSize9">
                        {`${resources.pages.maintenanceReport.siteArea} : `}
                        {maintenanceReportInfo && maintenanceReportInfo.AreaName
                          ? maintenanceReportInfo.AreaName
                          : " - "}
                      </label>
                    </Segment>
                    <Segment className="segment-fifty no-box-shadow p-x-2px">
                      <label className="textSize9">
                        {`${resources.pages.maintenanceReport.area} : `}
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.BranchName
                          ? maintenanceReportInfo.BranchName
                          : " - "}
                      </label>
                    </Segment>
                  </Segment.Group>

                  <Segment.Group horizontal className="no-box-shadow">
                    <Segment className="left-border segment-fifty no-box-shadow p-x-2px">
                      <label className="textSize9">
                        {`${resources.pages.maintenanceReport.visitType} :    ${
                          maintenanceReportInfo &&
                          maintenanceReportInfo.VisitType
                            ? maintenanceReportInfo.VisitType
                            : resources.common.notSpecified
                        }`}
                      </label>
                    </Segment>
                    <Segment className="segment-fifty no-box-shadow p-x-2px">
                      <label className="textSize9">
                        {`${
                          resources.pages.maintenanceReport.maintenanceType
                        } :   ${
                          maintenanceReportInfo &&
                          maintenanceReportInfo.MaintenanceType
                            ? maintenanceReportInfo.MaintenanceType
                            : resources.common.notSpecified
                        }`}
                      </label>
                    </Segment>
                  </Segment.Group>

                  {!isITMaintenanceReport && (
                    <div className="tableContainer margin-y-5">
                      <Table
                        celled
                        className="textSize9  no-box-shadow"
                        unstackable
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              width="5"
                              textAlign="center"
                              className="left-border"
                            ></Table.Cell>
                            <Table.Cell textAlign="center">
                              {
                                resources.pages.maintenanceReport
                                  .blackAndWhiteCounter
                              }
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {
                                resources.pages.maintenanceReport
                                  .colorfulCounter
                              }
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {
                                resources.pages.maintenanceReport
                                  .previousCounterRead
                              }
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.unColoredPreviousCounters
                                ? maintenanceReportInfo.unColoredPreviousCounters
                                : "0"}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.coloredPreviousCounters
                                ? maintenanceReportInfo.coloredPreviousCounters
                                : "0"}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {
                                resources.pages.maintenanceReport
                                  .currentCounterRead
                              }
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.unColoredCurrentCounters
                                ? maintenanceReportInfo.unColoredCurrentCounters
                                : " - "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.coloredCurrentCounters
                                ? maintenanceReportInfo.coloredCurrentCounters
                                : " - "}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {resources.pages.maintenanceReport.acheivedCopies}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.unColoredAchievedCopies
                                ? maintenanceReportInfo.unColoredAchievedCopies
                                : " 0 "}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.coloredAchievedCopies
                                ? maintenanceReportInfo.coloredAchievedCopies
                                : " 0 "}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                  )}

                  <Segment.Group horizontal className="no-box-shadow m-t-10px">
                    <Segment className="left-border segment-fifty no-box-shadow p-x-2px">
                      <label className=" textSize9">
                        {`${resources.pages.maintenanceReport.startHour} : `}
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.startDate
                          ? maintenanceReportInfo.startDate
                          : " - "}
                      </label>
                    </Segment>
                    <Segment className="segment-fifty no-box-shadow p-x-2px">
                      <label className=" textSize9">
                        {`${resources.pages.maintenanceReport.endHour} : `}
                        {maintenanceReportInfo && maintenanceReportInfo.endDate
                          ? maintenanceReportInfo.endDate
                          : " - "}
                      </label>
                    </Segment>
                  </Segment.Group>

                  <div className="tableContainer ">
                    <label className=" textSize9">{`${resources.pages.maintenanceReport.partsMaterialTable} : `}</label>
                    <Table celled unstackable className="no-box-shadow">
                      <Table.Header>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">Name</Table.Cell>
                          <Table.Cell>QTY</Table.Cell>
                          <Table.Cell>FC</Table.Cell>
                          <Table.Cell>ACT</Table.Cell>
                          <Table.Cell>Description</Table.Cell>
                          <Table.Cell>PartNumber</Table.Cell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {maintenanceReportInfo.parts.map((item, idx) => (
                          <Table.Row key={idx}>
                            <Table.Cell className="left-border">
                              {item.partName}
                            </Table.Cell>
                            <Table.Cell>{item.Quantity}</Table.Cell>
                            <Table.Cell>{item.FC}</Table.Cell>
                            <Table.Cell>{item.ACT}</Table.Cell>
                            <Table.Cell>{item.Description}</Table.Cell>
                            <Table.Cell>{item.PartNumber}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>

                  <Segment className="padding5 borderLightwight textSize9 no-box-shadow no-border">
                    {resources.pages.maintenanceReport.machineReportNeeds}
                  </Segment>

                  <div className="tableContainer margin-y-10 page-break-inside">
                    <Table celled unstackable>
                      <Table.Header>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">
                            {resources.pages.maintenanceReport.issue}
                          </Table.Cell>
                          <Table.Cell>
                            {resources.pages.maintenanceReport.reason}
                          </Table.Cell>
                          <Table.Cell>
                            {resources.pages.maintenanceReport.procedure}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.Damage
                              ? maintenanceReportInfo.Damage
                              : " - "}
                          </Table.Cell>
                          <Table.Cell>
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.Causes
                              ? maintenanceReportInfo.Causes
                              : " - "}
                          </Table.Cell>
                          <Table.Cell>
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.Action
                              ? maintenanceReportInfo.Action
                              : " - "}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>

                  <div
                    style={{
                      marginBottom: "5px",
                    }}
                    className="tableContainer"
                  >
                    <Table celled unstackable className="page-break-inside">
                      <Table.Header>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">
                            {resources.pages.maintenanceReport.technicianNotes}
                          </Table.Cell>
                          <Table.Cell>
                            {resources.pages.maintenanceReport.userNotes}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.TechnicianNotes
                              ? maintenanceReportInfo.TechnicianNotes
                              : " - "}
                          </Table.Cell>
                          <Table.Cell rowSpan="2">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.ClientNotes
                              ? maintenanceReportInfo.ClientNotes
                              : " - "}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row className="textSize9">
                          <Table.Cell className="left-border">
                            <div>
                              {`${resources.pages.maintenanceReport.technicianName} : `}
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.TechnicianName
                                ? maintenanceReportInfo.TechnicianName
                                : " - "}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>

                  <Segment>
                    <div className="textSize9">
                      <li>{resources.pages.maintenanceReport.costReport}</li>
                      <li>
                        {resources.pages.maintenanceReport.reportValidity}
                      </li>
                      <li>
                        {resources.pages.maintenanceReport.machineReceived}
                      </li>
                    </div>
                  </Segment>
                  <Segment className="padding5 textSize9">
                    {`${resources.pages.maintenanceReport.employeeName} : `}
                    {maintenanceReportInfo &&
                    maintenanceReportInfo.ClientEmployeeName
                      ? maintenanceReportInfo.ClientEmployeeName
                      : " - "}
                  </Segment>
                  <Segment className="padding5 textSize9">
                    {`${resources.pages.maintenanceReport.job} : `}
                    {maintenanceReportInfo &&
                    maintenanceReportInfo.ClientEmployeeJob
                      ? maintenanceReportInfo.ClientEmployeeJob
                      : " - "}
                  </Segment>
                  <Segment className="padding5 textSize9">
                    <div className="client-signature-img-container">
                      {`${resources.pages.maintenanceReport.stampAndSignature} : `}
                      {maintenanceReportInfo &&
                        maintenanceReportInfo.ClientSignature && (
                          <img src={maintenanceReportInfo.ClientSignature} />
                        )}
                    </div>
                  </Segment>
                  <Segment className="padding5 textSize9">
                    {`${resources.pages.maintenanceReport.reportStatus} : `}
                    {maintenanceReportInfo &&
                    maintenanceReportInfo.DeliverStatus
                      ? maintenanceReportInfo.DeliverStatus
                      : " - "}
                  </Segment>
                </Segment.Group>
                <SuccessModal
                  visible={openSuccessModal}
                  onConfirm={() => setOpenSuccessModal(false)}
                  header={resources.common.sentSuccessfully}
                />
              </div>
              <footer className="footer-style ta-left">
                <div className="flex-row align-center">
                  <div className=" flex-col ">
                    <p>Smart Business Solutions since 1975</p>
                    <p>tesco.com.jo</p>
                  </div>
                  <div className="flex-1 justify-center flex-row ">
                    <div className="flex-col">
                      <p>T +962 6 552 8147</p>
                      <p>F +962 6 552 8154</p>
                      <p>sales@tesco.com.jo</p>
                    </div>
                  </div>
                  <div className=" flex-col">
                    <p>Technical Equipment & Supplies Company [Tesco]</p>
                    <p>شركة المعدات والتجهيزات الفنية</p>
                    <p>P.O. Box 2147 Amman 11181 Jordan</p>
                  </div>
                </div>
              </footer>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
