import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Input,
  Message,
  Modal,
  Segment,
  Select,
} from "semantic-ui-react";
import serviceApi from "../../../api";
import { resources } from "../../../assets/LocalizationResources";
import useDebounce from "../../../components/helpers/useDebounce";

const AddMachinePartsModal = ({
  machineId,
  isOpen,
  closeModal,
  addNewMachinePart,
  machineSelectedParts,
  isReportHavePartInvoice,
}) => {
  const [machinePartsList, setMachinePartsList] = useState([]);
  const [itemDescLoading, setItemDescLoading] = useState(false);

  const [parts, setParts] = useState([]);
  const [isMachinePartSelectedModalOpen, setIsMachinePartSelectedModalOpen] =
    useState(false);
  const [itemInfoFilterAlphaSearch, setItemInfoFilterAlphaSearch] =
    useState("");
  const debouncedItemInfoSearch = useDebounce(itemInfoFilterAlphaSearch, 1000);
  const [machineSelectedPartDetails, setMachineSelectedPartDetails] = useState({
    quantity: 1,
    PartNumber: null,
    description: "",
    MachinePartID: -1,
    MachinePartName: "",
    ACT: "",
    ACTID: -1,
    FC: "",
    FCID: -1,
    machinePartTypeId: -1,
  });
  const [openIsEmptyMessage, setOpenIsEmptyMessage] = useState(false);
  const [machinePartsListIsLoading, setMachinePartsListIsLoading] =
    useState(false);

  useEffect(() => {
    if (
      Boolean(itemInfoFilterAlphaSearch && itemInfoFilterAlphaSearch.length > 2)
    ) {
      getPartsNumberAndDescription();
    }
  }, [debouncedItemInfoSearch]);

  const getPartsNumberAndDescription = async () => {
    if (
      machineSelectedPartDetails.description ||
      machineSelectedPartDetails.PartNumber
    ) {
      setMachineSelectedPartDetails({
        ...machineSelectedPartDetails,
        description: "",
        PartNumber: null,
      });
    }

    try {
      setItemDescLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          filter: itemInfoFilterAlphaSearch,
          limit: 20,
          type: 30,
        },
      });
      if (res && res.length > 0) {
        setParts(
          res.map((part) => ({
            key: part.ItemNumber,
            text: `${part.ItemDesc}<~>${part.ItemNumber}`,
            value: `${part.ItemDesc}<~>${part.ItemNumber}`,
          }))
        );
      } else {
        setParts([]);
      }

      setItemDescLoading(false);
    } catch (e) {
      setItemDescLoading(false);
      console.log(e);
    }
  };
  const ACTTypes = [
    {
      text: "بحاجة N",
      value: 11,
    },
    {
      text: "تبديل R",
      value: 12,
    },
  ];

  const FCTypes = [
    {
      text: "تلف",
      value: 15,
    },
    {
      text: "إنتهاء",
      value: 16,
    },
    {
      text: "كسر",
      value: 17,
    },
    {
      text: "حرق",
      value: 1755,
    },
    {
      text: "نقص",
      value: 18,
    },
  ];

  const getMachinePartsList = async () => {
    try {
      setMachinePartsListIsLoading(true);
      const machineParts = await serviceApi.service("queries").find({
        query: {
          model: "MachinePart",
          attributes: ["MachinePartID", "PartNameID", "PartNumber"],
          include: [
            {
              model: "Lookup",
              attributes: ["LookupName", "LookupID"],
            },
          ],
          where: { MachineID: machineId },
        },
      });

      if (isReportHavePartInvoice === 2) {
        const filteredMachineParts = machineParts.data.filter(
          (part) => part.Lookup.LookupID !== 172 /*آخرى*/
        );
        setMachinePartsList(
          filteredMachineParts.map((filteredParts) => ({
            key: filteredParts.MachinePartID,
            text: filteredParts.Lookup.LookupName,
            value: filteredParts.MachinePartID,
            code: filteredParts.PartNumber,
            machinePartTypeId: filteredParts.Lookup.LookupID,
          }))
        );
      } else if (
        machineParts &&
        machineParts.data &&
        machineParts.data.length > 0
      )
        setMachinePartsList(
          machineParts.data.map((machinePart) => ({
            key: machinePart.MachinePartID,
            text: machinePart.Lookup.LookupName,
            value: machinePart.MachinePartID,
            code: machinePart.PartNumber,
            machinePartTypeId: machinePart.Lookup.LookupID,
          }))
        );
      else setMachinePartsList([]);

      setMachinePartsListIsLoading(false);
    } catch (error) {
      setMachinePartsList([]);
      setMachinePartsListIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (machineId) getMachinePartsList();
  }, [machineId]);

  const radioButtons = (item, type) => {
    if (type === "ACT")
      return item.map((row, idx) => (
        <Checkbox
          radio
          key={idx}
          name="ACTRadioGroup"
          label={row.text}
          value={row.value}
          checked={
            row.value === machineSelectedPartDetails.ACTID ? true : false
          }
          onChange={(e, elm) =>
            setMachineSelectedPartDetails({
              ...machineSelectedPartDetails,
              ACTID: +elm.value,
              ACT: elm.label + "",
            })
          }
        />
      ));
    else
      return item.map((row, idx) => (
        <Checkbox
          radio
          key={idx}
          name="FCRadioGroup"
          label={row.text}
          value={row.value}
          checked={row.value === machineSelectedPartDetails.FCID ? true : false}
          onChange={(e, elm) =>
            setMachineSelectedPartDetails({
              ...machineSelectedPartDetails,
              FCID: +elm.value,
              FC: elm.label + "",
            })
          }
        />
      ));
  };

  const checkFieldsAreEmpty = () => {
    for (var key in machineSelectedPartDetails) {
      if (
        isReportHavePartInvoice == 1 &&
        (key === "PartNumber" || key === "description")
      ) {
        continue;
      }

      if (
        Boolean(
          machineSelectedPartDetails[key] === null ||
            machineSelectedPartDetails[key] === ""
        )
      )
        return true;
    }

    return false;
  };

  const onSaveMachinePart = () => {
    if (
      machineSelectedParts.some(
        (item) =>
          item.PartNumber === machineSelectedPartDetails.PartNumber &&
          item.PartNumber !== null &&
          machineSelectedPartDetails.PartNumber !== null
      )
    ) {
      setIsMachinePartSelectedModalOpen(true);
      return;
    }

    const fieldValidation = checkFieldsAreEmpty();
    if (fieldValidation) {
      setOpenIsEmptyMessage(fieldValidation);
      return;
    } else {
      addNewMachinePart(machineSelectedPartDetails);
      setMachineSelectedPartDetails({
        quantity: 0,
        PartNumber: null,
        description: "",
        MachinePartID: -1,
        MachinePartName: "",
        ACT: "",
        ACTID: -1,
        FC: "",
        FCID: -1,
      });
      setOpenIsEmptyMessage(false);
    }
  };

  const handleSearchChange = useCallback(
    (e, data) => {
      setItemInfoFilterAlphaSearch(data.searchQuery);
    },
    [parts, machineSelectedPartDetails]
  );
  return (
    <Modal open={isOpen} size="tiny">
      <Modal.Header>
        {resources.pages.maintenanceReport.addNewItem}
      </Modal.Header>
      <Modal.Content>
        <div className="machine-parts-modal-content">
          <div className="machine-parts-field-container">
            <label>{`${resources.pages.maintenanceReport.item} : `}</label>
            <Select
              options={machinePartsList}
              placeholder={resources.pages.maintenanceReport.pleaseChoosePart}
              onChange={(e, { value, options }) => {
                const result = options.find((t) => t.key === value);
                if (result)
                  setMachineSelectedPartDetails({
                    ...machineSelectedPartDetails,
                    MachinePartID: +value,
                    MachinePartName: result.text,
                    machinePartTypeId: result.machinePartTypeId,
                  });
              }}
              search={Boolean(machinePartsList.length > 0)}
              loading={machinePartsListIsLoading}
              style={{ width: "100%" }}
              className="machine-parts-field-input"
            />
          </div>
          {isReportHavePartInvoice === 1 &&
          machineSelectedPartDetails.machinePartTypeId === 172 ? (
            <>
              <div className="machine-parts-field-container">
                <label>{`${resources.pages.machines.partNumber} : `}</label>
                <Input
                  placeholder={`${resources.pages.machines.partNumber} : `}
                  value={machineSelectedPartDetails.PartNumber}
                  onChange={(e) => {
                    const partNumber = e.target.value;
                    setMachineSelectedPartDetails({
                      ...machineSelectedPartDetails,
                      PartNumber: partNumber,
                    });
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="machine-parts-field-container">
                <label>{`${resources.pages.machines.partName} : `}</label>
                <Input
                  placeholder={`${resources.pages.machines.partName} : `}
                  value={machineSelectedPartDetails.description}
                  onChange={(e) => {
                    const description = e.target.value;
                    setMachineSelectedPartDetails({
                      ...machineSelectedPartDetails,
                      description: description,
                    });
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </>
          ) : (
            <div className="machine-parts-field-container">
              <label>{`${resources.pages.machines.part} : `}</label>
              <Select
                options={parts}
                placeholder={"Parts"}
                value={
                  machineSelectedPartDetails.description &&
                  machineSelectedPartDetails.PartNumber
                    ? `${machineSelectedPartDetails.description}<~>${machineSelectedPartDetails.PartNumber}`
                    : ""
                }
                onSearchChange={handleSearchChange}
                onChange={(e, { value }) => {
                  if (value) {
                    const [partDescription, partNumber] = value.split("<~>");
                    setMachineSelectedPartDetails({
                      ...machineSelectedPartDetails,
                      description: partDescription,
                      PartNumber: partNumber,
                    });
                  } else {
                    setMachineSelectedPartDetails({
                      ...machineSelectedPartDetails,
                      description: "",
                      PartNumber: "",
                    });
                  }
                }}
                search={true}
                loading={itemDescLoading}
                fluid
                className="machine-parts-field-input"
              />
            </div>
          )}
          <div className="machine-parts-field-container">
            <label>{`${resources.pages.maintenanceReport.quantity} : `}</label>
            <Input
              placeholder={resources.pages.maintenanceReport.quantity}
              type="number"
              onChange={(e) => {
                const quantity = +e.target.value;
                if (quantity > 0) {
                  setMachineSelectedPartDetails({
                    ...machineSelectedPartDetails,
                    quantity,
                  });
                }
              }}
              min="1"
              value={`${machineSelectedPartDetails.quantity}`}
              style={{ width: "100%" }}
              className="machine-parts-field-input "
            />
          </div>
          <div className="machine-parts-field-container">
            <label>ACT : </label>
            <div className="machine-parts-field-input checkBoxes">
              {radioButtons(ACTTypes, "ACT")}
            </div>
          </div>
          <div className="machine-parts-field-container">
            <label>FC : </label>
            <div className="machine-parts-field-input checkBoxes">
              {radioButtons(FCTypes, "FC")}
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="button"
          className="modal-button"
          onClick={onSaveMachinePart}
          content={resources.pages.maintenanceReport.add}
          positive
        />
        <Button
          type="button"
          className="modal-button"
          onClick={() => {
            setOpenIsEmptyMessage(false);
            closeModal();
          }}
          content={resources.pages.maintenanceReport.cancel}
          negative
        />
        {(openIsEmptyMessage || isMachinePartSelectedModalOpen) && (
          <Segment.Group style={{ boxShadow: "none", border: "none" }}>
            {openIsEmptyMessage && (
              <Message
                negative
                compact
                content={
                  machineSelectedPartDetails.machinePartTypeId === 172
                    ? resources.pages.maintenanceReport
                        .onlyPartNumberAndPartDescriptionOptional
                    : isReportHavePartInvoice === 1
                    ? resources.pages.maintenanceReport
                        .onlyPartNumberAndPartDetailsOptional
                    : resources.pages.branch.fillAllFieldsError
                }
              />
            )}
            {isMachinePartSelectedModalOpen && (
              <Message
                negative
                compact
                content={
                  resources.pages.maintenanceReport.pleaseSelectAnotherItem
                }
              />
            )}
          </Segment.Group>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default AddMachinePartsModal;
