import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import DTPicker from "../../components/DTPicker/DTPicker";
import moment from "moment";

export default function ManageLeave({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [userLeavesTypes, setUserLeavesTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const router = useHistory();

  const fillUsers = async () => {
    try {
      const res = await serviceApi.service("users").find({
        query: {
          $select: ["userid", "fullname"],
          $limit: 100,
          isActive: 1,
        },
      });

      if (res && res.data && res.data.length > 0)
        setUsers(
          res.data.map((c) => {
            return { key: c.userid, text: c.fullname, value: c.userid };
          })
        );
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillUserLeavesTypes = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 135,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setUserLeavesTypes(
          res.data.map((leave) => {
            return {
              key: leave.LookupID,
              text: leave.LookupName,
              value: leave.LookupID,
            };
          })
        );
      else setUserLeavesTypes([]);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fillUsers();
    fillUserLeavesTypes();
    if (!Boolean(location && location.state && location.state.leaveID))
      setIsLoading(false);
  }, []);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (initialValues && initialValues.leaveId) {
        await serviceApi
          .service("UserLeave")
          .patch(+initialValues.leaveId, values);
      } else {
        let params = values;
        if (Boolean(location && location.state && location.state.userId))
          params = { ...params, UserID: location.state.userId };
        if (!values.from) params = { ...params, from: moment() };
        if (!values.to) params = { ...params, to: moment() };

        await serviceApi.service("UserLeave").create(params);
      }
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const fillEditData = async () => {
    try {
      const res = await serviceApi
        .service("UserLeave")
        .get(+location.state.leaveID);
      if (res) {
        setInitialValues({
          ...res,
          from: moment(res.from),
          to: moment(res.to),
        });
        setIsLoading(false);
      }
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state))
      if (Boolean(location.state.leaveID)) fillEditData();
      else if (Boolean(location.state.userId))
        setInitialValues({ ...initialValues, UserID: location.state.userId });
      else setInitialValues(null);
  }, [location]);

  const handleConfirm = () => {
    router.replace("/settings/Leave/LeaveList");
  };

  const validator = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;

    if (
      !values.UserID &&
      !Boolean(location && location.state && location.state.userId)
    )
      errors.UserID = requiredField;
    if (!values.UsersLeavesID) errors.UsersLeavesID = requiredField;
    // if (!values.notes) errors.notes = requiredField;

    return errors;
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              initialValues && initialValues.leaveId
                ? resources.pages.leave.editLeave
                : resources.pages.leave.addLeave
            }
            icon="time"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              validate={validator}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid stackable>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.user.fullname}</p>
                        <Field name="UserID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={users}
                              placeholder={resources.pages.user.fullname}
                              noResultsMessage={resources.common.noData}
                              search={users.length > 0}
                              onChange={(e, { value }) => input.onChange(value)}
                              error={meta.touched && meta.error}
                              disabled={Boolean(
                                location &&
                                  location.state &&
                                  Boolean(
                                    location.state.leaveID ||
                                      location.state.userId
                                  )
                              )}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.common.from}</p>
                        <Field name="from">
                          {({ input, meta }) => (
                            <DTPicker
                              selectedValue={input.value}
                              isTrigger
                              setSelectedValue={(v) => input.onChange(v)}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.common.to}</p>
                        <Field name="to">
                          {({ input, meta }) => (
                            <DTPicker
                              selectedValue={input.value}
                              isTrigger
                              setSelectedValue={(v) => input.onChange(v)}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.user.leavesType}</p>
                        <Field name="UsersLeavesID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              name={input.name}
                              options={userLeavesTypes}
                              placeholder={resources.pages.user.leavesType}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(userLeavesTypes.length)}
                              onChange={(e, { value }) => {
                                input.onChange(value);
                              }}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.common.notes}</p>
                        <Field name="notes">
                          {({ input, meta }) => (
                            <Form.TextArea
                              {...input}
                              error={meta.touched && meta.error}
                              placeholder={resources.common.notes}
                              icon="sticky note outline"
                              onChange={(e, { value }) => input.onChange(value)}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button size="large" type="button" onClick={handleConfirm}>
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
