import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import DTPicker from "../../components/DTPicker/DTPicker";
import moment from "moment";
import useDebounce from "../../components/helpers/useDebounce";

export default function ManageMachine({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineModels, setMachineModels] = useState([]);
  const [manufacturingCompanies, setManufacturingCompanies] = useState([]);
  const [sellers, setSellers] = useState([]);
  //const [technicians, setTechnicians] = useState([]);
  const [machineID, setMachineID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState(0);
  const [computerNumber, setComputerNumber] = useState("");
  //const [modelIsNotEditable, setModelIsNotEditable] = useState(false);
  const [warrantyEndDate, setWarrantyEnd] = useState("");
  const [computerNumberIsLoading, setComputerNumberIsLoading] = useState(false);

  const router = useHistory();

  const [machineTypeName, setMachineTypeName] = useState("");
  const [machineTypeNameIsLoading, setMachineTypeNameIsLoading] =
    useState(false);
  const debouncedMachineTypeSearch = useDebounce(machineTypeName, 1000);

  const [machineModelName, setMachineModelName] = useState("");
  const [machineModelNameIsLoading, setMachineModelNameIsLoading] =
    useState(false);
  const debouncedMachineModelSearch = useDebounce(machineModelName, 1000);

  const [machineManufacturingCompanyName, setMachineManufacturingCompanyName] =
    useState("");
  const [
    machineManufacturingCompanyNameIsLoading,
    setMachineManufacturingCompanyNameIsLoading,
  ] = useState(false);
  const debouncedManufacturingCompanySearch = useDebounce(
    machineManufacturingCompanyName,
    1000
  );

  const [machineSellerName, setMachineSellerName] = useState("");
  const [machineSellerIsLoading, setMachineSellerIsLoading] = useState(false);
  const debouncedSellerSearch = useDebounce(machineSellerName, 1000);

  const getComputerNumber = async () => {
    try {
      setComputerNumberIsLoading(true);
      const res = await serviceApi.service("machine").find({
        query: {
          $select: ["machineId"],
          $sort: {
            machineId: -1,
          },
          $limit: 1,
        },
      });

      if (res && res.data && res.data.length > 0)
        setComputerNumber(res.data[0].machineId + 1);
      else setComputerNumber("");
      setComputerNumberIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setComputerNumberIsLoading(false);
      console.log(error);
    }
  };

  const numberOfModelMachines = async (modelID) => {
    const res = await serviceApi.service("machine").find({
      query: {
        MachineModelID: modelID,
        $select: ["CardNo"],
        $limit: 1,
      },
    });

    if (modelID) {
      let newCardNumber = res.data[0].CardNo;
      setCardNumber(++newCardNumber);
    }
  };

  const fillMachineTypes = async (machineTypeID = null) => {
    try {
      setMachineTypeNameIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 19,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machineTypeName}%` },
          ...(machineTypeID ? { LookupID: machineTypeID } : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setMachineTypes(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setMachineTypes([]);
      setMachineTypeNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachineTypeNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(machineTypeName && machineTypeName.length > 2))
      fillMachineTypes();
  }, [debouncedMachineTypeSearch]);

  const fillSellers = async (sellerID = null) => {
    try {
      setMachineSellerIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 148,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machineSellerName}%` },
          ...(sellerID ? { LookupID: sellerID } : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setSellers(
          res.data.map((s) => {
            return {
              key: s.LookupID,
              text: s.LookupName,
              value: s.LookupID,
            };
          })
        );
      else setSellers([]);

      setMachineSellerIsLoading(false);
    } catch (error) {
      console.log(error);
      setMachineSellerIsLoading(false);
    }
  };
  useEffect(() => {
    if (Boolean(machineSellerName && machineSellerName.length > 2))
      fillSellers();
  }, [debouncedSellerSearch]);

  const fillMachineModels = async (machineModelID = null) => {
    try {
      setMachineModelNameIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 22,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machineModelName}%` },
          ...(machineModelID ? { LookupID: machineModelID } : {}),
          $limit: 25,
        },
      });
      if (res && res.data && res.data.length > 0)
        setMachineModels(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setMachineModels([]);
      setMachineModelNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachineModelNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(machineModelName && machineModelName.length > 2))
      fillMachineModels();
  }, [debouncedMachineModelSearch]);

  const fillManufacturingCompanies = async (manufacturingCompanyID = null) => {
    try {
      setMachineManufacturingCompanyNameIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 46,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machineManufacturingCompanyName}%` },
          ...(manufacturingCompanyID
            ? { LookupID: manufacturingCompanyID }
            : {}),
          $limit: 25,
        },
      });
      if (res && res.data && res.data.length > 0)
        setManufacturingCompanies(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setManufacturingCompanies([]);
      setMachineManufacturingCompanyNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachineManufacturingCompanyNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      Boolean(
        machineManufacturingCompanyName &&
          machineManufacturingCompanyName.length > 2
      )
    )
      fillManufacturingCompanies();
  }, [debouncedManufacturingCompanySearch]);

  const getMachineInfo = async (machineID) => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("machine").get(machineID);

      if (res) {
        fillMachineTypes(res.MachineTypeID);
        fillMachineModels(res.MachineModelID);
        fillManufacturingCompanies(res.ManufacturingCompanyID);
        fillSellers(res.SellerID);

        setCardNumber(res.cardNo);
        setInitialValues(res);
        setComputerNumber(res.computerNumber);
        setWarrantyEnd(res.warrantyEndDate);
        //if (res.MachineModelID) setModelIsNotEditable(true);
      } else {
        setCardNumber(0);
        setInitialValues(null);
        setWarrantyEnd(null);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.machineID))
      getMachineInfo(+location.state.machineID);
    else getComputerNumber();
  }, [location]);

  const isUnique = async (serialNumber) => {
    try {
      const res = await serviceApi.service("machine").find({
        query: {
          serialNumber: serialNumber,
          ...(initialValues && +initialValues.machineId
            ? { machineId: { $ne: +initialValues.machineId } }
            : {}),
          $select: ["machineId"],
          $limit: 1,
        },
      });
      if (res && res.data && res.data.length > 0) {
        setIsSubmitting(false);
        setErrorMessage(resources.pages.machines.error.duplicateMachine);
        setErrorVisible({
          visible: true,
          timeout: setTimeout(() => {
            setErrorVisible({ visible: false, timeout: null });
            setErrorMessage(null);
          }, 5000),
        });
        return false;
      } else return true;
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
    return true;
  };
  const onSubmit = async (values) => {
    setIsSubmitting(true);
    values.cardNo = cardNumber;
    values.computerNumber = computerNumber;
    try {
      if (await isUnique(values.serialNumber)) {
        values.warrantyEndDate = warrantyEndDate;
        if (Boolean(initialValues) && Boolean(initialValues.machineId)) {
          await serviceApi
            .service("machine")
            .patch(+initialValues.machineId, values);
          setMachineID(values.machineId);
        } else {
          let params = values;
          if (!values.invoiceDate)
            params = { ...params, invoiceDate: moment() };
          if (!values.warrantyEndDate)
            params = {
              ...params,
              warrantyEndDate: new Date(Date.now() + 31449000000),
            };
          if (!values.firstInstallationDate)
            params = { ...params, firstInstallationDate: moment() };
          await serviceApi
            .service("queries-text")
            .create({
              values: params,
            })
            .then((res) => {
              if (res) setMachineID(res.MachineID);
            });
        }
        setIsSubmitting(false);
        setSuccessVisible(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    router.push("/machines/ManageMachineParts", {
      machineID: machineID,
    });
  };

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!values.MachineTypeID) {
      errors.MachineTypeID = requiredField;
    }
    if (!values.MachineModelID) {
      errors.MachineModelID = requiredField;
    }
    if (!values.serialNumber) {
      errors.serialNumber = requiredField;
    }
    if (!values.ManufacturingCompanyID) {
      errors.ManufacturingCompanyID = requiredField;
    }
    if (!values.maintenanceDays) {
      errors.maintenanceDays = requiredField;
    }
    // if (!values.TechnicianID) {
    //   errors.TechnicianID = requiredField;
    // }
    return errors;
  };
  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              Boolean(initialValues)
                ? resources.pages.machines.editMachine
                : resources.pages.machines.addMachine
            }
            icon="computer"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={checkValidation}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={3} stackable>
                    <Grid.Column>
                      <p>{resources.pages.machines.computerNumber}</p>
                      <Form.Input
                        name="computerNumber"
                        value={computerNumber}
                        icon="computer"
                        readOnly
                        autoComplete="off"
                        loading={computerNumberIsLoading}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.MachineType}</p>
                      <Field name="MachineTypeID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            options={machineTypes}
                            placeholder={resources.pages.machines.MachineType}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setMachineTypeName(e.target.value.trim())
                            }
                            loading={machineTypeNameIsLoading}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            // disabled={Boolean(
                            //   location &&
                            //     location.state &&
                            //     location.state.machineID
                            // )}
                            clearable
                            className={`${
                              Boolean(machineTypes.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.MachineModel}</p>
                      <Field name="MachineModelID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            options={machineModels}
                            placeholder={resources.pages.machines.MachineModel}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setMachineModelName(e.target.value.trim())
                            }
                            loading={machineModelNameIsLoading}
                            onChange={(e, { value }) => {
                              input.onChange(value);

                              numberOfModelMachines(value);
                            }}
                            error={meta.touched && meta.error}
                            // disabled={modelIsNotEditable}
                            clearable
                            className={`${
                              Boolean(machineModels.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.serialNumber}</p>
                      <Field name="serialNumber">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="barcode"
                            placeholder={resources.pages.machines.serialNumber}
                            autoComplete="off"
                            // disabled={Boolean(
                            //   location &&
                            //     location.state &&
                            //     location.state.machineID
                            // )}
                            clearable="true"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.ManufacturingCompany}</p>
                      <Field name="ManufacturingCompanyID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            options={manufacturingCompanies}
                            placeholder={
                              resources.pages.machines.ManufacturingCompany
                            }
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setMachineManufacturingCompanyName(
                                e.target.value.trim()
                              )
                            }
                            loading={machineManufacturingCompanyNameIsLoading}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            // disabled={Boolean(
                            //   location &&
                            //     location.state &&
                            //     location.state.machineID
                            // )}
                            clearable
                            className={`${
                              Boolean(manufacturingCompanies.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.cardNo}</p>
                      <Field name="cardNo">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            readOnly
                            icon="credit card"
                            value={cardNumber}
                            onChange={(e, { value }) => input.onChange(value)}
                            placeholder={resources.pages.machines.cardNo}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.invoiceNumber}</p>
                      <Field name="invoiceNumber">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="credit card alternative"
                            placeholder={resources.pages.machines.invoiceNumber}
                            clearable="true"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.invoiceDate}</p>
                      <Field name="invoiceDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={input.value}
                            isTrigger
                            setSelectedValue={(v) => {
                              input.onChange(v);
                              setWarrantyEnd(
                                new Date(new Date(v).getTime() + 31449000000)
                              );
                            }}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.invoiceValue}</p>
                      <Field name="invoiceValue">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            icon="dollar sign"
                            type="number"
                            placeholder={resources.pages.machines.invoiceValue}
                            clearable="true"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.firstInstallationDate}</p>
                      <Field name="firstInstallationDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={input.value}
                            isTrigger
                            setSelectedValue={(v) => input.onChange(v)}
                            format="date"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.warrantyEndDate}</p>
                      <Field name="warrantyEndDate">
                        {({ input, meta }) => (
                          <DTPicker
                            selectedValue={warrantyEndDate}
                            isTrigger
                            setSelectedValue={(v) => {
                              input.onChange(v);
                              setWarrantyEnd(v);
                            }}
                            format="date"
                            defaultDate={new Date(Date.now() + 31449000000)}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.seller}</p>
                      <Field name="SellerID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            options={sellers}
                            onSearchChange={(e) =>
                              setMachineSellerName(e.target.value.trim())
                            }
                            loading={machineSellerIsLoading}
                            placeholder={resources.pages.machines.seller}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            className={`${
                              Boolean(sellers.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                            clearable
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>
                        {resources.pages.addContractMachine.maintenanceDays}
                      </p>
                      <Field name="maintenanceDays">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="calendar alternate"
                            type="number"
                            placeholder={
                              resources.pages.addContractMachine.maintenanceDays
                            }
                            clearable="true"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.machines.notes}</p>
                      <Field name="notes">
                        {({ input, meta }) => (
                          <Form.TextArea
                            {...input}
                            error={meta.touched && meta.error}
                            icon="sticky note"
                            placeholder={resources.pages.machines.notes}
                            clearable="true"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button
                    size="large"
                    type="button"
                    onClick={() => router.replace("/machines/machinesList")}
                  >
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
