import React, { useState, useEffect } from "react";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory, useLocation } from "react-router-dom";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";

export default function ManageTablets() {
  const router = useHistory();
  const location = useLocation();

  const [successVisible, setSuccessVisible] = useState(false);
  const [notSuccessfulVisible, setNotSuccessfulVisible] = useState(false);
  const [isIMEINotEditable, setIsIMEINotEditable] = useState(false);

  const [users, setUsers] = useState([]);
  const [tablet, setTablet] = useState({
    AssociatedUserID: -1,
    tabletID: -1,
    IMEI: "",
    SIMCard: "",
  });

  const usersList = async () => {
    try {
      const res = await serviceApi.service("users").find({
        query: {
          $select: ["userid", "fullname"],
          $limit: 100,
        },
      });

      if (res.data.length > 0) {
        setUsers([
          {
            key: -1,
            text: resources.pages.tablet.notAssociatedWithaUser,
            value: -1,
          },
          ...res.data.map((item) => {
            return {
              key: item.userid,
              text: item.fullname,
              value: item.userid,
            };
          }),
        ]);
      } else setUsers([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTabletDetails = async (tabletID) => {
    try {
      const res = await serviceApi.service("tablet").get(tabletID);

      if (res) {
        setTablet({
          ...res,
          tabletID: tabletID,
        });

        if (res.IMEI) setIsIMEINotEditable(true);
      } else
        setTablet({
          AssociatedUserID: -1,
          tabletID: -1,
          IMEI: "",
          SIMCard: "",
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    usersList();
  }, []);

  useEffect(() => {
    if (Boolean(location && location.state && location.state.tabletID))
      getTabletDetails(+location.state.tabletID);
  }, [location]);

  const validator = (v) => {
    const requiredField = resources.common.errors.requiredField;
    const errors = {};
    if (!v.AssociatedUserID) errors.AssociatedUserID = requiredField;
    if (!v.IMEI) errors.IMEI = requiredField;
    else if (v.IMEI.length !== 15)
      errors.IMEI = resources.pages.tablet.errors.IMEILength;
    if (!v.SIMCard) errors.SIMCard = requiredField;
    else if (
      !(
        v.SIMCard.startsWith("079") ||
        v.SIMCard.startsWith("078") ||
        v.SIMCard.startsWith("077")
      )
    ) {
      errors.SIMCard = resources.pages.user.errors.phone;
    } else if (v.SIMCard.length !== 10) {
      errors.SIMCard = resources.pages.user.errors.phoneLength;
    }
    return errors;
  };

  const handleConfirm = () => {
    if (notSuccessfulVisible) setNotSuccessfulVisible(false);
    else router.replace("/settings/tabletsList");
  };

  const onSubmit = async (values) => {
    try {
      if (tablet && tablet.tabletID === -1) {
        const preIMEI = await serviceApi.service("tablet").find({
          query: {
            IMEI: values.IMEI,
            $limit: 1,
          },
        });
        if (preIMEI.data.length) {
          setNotSuccessfulVisible(true);
        } else {
          await serviceApi.service("tablet").create({
            ...values,
            AssociatedUserID:
              values.AssociatedUserID === -1 ? null : values.AssociatedUserID,
          });
          setSuccessVisible(true);
        }
      } else {
        await serviceApi.service("tablet").patch(+tablet.tabletID, {
          ...values,
          AssociatedUserID:
            values.AssociatedUserID === -1 ? null : values.AssociatedUserID,
        });
        setSuccessVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid className="form-container">
      <SuccessModal
        visible={notSuccessfulVisible ? notSuccessfulVisible : successVisible}
        onConfirm={handleConfirm}
        header={
          notSuccessfulVisible
            ? resources.pages.tablet.dublicateIMEI
            : resources.common.savedSuccessfully
        }
      />
      <Grid.Column>
        <PageHeaderTitle
          content={
            Boolean(location && location.state)
              ? resources.pages.tablet.editTabletInfo
              : resources.pages.tablet.addNewTablet
          }
          icon="tablet alternate"
        />
        <Segment>
          <FinalForm
            initialValues={{
              AssociatedUserID: tablet.AssociatedUserID || -1,
              IMEI: tablet.IMEI,
              SIMCard: tablet.SIMCard,
            }}
            validate={validator}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Grid columns={1} stackable>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <p>{resources.pages.tablet.chooseUserName}</p>
                      <Field name="AssociatedUserID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            placeholder={resources.pages.tablet.chooseUserName}
                            options={users}
                            onChange={(e, { value }) => {
                              input.onChange(value);
                            }}
                            search={users.length > 0}
                            noResultsMessage={resources.common.noData}
                            error={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <p>{resources.pages.tablet.imeiNumber}</p>
                      <Field name="IMEI">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            placeholder={resources.pages.tablet.imeiNumber}
                            icon="barcode"
                            error={meta.touched && meta.error}
                            readOnly={isIMEINotEditable}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="6">
                      <p>{resources.pages.tablet.phoneNumber}</p>
                      <Field name="SIMCard">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            placeholder={resources.pages.tablet.phoneNumber}
                            icon="phone"
                            type="number"
                            error={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  type="submit"
                  className="save-btn"
                >
                  {resources.common.save}
                </Button>
                <Button
                  size="large"
                  type="button"
                  onClick={() => router.replace("/settings/tabletsList")}
                >
                  {resources.common.back}
                </Button>
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
