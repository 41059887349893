import React, { useState, useEffect } from "react";
import { Select } from "semantic-ui-react";
import serviceApi from "../../api";
import { useDispatch } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import { reportsActionCreator } from "../../redux/actions";

const ReportNamePicker = () => {
  const dispatch = useDispatch();
  const [techNames, setTechNames] = useState([]);
  const [techInfo, setTechInfo] = useState({
    techID: "",
    techName: "",
  });

  const fetchData = async () => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Technicians",
          attributes: ["TechnicianID", "TechnicianName"],
          where: { TechnicianID: { lt: 87 } },
        },
      });

      if (Boolean(res.data && res.data.length))
        setTechNames(
          res.data.map((techName) => {
            return {
              key: techName.TechnicianID,
              text: techName.TechnicianName,
              value: techName.TechnicianID,
            };
          })
        );
      else setTechNames([]);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (Boolean(techInfo.techID)) {
      dispatch(
        reportsActionCreator.setReportOptions({
          TechnicianID: techInfo.techID,
          TechnicianName: techInfo.techName,
        })
      );
    } else
      setTechInfo({
        techID: "",
        techName: "",
      });
  }, [techInfo.techID]);

  return (
    <div>
      <Select
        options={techNames}
        value={techInfo.techID}
        placeholder={resources.pages.technicians.technicianName}
        onChange={(e, { value }) =>
          setTechInfo({
            techID: value,
            techName: e.target.innerText,
          })
        }
        search
      />
    </div>
  );
};

export default ReportNamePicker;
