import React, { useState, useEffect } from "react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Button, Grid, Icon, Message, Segment } from "semantic-ui-react";
import DeleteModal from "../../components/DeleteModal";
import ManageContractPayments from "./ManageContractPayments";
import TableTemplate from "../../components/Table/TableTemplate";
import getDeleteError from "../../components/helpers/getDeleteError";
//import SearchBy from "../../components/SearchBy/SearchBy";
import serviceApi from "../../api";
import moment from "moment";

const ContractPayments = ({ location }) => {
  const [contractID, setContractID] = useState(null);
  const [contractPaymentID, setContractPaymentID] = useState(null);
  const [contractPayments, setContractPayments] = useState([]);
  const [contractDetails, setContractDetails] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isContractEnd, setIsContractEnd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  // const [searchData, setSearchData] = useState({});
  // const [searching, setSearching] = useState(null);
  const [openManageContractPaymentsModal, setOpenManageContractPaymentsModal] =
    useState(false);

  const headers = {
    paymentDateView: resources.pages.contractPayment.paymentDate,
    paymentAmountView: resources.pages.contractPayment.paymentAmount,
    isDeservedView: resources.pages.contractPayment.isDeserved,
    deservedDateView: resources.pages.contractPayment.deservedDate,
    invoiceNumberView: resources.pages.contractPayment.invoiceNum,
  };

  // const getSearchResults = (searchBy = "", term = "") => {
  //   setSearching({ term: term, searchBy: searchBy });
  // };

  // const fillSearchData = async () => {
  //   setSearchData({});
  // };

  const onPaging = (page) => setActivePage(page);

  const loadContractPayments = async (id) => {
    setIsLoading(true);
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractPayments",
          where: { contractID: id ? id : contractID },
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      if (res && res.data && res.data.length > 0)
        setContractPayments(
          res.data.map((row) => {
            return {
              ...row,
              paymentDateView: row.paymentDate
                ? moment(row.paymentDate).format("DD/MM/YYYY")
                : resources.common.notSpecified,
              paymentAmountView: row.paymentAmount
                ? row.paymentAmount
                : resources.common.notSpecified,
              isDeservedView: row.isDeserved
                ? resources.common.yes
                : resources.common.no,
              deservedDateView: row.deservedDate
                ? moment(row.deservedDate).format("DD/MM/YYYY")
                : resources.common.notSpecified,
              invoiceNumberView: row.invoiceNumber
                ? row.invoiceNumber
                : resources.common.notSpecified,
            };
          })
        );
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillContractDetails = async (contractID) => {
    const res = await serviceApi.service("queries").find({
      query: {
        model: "Contract",
        attributes: [
          "ContractNumber",
          "ClientID",
          "startDate",
          "EndDate",
          "ContractTypeID",
        ],
        include: [
          {
            model: "Lookup",
            attributes: ["LookupName", "LookupID"],
            as: "ContractType",
          },
          {
            model: "Client",
            attributes: ["ClientName"],
          },
        ],
        where: { contractId: contractID },
      },
    });

    if (res && res.data && res.data.length) {
      const contract = res.data[0];
      setContractDetails([
        {
          label: resources.pages.addContract.contractNumber,
          value: contract.ContractNumber,
        },
        {
          label: resources.pages.addContract.client,
          value: contract.Client
            ? contract.Client.ClientName
            : resources.common.notSpecified,
        },
        {
          label: resources.pages.addContract.contractType,
          value: contract.ContractType.LookupName,
        },
        {
          label: resources.pages.addContract.startDate,
          value: contract.startDate
            ? moment(contract.startDate).format("DD/MM/YYYY")
            : resources.common.notSpecified,
        },
        {
          label: resources.pages.addContract.endDate,
          value: contract.EndDate
            ? moment(contract.EndDate).format("DD/MM/YYYY")
            : resources.common.notSpecified,
        },
      ]);
    }
  };

  useEffect(() => {
    if (
      Boolean(
        location && location.state && location.state.contractId && activePage
      )
    ) {
      if (
        location.state.endContract === resources.pages.addContract.endContract
      )
        setIsContractEnd(true);
      else setIsContractEnd(false);
      setContractID(+location.state.contractId);
      fillContractDetails(+location.state.contractId);
      loadContractPayments(+location.state.contractId);
    }
  }, [activePage]);

  const onCellClick = (row, type) => {
    setContractPaymentID(row.contractPaymentId);
    if (type === "edit") {
      setInitialValues(row);
      setOpenManageContractPaymentsModal(true);
    } else if (type === "remove") {
      setIsDelete(true);
    }
  };

  const deleteContractPayment = async () => {
    try {
      const res = await serviceApi
        .service("ContractPayments")
        .remove(contractPaymentID);
      if (res) {
        setIsDelete(false);
        loadContractPayments();
      }
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteContractPayment()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.contractPayment.contractPayments}
            icon="payment"
          />
          <Message info>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {contractDetails.map((r, idx) => (
                <div key={idx}>
                  <Icon name="info circle" />
                  <label style={{ paddingRight: 2 }}>
                    {r.label}:{r.value}
                  </label>
                </div>
              ))}
            </div>
          </Message>
          {openManageContractPaymentsModal && (
            <ManageContractPayments
              isOpen={openManageContractPaymentsModal}
              contractID={contractID}
              initialValues={initialValues}
              contractPaymentId={contractPaymentID}
              closeManageContractPayments={(reload) => {
                if (reload) loadContractPayments();
                setOpenManageContractPaymentsModal(false);
              }}
            />
          )}
          <Segment>
            {/* <SearchBy
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            /> */}
            {!isContractEnd && (
              <Button
                basic
                color="blue"
                content={resources.pages.contractPayment.addPayment}
                onClick={() => {
                  setContractPaymentID(null);
                  setInitialValues(null);
                  setOpenManageContractPaymentsModal(true);
                }}
                className="add-payment-button"
              />
            )}
            <TableTemplate
              edit={!isContractEnd}
              remove={!isContractEnd}
              isLoading={isLoading}
              data={contractPayments}
              headers={headers}
              onCellClick={onCellClick}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ContractPayments;
