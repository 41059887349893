import React from "react";
import { Table, Icon, Pagination, Loader, Popup } from "semantic-ui-react";
import "./table.scss";
import { resources } from "../../assets/LocalizationResources";

export default function TableTemplate({
  headers,
  data,
  celled = false,
  collapsing = false,
  edit = null,
  remove = null,
  info = false,
  onCellClick = null,
  onRowClick = null,
  extraCells = [], //{key , children , header, isCliCkable}
  paging = null,
  activePage = 0,
  isLoading = false,
  onPaging = null,
  withDetails = false,
  getDetails = null,
  details = null,
  detailKey = null,
  closeDetails = null,
}) {
  const noColumns =
    Object.values(headers).length +
    (edit ? 1 : 0) +
    (remove ? 1 : 0) +
    (info ? 1 : 0) +
    extraCells.length;

  const onDetailsClick = (row) => {
    if (detailKey == row.id) {
      if (typeof closeDetails === "function") closeDetails();
    } else if (typeof getDetails === "function") getDetails(row);
  };

  const footerColSpan = extraCells.length
    ? Object.keys(headers).length + extraCells.length + 2
    : Object.keys(headers).length + 2;

  return (
    <Table className="table" celled={celled} collapsing={collapsing}>
      <Table.Header>
        <Table.Row>
          {Object.values(headers).map((header, idx) => (
            <Table.HeaderCell key={idx}>{header}</Table.HeaderCell>
          ))}
          {edit && <Table.HeaderCell></Table.HeaderCell>}
          {remove && <Table.HeaderCell></Table.HeaderCell>}
          {info && <Table.HeaderCell></Table.HeaderCell>}
          {extraCells.map((cell, idx) => (
            <Table.HeaderCell key={idx}>{cell.header}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isLoading ? (
          data && data.length > 0 ? (
            <>
              {data.map((row, idx) => (
                <React.Fragment key={idx}>
                  <Table.Row
                    className={
                      detailKey === row["id"] ? "selected-details" : ""
                    }
                    {...(row["rowColor"]
                      ? { style: { background: row["rowColor"] } }
                      : {})}
                    key={idx}
                  >
                    {Object.keys(headers).map((key, idx) => (
                      <Table.Cell
                        className={onRowClick && "clickable"}
                        onClick={() => onRowClick && onRowClick(row)}
                        key={idx}
                      >
                        {withDetails && Object.keys(headers)[0] === key && (
                          <Icon
                            name={
                              detailKey === row["id"]
                                ? "angle down"
                                : "angle left"
                            }
                            onClick={() => onDetailsClick(row)}
                          />
                        )}
                        {row[key]}
                      </Table.Cell>
                    ))}
                    {extraCells
                      .filter((cell) => {
                        if (!cell.renderCondition) return true;
                        if (typeof cell.renderCondition === "function") {
                          return cell.renderCondition(row);
                        }
                      })
                      .map((cell, idx) => {
                        return cell.values &&
                          cell.values.length &&
                          cell.values.find((f) => f === row[cell.valuesID]) ? (
                          <Table.Cell key={idx}></Table.Cell>
                        ) : (
                          <Table.Cell
                            className={
                              cell.isCliCkable && onCellClick && "clickable"
                            }
                            onClick={() =>
                              cell.isCliCkable &&
                              onCellClick &&
                              onCellClick(row, cell.key)
                            }
                            key={cell.key}
                            width={1}
                            textAlign="left"
                          >
                            {cell.tooltip ? (
                              <Popup
                                size="mini"
                                position="bottom center"
                                content={cell.tooltip}
                                trigger={cell.children}
                              />
                            ) : (
                              cell.children
                            )}
                          </Table.Cell>
                        );
                      })}
                    {info && (
                      <Table.Cell
                        textAlign="left"
                        width={1}
                        className={onCellClick && "clickable"}
                        onClick={() => onCellClick && onCellClick(row, "info")}
                        key="info"
                      >
                        <Popup
                          size="mini"
                          content={resources.common.info}
                          trigger={<Icon color="blue" name="info circle" />}
                        />
                      </Table.Cell>
                    )}
                    {edit &&
                    edit.values &&
                    edit.values.length &&
                    edit.values.find((f) => f === row[edit.valuesID]) ? (
                      <Table.Cell></Table.Cell>
                    ) : (
                      edit && (
                        <Table.Cell
                          textAlign="left"
                          width={1}
                          className={onCellClick && "clickable"}
                          onClick={() =>
                            onCellClick && onCellClick(row, "edit")
                          }
                          key="edit"
                        >
                          <Popup
                            size="mini"
                            content={resources.common.edit}
                            trigger={<Icon color="blue" name="edit" />}
                          />
                        </Table.Cell>
                      )
                    )}
                    {remove &&
                    remove.values &&
                    remove.values.length &&
                    remove.values.find((f) => f === row[remove.valuesID]) ? (
                      <Table.Cell></Table.Cell>
                    ) : (
                      remove && (
                        <Table.Cell
                          textAlign="left"
                          width={1}
                          className={onCellClick && "clickable"}
                          onClick={() =>
                            onCellClick && onCellClick(row, "remove")
                          }
                          key="remove"
                        >
                          <Popup
                            size="mini"
                            content={resources.common.delete}
                            trigger={<Icon color="red" name="trash" />}
                          />
                        </Table.Cell>
                      )
                    )}
                  </Table.Row>
                  {detailKey === row["id"] && (
                    <Table.Row>
                      {details ? (
                        <Table.Cell
                          colSpan={noColumns}
                          className="td-detail-table"
                        >
                          {details}
                        </Table.Cell>
                      ) : (
                        <Table.Cell colSpan={noColumns} className="center">
                          {resources.common.noData}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={noColumns} className="center">
                {resources.common.noData}
              </Table.Cell>
            </Table.Row>
          )
        ) : (
          <Table.Row>
            <Table.Cell colSpan={noColumns} className="center">
              <Loader active inline />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      {Boolean(paging) && data && paging.total > paging.pageSize && (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={footerColSpan}>
              <Pagination
                // className='ltr'
                activePage={activePage}
                totalPages={Math.ceil(paging.total / paging.pageSize)}
                onPageChange={(e, v) => onPaging(v.activePage)}
                boundaryRange={1}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
}
