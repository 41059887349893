import React, { useState, useEffect } from "react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import "./GeneralInfoOnMachinesPrintOut.scss";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";

const GeneralInfoOnMachinesPrintOut = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [generalMachineInfoData, setGeneralMachineInfoData] = useState([]);

  const headers = {
    ComputerNumber: "رقم الكمبيوتر",
    LastVisitDate: "تاريخ اخر زيارة",
    counterNumber: "رقم العداد",
    AreaName: "المنطقة",
    ClientName: "اسم العميل",
    TechnicianName: "الفني",
    LookupName: "موديل الالة",
    InstallationDate: "تاريخ التركيب",
    EndDate: "تاريخ نهاية العقد",
  };

  const { from, to, TechnicianID, clientID, areaID } = qs.parse(
    location.search,
    {
      ignoreQueryPrefix: true,
    }
  );

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          TechnicianID: TechnicianID,
          clientID: clientID,
          areaID: areaID,
          type: 5,
        },
      });
      const data = res.map((row) => {
        return {
          ...row,
          ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
          LastVisitDate: row.LastVisitDate
            ? moment(row.LastVisitDate).format("YYYY-MM-DD")
            : null,
          counterNumber: row.counterNumber ? row.counterNumber : null,
          AreaName: row.AreaName ? row.AreaName : null,
          ClientName: row.ClientName ? row.ClientName : null,
          TechnicianName: row.TechnicianName ? row.TechnicianName : null,
          LookupName: row.LookupName ? row.LookupName : null,
          InstallationDate: row.InstallationDate
            ? moment(row.InstallationDate).format("YYYY-MM-DD")
            : null,
          EndDate: row.EndDate
            ? moment(row.EndDate).format("YYYY-MM-DD")
            : null,
        };
      });
      setGeneralMachineInfoData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setGeneralMachineInfoData([]);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="general-info-on-machines-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(generalMachineInfoData.length) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"معلومات الاّلاّت"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
            data={generalMachineInfoData}
            headers={headers}
          />
          <div className="general-info-on-machines-table-container">
            <table>
              <thead>
                <tr>
                  <th>رقم الكمبيوتر</th>
                  <th>تاريخ اخر زيارة</th>
                  <th>رقم العداد</th>
                  <th>المنطقة</th>
                  <th>اسم العميل</th>
                  <th>الفني</th>
                  <th>موديل الاّلة</th>
                  <th>تاريخ التركيب</th>
                  <th>تاريخ نهاية العقد</th>
                </tr>
              </thead>
              <tbody>
                {generalMachineInfoData.map((info, idx) => (
                  <tr key={idx}>
                    <td>{info.ComputerNumber}</td>
                    <td>{info.LastVisitDate}</td>
                    <td>{info.counterNumber}</td>
                    <td>{info.AreaName}</td>
                    <td>{info.ClientName}</td>
                    <td>{info.TechnicianName}</td>
                    <td>{info.LookupName}</td>
                    <td>{info.InstallationDate}</td>
                    <td>{info.EndDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default GeneralInfoOnMachinesPrintOut;
