import L from "leaflet";
import { icons } from "./MapIcons";

let layer = null;

export const addJsonData = ({ map, jsonData, clear = true, zoom = true }) => {
  if ((map, jsonData)) {
    if (layer && clear) layer.clearLayers();
    layer = L.geoJSON(jsonData, {
      pointToLayer: (feature, latlng) => {
        if (feature.type === "Point") {
          return L.marker(latlng, {
            icon: feature.icon ? feature.icon : icons.MarkerUrl,
          });
        }
      },
      // onEachFeature: function (feature, layer) {
      //   if (feature.type != "Point") {
      //     layer.setStyle({ color: "#ff0000" });
      //   }
      // },
    }).addTo(map);
    if (zoom) map.fitBounds(layer.getBounds());
  }
};

export const decodePath = (encoded) => {
  var len = encoded.length;
  var index = 0;
  var array = [];
  var lat = 0;
  var lng = 0;

  while (index < len) {
    var b;
    var shift = 0;
    var result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += deltaLat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var deltaLon = result & 1 ? ~(result >> 1) : result >> 1;
    lng += deltaLon;
    array.push([lng * 1e-5, lat * 1e-5]);
  }
  return array;
};
