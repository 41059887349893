import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import "./MachinesContarctTechniciansPrintOut.scss";

function RepeatedTicketsPrintOut() {
  const [isLoading, setIsLoading] = useState(false);
  const [repeatedTicketsData, setRepeatedTicketsData] = useState({});
  const [numberOfMachines, setNumberOfMachines] = useState(1);
  const location = useLocation();
  const { from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          type: 15,
        },
      });
      setNumberOfMachines(res.length);
      const grouped = res.reduce((p, c) => {
        if (p[c.ComputerNumber]) {
          p[c.ComputerNumber].push(c);
        } else {
          p[c.ComputerNumber] = [c];
        }
        return p;
      }, {});
      setRepeatedTicketsData(grouped);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setRepeatedTicketsData({});
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="machines-contract-tech-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(repeatedTicketsData) &&
        Object.values(repeatedTicketsData).length ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={" تقرير الاتصالات المتكررة"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
          />
          <div className="machines-contract-tech-table-container">
            <table>
              <thead>
                <tr>
                  <th>رقم الكمبيوتر</th>
                  <th style={{ width: 200 }}>اسم العميل</th>
                  <th>العقد</th>
                  <th>SLA</th>
                  <th>مميز</th>
                  <th style={{ width: "15rem" }}>الفني</th>
                  <th style={{ width: "10rem" }}>التاريخ</th>
                  <th style={{ width: "10rem" }}>إنجاز الاتصال</th>
                  {/* <th></th>
              <th>مكرر</th> */}
                  <th>مدة التأخير</th>
                  <th>ملاحظات</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(repeatedTicketsData).map((key) => (
                  <>
                    {repeatedTicketsData[key].map((info, idx) =>
                      idx === 0 ? (
                        <tr key={idx}>
                          <td>{info.ComputerNumber}</td>
                          <td>{info.ClientName}</td>
                          <td>{info.contractType}</td>
                          <td>
                            {info.sla ? <span>&#10003;</span> : <span></span>}
                          </td>
                          <td>
                            {info.vip ? <span>&#10003;</span> : <span></span>}
                          </td>
                          <td>{info.TechnicianName}</td>
                          <td>{moment(info.createdAt).format("YYYY-MM-DD")}</td>
                          <td>
                            {moment(info.closedDate).format("YYYY-MM-DD")}
                          </td>
                          {/* <td>
                        {repeatedTicketsData[key].length > 1 ? (
                          <span>&#10003;</span>
                        ) : (
                          <span></span>
                        )}
                      </td>
                      <td>{repeatedTicketsData[key].length}</td> */}
                          <td>{info.lateDays}</td>
                          <td>{info.notes}</td>
                        </tr>
                      ) : (
                        <tr key={idx}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {info.sla ? <span>&#10003;</span> : <span></span>}
                          </td>
                          <td>
                            {info.vip ? <span>&#10003;</span> : <span></span>}
                          </td>
                          <td>{info.TechnicianName}</td>
                          <td>{moment(info.createdAt).format("YYYY-MM-DD")}</td>
                          <td>
                            {moment(info.closedDate).format("YYYY-MM-DD")}
                          </td>
                          {/* <td>{info.isRepeated}</td>
                      <td>
                        {info.repeats > 0 ? (
                          <span> &#10003;</span>
                        ) : (
                          <span></span>
                        )}
                      </td> */}
                          <td>{info.lateDays}</td>
                          <td>{info.notes}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td
                        colSpan="12"
                        style={{ borderBottom: "1px solid black" }}
                      ></td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td>{`عدد الالات: ${numberOfMachines}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
}

export default RepeatedTicketsPrintOut;
