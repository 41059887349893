import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Icon,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import { useHistory, useLocation } from "react-router-dom";
import TableTemplate from "../../components/Table/TableTemplate";
import SuccessModal from "../../components/SuccessModal";
import DeleteModal from "../../components/DeleteModal";
import getDeleteError from "../../components/helpers/getDeleteError";
import useDebounce from "../../components/helpers/useDebounce";

export default function ManageMachineExtraDevices() {
  const router = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState(null);
  const [successVisible, setSuccessVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const [machineID, setMachineID] = useState(null);
  const [machineDetails, setMachineDetails] = useState([]);

  const [machineExtraDevices, setMachineExtraDevices] = useState([]);
  const [lookupExtraDevices, setLookupExtraDevices] = useState([]);

  const [machineExtraDevicesID, setMachineExtraDevicesID] = useState(null);

  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [machineExtraDevicesIsLoading, setMachineExtraDevicesIsLoading] =
    useState(false);

  const headers = {
    machineExtraDevices: resources.pages.machines.extraDevice,
    codeNo: resources.pages.machines.codeNo,
  };

  const [machineExtraDeviceName, setMachineExtraDeviceName] = useState("");
  const [machineExtraDeviceNameIsLoading, setMachineExtraDeviceNameIsLoading] =
    useState(false);
  const debouncedMachineExtraDeviceSearch = useDebounce(
    machineExtraDeviceName,
    1000
  );

  const onPaging = (page) => setActivePage(page);

  const fillLookupExtraDevices = async (machineExtraDeviceNameID = null) => {
    try {
      setMachineExtraDeviceNameIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 13,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machineExtraDeviceName}%` },
          ...(machineExtraDeviceNameID
            ? { LookupID: machineExtraDeviceNameID }
            : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setLookupExtraDevices(
          res.data.map((item) => {
            return {
              key: item.LookupID,
              text: item.LookupName,
              value: item.LookupID,
            };
          })
        );
      else setLookupExtraDevices([]);

      setMachineExtraDeviceNameIsLoading(false);
    } catch (error) {
      setMachineExtraDeviceNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(machineExtraDeviceName && machineExtraDeviceName.length > 2))
      fillLookupExtraDevices();
  }, [debouncedMachineExtraDeviceSearch]);

  const fillMachinesDetails = async (machineID) => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Machine",
          attributes: ["machineId", "serialNumber", "computerNumber"],
          where: { machineId: machineID },
          include: [
            { model: "Lookup", as: "MachineType", attributes: ["LookupName"] },
            { model: "Lookup", as: "MachineModel", attributes: ["LookupName"] },
            {
              model: "Lookup",
              as: "ManufacturingCompany",
              attributes: ["LookupName"],
            },
          ],
        },
      });

      if (res && res.data && res.data.length) {
        const machine = res.data[0];
        setMachineDetails([
          {
            label: resources.pages.machines.computerNumber,
            value: machine.computerNumber,
          },
          {
            label: resources.pages.machines.serialNumber,
            value: machine.serialNumber,
          },
          {
            label: resources.pages.machines.MachineModel,
            value: machine.MachineModel
              ? machine.MachineModel.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.MachineType,
            value: machine.MachineType
              ? machine.MachineType.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.ManufacturingCompany,
            value: machine.ManufacturingCompany
              ? machine.ManufacturingCompany.LookupName
              : resources.common.notSpecified,
          },
        ]);
      } else setMachineDetails([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.machineID)) {
      setMachineID(location.state.machineID);
      fillMachinesDetails(location.state.machineID);
    }
  }, [location]);

  const fillMachineExtraDevices = async () => {
    try {
      setMachineExtraDevicesIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MachineExtraDevices",
          where: { machineId: machineID },
          include: [{ model: "Lookup", attributes: ["LookupName"] }],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      if (res && res.data && res.data.length) {
        setMachineExtraDevices(
          res.data.map((item) => ({
            ...item,
            machineExtraDevices: item.Lookup.LookupName,
          }))
        );
        setPagination({
          ...pagination,
          total: res.total,
        });
        if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      } else setMachineExtraDevices([]);

      setMachineExtraDevicesIsLoading(false);
    } catch (error) {
      setMachineExtraDevicesIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (machineID && activePage) fillMachineExtraDevices();
  }, [machineID, activePage]);

  const validator = (v) => {
    const requiredField = resources.common.errors.requiredField;
    const errors = {};
    if (!v.nameID) errors.nameID = requiredField;
    if (!v.codeNo) errors.codeNo = requiredField;
    return errors;
  };

  const onCellClick = (row, type) => {
    setMachineExtraDevicesID(row.id);
    if (type === "edit") {
      fillLookupExtraDevices(row.nameID);
      setInitialValues(row);
    } else if (type === "remove") {
      setIsDelete(true);
    }
  };

  const handleConfirm = () => {
    setInitialValues({});
    fillMachineExtraDevices();
    setMachineExtraDevicesID(null);
    setSuccessVisible(false);
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (Boolean(machineExtraDevicesID))
        await serviceApi
          .service("machine-extra-devices")
          .patch(machineExtraDevicesID, {
            ...values,
            MachineID: machineID,
          });
      else
        await serviceApi.service("machine-extra-devices").create({
          ...values,
          machineID: machineID,
        });
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
    }
  };

  const deleteMachine = async () => {
    try {
      const res = await serviceApi
        .service("machine-extra-devices")
        .remove(machineExtraDevicesID);

      if (res) {
        setIsDelete(false);
        fillMachineExtraDevices();
      }
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };
  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <DeleteModal
        onConfirm={() => deleteMachine()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.machines.addDevices}
            icon="computer"
          />
          <Message info>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {machineDetails.map((row, idx) => (
                <div key={idx}>
                  <Icon name="info circle" />
                  <label style={{ paddingRight: 2 }}>
                    {row.label} : {row.value}
                  </label>
                </div>
              ))}
            </div>
          </Message>
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validator}
              render={({ handleSubmit }) => (
                <Form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={2}>
                    <Grid.Column>
                      <p>{resources.pages.machines.extraDevice}</p>
                      <Field name="nameID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setMachineExtraDeviceName(e.target.value.trim())
                            }
                            loading={machineExtraDeviceNameIsLoading}
                            options={lookupExtraDevices}
                            placeholder={
                              resources.pages.machines.chooseExtraDevice
                            }
                            noResultsMessage={resources.common.noData}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                            className={`${
                              Boolean(lookupExtraDevices.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>

                    <Grid.Column>
                      <p>{resources.pages.machines.deviceCode}</p>
                      <Field name="codeNo">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            icon="barcode"
                            error={meta.touched && meta.error}
                            placeholder={
                              resources.pages.machines.enterDeviceCode
                            }
                            type="number"
                          />
                        )}
                      </Field>
                    </Grid.Column>

                    <Button
                      color="blue"
                      size="large"
                      className="save-btn"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {resources.common.save}
                    </Button>
                    <Button
                      color="red"
                      className="save-btn"
                      onClick={() => router.replace("/machines/machinesList")}
                    >
                      {resources.common.cancel}
                    </Button>
                  </Grid>
                </Form>
              )}
            />
          </Segment>
          <TableTemplate
            headers={headers}
            data={machineExtraDevices}
            edit
            remove
            onCellClick={onCellClick}
            paging={pagination}
            activePage={activePage}
            onPaging={onPaging}
            isLoading={machineExtraDevicesIsLoading}
          />
        </Grid.Column>
      </Grid>
    </>
  );
}
