import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import "./MachinesContarctTechniciansPrintOut.scss";

const MachineCardForPeriodicalVisits = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [preiodicalVisitsData, setPeriodicalVisitsData] = useState({});
  const location = useLocation();
  const { from, to, compuerNumber, model } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          compuerNumber: compuerNumber,
          model: model,
          type: 16,
        },
      });
      const grouped = res.reduce((p, c) => {
        if (p[c.ComputerNumber]) {
          p[c.ComputerNumber].push(c);
        } else {
          p[c.ComputerNumber] = [c];
        }
        return p;
      }, {});
      setPeriodicalVisitsData(grouped);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setPeriodicalVisitsData({});
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="machines-contract-tech-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(preiodicalVisitsData) &&
        Object.values(preiodicalVisitsData).length ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"بطاقة الالة للزيارات الدورية"}
            startDate={from}
            endDate={to}
            hasDate={true}
            model={model}
          />
          {Object.keys(preiodicalVisitsData).map((key, parentIdx) => (
            <>
              {preiodicalVisitsData[key].map((info, idx) =>
                idx === 0 ? (
                  <>
                    <div
                      className="machines-contract-tech-table-container"
                      style={{
                        ...(parentIdx !== 0
                          ? { marginTop: "50px", borderTop: "1px solid black" }
                          : {}),
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>رقم الكمبيوتر</th>
                            <th style={{ width: 100 }}>موديل الالة</th>
                            <th>نوع الالة</th>
                            <th>الرقم المتسلسل</th>
                            <th>اسم العميل</th>
                            <th style={{ width: 80 }}>الفرع</th>
                            <th style={{ width: "10rem" }}>الهاتف</th>
                            <th style={{ width: "10rem" }}>رقم العقد</th>
                            <th>تاريخ نهاية</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={idx}>
                            <td>{info.ComputerNumber}</td>
                            <td>{info.machineModel}</td>
                            <td>{info.machineType}</td>
                            <td>{info.SerialNumber}</td>
                            <td>{info.ClientName}</td>
                            <td>{info.BranchName}</td>
                            <td>{info.Phone}</td>
                            <td>{info.ContractNumber}</td>
                            <td>{moment(info.EndDate).format("YYYY-MM-DD")}</td>
                          </tr>

                          <tr>
                            <td colSpan="10">
                              <div
                                style={{
                                  border: "1px solid black",
                                  margin: 0,
                                  width: "fit-content",
                                }}
                              >
                                <table
                                  style={{ borderBottom: "1px solid black" }}
                                >
                                  <thead style={{ width: 150 }}>
                                    <tr>
                                      <th style={{ width: 125 }}>
                                        رقم التقرير
                                      </th>
                                      <th style={{ width: 125 }}>
                                        تاريخ الزيارة
                                      </th>
                                      <th style={{ width: 125 }}>ساعة البدء</th>
                                      <th style={{ width: 125 }}>
                                        ساعة الإنتهاء
                                      </th>
                                      <th style={{ width: 125 }}>
                                        نوع الزيارة
                                      </th>
                                      <th style={{ width: 125 }}>
                                        رقم العداد 1
                                      </th>
                                      <th style={{ width: 125 }}>
                                        رقم العداد 2
                                      </th>
                                      <th style={{ width: 150 }}>الفني</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{info.MaintenanceReportID}</td>
                                      <td>{info.LastVisitDate}</td>
                                      <td>
                                        {moment(info.StartDate).format(
                                          "HH:mm a"
                                        )}
                                      </td>
                                      <td>
                                        {moment(info.EndDate).format("HH:mm a")}
                                      </td>
                                      <td>{info.visitType}</td>
                                      <td>{info.Counter1}</td>
                                      <td>{info.Counter2}</td>
                                      <td>{info.TechnicianName}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingInline: 230,
                                  }}
                                >
                                  <div
                                    style={{
                                      borderLeft: "1px solid black",
                                    }}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            style={{
                                              paddingLeft: 150,
                                            }}
                                          >
                                            العطل
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td style={{ paddingBottom: 50 }}>
                                            {info.damage}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    style={{ borderLeft: "1px solid black" }}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th style={{ paddingLeft: 150 }}>
                                            الاسباب
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{info.cause}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th style={{ paddingLeft: 150 }}>
                                            الاجراء
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{info.action}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <tr className="machines-contract-tech-table-container">
                    <td colSpan="10">
                      <div
                        style={{
                          border: "1px solid black",
                        }}
                      >
                        <table style={{ borderBottom: "1px solid black" }}>
                          <thead style={{ width: 150 }}>
                            <tr>
                              <th style={{ width: 125 }}>رقم التقرير</th>
                              <th style={{ width: 125 }}>تاريخ الزيارة</th>
                              <th style={{ width: 125 }}>ساعة البدء</th>
                              <th style={{ width: 125 }}>ساعة الإنتهاء</th>
                              <th style={{ width: 125 }}>نوع الزيارة</th>
                              <th style={{ width: 125 }}>رقم العداد 1</th>
                              <th style={{ width: 125 }}>رقم العداد 2</th>
                              <th style={{ width: 150 }}>الفني</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{info.MaintenanceReportID}</td>
                              <td>{info.LastVisitDate}</td>
                              <td>
                                {moment(info.StartDate).format("HH:mm a")}
                              </td>
                              <td>{moment(info.EndDate).format("HH:mm a")}</td>
                              <td>{info.visitType}</td>
                              <td>{info.Counter1}</td>
                              <td>{info.Counter2}</td>
                              <td>{info.TechnicianName}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          style={{
                            display: "flex",
                            paddingInline: 230,
                          }}
                        >
                          <div
                            style={{
                              borderLeft: "1px solid black",
                            }}
                          >
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ paddingLeft: 150 }}>العطل</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ paddingBottom: 50 }}>
                                    {info.damage}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div style={{ borderLeft: "1px solid black" }}>
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ paddingLeft: 150 }}>الاسباب</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{info.cause}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ paddingLeft: 150 }}>الاجراء</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{info.action}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </>
          ))}
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default MachineCardForPeriodicalVisits;
