import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import BranchesList from "./BranchesList";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import { useSelector } from "react-redux";

export default function ClientBranchesList() {
  const router = useHistory();
  const [clients, setClients] = useState(null);
  const [clientBranches, setClientBranches] = useState(null);
  const [clientID, setClientID] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [searching, setSearching] = useState(null);
  const headers = {
    clientName: resources.pages.clients.clientName,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const fillSearchData = async () => {
    setSearchData({
      clientName: {
        type: "text",
        label: resources.pages.clients.clientName,
      },
      // email: {
      //   type: "text",
      //   label: resources.pages.ManageTechnicians.email,
      // },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "clientBranch"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  // const whereSearch = (id, colID = id) => {
  //   if (searching && searching.searchBy === id)
  //     return { [colID]: searching.term };
  //   else return {};
  // };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { [colID]: { $like: `%${searching.term}%` } };
    } else return {};
  };

  const loadClients = async () => {
    try {
      setIsLoading(true);
      var res = await serviceApi.service("client").find({
        query: {
          $select: ["clientId", "clientName", "VIP"],
          ...whereLikeSearch("clientName"),
          $limit: pagination.pageSize,
          $skip: pagination.pageSize * (activePage - 1),
        },
      });

      const newData = res.data.map((row, idx) => {
        return {
          id: row.clientId,
          clientName: (
            <>
              <Icon name="star" key={idx} color={row.VIP ? "yellow" : "grey"} />
              <label style={{ margin: "0px 5px" }}>{row.clientName}</label>
            </>
          ),
        };
      });
      setClients(newData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadClients();
  }, [searching, activePage]);

  const getDetails = (row) => {
    setClientID(row.id);
    setClientBranches(<BranchesList clientID={row.id} />);
  };

  const closeDetails = () => {
    setClientID(null);
    setClientBranches(null);
  };

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/ClientBranches/ManageClient", {
        clientID: row.id,
      });
    } else if (type === "remove") {
      setClientID(row.id);
      setIsDelete(true);
    } else if (type === "addBranch") {
      router.push("/ClientBranches/ManageBranch", {
        clientID: row.id,
      });
    }
  };

  const deleteClient = async () => {
    try {
      await serviceApi
        .service("client")
        .remove(clientID)
        .then((res) => {
          setIsDelete(false);
          loadClients();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };
  return (
    <>
      <DeleteModal
        onConfirm={() => deleteClient()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.clients.pageTitle}
            icon="building"
          />
          {isAuthorized(Perm.AddNewClient) && (
            <Button
              basic
              color="blue"
              content={resources.pages.clients.addClient}
              onClick={() => router.push("/ClientBranches/ManageClient")}
            />
          )}
          <Segment>
            <SearchBy
              pageName="clientBranch"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={clients}
              edit={isAuthorized(Perm.editClient) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              extraCells={[
                ...(isAuthorized(Perm.addBranch)
                  ? [
                      {
                        key: "addBranch",
                        children: (
                          <Button
                            style={{ padding: "3px" }}
                            icon="plus"
                            size="tiny"
                            content={
                              <p
                                style={{
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  padding: 0,
                                }}
                              >
                                إضافة فرع
                              </p>
                            }
                            labelPosition="left"
                            basic
                            color="blue"
                          />
                        ),
                        isCliCkable: true,
                        tooltip: resources.pages.branch.addBranch,
                      },
                    ]
                  : []),
              ]}
              remove={isAuthorized(Perm.deleteClient) ? true : null}
              withDetails
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              getDetails={getDetails}
              closeDetails={closeDetails}
              details={clientBranches}
              detailKey={clientID}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
