import React, { useState, useEffect, useRef } from "react";
import { layoutActionCreator } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import tickets from "../../assets/images/tickets.svg";
import visit from "../../assets/images/visit.svg";
import Perm, { isAuthorized } from "../helpers/Permissions";
import { useSelector } from "react-redux";
import tescoLogo from "../../assets/images/tescoLogo.png";
import UserNavbarMenu from "./UserNavbarMenu";

const HorizontalNavbar = () => {
  const router = useHistory();

  const userBadgeDivRef = useRef();

  const dispatch = useDispatch();
  const openSidebar = () => {
    dispatch(layoutActionCreator.toggleSideBar(true));
  };

  const handleOutsideClicks = (e) => {
    if (userBadgeDivRef.current.contains(e.target)) {
      return;
    }
    setOpenUserMenuList(false);
  };

  const userInfo = useSelector((state) => state.user.user);
  const [openUserMenuList, setOpenUserMenuList] = useState(false);

  useEffect(() => {
    if (openUserMenuList)
      document.addEventListener("mousedown", handleOutsideClicks);
    else document.removeEventListener("mousedown", handleOutsideClicks);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [openUserMenuList]);

  return (
    <div className="navbar-container">
      <div>
        <Icon
          name="sidebar"
          className="sidebar-bars-icon"
          onClick={openSidebar}
        />
        {!userInfo.isExternalUser && (
          <Icon
            name="home"
            className="sidebar-bars-icon"
            onClick={() => router.push("/Home")}
          />
        )}
        {isAuthorized(Perm.technicianVisits) && (
          <Popup
            trigger={
              <img
                src={visit}
                className="bar-icons"
                onClick={() => router.push("/tech/visits")}
              />
            }
            content={resources.pages.ticket.periodicVisits}
          />
        )}
        {isAuthorized(Perm.technicianTickets) && (
          <Popup
            trigger={
              <img
                src={tickets}
                className="bar-icons"
                onClick={() => router.push("/tech/tickets")}
              />
            }
            content={resources.pages.ticket.ticketsCalls}
          />
        )}
      </div>

      <div
        className="user-badge-container"
        onClick={() => setOpenUserMenuList(!openUserMenuList)}
        ref={userBadgeDivRef}
      >
        <div className="user-name-container">
          <h5>{userInfo.userName}</h5>
        </div>
        <img className="user-avatar-img" src={tescoLogo} />
        <Icon name="angle down" size="large" />
        {openUserMenuList && <UserNavbarMenu userInfo={userInfo} />}
      </div>
    </div>
  );
};
export default HorizontalNavbar;
