import React, { useEffect } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import trackingImg from "../../assets/images/routing/trackingLocationImg.svg";
import "./PageHeaderTitle.scss";
import { useSelector } from "react-redux";

const PageHeaderTitle = ({ content, icon = null }) => {
  const router = useHistory();
  const user = useSelector((state) => state.user).user;

  return (
    <div className="page-header-container">
      <div className="page-header-content-container">
        {Boolean(content === resources.pages.playBackMap.techsMovePoints) ? (
          <img src={trackingImg} />
        ) : (
          <Icon className="iconc" name={icon ? icon : {}} size="large" />
        )}
        <div className="content-container">{content}</div>
      </div>

      {!user.isExternalUser && (
        <Popup
          content={resources.common.goToSettingPage}
          trigger={
            <Icon
              name="setting"
              onClick={() => router.push("/settings")}
              size="large"
              className="page-header-setting-icon"
            />
          }
        />
      )}
    </div>
  );
};

export default PageHeaderTitle;
