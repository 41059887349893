import { resources } from "../../assets/LocalizationResources";

const validateEmail = (email) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export default function validate(values, isUpdate) {
  const errors = {};
  if (!values.userName) {
    errors.userName = resources.common.errors.requiredField;
  }
  if (!values.fullName) {
    errors.fullName = resources.common.errors.requiredField;
  }
  if (!isUpdate) {
    if (!values.password) {
      errors.password = resources.common.errors.requiredField;
    } else if (values.password.length < 5) {
      errors.password = resources.pages.user.errors.passLength;
    }
    if (!values.confirmPassword || values.confirmPassword !== values.password) {
      errors.confirmPassword = resources.pages.user.errors.passConfirm;
    }
  }
  // if (!values.email) {
  //   errors.email = resources.common.errors.requiredField;
  // } else if (!validateEmail(values.email)) {
  //   errors.email = resources.common.errors.invalidEntry;
  // }
  if (!values.phone) {
    errors.phone = resources.common.errors.requiredField;
  } else if (
    !(
      values.phone.startsWith("96279") ||
      values.phone.startsWith("96278") ||
      values.phone.startsWith("96277")
    )
  ) {
    errors.phone = resources.pages.user.errors.phone;
  } else if (values.phone.length !== 12)
    errors.phone = resources.pages.user.errors.phoneLength;

  return errors;
}
export function validatePassword(values) {
  const errors = {};

  if (!values.password) {
    errors.password = resources.common.errors.requiredField;
  } else if (values.password.length < 5) {
    errors.password = resources.pages.user.errors.passLength;
  }
  if (!values.confirmPassword || values.confirmPassword !== values.password) {
    errors.confirmPassword = resources.pages.user.errors.passConfirm;
  }
  return errors;
}
