import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Button,
  Dimmer,
  Loader,
  Message,
  List,
  Icon,
  Checkbox,
  Form,
} from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";

export default function ManageGroupPermissions({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({});
  const [treeExpanded, setTreeExpanded] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionsAreLoading, setPermissionsAreLoading] = useState(false);
  const router = useHistory();

  const getChildNodes = (data, selectedPermissions) => {
    let children = {};
    data.forEach((c) => {
      children = {
        ...children,
        [c.permissionId]: {
          value: c.permissionId,
          label: c.permissionName,
          isChecked: selectedPermissions.find((f) => c.permissionId == f)
            ? true
            : false,
        },
      };
    });
    return children;
  };
  const fillGroupData = async () => {
    setGroupName(location.state.groupName);

    const res = await serviceApi
      .service("group-permissions")
      .find({ query: { GroupID: location.state.groupID, $limit: 999 } });
    setIsLoading(false);
    return res.data.map((m) => m.PermissionID);
  };
  const fillPermissions = async () => {
    try {
      setPermissionsAreLoading(true);
      const res = await serviceApi.service("permissions").find();
      if (res && res && res.length > 0) {
        let data = {};
        let resSelectedPermissions = [];
        if (location && location.state && location.state.groupID) {
          resSelectedPermissions = await fillGroupData();
          setSelectedPermissions(resSelectedPermissions);
        }
        res.forEach((e) => {
          if (e.ParentPermissionID == -1 && e.permissionId != -1) {
            data = {
              ...data,
              [e.permissionId]: {
                value: e.permissionId,
                label: e.permissionName,
                isChecked: false,
                isExpanded: true,
                isIndeterminate: false,
                children: getChildNodes(
                  res.filter((f) => f.ParentPermissionID == e.permissionId),
                  resSelectedPermissions
                ),
              },
            };
            if (resSelectedPermissions.length > 0) {
              if (
                Object.values(data[e.permissionId].children).find(
                  (f) => f.isChecked === false
                )
              ) {
                if (
                  Object.values(data[e.permissionId].children).find(
                    (f) => f.isChecked === true
                  )
                )
                  data[e.permissionId].isIndeterminate = true;
              } else data[e.permissionId].isChecked = true;
            }
          }
        });
        setPermissions(data);
      }
      setPermissionsAreLoading(false);
    } catch (error) {
      // TODO: Show error message
      setPermissionsAreLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fillPermissions();
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      let selectedIDs = [];
      if (location && location.state && location.state.groupID) {
        let deletedIDs = [];
        if (groupName !== location.state.groupName) {
          await serviceApi
            .service("groups")
            .patch(location.state.groupID, { groupName: groupName });
        }
        Object.values(permissions).forEach((e) => {
          Object.values(e.children).forEach((c) => {
            if (selectedPermissions.filter((f) => f === c.value).length > 0) {
              if (!c.isChecked) deletedIDs.push(c.value);
            } else if (c.isChecked) selectedIDs.push(c.value);
          });
        });
        if (selectedPermissions.length == 0 && selectedIDs.length == 0) {
          setIsSubmitting(false);
          setErrorMessage(resources.pages.permissions.errorSave);
          errorTimeout();
          return;
        }
        if (selectedIDs.length > 0) {
          await serviceApi.service("group-permissions").create(
            selectedIDs.map((m) => {
              return { PermissionID: m, GroupID: location.state.groupID };
            })
          );
        }
        if (deletedIDs.length > 0)
          await serviceApi.service("group-permissions").remove(null, {
            query: {
              PermissionID: {
                $in: deletedIDs,
              },
            },
          });
      } else {
        Object.values(permissions).forEach((e) => {
          Object.values(e.children).forEach((c) => {
            if (c.isChecked) selectedIDs.push(c.value);
          });
        });
        if (selectedIDs.length == 0) {
          setIsSubmitting(false);
          setErrorMessage(resources.pages.permissions.errorSave);
          errorTimeout();
          return;
        }
        const res = await serviceApi
          .service("groups")
          .create({ groupName: groupName });

        if (res) {
          await serviceApi.service("group-permissions").create(
            selectedIDs.map((m) => {
              return { PermissionID: m, GroupID: res.groupId };
            })
          );
        }
      }
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      errorTimeout();
    }
  };
  const errorTimeout = () => {
    setErrorVisible({
      visible: true,
      timeout: setTimeout(() => {
        setErrorVisible({ visible: false, timeout: null });
        setErrorMessage(null);
      }, 5000),
    });
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  useEffect(() => {
    if (permissions) {
      setIsLoading(false);
    }
  }, [permissions]);

  const handleConfirm = () => {
    router.replace("/groupPermissions");
  };

  const checkPermission = (id, parentID) => {
    const data = { ...permissions };
    data[parentID].children[id].isChecked =
      !data[parentID].children[id].isChecked;
    if (
      Object.values(data[parentID].children).find((f) => f.isChecked === false)
    ) {
      data[parentID].isIndeterminate = true;
      data[parentID].isChecked = false;
    } else {
      data[parentID].isIndeterminate = false;
      data[parentID].isChecked = true;
    }
    setPermissions(data);
  };

  const checkParentPermission = (id) => {
    const data = { ...permissions };
    data[id].isIndeterminate = false;
    data[id].isChecked = !data[id].isChecked;
    Object.values(data[id].children).forEach(
      (f) => (f.isChecked = data[id].isChecked)
    );
    setPermissions(data);
  };

  const onParentExpanded = (id) => {
    const data = { ...permissions };
    data[id].isExpanded = !data[id].isExpanded;
    setPermissions(data);
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              location && location.state && location.state.groupID
                ? resources.pages.permissions.editGroup
                : resources.pages.permissions.addGroup
            }
            icon="hand paper"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              validate={() => {
                const errors = {};
                if (!groupName) {
                  errors.groupName = resources.common.errors.requiredField;
                  return errors;
                }
              }}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  initialvalues={{ groupName: groupName }}
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={1}>
                    <Grid.Row>
                      <Grid.Column width="8">
                        <p>{resources.pages.permissions.groupName}</p>
                        <Field name="groupName">
                          {({ input, meta }) => (
                            <Form.Input
                              value={groupName}
                              name={"groupName"}
                              fluid
                              placeholder={
                                resources.pages.permissions.groupName
                              }
                              onChange={(e, { value }) => {
                                input.onChange(value);
                                setGroupName(e.target.value);
                              }}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="8">
                        <Segment style={{ maxHeight: 300, overflow: "auto" }}>
                          {permissionsAreLoading ? (
                            <Loader active={permissionsAreLoading} />
                          ) : (
                            <List>
                              <List.Item as="a">
                                <List.Content>
                                  <List.Header>
                                    <Icon
                                      style={{ marginRight: "-11px" }}
                                      color="blue"
                                      onClick={() =>
                                        setTreeExpanded(!treeExpanded)
                                      }
                                      size="large"
                                      name={
                                        treeExpanded
                                          ? "down triangle"
                                          : "left triangle"
                                      }
                                    />
                                    {resources.pages.permissions.permissions}
                                    <List
                                      style={{
                                        display: treeExpanded
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      {Object.values(permissions).map(
                                        (parent) => (
                                          <List.Item key={parent.value}>
                                            <List.Content>
                                              <List.Header>
                                                <Icon
                                                  color="blue"
                                                  size="large"
                                                  name={
                                                    permissions[parent.value]
                                                      .isExpanded
                                                      ? "down triangle"
                                                      : "left triangle"
                                                  }
                                                  onClick={() =>
                                                    onParentExpanded(
                                                      parent.value
                                                    )
                                                  }
                                                />
                                                <Checkbox
                                                  style={{ padding: 2 }}
                                                  checked={parent.isChecked}
                                                  indeterminate={
                                                    parent.isIndeterminate
                                                  }
                                                  onChange={() =>
                                                    checkParentPermission(
                                                      parent.value
                                                    )
                                                  }
                                                />
                                                {parent.label}
                                              </List.Header>
                                              {permissions[parent.value]
                                                .isExpanded && (
                                                <List
                                                  style={{
                                                    marginRight: 40,
                                                    padding: 2,
                                                  }}
                                                >
                                                  {Object.values(
                                                    parent.children
                                                  ).map((child) => (
                                                    <List.Item
                                                      key={child.value}
                                                    >
                                                      <List.Content>
                                                        <List.Header>
                                                          <Checkbox
                                                            style={{
                                                              padding: 2,
                                                            }}
                                                            checked={
                                                              permissions[
                                                                parent.value
                                                              ].children[
                                                                child.value
                                                              ].isChecked
                                                            }
                                                            onChange={() => {
                                                              checkPermission(
                                                                child.value,
                                                                parent.value
                                                              );
                                                            }}
                                                          />
                                                          {child.label}
                                                        </List.Header>
                                                      </List.Content>
                                                    </List.Item>
                                                  ))}
                                                </List>
                                              )}
                                            </List.Content>
                                          </List.Item>
                                        )
                                      )}
                                    </List>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            </List>
                          )}
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button size="large" type="button" onClick={handleConfirm}>
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
