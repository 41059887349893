import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { resources } from "../../assets/LocalizationResources.js";
import { Form } from "semantic-ui-react";
import "./DTPicker.scss";

export default function DTPicker({
  isOpen = true,
  selectedValue = null,
  setSelectedValue,
  isTrigger = false,
  format = null,
  isForTechMovePoints = false,
  defaultDate = new Date(),
  popperPlacement = "auto",
  isReadOnly = false,
}) {
  const [startDate, setStartDate] = useState(defaultDate);

  const handleDefaultDateChangeRow = (e) => e.preventDefault();

  return isTrigger ? (
    isForTechMovePoints ? (
      <Form>
        <DatePicker
          showTimeInput={format !== "date" ? true : false}
          dateFormat={format === "date" ? "dd/MM/y" : "dd/MM/y ، HH:mm "}
          selected={selectedValue ? new Date(selectedValue) : startDate}
          showYearDropdown
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          onYearChange={(date) => {
            setSelectedValue(date);
            setStartDate(date);
          }}
          onChange={(date) => {
            setSelectedValue(date);
            setStartDate(date);
          }}
          timeInputLabel={resources.pages.playBackMap.hour}
          fixedHeight
          onChangeRaw={handleDefaultDateChangeRow}
          popperPlacement="top-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "10px, 5px",
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
            },
          }}
        />
      </Form>
    ) : (
      <DatePicker
        showTimeSelect={format !== "date" ? true : false}
        dateFormat={format === "date" ? "dd/MM/y" : "dd/MM/y ، HH:mm "}
        selected={selectedValue ? new Date(selectedValue) : startDate}
        showYearDropdown
        yearDropdownItemNumber={5}
        scrollableYearDropdown
        onYearChange={(date) => {
          setSelectedValue(date);
          setStartDate(date);
        }}
        onChange={(date) => {
          setSelectedValue(date);
          setStartDate(date);
        }}
        popperPlacement={popperPlacement}
        fixedHeight
        onChangeRaw={handleDefaultDateChangeRow}
        readOnly={isReadOnly}
      />
    )
  ) : (
    <DatePicker
      selected={selectedValue ? new Date(selectedValue) : startDate}
      onChange={(date) => {
        setSelectedValue(date);
        setStartDate(date);
      }}
      onYearChange={(date) => {
        setSelectedValue(date);
        setStartDate(date);
      }}
      showYearDropdown
      yearDropdownItemNumber={5}
      scrollableYearDropdown
      dateFormat={format === "date" ? "dd/MM/y" : "dd/MM/y ،HH:mm "}
      inline
      fixedHeight
      onChangeRaw={handleDefaultDateChangeRow}
    />
  );
}
