import React, { useState, useEffect } from "react";
import { Grid, Input, Segment, Button, Select } from "semantic-ui-react";
import serviceApi from "../../api";
import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import TableTemplate from "../../components/Table/TableTemplate";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";

import "./ManageLookUp.scss";
const ManageLookUp = () => {
  const headers = {
    LookupName: resources.pages.lookup.lookUpTableTitle,
  };
  const [lookUpNames, setlookUpNames] = useState([]);
  const [childs, setChilds] = useState([]);
  const [parentLookupID, setparentLookupID] = useState(0);
  const [newLookupName, setNewLookupName] = useState("");
  const [newLookupId, setNewLookupId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState(false);
  const [allowisEditable, setAllowisEditable] = useState(true);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const onPaging = (page) => setActivePage(page);

  const fillParentLookUpNames = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          ParentLookupID: -1,
          $select: ["LookupID", "LookupName", "isEditable"],
        },
      });

      if (res && res.data && res.data.length > 0) {
        setlookUpNames(
          res.data.map((c) => {
            return {
              key: c.LookupID,
              text: c.LookupName,
              value: c.LookupID,
              iseditable: c.isEditable,
            };
          })
        );
      } else setlookUpNames([]);
    } catch (e) {
      console.log(e);
    }
  };

  const fillChilds = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          ParentLookupID: parentLookupID,
          $select: ["LookupID", "LookupName", "isEditable"],
          $limit: pagination.pageSize,
          $skip: pagination.pageSize * (activePage - 1),
        },
      });

      if (res && res.data && res.data.length > 0) {
        setChilds(
          res.data.map((m) => ({
            ...m,
            iseditable: m.isEditable ? 1 : "NotEditable",
          }))
        );
      } else setChilds([]);

      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleInputChanges = (event) => {
    setNewLookupName(event.target.value);
  };

  useEffect(() => {
    fillParentLookUpNames();
  }, []);

  useEffect(() => {
    if (parentLookupID && activePage) {
      let parent = lookUpNames.find((f) => f.value === parentLookupID);
      if (parent) setAllowisEditable(parent.iseditable);
      fillChilds();
    }
  }, [parentLookupID, activePage]);

  const addNewLookup = async () => {
    if (newLookupName && parentLookupID) {
      await serviceApi
        .service("lookup")
        .create({ lookupName: newLookupName, ParentLookupID: parentLookupID });
      fillChilds();
      setNewLookupName("");
      setError(false);
    } else setError(true);
  };

  const onEditing = async () => {
    if (newLookupName && newLookupName) {
      await serviceApi
        .service("lookup")
        .patch(newLookupId, { lookupName: newLookupName });

      const edited = [...childs];
      const item = edited.find((element) => element.LookupID === newLookupId);
      item.LookupName = newLookupName;
      setChilds(edited);
      setNewLookupId(null);
      setNewLookupName("");
      setEditing(false);
      setError(false);
    } else setError(true);
  };

  const onCellClick = (row, type) => {
    if (type === "edit") {
      setEditing(true);
      setNewLookupName(row.LookupName);
      setNewLookupId(row.LookupID);
    } else if (type === "remove") {
      setNewLookupId(row.LookupID);
      setIsDelete(true);
    }
  };

  const deleteLookup = async () => {
    try {
      const res = await serviceApi.service("lookup").remove(newLookupId);
      setIsDelete(false);
      const filteredChilds = childs.filter(
        (item) => item.LookupID !== newLookupId
      );
      setChilds(filteredChilds);
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <Grid>
      <Grid.Column>
        <PageHeadeTitle content={resources.pages.settings.lookup} icon="star" />
        <DeleteModal
          onConfirm={() => deleteLookup()}
          onCancel={() => {
            setIsDelete(false);
            setDeleteMessage("");
          }}
          isOpen={isDelete}
          errorMessage={deleteMessage}
        />

        <Segment>
          <div className="lookUp-selectlist">
            <p style={{ marginTop: "0.5rem" }}>
              {resources.pages.lookup.chooseLookUp}
            </p>
            <Select
              options={lookUpNames}
              placeholder={resources.pages.lookup.chooseLookUp}
              noResultsMessage={resources.common.noData}
              search={Boolean(lookUpNames.length)}
              onChange={(e, { value }) => setparentLookupID(+value)}
              error={parentLookupID ? false : error}
            />
          </div>
          {(isAuthorized(Perm.addLookups) ||
            (isAuthorized(Perm.editLookups) && editing)) &&
          allowisEditable ? (
            <>
              <p style={{ marginTop: "1rem" }}>
                {resources.pages.lookup.lookUpTableTitle}
              </p>
              <div className="lookup-input_button">
                <Input
                  icon="star"
                  autoComplete="off"
                  placeholder={resources.pages.lookup.lookUpTableTitle}
                  value={newLookupName}
                  onChange={handleInputChanges}
                  error={newLookupName ? false : error}
                />
                {editing ? (
                  <>
                    <Button
                      color="green"
                      className="lookup-addButton"
                      onClick={onEditing}
                    >
                      {resources.common.edit}
                    </Button>
                    <Button
                      color="red"
                      className="lookup-addButton"
                      onClick={() => {
                        setEditing(false);
                        setError(false);
                        setNewLookupName("");
                      }}
                    >
                      {resources.common.cancel}
                    </Button>
                  </>
                ) : (
                  <Button
                    color="blue"
                    className="lookup-addButton"
                    onClick={addNewLookup}
                  >
                    {resources.common.save}
                  </Button>
                )}
              </div>
            </>
          ) : null}

          <div className="table-template-container">
            <TableTemplate
              onCellClick={onCellClick}
              headers={headers}
              data={childs}
              edit={
                isAuthorized(Perm.editLookups)
                  ? { valuesID: "iseditable", values: ["NotEditable"] }
                  : null
              }
              remove={
                isAuthorized(Perm.deleteLookups)
                  ? { valuesID: "iseditable", values: ["NotEditable"] }
                  : null
              }
              isLoading={isLoading}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default ManageLookUp;
