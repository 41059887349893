import React from "react";
import { Field } from "react-final-form";
import { Segment, Form, Icon } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";

const DamageDetails = () => {
  return (
    <Segment className="damage-details-section-container">
      <div className="header-container margin-bottom">
        <div className="icon-label-container">
          <Icon name="delete" />
          <label>{resources.pages.maintenanceReport.damageDetails}</label>
        </div>
      </div>

      <Segment.Group horizontal className="damage-details-segment-group">
        <Segment className="damage-details-input-segment left-border">
          <label>{`${resources.pages.maintenanceReport.damage} : `}</label>
          <Field name="damage">
            {({ input, meta }) => (
              <Form.Input
                transparent
                {...input}
                placeholder={resources.pages.maintenanceReport.enterDamage}
                className="damage-details-fields-input"
                autoComplete="off"
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </Segment>

        <Segment className="damage-details-input-segment">
          <label>{`${resources.pages.maintenanceReport.cause} : `}</label>
          <Field name="causes">
            {({ input, meta }) => (
              <Form.Input
                transparent
                {...input}
                placeholder={resources.pages.maintenanceReport.enterCause}
                className="damage-details-fields-input"
                autoComplete="off"
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </Segment>

        <Segment className="damage-details-input-segment">
          <label>{`${resources.pages.maintenanceReport.action} : `}</label>
          <Field name="action">
            {({ input, meta }) => (
              <Form.Input
                transparent
                {...input}
                placeholder={resources.pages.maintenanceReport.enterAction}
                className="damage-details-fields-input"
                autoComplete="off"
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </Segment>
      </Segment.Group>

      <Segment.Group horizontal className="damage-details-segment-group">
        <Segment className="damage-details-note-segment left-border">
          <label>{`${resources.pages.maintenanceReport.technicianNotes} : `}</label>
          <Field name="technicianNotes">
            {({ input, meta }) => (
              <Form.TextArea
                {...input}
                placeholder={resources.pages.maintenanceReport.technicianNotes}
                className="damage-details-fields-input"
                autoComplete="off"
              />
            )}
          </Field>
        </Segment>

        <Segment className="damage-details-note-segment left-border">
          <label>{`${resources.pages.maintenanceReport.userNotes} : `}</label>
          <Field name="clientNotes">
            {({ input, meta }) => (
              <Form.TextArea
                {...input}
                placeholder={resources.pages.maintenanceReport.userNotes}
                className="damage-details-fields-input"
                autoComplete="off"
              />
            )}
          </Field>
        </Segment>
      </Segment.Group>
    </Segment>
  );
};

export default DamageDetails;
