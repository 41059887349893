import React, { useEffect, useState } from "react";
import {
  Button,
  Icon,
  Popup,
  Table,
  Radio,
  Message,
  Modal,
  ModalContent,
  ModalActions,
} from "semantic-ui-react";
import { resources } from "../../../assets/LocalizationResources";
import AddMachinePartsModal from "./AddMachinePartsModal";

const MachineParts = ({
  machineId,
  selectedParts,
  setSelectedParts,
  isReportHavePartInvoice,
  setIsReportHavePartsInvoice,
}) => {
  const [machineSelectedParts, setMachineSelectedParts] =
    useState(selectedParts);
  const [isOpenAddMachinePartsModal, setIsOpenAddMachinePartsModal] =
    useState(false);
  const [
    isSomePartsDoesNotHaveDescriptionModalOpen,
    setIsSomePartsDoesNotHaveDescriptionModalOpen,
  ] = useState(false);

  const handleMachinePartInvoiceTypeChange = (value) => {
    if (
      (value === 2 && selectedParts.some((part) => !part.description)) ||
      selectedParts.some((part) => part.machinePartTypeId === 172)
    ) {
      setIsSomePartsDoesNotHaveDescriptionModalOpen(true);
      return;
    }

    setIsReportHavePartsInvoice(value);
  };

  const addNewMachinePart = (newMachinePart) => {
    setIsOpenAddMachinePartsModal(false);
    setSelectedParts([...machineSelectedParts, { ...newMachinePart }]);
    setMachineSelectedParts([...machineSelectedParts, { ...newMachinePart }]);
  };

  const removeMachinePartRow = (rowIndex) => {
    const values = [...machineSelectedParts];
    values.splice(rowIndex, 1);
    setSelectedParts(values);
    setMachineSelectedParts(values);
  };

  return (
    <>
      <div className="machine-parts-table-container">
        <div className="header-container margin-bottom">
          <div className="icon-label-container">
            <Icon name="settings" />
            <label>{resources.pages.maintenanceReport.machinePartsTable}</label>
          </div>
        </div>

        <Table className="table-container">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>QTY</Table.HeaderCell>
              <Table.HeaderCell>FC</Table.HeaderCell>
              <Table.HeaderCell>ACT</Table.HeaderCell>
              <Table.HeaderCell>description</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <Radio
                  checked={isReportHavePartInvoice === 1}
                  label={resources.pages.maintenanceReport.statistical}
                  onChange={() => handleMachinePartInvoiceTypeChange(1)}
                  value={1}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Radio
                  checked={isReportHavePartInvoice === 2}
                  label={
                    resources.pages.maintenanceReport
                      .createItemMaintenanceInvoice
                  }
                  onChange={() => handleMachinePartInvoiceTypeChange(2)}
                  value={2}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {machineSelectedParts.map((row, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>
                  {row.PartNumber
                    ? row.PartNumber
                    : resources.common.notSpecified}
                </Table.Cell>
                <Table.Cell>{row.MachinePartName}</Table.Cell>
                <Table.Cell>{row.quantity}</Table.Cell>
                <Table.Cell>{row.FC}</Table.Cell>
                <Table.Cell>{row.ACT}</Table.Cell>
                <Table.Cell>
                  {row.description
                    ? row.description
                    : resources.common.notSpecified}
                </Table.Cell>
                <Table.Cell
                  textAlign="center"
                  width={1}
                  onClick={() => removeMachinePartRow(idx)}
                  key="remove"
                >
                  <Popup
                    size="mini"
                    content={resources.common.delete}
                    trigger={<Icon color="red" name="trash" />}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Button
                  floated="left"
                  labelPosition="left"
                  primary
                  icon
                  type="button"
                  size="small"
                  onClick={() => setIsOpenAddMachinePartsModal(true)}
                >
                  <Icon name="plus" />
                  {resources.pages.maintenanceReport.add}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
      <Modal
        open={isSomePartsDoesNotHaveDescriptionModalOpen}
        onClose={() => setIsSomePartsDoesNotHaveDescriptionModalOpen(false)}
        onOpen={() => setIsSomePartsDoesNotHaveDescriptionModalOpen(true)}
        size="tiny"
      >
        <ModalContent>
          <Message
            negative
            compact
            content={
              resources.pages.maintenanceReport
                .thereIsPartsYouAddedDosentHavePartNumberAndDescription
            }
          />
        </ModalContent>
        <ModalActions>
          <Button
            color="black"
            onClick={() => setIsSomePartsDoesNotHaveDescriptionModalOpen(false)}
          >
            {resources.common.close}
          </Button>
        </ModalActions>
      </Modal>

      <AddMachinePartsModal
        machineId={machineId}
        machineSelectedParts={machineSelectedParts}
        isOpen={isOpenAddMachinePartsModal}
        closeModal={() => setIsOpenAddMachinePartsModal(false)}
        addNewMachinePart={addNewMachinePart}
        setIsReportHavePartsInvoice={setIsReportHavePartsInvoice}
        isReportHavePartInvoice={isReportHavePartInvoice}
      />
    </>
  );
};

export default MachineParts;
