import React, { useState, useEffect } from "react";
import serviceApi from "../../api";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TicketActionsModal from "../../components/Tickets/TicketActionsModal";
import {
  Segment,
  Container,
  Modal,
  Button,
  Dimmer,
  Loader,
  Input,
  Label,
  Message,
  Divider,
} from "semantic-ui-react";
import { Form } from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import ComputerInfos from "./ComputerInfos";
import Counters from "./Counters";
import MachineParts from "./MachinePartsSection/MachineParts";
import DamageDetails from "./DamageDetails";
import ClientInformations from "./ClientInformations";
import { resources } from "../../assets/LocalizationResources";
import "../MaintenanceReport/MaintenanceReport.scss";
import CustomModal from "./CustomModal";

export default function MaintenanceReport({ location }) {
  // Ticket Information
  const finalFormInitialValues = {
    TicketID: Boolean(location && location.state && location.state.ticketID)
      ? location.state.ticketID
      : null,
    counter1Image: null,
    counter2Image: null,
    VisitTypeID: 2,
    MaintenanceTypeID: 7,
    lastCounter1: 0,
    lastCounter2: 0,
    counter1: 0,
    counter2: 0,
    startDate: new Date(),
    damage: null,
    causes: null,
    action: null,
    technicianNotes: null,
    clientNotes: null,
    clientEmployeeName: null,
    clientEmployeeJob: null,
    clientSignature: null,
    clientCardImage: null,
    DeliverStatusID: 8044,
    manualReportNumber: "",
    manualReportImg: null,
    counter1Confirm: 0,
    counter2Confirm: 0,
  };
  const [finalFormDetails, setFinalFormDetails] = useState({
    finalFormPageNumber: 0,
    finalFormValues: { ...finalFormInitialValues },
  });

  // User Information
  const currentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );

  // Error Handling
  const [errorMessage, setErrorMessage] = useState("");

  // Parts and Machines
  const [selectedParts, setSelectedParts] = useState([]);
  const [machineInfo, setMachineInfo] = useState(null);
  const [ticketStatus, setTicketStatus] = useState([]);
  const [maintenanceReportId, setMaintenanceReportId] = useState(null);
  const [contractMachinIsNull, setContractMachineIsNull] = useState(false);
  const [isReportHavePartInvoice, setIsReportHavePartsInvoice] = useState(1);

  // Modals and Alerts
  const [openTicketActionModal, setOpenTicketActionModal] = useState(false);

  const [openMisMatchCountersModal, setOpenMisMatchCountersModal] =
    useState(false);
  const [openInvoiceCountersModal, setOpenInvoiceCountersModal] =
    useState(false);
  const [openManualReportModal, setOpenManualReportModal] = useState(false);

  // Loading State
  const [isLoading, setIsLoading] = useState(false);

  // Image Uploads
  const [counterImgs, setCounterImgs] = useState({
    counter1Img: null,
    counter2Img: null,
  });
  const [clientSignature, setClientSignature] = useState(null);
  const [clientCardPhoto, setClientCardPhoto] = useState(null);

  // Invoice Counters and Errors
  const [invoiceCountersFormData, setInvoiceCountersFormData] = useState({
    withInvoice: false,
    counter1: 0,
    counter1Confirm: 0,
    counter2: 0,
    counter2Confirm: 0,
  });
  const [invoiceCountersError, setInvoiceCountersError] = useState(false);

  // Manual Report
  const [manualReportNumber, setManualReportNumber] = useState("");
  const [manualReportImg, setManualReportImg] = useState(null);
  const [manualReportData, setManualReportData] = useState({
    manualReportNumber: "",
    manualReportImg: null,
  });

  const [modalData, setModalData] = useState({
    open: false,
    header: "",
    content: "",
    buttonContent: "",
  });

  const router = useHistory();

  const setLastCounters = (args, state, utils) => {
    utils.changeValue(state, "lastCounter1", () => args[0].lastCounter1);
    utils.changeValue(state, "lastCounter2", () => args[0].lastCounter2);
  };

  const getLastInvoiceCounterForSelectedMachine = async (machineId) => {
    const counter1 = await serviceApi.service("queries-text").find({
      query: {
        machineId: machineId,
        type: 33,
      },
    });
    setInvoiceCountersFormData({
      ...invoiceCountersFormData,
      counter1: counter1,
      counter1Confirm: counter1,
    });
  };

  const onSubmit = async (values) => {
    if (
      !invoiceCountersFormData.withInvoice &&
      values.MaintenanceTypeID == 9 &&
      finalFormDetails.finalFormPageNumber === 4
    ) {
      setModalData({
        open: true,
        header: resources.common.errors.warning,
        content:
          resources.pages.maintenanceReport
            .youCanNotCreateInvoiceWithoutConfirmInvoiceCounters,
        buttonContent: resources.common.ok,
      });
      return;
    }
    if (finalFormDetails.finalFormPageNumber === 4) {
      try {
        setIsLoading(true);
        const MachineID = machineInfo.MachineID;
        if (
          values.MaintenanceTypeID == 9 &&
          invoiceCountersFormData.withInvoice
        ) {
          if (machineInfo.CopyPrice === 0) {
            setModalData({
              open: true,
              header: resources.common.errors.warning,
              content:
                resources.pages.maintenanceReport.zeroCopyPriceWarningModalBody,
              buttonContent: resources.common.ok,
            });
            setIsLoading(false);
            return;
          }
          if (values.counter1 - invoiceCountersFormData.counter1 <= 0) {
            setModalData({
              open: true,
              header: resources.common.errors.warning,
              content:
                resources.pages.maintenanceReport
                  .countersMismatchValidationError,
              buttonContent: resources.common.ok,
            });
            setIsLoading(false);
            return;
          }
          const res = await getInvoiceRelatedDataFromAlphaDB(
            MachineID,
            currentUserID
          );
          if (!res) return;

          const { salesmenNo, number, itemNumber } = res;
          const unColoredAchievedCopies =
            values.counter1 - invoiceCountersFormData.counter1;
          const coloredAchievedCopies =
            values.counter2 - invoiceCountersFormData.counter2;
          const totalValue =
            unColoredAchievedCopies * machineInfo.CopyPrice +
            coloredAchievedCopies * machineInfo.CopyPriceColorful;

          const { maintenanceReportID, machinePartInvoiceNumber } =
            await createMaintenanceReportDetails(values);

          if (
            selectedParts.length > 0 &&
            machinePartInvoiceNumber &&
            isReportHavePartInvoice === 2
          ) {
            await syncMachinePartInvoiceToAlphaDB(
              machinePartInvoiceNumber,
              res,
              values.counter1
            );
          }
          await serviceApi.service("queries-text").create({
            type: "createInvoice",
            copyPrice: machineInfo.CopyPrice,
            maintenanceReportID: maintenanceReportID,
            total: totalValue,
            tax: totalValue * 0.16,
            currentCounter: values.counter1,
            lastInvoiceCounter: invoiceCountersFormData.counter1,
            createdBy: currentUserID,
            salesmenNo,
            number,
            itemNumber,
          });

          /* try {
            await serviceApi.service("queries-text").create({
              type: "invoiceLogger",
              valuesCounter1: values.counter1,
              valuesLastCounter1: values.lastCounter1,
              invoiceCounter1: invoiceCountersFormData.counter1,
              maintenanceReportId: maintenanceReportID,
              copyPrice: machineInfo.CopyPrice,
              copyPriceColorful: machineInfo.CopyPriceColorful,
              tax: totalValue * 0.16,
              total: totalValue,
              itemNumber,
              salesmenNo,
              number,
            });
          } catch (error) {} */
          onSuccessMaintenanceReportCreation(maintenanceReportID);
        } else {
          let alaphRes;
          if (selectedParts.length > 0 && isReportHavePartInvoice === 2) {
            const res = await getInvoiceRelatedDataFromAlphaDB(
              MachineID,
              currentUserID
            );
            if (!res) return;

            alaphRes = res;
          }

          const { maintenanceReportID, machinePartInvoiceNumber } =
            await createMaintenanceReportDetails(values);
          if (
            alaphRes &&
            machinePartInvoiceNumber &&
            isReportHavePartInvoice === 2
          ) {
            await syncMachinePartInvoiceToAlphaDB(
              machinePartInvoiceNumber,
              alaphRes,
              values.counter1
            );
          }

          onSuccessMaintenanceReportCreation(maintenanceReportID);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      if (
        values.counter1Confirm !== values.counter1 ||
        values.counter2Confirm !== values.counter2
      ) {
        setOpenMisMatchCountersModal(true);
        return;
      } else if (
        finalFormDetails.finalFormPageNumber === 1 &&
        values.lastCounter1 > values.counter1
      ) {
        setModalData({
          open: true,
          header: resources.common.errors.warning,
          content: resources.pages.maintenanceReport.counterWarningModalBody,
          buttonContent: resources.common.cancel,
        });
        return;
      } else if (
        finalFormDetails.finalFormPageNumber === 1 &&
        values.counter1 >= values.lastCounter1
      ) {
        setModalData({
          open: true,
          header: resources.common.errors.warning,
          content: `${
            resources.pages.maintenanceReport
              .theDifferenceBetweenCounter1AndLastCounter1
          } ${values.counter1 - values.lastCounter1}`,
          buttonContent: resources.common.cancel,
        });
      }

      setFinalFormDetails({
        finalFormPageNumber: finalFormDetails.finalFormPageNumber + 1,
        finalFormValues: { ...values },
      });
    }
  };

  const syncMachinePartInvoiceToAlphaDB = async (
    machinePartInvoiceNumber,
    res,
    counter1
  ) => {
    await serviceApi.service("queries-text").create({
      type: "syncMachinePartInvoice",
      machinePartInvoiceNumber,
      selectedParts: selectedParts.map((row) => ({
        itemNumber: row.PartNumber,
        quantity: row.quantity,
      })),
      ...res,
      counter1
    });
  };

  const getInvoiceRelatedDataFromAlphaDB = async (machineId, currentUserID) => {
    const res = await serviceApi.service("queries-text").find({
      query: {
        type: 28,
        machineID: machineId,
        userId: currentUserID,
      },
    });
    if (!res.success) {
      setModalData({
        open: true,
        header: resources.common.errors.anErrorOccur,
        content: resources.pages.maintenanceReport[res.msg],
        buttonContent: resources.common.ok,
      });
      setIsLoading(false);
      return;
    }

    return res;
  };

  const onSuccessMaintenanceReportCreation = (maintenanceReportId) => {
    setIsLoading(false);
    if (Boolean(location && location.state && location.state.ticketID)) {
      setOpenTicketActionModal(true);
    } else {
      setOpenTicketActionModal(false);
      window.open(
        `/maintenanceReportPrintView?id=${maintenanceReportId}`,
        "_blank"
      );
      router.replace("/maintenanceReportsList");
    }
  };

  const createMaintenanceReport = async (values) => {
    let machinePartInvoiceNumber = null;
    if (selectedParts.length > 0 && isReportHavePartInvoice == 2) {
      //! get next machine part invoice number
      machinePartInvoiceNumber = await serviceApi.service("queries-text").find({
        query: {
          type: 31,
        },
      });
    }

    const res = await serviceApi.service("maintenanceReport").create({
      ...values,
      counter1: values.counter1,
      counter2: values.counter2,
      counter1Image: counterImgs.counter1Img ? counterImgs.counter1Img : null,
      counter2Image: counterImgs.counter2Img ? counterImgs.counter2Img : null,
      ContractMachineID: machineInfo.ContractMachineID,
      MachineID: machineInfo.MachineID,
      clientSignature: clientSignature ? clientSignature : null,
      clientCardImage: clientCardPhoto ? clientCardPhoto : null,
      manualReportNumber: manualReportData.manualReportNumber
        ? manualReportData.manualReportNumber
        : "",
      manualReportImg: manualReportData.manualReportImg
        ? manualReportData.manualReportImg
        : null,
      endDate: new Date(),
      lastVisitDate: values.startDate,
      machinePartInvoiceNumber,
    });
    const maintenanceReportID = res.maintenanceReportId;
    setMaintenanceReportId(maintenanceReportID);

    return { maintenanceReportID, machinePartInvoiceNumber };
  };

  const createMaintenanceReportDetails = async (values) => {
    const { maintenanceReportID, machinePartInvoiceNumber } =
      await createMaintenanceReport(values);
    const MachineID = machineInfo.MachineID;

    const requests = [
      ...selectedParts.map(async (row) => {
        serviceApi.service("maintenanceReportMachinePart").create({
          ...row,
          partNumber: row.PartNumber,
          MaintenanceReportID: maintenanceReportID,
        });
      }),
      serviceApi.service("maintenanceReportTechnician").create({
        MaintenanceReportID: maintenanceReportID,
        TechnicianID: CurrentTechnicianID,
      }),
      serviceApi.service("Machine").patch(MachineID, {
        lastVisitDate: values.startDate,
      }),
    ];

    await Promise.all(requests);

    if (values.VisitTypeID === 2 /*طلب*/)
      await serviceApi.service("Machine").patch(MachineID, {
        lastMaintenaceDate: values.startDate,
      });

    //based on our phone call with renata 24/4/2022, she told us on every visit the last visit date should be updated regardless of the type of visit
    // else if (values.VisitTypeID === 3 /*دوري*/)
    //   await serviceApi.service("Machine").patch(MachineID, {
    //     lastVisitDate: values.startDate,
    //   });
    return { maintenanceReportID, machinePartInvoiceNumber };
  };

  const getMachineInfo = async (
    enteredNumber,
    searchBySerialNumber = false
  ) => {
    getLastInvoiceCounterForSelectedMachine(enteredNumber);
    try {
      if (enteredNumber) {
        const res = await serviceApi.service("queries").find({
          query: {
            model: "ContractMachine",
            attributes: [
              "ContractMachineID",
              "copyPriceColorful",
              "contractIsActive",
              "copyPrice",
            ],
            include: [
              {
                model: "ClientBranch",
                attributes: ["BranchName", "ClientID", "BranchID"],
                include: [
                  {
                    model: "Client",
                    attributes: ["ClientName"],
                  },
                  {
                    model: "Area",
                    attributes: ["AreaName"],
                  },
                ],
              },
              {
                model: "Machine",
                attributes: [
                  "machineId",
                  "serialNumber",
                  "machineTypeID",
                  "machineModelID",
                  "computerNumber",
                ],
                include: [
                  {
                    model: "Lookup",
                    as: "MachineType",
                    attributes: ["LookupName"],
                  },
                  {
                    model: "Lookup",
                    as: "MachineModel",
                    attributes: ["LookupName"],
                  },
                ],
                ...(searchBySerialNumber && {
                  where: {
                    serialNumber: enteredNumber,
                  },
                }),
              },
              {
                innerJoin: true,
                model: "Contract",
                attributes: [
                  "ContractID",
                  "contractNumber",
                  "endDate",
                  "HasPDF",
                ],
                include: [
                  {
                    model: "Lookup",
                    as: "ContractType",
                    attributes: ["LookupName"],
                  },
                ],
              },
            ],
            where: {
              contractIsActive: 1,
              ...(!searchBySerialNumber && { MachineID: enteredNumber }),
            },
          },
        });
        const flattenedData = res.data.map((row) => ({
          MachineID: row.Machine.machineId,
          MachineType: row.Machine.MachineType.LookupName,
          MachineModel: row.Machine.MachineModel.LookupName,
          SerialNumber: row.Machine.serialNumber,
          computerNumber: row.Machine.computerNumber,
          ClientName: row.ClientBranch.Client.ClientName,
          AreaName: row.ClientBranch.Area.AreaName,
          BranchName: row.ClientBranch.BranchName,
          BranchID: row.ClientBranch.BranchID,
          ContractMachineID: row.ContractMachineID,
          ContractNumber: row.Contract.contractNumber,
          ContractType: row.Contract.ContractType.LookupName,
          CopyPrice: row.copyPrice ? row.copyPrice : 0,
          CopyPriceColorful: row.copyPriceColorful ? row.copyPriceColorful : 0,
          contractIsEnd: Boolean(
            new Date().getTime() > new Date(row.Contract.endDate).getTime()
          ),
          ContractID: row.Contract.ContractID,
          HasPDF: row.Contract.HasPDF,
        }));
        setFinalFormDetails({
          finalFormPageNumber: 0,
          finalFormValues: { ...finalFormInitialValues },
        });
        if (flattenedData[0]) {
          setMachineInfo(flattenedData[0]);
          if (flattenedData[0].CopyPrice === 0) {
            setModalData({
              open: true,
              header: resources.common.errors.warning,
              content:
                resources.pages.maintenanceReport.zeroCopyPriceWarningModalBody,
              buttonContent: resources.common.ok,
            });

            setIsLoading(false);
            return;
          }
        } else {
          setMachineInfo(null);
          setModalData({
            open: true,
            header:
              resources.pages.maintenanceReport.numberNotFoundErrorMessage,
            content:
              resources.pages.maintenanceReport.pleaseCheckTheEnteredNumber,
            buttonContent: resources.common.ok,
          });
        }
      } else {
        setMachineInfo(null);
        setSelectedParts([]);
        setClientSignature(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTicketStatus = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 96 /*TICKET STATUS */,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0) {
        setTicketStatus(
          res.data
            .filter((m) => m.LookupID !== 97)
            .map((c) => ({
              key: c.LookupID,
              text: c.LookupName,
              value: c.LookupID,
            }))
        );
      } else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.computerNumber)) {
      getMachineInfo(location.state.computerNumber);
      setContractMachineIsNull(false);
    } else setContractMachineIsNull(true);

    Boolean(location && location.state && location.state.ticketID) &&
      getTicketStatus();
  }, []);

  const CurrentForm = ({ form }) => {
    switch (finalFormDetails.finalFormPageNumber) {
      case 0:
        return (
          <ComputerInfos
            getMachineInfo={getMachineInfo}
            machineInfo={machineInfo}
          />
        );
      case 1:
        return (
          <Counters
            contractMachineId={machineInfo.ContractMachineID}
            setLastCounters={form.mutators.setLastCounters}
            counterImgs={counterImgs}
            setCounterImgs={setCounterImgs}
            HasPDF={machineInfo.HasPDF}
            contractID={machineInfo.ContractID}
            setOpenMisMatchCountersModal={setOpenMisMatchCountersModal}
            openMisMatchCountersModal={openMisMatchCountersModal}
          />
        );
      case 2:
        return (
          <MachineParts
            machineId={machineInfo.MachineID}
            setSelectedParts={setSelectedParts}
            selectedParts={selectedParts}
            setIsReportHavePartsInvoice={setIsReportHavePartsInvoice}
            isReportHavePartInvoice={isReportHavePartInvoice}
          />
        );
      case 3:
        return <DamageDetails />;
      case 4:
        return (
          <ClientInformations
            clientSig={clientSignature}
            setClientSig={setClientSignature}
            clientCardPhoto={clientCardPhoto}
            setClientCardPhoto={setClientCardPhoto}
          />
        );
    }
  };

  const validator = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;

    if (!values.damage && finalFormDetails.finalFormPageNumber === 3)
      errors.damage = requiredField;
    // if (!values.causes && finalFormDetails.finalFormPageNumber === 3)
    //   errors.causes = requiredField;
    if (!values.action && finalFormDetails.finalFormPageNumber === 3)
      errors.action = requiredField;
    if (
      !values.clientEmployeeName &&
      finalFormDetails.finalFormPageNumber === 4
    )
      errors.clientEmployeeName = requiredField;
    if (!values.clientEmployeeJob && finalFormDetails.finalFormPageNumber === 4)
      errors.clientEmployeeJob = requiredField;

    return errors;
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (upload) {
        const binaryString = upload.target.result;
        setManualReportImg(`data:image/jpeg;base64, ${btoa(binaryString)}`);
      };
    }
  };

  const validateInput = () => {
    let error = "";
    if (!Boolean(manualReportNumber || manualReportData.manualReportNumber))
      error = "الرجاء إدخال رقم التقرير";

    setErrorMessage(error);

    if (Boolean(error)) return false;
    else return true;
  };

  const onManualReportSave = () => {
    if (validateInput()) {
      setManualReportData({
        manualReportImg: manualReportImg
          ? manualReportImg
          : manualReportData.manualReportImg,
        manualReportNumber: manualReportNumber
          ? manualReportNumber
          : manualReportData.manualReportNumber,
      });
      setOpenManualReportModal(false);
    }
  };

  const clearManualReportModal = () => {
    setManualReportNumber("");
    setManualReportImg(null);
    setOpenManualReportModal(false);
  };

  useEffect(() => {
    if (Boolean(manualReportNumber)) validateInput();
  }, [manualReportNumber]);

  const validateCounters = () => {
    if (
      invoiceCountersFormData.counter1 !=
      invoiceCountersFormData.counter1Confirm
    ) {
      setInvoiceCountersError(true);
      return true;
    }
    if (
      invoiceCountersFormData.counter2 !=
      invoiceCountersFormData.counter2Confirm
    ) {
      setInvoiceCountersError(true);
      return true;
    }
    return false;
  };

  return (
    <>
      <Container className="maintenance-report-container">
        <CustomModal modalData={modalData} setModalData={setModalData} />

        {/*Submit Button*/}
        <Segment className="maintenance-report-content">
          <Segment className="maintenance-report-header">
            <label>{resources.pages.maintenanceReport.maintenanceReport}</label>
          </Segment>
          <FinalForm
            initialValues={finalFormDetails.finalFormValues}
            mutators={{
              setLastCounters: setLastCounters,
            }}
            validate={validator}
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <CurrentForm form={form} />

                  <div
                    className={
                      Boolean(finalFormDetails.finalFormPageNumber > 0)
                        ? "submit-previous-buttons-container"
                        : "submit-button-container"
                    }
                  >
                    {finalFormDetails.finalFormPageNumber > 0 && (
                      <Button
                        type="button"
                        onClick={() =>
                          setFinalFormDetails({
                            ...finalFormDetails,
                            finalFormPageNumber:
                              finalFormDetails.finalFormPageNumber - 1,
                          })
                        }
                        icon="arrow right"
                        content={resources.common.previous}
                        labelPosition="right"
                      />
                    )}
                    {finalFormDetails.finalFormPageNumber <= 4 && (
                      <div>
                        {finalFormDetails.finalFormPageNumber === 4 && (
                          <>
                            <Button
                              type="button"
                              content={resources.pages.machines.createInvoice}
                              color="blue"
                              onClick={() => setOpenInvoiceCountersModal(true)}
                              icon="wpforms"
                              labelPosition="left"
                              primary
                            />
                            <Button
                              type="button"
                              content={
                                resources.pages.maintenanceReport
                                  .manualReportInfo
                              }
                              color="blue"
                              onClick={() => setOpenManualReportModal(true)}
                              icon="file alternate outline"
                              labelPosition="left"
                              primary
                            />
                          </>
                        )}
                        <Button
                          type="sumbit"
                          icon={
                            finalFormDetails.finalFormPageNumber === 4
                              ? "save"
                              : "arrow left"
                          }
                          content={
                            finalFormDetails.finalFormPageNumber === 4
                              ? resources.common.save
                              : resources.pages.maintenanceReport.next
                          }
                          labelPosition="left"
                          primary
                          disabled={
                            Boolean(
                              !selectedParts.length &&
                                isReportHavePartInvoice === 2
                            ) ||
                            !Boolean(
                              machineInfo && machineInfo.ContractMachineID
                            )
                              ? true
                              : false
                          }
                        />
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          />
        </Segment>
      </Container>

      {/*انشاء فاتورة*/}
      <Modal
        open={openInvoiceCountersModal}
        onClose={() => setOpenInvoiceCountersModal(false)}
      >
        <Modal.Header>{resources.pages.machines.createInvoice}</Modal.Header>
        <Modal.Content
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <label>{resources.pages.maintenanceReport.lastCounter1}</label>
            <Input
              value={invoiceCountersFormData.counter1}
              onChange={(e, { value }) => {
                if (+value >= 0) {
                  setInvoiceCountersFormData({
                    ...invoiceCountersFormData,
                    counter1: value,
                  });
                  setInvoiceCountersError(false);
                }
              }}
            />
            <label className="maintenance-label">
              {resources.pages.maintenanceReport.counter1Confirm}
            </label>
            <Input
              value={invoiceCountersFormData.counter1Confirm}
              onChange={(e, { value }) => {
                if (+value >= 0) {
                  setInvoiceCountersFormData({
                    ...invoiceCountersFormData,
                    counter1Confirm: value,
                  });
                  setInvoiceCountersError(false);
                }
              }}
            />
          </div>
          <Divider />
          <div style={{ display: "flex", flexDiraction: "row", gap: "1rem" }}>
            <label className="maintenance-label" style={{ width: "11.5rem" }}>
              {resources.pages.maintenanceReport.lastCounter2}
            </label>
            <Input
              value={invoiceCountersFormData.counter2}
              onChange={(e, { value }) => {
                if (+value >= 0) {
                  setInvoiceCountersFormData({
                    ...invoiceCountersFormData,
                    counter2: value,
                  });
                  setInvoiceCountersError(false);
                }
              }}
            />
            <label className="maintenance-label" style={{ width: "14.5rem" }}>
              {resources.pages.maintenanceReport.counter2Confirm}
            </label>
            <Input
              value={invoiceCountersFormData.counter2Confirm}
              onChange={(e, { value }) => {
                if (+value >= 0) {
                  setInvoiceCountersFormData({
                    ...invoiceCountersFormData,
                    counter2Confirm: value,
                  });
                  setInvoiceCountersError(false);
                }
              }}
            />
          </div>
          {invoiceCountersError && (
            <Message error>
              <Message.Header>
                {resources.pages.maintenanceReport.countersDontMatch}
              </Message.Header>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            labelPosition="left"
            content={resources.common.confirm}
            onClick={() => {
              if (!validateCounters()) {
                setInvoiceCountersFormData({
                  ...invoiceCountersFormData,
                  withInvoice: true,
                });
                setOpenInvoiceCountersModal(false);
              }
            }}
            icon="check"
          />
          <Button
            content={resources.common.close}
            labelPosition="left"
            onClick={() => {
              setInvoiceCountersFormData({
                withInvoice: false,
                counter1: 0,
                counter1Confirm: 0,
                counter2: 0,
                counter2Confirm: 0,
              });
              setOpenInvoiceCountersModal(false);
            }}
            icon={"close"}
          />
        </Modal.Actions>
      </Modal>

      {/*معلومات التقرير اليدوي*/}
      <Modal size="small" open={openManualReportModal}>
        <Modal.Header>معلومات التقرير اليدوي</Modal.Header>
        <Modal.Content className="manual-report-content">
          <div className="manual-report-fields-container">
            <label>رقم التقرير</label>
            <Input
              id="manual-report-input"
              placeholder="رقم التقرير"
              value={
                manualReportNumber
                  ? manualReportNumber
                  : manualReportData.manualReportNumber
              }
              onChange={(e, { value }) => setManualReportNumber(value)}
              fluid
              error={Boolean(errorMessage)}
              autoComplete="off"
            />
            {Boolean(errorMessage) && (
              <Label basic color="red" pointing="right">
                {errorMessage}
              </Label>
            )}
          </div>
          <div className="manual-report-fields-container">
            <label>رفع صورة التقرير</label>
            <Input
              type="file"
              onChange={(e) => handleImage(e)}
              accept=".jpeg, .png, .jpg"
            />
          </div>
          {Boolean(manualReportImg || manualReportData.manualReportImg) && (
            <img
              src={
                manualReportImg
                  ? manualReportImg
                  : manualReportData.manualReportImg
              }
              alt="manual-report-img"
              style={{
                maxWidth: "100%",
                maxHeight: 400,
                objectFit: "contain",
              }}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" type="button" onClick={onManualReportSave}>
            حفظ
          </Button>

          <Button
            type="button"
            color="red"
            onClick={() => {
              clearManualReportModal();
              setManualReportData({
                manualReportImg: null,
                manualReportNumber: "",
              });
            }}
          >
            حذف البيانات
          </Button>

          <Button
            type="button"
            onClick={() => {
              clearManualReportModal();
            }}
          >
            إلغاء
          </Button>
        </Modal.Actions>
      </Modal>

      <TicketActionsModal
        ticketStatus={ticketStatus}
        ticketID={finalFormDetails.finalFormValues.TicketID}
        isOpen={openTicketActionModal}
        contractMachineID={
          Boolean(contractMachinIsNull) && Boolean(machineInfo)
            ? machineInfo.ContractMachineID
            : null
        }
        closeChangeTicketModal={() => {
          setOpenTicketActionModal(false);
          window.open(`/maintenanceReportPrintView?id=${maintenanceReportId}`);
          router.replace("/maintenanceReportsList");
        }}
      />

      <Dimmer active={isLoading} inverted>
        <Loader>{resources.common.loading}</Loader>
      </Dimmer>
    </>
  );
}
