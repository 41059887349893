import React, { useState, useEffect } from "react";
import PrintOutHeader from "../PrintOutHeader/PrintOutHeader";
import "./techAreasAndMachinesNumPrintOut.scss";
import serviceApi from "../../api";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";

const TechAreasAndMachinesNumPrintOut = () => {
  const [techAreasAndMachinesData, setTechAreasAndMachinesData] = useState([]);
  const [totalNumberOfMachines, setTotalNumberOfMachines] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { TechnicianID, TechnicianName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          techID: TechnicianID,
          type: 4,
        },
      });
      let totalSum = 0;
      const data = res.map((row) => {
        totalSum += row.machineNum;
        return {
          ...row,
          AreaName: row.AreaName,
          machineNum: row.machineNum,
        };
      });
      setTechAreasAndMachinesData(data);
      setTotalNumberOfMachines(totalSum);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return [];
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="tech-areas-machine-num-printout-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(techAreasAndMachinesData.length) ? (
        <div>
          <PrintOutHeader
            printOutTitle={"مناطق الفني و عدد الاّلات التي لها عقود"}
            hasDate={false}
          />
          <div className="tech-areas-machine-num-printout-table-container">
            <div className="tech-areas-machine-num-printout-techName-container">
              <h4>{TechnicianName}</h4>
            </div>
            <table className="tech-areas-machine-num-printout-table">
              <thead>
                <tr>
                  <th>المنطقه</th>
                  <th>عدد الاّلاّت</th>
                </tr>
              </thead>
              <tbody>
                {techAreasAndMachinesData.map((info, idx) => (
                  <tr key={idx}>
                    <td> {info.AreaName ? info.AreaName : " "}</td>
                    <td> {info.machineNum ? info.machineNum : "0"}</td>
                  </tr>
                ))}
                {/* <td></td>
              <td></td> */}
              </tbody>
            </table>
          </div>
          <div className="tech-areas-machine-num-printout-divider"></div>
          <div className="tech-areas-machine-num-printout-sum-lbl-container">
            <label>{`عدد الاّلاّت: ${totalNumberOfMachines}`}</label>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default TechAreasAndMachinesNumPrintOut;
