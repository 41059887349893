import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Icon,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import MapModal from "../../components/Map/MapModal";
import wkt from "terraformer-wkt-parser";
import AddContactPersons from "./AddContactPersonModal";
import useDebounce from "../../components/helpers/useDebounce";

import AddressingServiceApi from "../../api/addressing-service.api";

export default function ManageBranch({ location }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [clients, setClients] = useState([]);
  const [locPickerModal, setLocPickerModal] = useState(null);
  const [areas, setAreas] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [xy, setXY] = useState({ x: 0, y: 0 });
  const [address, setAddress] = useState("");
  const [getAddressLoading, setGetAddressLoading] = useState(false);
  const [getAddressError, setGetAddressError] = useState("");
  const [isViewArea, setIsViewArea] = useState(false);
  const [areaGeometry, setAreaGeometry] = useState(null);
  const [AreaID, setAreaID] = useState(null);
  const [contactPersons, setCotnactPersons] = useState([
    { personName: "", personPhoneNumber: "", personEmail: "" },
  ]);
  const [openContactPersonModal, setOpenContactPersonModal] = useState(false);
  const router = useHistory();

  const [areaName, setAreaName] = useState("");
  const [areaNamesIsLoading, setAreaNamesIsLoading] = useState(false);

  const debouncedAreaNameSearch = useDebounce(areaName, 1000);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const newValues = {
        ...values,
        longitude: xy.x,
        latitude: xy.y,
        address: address,
        contactPerson: Boolean(
          contactPersons &&
            contactPersons.every((p) => p.personName && p.personPhoneNumber)
        )
          ? JSON.stringify(contactPersons)
          : null,
      };

      if (initialValues && initialValues.branchId) {
        await serviceApi
          .service("ClientBranch")
          .patch(+initialValues.branchId, newValues);
      } else await serviceApi.service("ClientBranch").create(newValues);
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const fillEditData = async (branchID) => {
    try {
      const res = await serviceApi.service("ClientBranch").get(branchID);

      if (res) {
        getClientName(res.ClientID);
        fillAreas(res.AreaID);
        setInitialValues(res);
      }
      setCotnactPersons(JSON.parse(res.contactPerson));
      setAddress(res.address);
      setXY({ x: res.longitude, y: res.latitude });
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.branchID))
      fillEditData(+location.state.branchID);
    else if (Boolean(location && location.state && location.state.clientID))
      getClientName(location.state.clientID);
  }, [location]);

  useEffect(() => {
    if (
      Boolean(clients && clients.length > 0) &&
      Boolean(location && location.state && location.state.clientID)
    )
      setInitialValues({
        ...initialValues,
        ClientID: location.state.clientID,
        branchName: resources.pages.branch.main,
      });
  }, [clients]);

  const getClientName = async (clientID) => {
    try {
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "ClientName"],
          ClientID: clientID,
        },
      });

      if (res && res.data && res.data.length > 0)
        setClients(
          res.data.map((c) => {
            return { key: c.ClientID, text: c.ClientName, value: c.ClientID };
          })
        );
      else setClients([]);
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  const fillAreas = async (areaID = null) => {
    try {
      setAreaNamesIsLoading(true);
      const res = await serviceApi.service("area").find({
        query: {
          $select: ["areaName", "areaID"],
          areaName: areaName,
          ...(areaID ? { areaID: areaID } : {}),
          limit: 10,
          skip: 0,
        },
      });

      if (res && res.data && res.data.length > 0)
        setAreas(
          res.data.map((c) => {
            return { key: c.areaID, text: c.areaName, value: c.areaID };
          })
        );
      else setAreas([]);

      setAreaNamesIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setAreaNamesIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(areaName && areaName.length > 2)) fillAreas();
  }, [debouncedAreaNameSearch]);

  useEffect(() => {
    if (!Boolean(location && location.state && location.state.branchID))
      setIsLoading(false);
  }, []);

  const handleConfirm = () => {
    router.replace("/ClientBranches/ClientBranchesList");
  };

  const handleSaveLocation = async (latLng) => {
    setGetAddressError("");
    setGetAddressLoading(true);
    if (Boolean(latLng) && locPickerModal) {
      setLocPickerModal(false);
      try {
        const text = await AddressingServiceApi.getAddress(
          latLng.lng,
          latLng.lat
        );
        if (text) {
          setXY({ x: latLng.lng, y: latLng.lat });
          setAddress(text);
          setGetAddressLoading(false);
        }
      } catch (error) {
        setGetAddressLoading(false);
        setGetAddressError(resources.common.errors.genericServerError);
        console.log("error", error);
      }
    }
  };

  //TODO: view area on popup
  const ViewArea = async () => {
    if (Boolean(AreaID)) {
      try {
        const res = await serviceApi.service("area").get(AreaID);
        if (res && res.length > 0 && res[0].geometry) {
          setAreaGeometry(wkt.parse(res[0].geometry));
          setIsViewArea(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const validateEmail = (email) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!values.ClientID) {
      errors.ClientID = requiredField;
    }
    // if (!values.branchNo) {
    //   errors.branchNo = requiredField;
    // }
    if (!values.branchName) {
      errors.branchName = requiredField;
    }
    // if (!values.email) {
    //   errors.email = requiredField;
    // } else if (!validateEmail(values.email))
    //   errors.email = resources.common.errors.invalidEntry;
    // if (!values.phone) {
    //   errors.phone = requiredField;
    // }
    if (!Boolean(values.AreaID)) errors.AreaID = requiredField;

    if (!Boolean(xy.x) || !Boolean(xy.y)) errors.address = requiredField;

    return errors;
  };

  return (
    <>
      <MapModal
        isOpen={locPickerModal}
        handleSaveLocation={handleSaveLocation}
        onCancel={() => setLocPickerModal(false)}
      />
      <MapModal
        isOpen={isViewArea}
        jsonData={areaGeometry}
        onCancel={() => setIsViewArea(false)}
      />
      <AddContactPersons
        isOpen={openContactPersonModal}
        contactPersons={contactPersons}
        setCotnactPersons={(values) => {
          setCotnactPersons(values);
        }}
        contactPersonsModalHeader={
          initialValues && initialValues.branchId
            ? resources.pages.branch.editContactPersons
            : resources.pages.branch.addContactPersons
        }
        closeModal={() => {
          setOpenContactPersonModal(false);
        }}
      />
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              initialValues && initialValues.branchId
                ? resources.pages.branch.editBranch
                : resources.pages.branch.addBranch
            }
            icon="building"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={checkValidation}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid columns={3} stackable>
                    <Grid.Column>
                      <p>{resources.pages.branch.clientName}</p>
                      <Field name="ClientID">
                        {({ input, meta }) => (
                          <Form.Select
                            options={clients}
                            noResultsMessage={resources.common.noData}
                            {...input}
                            disabled={Boolean(location && location.state)}
                            onChange={(e, { value }) => input.onChange(value)}
                            error={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    {/* <Grid.Column>
                      <p>{resources.pages.branch.branchNo}</p>
                      <Field name="branchNo">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="info"
                            placeholder={resources.pages.branch.branchNo}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column> */}
                    <Grid.Column>
                      <p>{resources.pages.branch.branchName}</p>
                      <Field name="branchName">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="building"
                            placeholder={resources.pages.branch.branchName}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.user.email}</p>
                      <Field name="email">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="mail"
                            placeholder={resources.pages.user.email}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.branchPhoneNumber}</p>
                      <Field name="phone">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="phone volume"
                            placeholder={
                              resources.pages.branch.branchPhoneNumber
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.fax}</p>
                      <Field name="fax">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="fax"
                            placeholder={resources.pages.branch.fax}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.POBox}</p>
                      <Field name="poBox">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="box"
                            placeholder={resources.pages.branch.POBox}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.zipCode}</p>
                      <Field name="zipCode">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="zip"
                            placeholder={resources.pages.branch.zipCode}
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.address}</p>
                      <Field name="address">
                        {({ input, meta }) => (
                          <Form.Input
                            value={address}
                            name="address"
                            error={
                              (meta.touched && meta.error) ||
                              (Boolean(getAddressError) && getAddressError)
                            }
                            icon="map marker alternate"
                            placeholder={resources.pages.branch.address}
                            autoComplete="off"
                            readOnly
                            onClick={() => setLocPickerModal(true)}
                            loading={getAddressLoading}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <div
                        style={{
                          justifyContent: "flex-start",
                          display: "flex",
                          cursor: "pointer",
                        }}
                        onClick={ViewArea}
                      >
                        <p>{resources.pages.area.areaName}</p>
                        <Icon name="eye" color="blue" />
                      </div>
                      <Field name="AreaID">
                        {({ input, meta }) => (
                          <Form.Select
                            {...input}
                            loading={areaNamesIsLoading}
                            options={areas}
                            placeholder={resources.pages.area.areaName}
                            noResultsMessage={resources.common.noData}
                            search={(e) => e}
                            onSearchChange={(e) =>
                              setAreaName(e.target.value.trim())
                            }
                            onChange={(e, { value }) => {
                              input.onChange(value);
                              setAreaID(value);
                            }}
                            error={meta.touched && meta.error}
                            className={`${
                              Boolean(areas.length > 0)
                                ? ""
                                : "without-down-arrow"
                            }`}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column>
                      <p>{resources.pages.branch.contactPerson}</p>
                      <Button
                        type="button"
                        onClick={() => {
                          setOpenContactPersonModal(true);
                        }}
                        content={
                          initialValues && initialValues.branchId
                            ? resources.pages.branch.editContactPersons
                            : resources.pages.branch.addContactPersons
                        }
                        className="contact-persons-open-button"
                      />
                    </Grid.Column>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button size="large" type="button" onClick={handleConfirm}>
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
