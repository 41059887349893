import React, { useState, useEffect } from "react";
import { Select } from "semantic-ui-react";
import serviceApi from "../../api";
import { useDispatch, useSelector, useLocation } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import useDebounce from "../../components/helpers/useDebounce";
import { reportsActionCreator } from "../../redux/actions";

const ReportClientPicker = () => {
  const [clients, setClients] = useState([]);
  const [clientNameIsLoading, setClientNameIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientID, setClientID] = useState("");
  const debouncedClientNameSearch = useDebounce(clientName, 1000);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      setClientNameIsLoading(true);
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "clientName"],
          clientName: { $like: `%${clientName}%` },
          $limit: 150,
        },
      });

      if (Boolean(res && res.data && res.data.length > 0))
        setClients(
          res.data.map((c) => {
            return { key: c.ClientID, text: c.clientName, value: c.ClientID };
          })
        );
      else setClients([]);

      setClientNameIsLoading(false);
    } catch (e) {
      setClientNameIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(clientName && clientName.length > 2)) {
      fetchData();
    } else {
      setClientName("");
      setClientID("");
    }
  }, [debouncedClientNameSearch]);

  const onClientSelected = (e, { value }) => {
    setClientID(value);
    dispatch(
      reportsActionCreator.setReportOptions({
        clientID: value,
        clientName: e.target.innerText,
      })
    );
  };

  return (
    <div>
      <p>{resources.pages.addContract.client} :</p>
      <Select
        options={clients}
        placeholder={resources.pages.ticket.clientName}
        noResultsMessage={resources.common.noData}
        search
        value={clientID}
        onSearchChange={(e) => setClientName(e.target.value.trim())}
        loading={clientNameIsLoading}
        onChange={onClientSelected}
        className={`${Boolean(clients.length) ? "" : "without-down-arrow"}`}
      />
    </div>
  );
};

export default ReportClientPicker;
