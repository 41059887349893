import { layoutActions } from "../actions";

const initialState = {
  sidebarVisible: false
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case layoutActions.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarVisible: action.payload
      };
    default:
      return state;
  }
};

export default layoutReducer;
