import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers/root.reducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/root.sagas";
import { userActionCreator } from "../actions";
import serviceApi from "../../api";

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [logger, sagaMiddleware];

const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export const checkAuth = async () => {
  try {
    const auth = await serviceApi.authenticate();
    if (auth) {
      const userData = await serviceApi
        .service("queries-text")
        .find({ query: { userID: auth.user.userId, type: 1 } });
      if (userData && userData.permissions.length) {
        auth.user.permission = userData.permissions.map((m) => m.PermissionID);
      }
      if (userData && userData.technicianID.length) {
        auth.user.technicianID = userData.technicianID[0].TechnicianID;
      }
      store.dispatch(userActionCreator.login(auth));
    }
  } catch (e) {
    store.dispatch(userActionCreator.loginFailed());
    if (window.location.pathname !== "/login") window.location.href = "/login";
  }
};

checkAuth();

export default store;
