import React, { useEffect, useState } from "react";
import { reportsActionCreator } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalContent,
  ModalActions,
  Message,
} from "semantic-ui-react";
import {
  MachinesThatHaveContractwithVisitsNewOptions,
  PartsMovementReportOptions,
  PeriodEndedContractsOptions,
} from "./reportsOptions";
import { NotDeservedContractsOptions } from "./reportsOptions";
import { TechAreasAndMachinesNumOptions } from "./reportsOptions";
import { TechMachinesWithContractsOptions } from "./reportsOptions";
import { GeneralMachineInfoOptions } from "./reportsOptions";
import { MachinesThatHaveContractwithVisitsOptions } from "./reportsOptions";
import { MachinesModelClientOptions } from "./reportsOptions";
import { MachinesContarctTechnicianOptions } from "./reportsOptions";
import { NumberOfTechVisitsOptions } from "./reportsOptions";
import { FinishedContractsOptions } from "./reportsOptions";
import { LateTicketsOptions } from "./reportsOptions";
import { RepeatedTicketsOptions } from "./reportsOptions";
import { PeriodicalVisitsOptions } from "./reportsOptions";
import { InvoicesReportOptions } from "./reportsOptions";
import { MaintenanceCardOptions } from "./reportsOptions";
import { resources } from "../../assets/LocalizationResources";
import ReportExecute from "./ReportExecute";

export default function ManageReportOptions({ reportID }) {
  const dispatch = useDispatch();
  const [openReport, setOpenReport] = useState(false);
  const intervalDates = useSelector((state) => state.reports.interval);
  const options = useSelector((state) => state.reports.options);

  const currentOption = () => {
    switch (reportID) {
      case "periodEndedContracts":
        return <PeriodEndedContractsOptions />;
      case "notDeservedContracts":
        return <NotDeservedContractsOptions />;
      case "techAreasAndMachinesNum":
        return <TechAreasAndMachinesNumOptions />;
      case "techMachinesWithContracts":
        return <TechMachinesWithContractsOptions />;
      case "generalMachineInfo":
        return <GeneralMachineInfoOptions />;
      case "machinesThatHaveContractWithVisits":
        return <MachinesThatHaveContractwithVisitsOptions />;
      case "machinesThatHaveContractwithVisitsWithContractDataPrintOut":
        return <MachinesThatHaveContractwithVisitsNewOptions />;
      case "machinesModelClient":
        return <MachinesModelClientOptions />;
      case "machinesContarctTechnician":
        return <MachinesContarctTechnicianOptions />;
      case "numberOfTechVisits":
        return <NumberOfTechVisitsOptions />;
      case "finishedContracts":
        return <FinishedContractsOptions />;
      case "repeatedTickets":
        return <RepeatedTicketsOptions />;
      case "periodicalVisits":
        return <PeriodicalVisitsOptions />;
      case "invoicesReport":
        return <InvoicesReportOptions />;
      case "maintenanceCard":
        return <MaintenanceCardOptions />;
      case "partsMovementReportPrintOut":
        return <PartsMovementReportOptions />;
    }
  };

  return (
    <Modal open={Boolean(reportID)} className="manage-report-operations-modal">
      <Modal.Header>{resources.common.options}</Modal.Header>
      <Modal.Content>{currentOption()}</Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          color="blue"
          content={resources.common.confirm}
          onClick={() => {
            ReportExecute(reportID, options);
            dispatch(reportsActionCreator.clearReportData(true));
          }}
        />
        <Button
          type="button"
          content={resources.common.cancel}
          onClick={() => dispatch(reportsActionCreator.clearReportData(true))}
        />
      </Modal.Actions>
    </Modal>
  );
}
