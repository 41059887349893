import React, { useState, useEffect } from "react";
import "./NotDeservedContractsPrintOut.scss";
import { useHistory, useLocation } from "react-router-dom";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import qs from "qs";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import { generateInvoiceNumber } from "../../containers/MaintenanceReport/MaintenanceReportsList";
import { generateMachinePartInvoiceNumber } from "../../containers/MaintenanceReport/MaintenanceReportsList";

const InvoicesPrintOut = () => {
  const [printOutInfo, setPrintOutOnfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { from, to, TechnicianID } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          TechnicianID: TechnicianID,
          type: 23,
        },
      });

      const data = res.map((row) => {
        return {
          ...row,
          InvoiceNumber: row.InvoiceNumber
            ? generateInvoiceNumber(row.CreatedAt, row.InvoiceNumber)
            : null,
          MachinePartInvoiceNumber: row.MachinePartInvoiceNumber
            ? generateMachinePartInvoiceNumber(
                row.createdAt,
                row.MachinePartInvoiceNumber
              )
            : null,
          machineId: row.MachineID ? row.MachineID : null,
          clientID: row.ClientID ? row.ClientID : null,
          clientName: row.ClientName ? row.ClientName : null,
          copyPrice: row.copyPrice ? row.copyPrice : null,
          lastInvoiceCounter: row.lastInvoiceCounter
            ? row.lastInvoiceCounter
            : null,
          currentCounter: row.currentCounter ? row.currentCounter : null,
          total: row.Total ? row.Total : null,
          tax: row.Tax ? row.Tax : null,
          totalAfterTax: row.TotalAfterTax ? row.TotalAfterTax : null,
          createdAt: row.CreatedAt ? row.CreatedAt : null,
        };
      });
      setPrintOutOnfo(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setPrintOutOnfo([]);
      console.log(error);
      return [];
    }
  };

  const headers = {
    CreatedAt: "تاريخ الفاتورة",
    InvoiceNumber: "رقم الفاتورة",
    MachinePartInvoiceNumber: "رقم الفاتورة القطع",
    TechnicianName: "اسم الفني",
    machineId: "رقم الكمبيوتر",
    clientID: "رقم العميل",
    ClientName: "اسم العميل",
    copyPrice: "سعر النسخة",
    lastInvoiceCounter: "العداد السابق",
    currentCounter: "العداد الحالي",
    total: "الإجمالي",
    tax: "الضريبة",
    totalAfterTax: "الإجمالي بعد الضريبة",
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="not-deserved-contract-printout-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(printOutInfo.length) ? (
        <>
          <PrintOutHeaderLandscape
            printOutTitle="تقرير الفواتير"
            dateTitle="تاريخ الفاتورة "
            startDate={from}
            endDate={to}
            hasDate={true}
            data={printOutInfo}
            headers={headers}
          />
          <div className="not-deserved-contract-printout-table-container">
            <table className="invoice-printout-table">
              <thead>
                <tr>
                  <th style={{ paddingLeft: "2.5rem" }}>{headers.CreatedAt}</th>
                  <th>{headers.InvoiceNumber}</th>
                  <th>{headers.MachinePartInvoiceNumber}</th>
                  <th>{headers.TechnicianName}</th>
                  <th>{headers.machineId} </th>
                  <th>{headers.clientID}</th>
                  <th style={{ paddingLeft: "10.5rem" }}>
                    {headers.ClientName}
                  </th>
                  <th>{headers.copyPrice}</th>
                  <th>{headers.lastInvoiceCounter}</th>
                  <th>{headers.currentCounter}</th>
                  <th>{headers.total}</th>
                  <th>{headers.tax}</th>
                  <th>{headers.totalAfterTax}</th>
                </tr>
              </thead>
              <tbody>
                {printOutInfo.map((info, idx) => (
                  <tr key={idx}>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {moment(info.CreatedAt).format("YYYY-MM-DD") ??
                        "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.InvoiceNumber ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.MachinePartInvoiceNumber ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.TechnicianName ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.machineId ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.clientID ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.clientName ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.copyPrice ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.lastInvoiceCounter ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.currentCounter ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.total ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.tax ?? "لا توجد بيانات"}
                    </td>
                    <td className="not-deserved-contract-printout-table pb-1">
                      {info.totalAfterTax ?? "لا توجد بيانات"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default InvoicesPrintOut;
