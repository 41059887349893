import React, { useState } from "react";
import { Segment, Button, Icon, Divider } from "semantic-ui-react";
import sharp_left from "../../assets/images/routing/sharp_left.png";
import left from "../../assets/images/routing/left.png";
import slight_left from "../../assets/images/routing/slight_left.png";
import continueImg from "../../assets/images/routing/continue.png";
import slight_right from "../../assets/images/routing/slight_right.png";
import right from "../../assets/images/routing/right.png";
import sharp_right from "../../assets/images/routing/sharp_right.png";
import roundabout from "../../assets/images/routing/roundabout.png";
import routeImg from "../../assets/images/routing/route.svg";
export default function Routing({ routingData }) {
  const [hideInstructions, setHideInstructions] = useState(false);
  const getInstructionImg = (sign) => {
    switch (sign) {
      case -3:
        return sharp_left;
      case -2:
        return left;
      case -1:
        return slight_left;
      case 0:
        return continueImg;
      case 1:
        return slight_right;
      case 2:
        return right;
      case 3:
        return sharp_right;
      //case 4: return "FINISH";
      //case 5: return "VIA_REACHED";
      case 6:
        return roundabout;
      default:
        return "";
    }
  };

  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }
  return (
    <div
      className="map-routing-container"
      onClick={() => setHideInstructions(!hideInstructions)}
    >
      <div className="header">
        <div>
          <Icon name={hideInstructions ? "angle up" : "angle down"} />
          الاتجاهات
        </div>
        <div>
          <img src={routeImg} />
          <label>
            المسافة : {Math.ceil(routingData.distance / 1000, 2)} كم
          </label>
          <Icon name="clock" />
          <label>الزمن :{msToTime(routingData.time)}</label>
        </div>
      </div>
      {!hideInstructions && (
        <Segment className="instructions">
          {routingData.instructions.map((r, idx) => (
            <div className="step" key={idx}>
              <img className="stepImg" src={getInstructionImg(r.sign)} />
              {r.text}
            </div>
          ))}
        </Segment>
      )}
    </div>
  );
}
