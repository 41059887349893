import React, { useState, useEffect } from "react";
import tescoNewLogo from "./../../assets/images/tescoNewLogo.png";
import serviceApi from "../../api";
import {
  Segment,
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableRow,
  Modal,
  Button,
  Input,
  Header,
  Label,
  Icon,
  TableCell,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import "../MaintenanceReport/MaintenanceReportA4PrintView.scss";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";
import axios from "axios";
import { host } from "../../api/index";
import SuccessModal from "../../components/SuccessModal";
import MaintenanceReportZebraPageView from "./MaintenanceReportZebraPrintView";

const EmailModal = ({
  isOpen,
  onSuccess,
  onModalClose,
  maintenanceReportInfo,
}) => {
  const [email, setEmail] = useState({
    to: "",
    from: "technical@tesco.com.jo",
    subject: `Tesco Maintenance Report ${moment().format("DD/MM/YYYY")}`,
    text: "Maintenance Report",
    attachments: [
      {
        content: "",
        filename: "MaintenanceReport.pdf",
        contentType: "application/pdf",
      },
    ],
  });
  const [isEmailStillSending, setIsEmailStillSending] = useState(false);
  const [sendingEmailErrorMsg, setSendingEmailErrorMsg] = useState("");

  const validateEmail = () => {
    let error = "الرجاء ادخال الإيميل";
    if (email.to) {
      const isMatch = email.to.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      error = isMatch ? "" : "الرجاء إدخال إيميل صحيح";
    }

    setSendingEmailErrorMsg(error);
    if (Boolean(error)) return false;
    else return true;
  };

  const sendEmail = async () => {
    if (validateEmail()) {
      setIsEmailStillSending(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });

      const input = document.querySelector(".a4-container");
      try {
        const canvas = await html2canvas(input, 0, 0);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 40, 0, 130, 270);
        const content = Buffer.from(pdf.output("arraybuffer"));
        if (Boolean(content)) {
          await axios.post(
            `${host}/email`,
            {
              email: {
                ...email,
                text: `Dear ${maintenanceReportInfo.ClientName},\n\nPlease find attached maintenance report for ${maintenanceReportInfo.SerialNumber} - ${maintenanceReportInfo.MachineModel}. If you have any comments, please do not hesitate to contact us.\n\nEmail:support@tesco.com.jo\nTel: +962 6 552 8147\nM: +962 793 090 353`,
                attachments: [{ ...email.attachments[0], content: content }],
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          onSucceedEmailSending();
        } else {
          setIsEmailStillSending(false);
          setSendingEmailErrorMsg(resources.common.errors.emailNotSent);
        }
      } catch (error) {
        setIsEmailStillSending(false);
        setSendingEmailErrorMsg(resources.common.errors.emailNotSent);
      }
    }
  };

  const onSucceedEmailSending = () => {
    onSuccess();
    setIsEmailStillSending(false);
    setSendingEmailErrorMsg("");
  };

  return (
    <Modal onClose={onModalClose} open={isOpen} size="mini">
      <Header>الرجاء إدخال البريد الالكتروني</Header>
      <Modal.Content>
        <Input
          fluid
          type="text"
          placeholder="البريد الالكتروني"
          onChange={(e) => setEmail({ ...email, to: e.target.value })}
          value={email.to}
          error={Boolean(sendingEmailErrorMsg)}
          autoComplete="off"
        />

        {Boolean(sendingEmailErrorMsg) && (
          <Label basic color="red" pointing>
            {sendingEmailErrorMsg}
          </Label>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="green"
          onClick={sendEmail}
          loading={isEmailStillSending}
          disabled={isEmailStillSending}
          icon="send"
          content="إرسال"
          labelPosition="right"
        />

        <Button
          onClick={onModalClose}
          disabled={isEmailStillSending}
          content="إلغاء"
          icon="remove"
          labelPosition="right"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default function MaintenanceReportA4PrintView() {
  const [maintenanceReportInfo, setMaintenanceReportInfo] = useState(null);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] = useState(false);
  const [isSendingEmailSucceed, setIsSendingEmailSucceed] = useState(false);
  const [shouldHeaderRepeatedInEachPage, setShouldHeaderRepeatedInEachPage] =
    useState(false);
  const [showZebraMaintenanceReportPage, setShowZebraMaintenanceReportPage] =
    useState(true);
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const getMaintenanceReportInfo = async (maintenanceReportID) => {
    try {
      const res = await serviceApi.service("queries-text").find({
        query: {
          type: 26,
          maintenanceReportID: maintenanceReportID,
        },
      });
      const parts = await serviceApi.service("queries-text").find({
        query: {
          type: 27,
          maintenanceReportID: maintenanceReportID,
        },
      });
      setMaintenanceReportInfo({
        ...res,
        startDate: moment(res.StartDate).format(" h:mm  a"),
        endDate: moment(res.EndDate).format(" h:mm  a"),
        parts: [...parts],
        createdAt: moment(res.createdAt).format("DD/MM/YYYY"),
        unColoredAchievedCopies: res.Counter1 - res.LastCounter1,
        coloredAchievedCopies: res.Counter2 - res.LastCounter2,
        unColoredPreviousCounters: res.LastCounter1,
        coloredPreviousCounters: res.LastCounter2,
        unColoredCurrentCounters: res.Counter1,
        coloredCurrentCounters: res.Counter2,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSucceedEmailSending = () => {
    setIsSendEmailModalOpen(false);
    setIsSendingEmailSucceed(true);
  };

  const onCloseSendEmailModal = () => {
    setIsSendEmailModalOpen(false);
  };

  const onSendEmailButtonClicked = () => {
    setIsSendEmailModalOpen(true);
  };

  useEffect(() => {
    getMaintenanceReportInfo(query.id);
  }, []);

  const printReport = async () => {
    window.print();
  };

  const TableHeaderContent = () => {
    return (
      <TableRow>
        <TableCell>
          <Segment.Group className="header-style no-border no-box-shadow ">
            <Segment.Group
              horizontal
              className="algin-center justify-between no-border no-box-shadow "
            >
              <img src={tescoNewLogo} />
              {!isSendEmailModalOpen && (
                <>
                  <div className="flex-col gap-1">
                    {/* <Checkbox
                      className="no-print"
                      checked={shouldHeaderRepeatedInEachPage}
                      onChange={(e) =>
                        setShouldHeaderRepeatedInEachPage(
                          !shouldHeaderRepeatedInEachPage
                        )
                      }
                      label="طباعة الترويسة مع كل صفحة"
                    /> */}
                    <Button
                      icon="send"
                      onClick={onSendEmailButtonClicked}
                      className="email-btn no-print action-button-style"
                      labelPosition="right"
                      content="إرسال التقرير عبر الإيميل"
                    />
                  </div>

                  <div className="flex-col gap-1">
                    <Button
                      icon="print"
                      onClick={printReport}
                      className="email-btn no-print action-button-style"
                      labelPosition="right"
                      content={
                        resources.pages.maintenanceReport.printMaintenanceReport
                      }
                    />
                    <Button
                      icon="redo"
                      color="red"
                      className="no-print"
                      onClick={() => setShowZebraMaintenanceReportPage(true)}
                      labelPosition="right"
                      content={
                        resources.pages.maintenanceReport
                          .goToZebraMaintenanceReport
                      }
                    />
                  </div>
                </>
              )}
            </Segment.Group>

            <Segment className="maintenance-head-title no-border no-box-shadow">
              <label>
                {resources.pages.maintenanceReport.maintenanceReport}
              </label>
            </Segment>
          </Segment.Group>
        </TableCell>
      </TableRow>
    );
  };

  return !maintenanceReportInfo ? (
    <Loader />
  ) : (
    <div style={{ width: "100%" }}>
      <SuccessModal
        visible={isSendingEmailSucceed}
        header={resources.common.sentSuccessfully}
        onConfirm={() => setIsSendingEmailSucceed(false)}
      />

      <EmailModal
        isOpen={isSendEmailModalOpen}
        onSuccess={onSucceedEmailSending}
        onModalClose={onCloseSendEmailModal}
        maintenanceReportInfo={maintenanceReportInfo}
      />

      {showZebraMaintenanceReportPage ? (
        <MaintenanceReportZebraPageView
          isITMaintenanceReport={query.isITMaintenanceReport}
          maintenanceReportInfo={maintenanceReportInfo}
          setShowZebraMaintenanceReportPage={setShowZebraMaintenanceReportPage}
        />
      ) : (
        <div className="a4-container">
          <Table className="no-border">
            <TableHeader>
              {shouldHeaderRepeatedInEachPage ? <TableHeaderContent /> : <></>}
            </TableHeader>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <div className="footer-space"></div>
                </TableCell>
              </TableRow>
            </TableFooter>
            <TableBody>
              {!shouldHeaderRepeatedInEachPage ? <TableHeaderContent /> : <></>}
              <TableRow>
                <TableCell>
                  <Segment.Group className=" no-border no-box-shadow ">
                    <Segment.Group
                      className="algin-center no-border gap-1"
                      horizontal
                    >
                      <label className="ts-9 label-color">
                        {`${resources.pages.maintenanceReport.reportNumber} : `}
                      </label>
                      <Segment className="value-label no-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.MaintenanceReportID
                          ? maintenanceReportInfo.MaintenanceReportID
                          : "-"}
                      </Segment>
                      <label className="ts-9 label-color">{`${resources.pages.maintenanceReport.date} : `}</label>
                      <Segment className="value-label no-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.createdAt
                          ? maintenanceReportInfo.createdAt
                          : "-"}
                      </Segment>
                    </Segment.Group>

                    <div className="no-border-table-container">
                      <Table
                        basic
                        celled
                        className="ts-9 default-border"
                        unstackable
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="label-color"
                            >
                              {resources.pages.machines.computerNumber}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="label-color"
                            >
                              {resources.pages.machines.MachineType}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="label-color"
                            >
                              {resources.pages.machines.MachineModel}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="label-color"
                            >
                              {resources.pages.machines.serialNumber}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="value-label"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.ComputerNumber
                                ? maintenanceReportInfo.ComputerNumber
                                : "-"}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="value-label"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.MachineType
                                ? maintenanceReportInfo.MachineType
                                : "-"}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="value-label"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.MachineModel
                                ? maintenanceReportInfo.MachineModel
                                : "-"}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="value-label"
                            >
                              {maintenanceReportInfo &&
                              maintenanceReportInfo.SerialNumber
                                ? maintenanceReportInfo.SerialNumber
                                : "-"}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>

                    <Segment className="ts-9 no-border no-box-shadow padding-0">
                      <Segment.Group
                        className="ts-9 margin-y-10 algin-center no-border no-box-shadow "
                        horizontal
                      >
                        <label className="label-color w-120-px">
                          {`${resources.pages.clients.clientName} : `}
                        </label>
                        <Segment className="value-label no-border">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.ClientName
                            ? maintenanceReportInfo.ClientName
                            : "-"}
                        </Segment>
                      </Segment.Group>
                      <Segment.Group
                        className="ts-9 margin-y-10 algin-center no-border no-box-shadow gap-1"
                        horizontal
                      >
                        <Segment.Group
                          horizontal
                          className="flex-1 algin-center no-border no-box-shadow"
                        >
                          <label className="label-color w-120-px">
                            {`${resources.pages.maintenanceReport.siteArea} : `}
                          </label>
                          <Segment className="value-label no-border">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.AreaName
                              ? maintenanceReportInfo.AreaName
                              : "-"}
                          </Segment>
                        </Segment.Group>
                        <Segment.Group
                          horizontal
                          className="flex-1 algin-center no-box-shadow no-border"
                        >
                          <label className=" label-color w-90-px">
                            {`${resources.pages.maintenanceReport.area} : `}
                          </label>
                          <Segment className="no-border value-label">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.BranchName
                              ? maintenanceReportInfo.BranchName
                              : "-"}
                          </Segment>
                        </Segment.Group>
                      </Segment.Group>
                      <Segment.Group
                        className="ts-9 algin-center no-border no-box-shadow gap-1"
                        horizontal
                      >
                        <Segment.Group horizontal className="flex-1 ">
                          <label className="ts-9 label-color w-120-px">
                            {`${resources.pages.maintenanceReport.visitType} :`}
                          </label>
                          <Segment className="value-label no-border">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.VisitType
                              ? maintenanceReportInfo.VisitType
                              : "-"}
                          </Segment>
                        </Segment.Group>
                        <Segment.Group
                          horizontal
                          className="flex-1 algin-center no-border no-box-shadow"
                        >
                          <label className="ts-9 label-color w-90-px">
                            {`${resources.pages.maintenanceReport.maintenanceType} : `}
                          </label>
                          <Segment className="value-label no-border">
                            {maintenanceReportInfo &&
                            maintenanceReportInfo.MaintenanceType
                              ? maintenanceReportInfo.MaintenanceType
                              : "-"}
                          </Segment>
                        </Segment.Group>
                      </Segment.Group>
                    </Segment>

                    {!query.isITMaintenanceReport && (
                      <div className="no-border-table-container">
                        <Table
                          basic
                          celled
                          className="ts-9 default-border"
                          unstackable
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell />
                              <Table.Cell
                                textAlign="center"
                                className="title-label-color"
                              >
                                {
                                  resources.pages.maintenanceReport
                                    .blackAndWhiteCounter
                                }
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="title-label-color"
                              >
                                {
                                  resources.pages.maintenanceReport
                                    .colorfulCounter
                                }
                              </Table.Cell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell
                                collapsing
                                textAlign="right"
                                className="label-color"
                              >
                                {
                                  resources.pages.maintenanceReport
                                    .previousCounterRead
                                }
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.unColoredPreviousCounters
                                  ? maintenanceReportInfo.unColoredPreviousCounters
                                  : "0"}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.coloredPreviousCounters
                                  ? maintenanceReportInfo.coloredPreviousCounters
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell
                                textAlign="right"
                                className="label-color"
                              >
                                {
                                  resources.pages.maintenanceReport
                                    .currentCounterRead
                                }
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.unColoredCurrentCounters
                                  ? maintenanceReportInfo.unColoredCurrentCounters
                                  : "0"}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.coloredCurrentCounters
                                  ? maintenanceReportInfo.coloredCurrentCounters
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell
                                textAlign="right"
                                className="label-color"
                              >
                                {
                                  resources.pages.maintenanceReport
                                    .acheivedCopies
                                }
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.unColoredAchievedCopies
                                  ? maintenanceReportInfo.unColoredAchievedCopies
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="center"
                                className="value-label"
                              >
                                {maintenanceReportInfo &&
                                maintenanceReportInfo.coloredAchievedCopies
                                  ? maintenanceReportInfo.coloredAchievedCopies
                                  : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </div>
                    )}
                    <Segment.Group
                      className="ts-9 margin-y-10 algin-center no-border no-box-shadow gap-1"
                      horizontal
                    >
                      <Segment.Group
                        horizontal
                        className="flex-1 algin-center no-border no-box-shadow"
                      >
                        <label
                          className={`title-label-color ${
                            query.isITMaintenanceReport ? `w-120-px` : `w-90-px`
                          }`}
                        >
                          {`${resources.pages.maintenanceReport.startHour} : `}
                        </label>
                        <Segment className="value-label no-border">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.startDate
                            ? maintenanceReportInfo.startDate
                            : "-"}
                        </Segment>
                      </Segment.Group>
                      <Segment.Group
                        horizontal
                        className="flex-1 algin-center no-border no-box-shadow"
                      >
                        <label className=" title-label-color w-90-px">
                          {`${resources.pages.maintenanceReport.endHour} : `}
                        </label>
                        <Segment className="value-label no-border">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.endDate
                            ? maintenanceReportInfo.endDate
                            : "-"}
                        </Segment>
                      </Segment.Group>
                    </Segment.Group>

                    <Segment
                      textAlign="right"
                      className="ts-9 label-color no-border no-box-shadow padding-1"
                    >
                      {`${resources.pages.maintenanceReport.partsMaterialTable} : `}
                    </Segment>
                    <div className="margin-y-10 no-border">
                      <Table
                        basic
                        celled
                        className="ts-9 border-y-1 no-border"
                        unstackable
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-x-1 border-y-1"
                            >
                              Name
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-left-1 border-y-1"
                            >
                              QTY
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-left-1 border-y-1"
                            >
                              FC
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-left-1 border-y-1"
                            >
                              ACT
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-left-1 border-y-1"
                            >
                              Description
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="title-label-color border-left-1 border-y-1"
                            >
                              Part Number
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <TableFooter>
                          <Table.Row>
                            <Table.Cell colSpan="5" className="label-color">
                              {
                                resources.pages.maintenanceReport
                                  .machineReportNeeds
                              }
                            </Table.Cell>
                          </Table.Row>
                        </TableFooter>
                        <Table.Body>
                          {maintenanceReportInfo &&
                            maintenanceReportInfo.parts.map((row) => {
                              return (
                                <Table.Row key={row.id} className="value-label">
                                  <Table.Cell
                                    textAlign="center"
                                    className=" border-x-1"
                                  >
                                    {row.partName ? row.partName : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="border-left-1"
                                  >
                                    {row.Quantity ? row.Quantity : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="border-left-1"
                                  >
                                    {row.FC ? row.FC : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="border-left-1"
                                  >
                                    {row.ACT ? row.ACT : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="border-left-1"
                                  >
                                    {row.Description ? row.Description : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="border-left-1"
                                  >
                                    {row.PartNumber ? row.PartNumber : "-"}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </div>

                    <Segment.Group
                      className="ts-9 algin-center no-border no-box-shadow gap-1 margin-0 hh page-break-inside"
                      horizontal
                    >
                      <Segment.Group className="flex-grow-1 algin-center  no-border no-box-shadow margin-0 ">
                        <label className=" label-color ">
                          {resources.pages.maintenanceReport.issue}
                        </label>
                        <Segment className="no-border value-label margin-top-10">
                          {maintenanceReportInfo && maintenanceReportInfo.Damage
                            ? maintenanceReportInfo.Damage
                            : "-"}
                        </Segment>
                      </Segment.Group>
                      <Segment.Group className="flex-grow-1 algin-center  no-border no-box-shadow margin-0">
                        <label className=" label-color">
                          {resources.pages.maintenanceReport.reason}
                        </label>
                        <Segment className="no-border value-label  margin-top-10">
                          {maintenanceReportInfo && maintenanceReportInfo.Causes
                            ? maintenanceReportInfo.Causes
                            : "-"}
                        </Segment>
                      </Segment.Group>
                      <Segment.Group className="flex-grow-1 algin-center no-border no-box-shadow margin-0">
                        <label className="label-color">
                          {resources.pages.maintenanceReport.procedure}
                        </label>
                        <Segment className="value-label no-border margin-top-10">
                          {maintenanceReportInfo && maintenanceReportInfo.Action
                            ? maintenanceReportInfo.Action
                            : "-"}
                        </Segment>
                      </Segment.Group>
                    </Segment.Group>

                    <div className="ts-9 flex-row margin-y-10 gap-1 default-border padding-20 page-break-inside">
                      <Segment className="flex-1 algin-center no-border no-box-shadow margin-0 padding-0">
                        <label className="label-color">
                          {resources.pages.maintenanceReport.technicianNotes}
                        </label>
                        <Segment className="value-label no-border no-box-shadow">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.TechnicianNotes
                            ? maintenanceReportInfo.TechnicianNotes
                            : "-"}
                        </Segment>
                        <label className="label-color">
                          {`${resources.pages.maintenanceReport.technicianName} : `}
                        </label>
                        <Segment className="value-label no-border no-box-shadow">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.TechnicianName
                            ? maintenanceReportInfo.TechnicianName
                            : "-"}
                        </Segment>
                      </Segment>
                      <div className="flex-col flex-1">
                        <label className="label-color">
                          {resources.pages.maintenanceReport.userNotes}
                        </label>
                        <Segment className=" value-label no-border h-px-120 no-box-shadow">
                          {maintenanceReportInfo &&
                          maintenanceReportInfo.ClientNotes
                            ? maintenanceReportInfo.ClientNotes
                            : "-"}
                        </Segment>
                      </div>
                    </div>
                  </Segment.Group>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className=" flex-col gap-1 margin-y-10 ">
                    <div className="flex-col gap-1 label-color dir-rtl margin-y-10">
                      <div className="flex-row gap-1 algin-center">
                        <Icon name="circle" size="mini" />
                        <p>{resources.pages.maintenanceReport.costReport}</p>
                      </div>
                      <div className="flex-row gap-1 algin-center">
                        <Icon name="circle" size="mini" />
                        <p>
                          {resources.pages.maintenanceReport.reportValidity}
                        </p>
                      </div>
                      <div className="flex-row gap-1 algin-center">
                        <Icon name="circle" size="mini" />
                        <p>
                          {resources.pages.maintenanceReport.machineReceived}
                        </p>
                      </div>
                    </div>
                    <Segment.Group
                      horizontal
                      className="algin-center no-border gap-1 margin-y-5 no-box-shadow"
                    >
                      <label className="ts-9 label-color w-150-px">
                        {`${resources.pages.maintenanceReport.employeeName} : `}
                      </label>
                      <Segment className="client-value-label-style  default-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.ClientEmployeeName
                          ? maintenanceReportInfo.ClientEmployeeName
                          : "-"}
                      </Segment>
                    </Segment.Group>
                    <Segment.Group
                      horizontal
                      className="algin-center no-border gap-1 margin-y-5 no-box-shadow"
                    >
                      <label className="ts-9 label-color w-150-px">
                        {`${resources.pages.maintenanceReport.job} : `}
                      </label>
                      <Segment className="client-value-label-style  default-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.ClientEmployeeJob
                          ? maintenanceReportInfo.ClientEmployeeJob
                          : "-"}
                      </Segment>
                    </Segment.Group>
                    <Segment.Group
                      horizontal
                      className="algin-center no-border gap-1 margin-y-5 no-box-shadow"
                    >
                      <label className="ts-9 label-color w-150-px">
                        {`${resources.pages.maintenanceReport.reportStatus} : `}
                      </label>
                      <Segment className="client-value-label-style  default-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.DeliverStatus
                          ? maintenanceReportInfo.DeliverStatus
                          : "-"}
                      </Segment>
                    </Segment.Group>
                    <Segment.Group
                      horizontal
                      className="algin-center no-border gap-1 margin-y-5 no-box-shadow"
                    >
                      <label className="ts-9 label-color w-150-px">
                        {`${resources.pages.maintenanceReport.stampAndSignature} : `}
                      </label>
                      <Segment className="client-value-label-style  default-border">
                        {maintenanceReportInfo &&
                        maintenanceReportInfo.ClientSignature ? (
                          <img
                            className="signature-img-dims"
                            src={maintenanceReportInfo.ClientSignature}
                          />
                        ) : (
                          "-"
                        )}
                      </Segment>
                    </Segment.Group>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <footer className="footer-style footer-text-style  ta-left">
            <div className="flex-row align-center">
              <div className=" flex-col ">
                <p>Smart Business Solutions since 1975</p>
                <p>tesco.com.jo</p>
              </div>
              <div className="flex-1 justify-center flex-row ">
                <div className="flex-col">
                  <p>T +962 6 552 8147</p>
                  <p>F +962 6 552 8154</p>
                  <p>sales@tesco.com.jo</p>
                </div>
              </div>
              <div className=" flex-col">
                <p>Technical Equipment & Supplies Company [Tesco]</p>
                <p>شركة المعدات والتجهيزات الفنية</p>
                <p>P.O. Box 2147 Amman 11181 Jordan</p>
              </div>
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}
