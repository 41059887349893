import React, { useState, useEffect } from "react";
import { resources } from "../../assets/LocalizationResources";
/**
 * @param {Array} data
 */
export default function getDeleteError(data) {
  let message = resources.common.errorDeletingMessage;
  data.forEach((model) => {
    switch (Object.values(model)[0]) {
      case "ClientBranch":
        message += resources.models.clientBranches;
        break;
      case "Client":
        message += resources.models.clients;
        break;
      case "ContractMachine":
        message += resources.models.ContractMachine;
        break;
      case "ContractInvoice":
        message += resources.models.ContractInvoice;
        break;
      case "Contract":
        message += resources.models.Contract;
        break;
      case "Groups":
        message += resources.models.Groups;
        break;
      case "Lookup":
        message += resources.models.Lookup;
        break;
      case "MachinePart":
        message += resources.models.MachinePart;
        break;
      case "MachineExtraDevices":
        message += resources.models.machineExtraDevices;
        break;
      case "Machine":
        message += resources.models.Machine;
        break;
      case "Tablet":
        message += resources.models.Tablet;
        break;
      case "Technicians":
        message += resources.models.Technicians;
        break;
      case "Tickets":
        message += resources.models.Tickets;
        break;
      case "Users":
        message += resources.models.Users;
        break;
      case "UserLeave":
        message += resources.models.UserLeaves;
        break;
      case "Locations":
        message += resources.models.Locations;
        break;
      case "UserGroups":
        message += resources.models.UserGroups;
        break;
      case "GroupPermissions":
        message += resources.models.GroupPermissions;
        break;
      case "TicketStatus":
        message += resources.models.TicketStatus;
        break;
      case "MaintenanceReport":
        message += resources.models.MaintenanceReport;
        break;
      case "ContractPayments":
        message += resources.models.ContractPayments;
        break;
      case "MaintenanceReportMachinePart":
        message += resources.models.MaintenanceReportMachinePart;
        break;
      default:
        message += model;
    }
    message += " ، ";
  });
  return message;
}
