import React, { useEffect } from "react";
import "./Layout.scss";
import { Segment, Sidebar } from "semantic-ui-react";
import VerticalSidebar from "./VerticalSidebar";
import { useSelector, useDispatch } from "react-redux";
import { layoutActionCreator } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import HorizontalNavbar from "./HorizontalNavbar";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";

const Layout = ({ children }) => {
  const loggedUser = useSelector((state) => state.user);

  const menuItems = [
    { icon: "home", label: resources.pages.sidbar.home, path: "/home" },
    {
      icon: "call square",
      label: resources.pages.sidbar.callCenter,
      path: "/callCenter",
      isAuthorized: isAuthorized(Perm.callCenter),
    },
    {
      icon: "ticket",
      label: resources.pages.sidbar.ticketsList,
      path: "/ticket/ticketsList",
      isAuthorized: isAuthorized(Perm.viewTicktes),
    },
    {
      icon: "clipboard",
      label: resources.pages.sidbar.maintenanceReport,
      path: "/maintenanceReportsList",
      isAuthorized: isAuthorized(Perm.viewMaintenaceReport),
    },
    {
      icon: "map",
      label: resources.pages.sidbar.areasList,
      path: "/areas/areasList",
      isAuthorized: isAuthorized(Perm.viewGeofences),
    },
    {
      icon: "road",
      label: resources.pages.playBackMap.techsMovePoints,
      path: "/techMovePoints",
      isAuthorized: isAuthorized(Perm.techMovePoints),
    },
    {
      icon: "cog",
      label: resources.pages.sidbar.settings,
      path: "/settings",
      isAuthorized: isAuthorized(
        Perm.viewUsers,
        Perm.viewGroupPermissions,
        Perm.viewClientBranch,
        Perm.viewMachines,
        Perm.viewContract,
        Perm.viewTech,
        Perm.viewtTechAreas,
        Perm.viewLeaves,
        Perm.viewLookups,
        Perm.viewTablets
      ),
    },
    {
      icon: "edit",
      label: resources.pages.contractMachineEditRequests.editRequests,
      path: "/ContractMachinesEditRequests",
      isAuthorized: isAuthorized(Perm.viewEditRequests),
    },
    {
      icon: "table",
      label: resources.pages.sidbar.reports,
      path: "/systemReports",
      isAuthorized: isAuthorized(
        Perm.viewAllReports,
        Perm.viewFinancialReports
      ),
    },
    {
      icon: "ticket",
      label: resources.pages.sidbar.ticketsList,
      path: "/manageExternalTicket",
      isAuthorized: loggedUser.user.isExternalUser,
    },
  ];

  const dispatch = useDispatch();
  const router = useHistory();
  const { sidebarVisible } = useSelector((state) => state.layout);

  const hideSidebar = () => {
    if (sidebarVisible) dispatch(layoutActionCreator.toggleSideBar(false));
  };

  useEffect(() => {
    if (
      (!loggedUser.user || !loggedUser.accessToken) &&
      (loggedUser.attempts > 0 || loggedUser.loggedOut)
    ) {
      router.replace("/login");
    }
  }, [loggedUser]);

  return (
    <div className="app-layout-container">
      <Sidebar.Pushable>
        <VerticalSidebar
          menuItems={menuItems.filter((f) => f.isAuthorized)}
          visible={sidebarVisible}
          hideSidebarFn={hideSidebar}
        />
        <Sidebar.Pusher dimmed={sidebarVisible} onClick={hideSidebar}>
          <HorizontalNavbar />
          <div className="layout-children-segment">{children}</div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default Layout;
