import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import { useSelector } from "react-redux";

export default function TabletsList() {
  const router = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [tablets, setTablets] = useState();
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [tabletID, setTabletID] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const headers = {
    userName: resources.pages.tablet.tabletUserName,
    IMEI: resources.pages.tablet.imeiNumber,
    SIMCard: resources.pages.tablet.phoneNumber,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const fillSearchData = async () => {
    setSearchData({
      userName: {
        type: "text",
        label: resources.pages.tablet.tabletUserName,
      },
      IMEI: {
        type: "text",
        label: resources.pages.tablet.imeiNumber,
      },
      SIMCard: {
        type: "text",
        label: resources.pages.tablet.phoneNumber,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "tablets"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  // const whereSearch = (id, colID = id) => {
  //   if (searching && searching.searchBy === id)
  //     return { where: { [colID]: searching.term } };
  //   else return {};
  // };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadTablets = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Tablet",
          attributes: ["tabletID", "IMEI", "SIMCard"],
          include: [
            {
              model: "Users",
              as: "AssociatedUser",
              attributes: ["userid", "fullname"],
              ...whereLikeSearch("userName"),
            },
          ],
          ...whereLikeSearch("IMEI"),
          ...whereLikeSearch("SIMCard"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      setTablets(
        res.data.map((tab) => ({
          ...tab,
          userName: tab.AssociatedUser
            ? tab.AssociatedUser.fullname
            : resources.pages.tablet.notAssociatedWithaUser,
        }))
      );

      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTablets();
  }, [searching, activePage]);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/tableList/manageTablets", {
        tabletID: row.tabletID,
      });
    } else if (type === "remove") {
      setTabletID(row.tabletID);
      setIsDelete(true);
    }
  };

  const deleteTablet = async () => {
    try {
      await serviceApi
        .service("tablet")
        .remove(tabletID)
        .then((res) => {
          setIsDelete(false);
          loadTablets();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteTablet()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            icon="tablet alternate"
            content={resources.pages.tablet.manageTablets}
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addTablets) && (
              <Button
                basic
                color="blue"
                content={resources.pages.tablet.addNewTablet}
                onClick={() => {
                  router.push("/tableList/manageTablets");
                }}
              />
            )}
            {isAuthorized(Perm.viewTablets) && (
              <ExportDataToExcel
                data={tablets}
                headers={headers}
                title={resources.common.excelExport.tablets}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="tablets"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              edit={isAuthorized(Perm.editTablets) ? true : null}
              remove={isAuthorized(Perm.deleteTablets) ? true : null}
              data={tablets}
              activePage={activePage}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
