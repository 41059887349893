import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import Map from "./Map";

export default function MapModal({
  isOpen = false,
  handleSaveLocation = null,
  onCancel = null,
  jsonData = null,
}) {
  const [latLng, setLatLng] = useState({});
  const OnSave = () => {
    if (latLng && typeof handleSaveLocation === "function")
      handleSaveLocation(latLng);
  };

  return (
    <Modal open={isOpen} basic size="fullscreen">
      <Modal.Content className="map-model-container">
        {typeof handleSaveLocation === "function" ? (
          <Map Clickable addMarkerOnClick OnMapClick={setLatLng} Search />
        ) : (
          <Map Search jsonData={jsonData} />
        )}
      </Modal.Content>
      <div className="map-model-actions">
        <Button icon="cancel" color="red" onClick={onCancel} />
        {typeof handleSaveLocation === "function" && (
          <Button icon="save" color="blue" onClick={OnSave} />
        )}
      </div>
    </Modal>
  );
}
