import React from "react";
import { useSelector } from "react-redux";
import { LayersControl, TileLayer } from "react-leaflet";

export default function Layers() {
  const EMMAPPath =
    process.env.REACT_APP_EMMAP_PATH ||
    `${window.location.protocol}//${window.location.hostname}`;

  const lang = useSelector((state) => state.user.lang);

  return (
    <>
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="GCE">
          <TileLayer
            attribution="&amp;copy Google Maps"
            url={`${EMMAPPath}/EMMAP/JordanTile.aspx?z={z}&x={x}&y={y}&lang=${lang}&clear=0`}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google Maps - Hybrid">
          <TileLayer
            attribution="&amp;copy Google Maps"
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google Maps - Traffic">
          <TileLayer
            attribution="&amp;copy Google Maps"
            url="https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Google Maps">
          <TileLayer
            attribution="&amp;copy Google Maps"
            url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="OpenStreetMap">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Esri - World Imagery">
          <TileLayer
            attribution='&amp;copy <a href="http://www.esri.com/">Esri</a>'
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={18}
            minZoom={3}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
    </>
  );
}
