import React from "react";
import moment from "moment";

const GetDate = () => {
  const date = moment().format("DD/MM/YYYY");
  const time = moment().format("hh:mm a");
  return (
    <div className="date-time-container">
      <p> تاريخ الطباعة : {date}</p>
      <div style={{ float: "left", direction: "ltr" }}>{time}</div>
    </div>
  );
};

export default GetDate;
