import FreeDraw from "leaflet-freedraw";
import wkt from "terraformer-wkt-parser";
import L from "leaflet";

export const setupDrawControl = ({
  _map,
  _mode = FreeDraw.ALL,
  _smoothFactor = 0.3, //By how much to smooth the polygons.
  _elbowDistance = 10, //Factor to determine when to delete or when to append an edge.
  _simplifyFactor = 1.1, //By how much to simplify the polygon.
  _mergePolygons = true, //Whether to attempt merging of polygons that intersect.
  _concavePolygon = true, //Whether to apply the concaving algorithm to the polygons.
  _maximumPolygons = 1, //	Maximum number of polygons to be added to the map layer.
  _notifyAfterEditExit = false, //	Whether to defer markers event until after exiting EDIT mode.
  _leaveModeAfterCreate = false, //	Whether to exit CREATE mode after each polygon creation.
  _strokeWidth = 2,
}) => {
  const freeDraw = new FreeDraw({
    mode: _mode,
    smoothFactor: _smoothFactor,
    elbowDistance: _elbowDistance,
    simplifyFactor: _simplifyFactor,
    mergePolygons: _mergePolygons,
    concavePolygon: _concavePolygon,
    maximumPolygons: _maximumPolygons,
    notifyAfterEditExit: _notifyAfterEditExit,
    leaveModeAfterCreate: _leaveModeAfterCreate,
    strokeWidth: _strokeWidth,
    id: "freeDraw",
  });

  _map.addLayer(freeDraw);

  freeDraw.on("markers", (event) => {
    //if (event.latLngs && event.latLngs.length > 0) {
    //console.log(event.latLngs[0]);
    //}
  });
};

export const getfreeDraw = (mapRef) => {
  for (var i in mapRef._layers) {
    if (mapRef._layers[i].options.id === "freeDraw") {
      return mapRef._layers[i];
    }
  }
  return null;
};

export const StartDrawing = (mapRef) => {
  const freeDraw = getfreeDraw(mapRef);
  if (!freeDraw) freeDraw.setupDrawControl();

  freeDraw.mode(FreeDraw.ALL);
};

export const StopDrawing = (mapRef) => {
  const freeDraw = getfreeDraw(mapRef);
  if (freeDraw) {
    freeDraw.mode(FreeDraw.NONE);
  }
};

export const getGeometry = (mapRef) => {
  const freeDraw = getfreeDraw(mapRef);
  if (freeDraw && freeDraw.all().length > 0)
    return wkt.convert(freeDraw.all()[0].toGeoJSON().geometry);
};

export const deletefreeDraw = (mapRef) => {
  const freeDraw = getfreeDraw(mapRef);
  if (freeDraw) mapRef.removeLayer(freeDraw);
};

export const addGeomtry = (mapRef, geometry) => {
  const freeDraw = getfreeDraw(mapRef);
  if (freeDraw) {
    var geo = wkt.parse(geometry);
    var polygon = geo.coordinates[0].map(
      (point) => new L.latLng(point[1], point[0])
    );
    freeDraw.clear();
    freeDraw.create(polygon);
    const bounds = L.latLngBounds(polygon);
    mapRef.flyToBounds(bounds);
  }
};

export const clearDrawing = (mapRef) => {
  const freeDraw = getfreeDraw(mapRef);
  if (freeDraw) {
    freeDraw.clear();
  }
};
