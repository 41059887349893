import React, { useState } from "react";
import "./PrintOutHeader.scss";
import Date from "../GetDate/GetDate";
import PrintOutdateFilter from "../PrintOutFilters/PrintOutDateFilter";

const PrintOutHeader = ({
  printOutTitle,
  dateTitle,
  startDate,
  endDate,
  hasDate,
}) => {
  return (
    <div className="printout-header">
      <div className="printout-title-and-info-container">
        <div className="printout-title">
          <h1 className="printout-title-main">
            شركة المعدات و التجهيزات الفنية
          </h1>
          <h3 className="printout-title-container"> {printOutTitle}</h3>
        </div>
        <div className="printout-info">
          <Date />
        </div>
      </div>
      <div className="printout-filter">
        {hasDate ? (
          <PrintOutdateFilter
            from={startDate}
            to={endDate}
            dateTitle={dateTitle}
          />
        ) : (
          <div> </div>
        )}
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default PrintOutHeader;
