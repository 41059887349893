import React from "react";
import { Modal, Button } from "semantic-ui-react";

const CustomModal = ({ modalData, setModalData }) => {
  const handleClose = () => {
    setModalData({
      ...modalData,
      open: false,
    });
  };

  return (
    <Modal size="small" open={modalData.open}>
      <Modal.Header>{modalData.header}</Modal.Header>
      <Modal.Content>{modalData.content}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          icon="cancel"
          labelPosition="right"
          content={modalData.buttonContent}
          onClick={handleClose}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CustomModal;
