import React from "react";
import { Button } from "semantic-ui-react";
import { excelExport } from "./excelExport";
import { resources } from "../../assets/LocalizationResources";

const ExportDataToExcel = ({ data, headers, title }) => {
  const exportFileHandler = () => {
    excelExport(data, headers, title);
  };

  return (
    <Button
      onClick={exportFileHandler}
      content={resources.common.exportDataToExcel}
      className="excel-export-button"
      disabled={!Boolean(data && data.length > 0)}
    />
  );
};

export default ExportDataToExcel;
