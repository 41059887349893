import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import "./MachinesContarctTechniciansPrintOut.scss";

const LateTicketsPrintOut = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [lateTicketsData, setLateTicketsData] = useState([]);
  const [numberOfMachines, setNumberOfMachines] = useState(0);
  const { from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      var date = new Date();

      var currentDate =
        date.getFullYear(0) +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate();

      const res = await serviceApi.service("queries-text").find({
        query: {
          from: currentDate,
          type: 17,
        },
      });
      setNumberOfMachines(res.length);
      const grouped = res.reduce((p, c) => {
        if (p[c.ticketTypeID]) {
          p[c.ticketTypeID].push(c);
        } else {
          p[c.ticketTypeID] = [c];
        }
        return p;
      }, {});
      const grouped2 = Object.keys(grouped).map((c) => {
        return grouped[c].reduce((p, c) => {
          const pre = p.find((x) => c.ComputerNumber === x.ComputerNumber);
          if (pre) {
            pre.count++;
          } else {
            p.push({ ...c, count: 0 });
          }

          return p;
        }, []);
      }, 0);
      setLateTicketsData(grouped2);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setLateTicketsData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="machines-contract-tech-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(lateTicketsData) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={" تقرير الاتصالات المتأخرة"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
          />
          {lateTicketsData.map((info, parentIdx) => (
            <>
              <label> {info[0].ticketType}</label>
              <div className="machines-contract-tech-table-container">
                <table>
                  <thead>
                    <tr key={parentIdx}>
                      <th>الفني</th>
                      <th>اسم العميل</th>
                      <th>ملاحظات</th>
                      <th>العقد</th>
                      <th style={{ width: 15 }}>العنوان</th>
                      <th>رقم الكمبيوتر</th>
                      <th>التاريخ</th>
                      <th>مكرر</th>
                      {/* عدد التكرار*/}
                      <th></th>
                      <th>SLA</th>
                      <th>مميز</th>
                      <th>مدة التأخير</th>
                    </tr>
                  </thead>

                  {info.map((data, idx) => (
                    <tbody>
                      <tr key={idx}>
                        <td>{data.TechnicianName}</td>
                        <td>{data.ClientName}</td>
                        <td>{data.notes}</td>
                        <td>{data.contractType}</td>
                        <td>{data.Address}</td>
                        <td>{data.ComputerNumber}</td>
                        <td>{moment(data.createdAt).format("YYYY-MM-DD")}</td>
                        <td>
                          {data.count > 0 ? (
                            <span>&#10003;</span>
                          ) : (
                            <span></span>
                          )}
                        </td>
                        <td>{data.count > 0 ? data.count : null}</td>
                        <td>{data.sla}</td>
                        <td>{data.vip}</td>
                        <td>{data.lateDayes}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </>
          ))}
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default LateTicketsPrintOut;
