import React, { useState, useEffect } from "react";
import "./GeneralInfoOnMachinesPrintOut.scss";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";

const FinishedContractsPrintOut = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [finishedContractData, setFinishedContractData] = useState([]);
  const { from, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const headers = {
    ClientName: "اسم العميل",
    ComputerNumber: "رقم الكمبيوتر",
    AreaName: "المنطقة",
    TechnicianName: "اسم الفني",
    ContractType: "نوع العقد",
    EndDate: "تاريخ نهاية العقد",
    MachineModel: "موديل الالة",
    LastVisitDate: "تاريخ اخر زيارة",
    LastCounter1: "رقم العداد 1",
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          type: 11,
        },
      });
      const data = res.map((row) => {
        return {
          ...row,
          ClientName: row.ClientName ? row.ClientName : null,
          ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
          AreaName: row.AreaName ? row.AreaName : null,
          TechnicianName: row.TechnicianName ? row.TechnicianName : null,
          ContractType: row.ContractType ? row.ContractType : null,
          EndDate: row.EndDate
            ? moment(row.EndDate).format("YYYY-MM-DD")
            : null,
          MachineModel: row.MachineModel ? row.MachineModel : null,
          LastVisitDate: row.LastVisitDate
            ? moment(row.LastVisitDate).format("YYYY-MM-DD")
            : null,
          LastCounter1: row.LastCounter1 ? row.LastCounter1 : null,
        };
      });
      setFinishedContractData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="general-info-on-machines-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(finishedContractData.length) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"العقود المنتهية التي لم تجدد"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
            data={finishedContractData}
            headers={headers}
          />
          <div className="general-info-on-machines-table-container">
            <table>
              <thead>
                <tr>
                  <th>{headers.ClientName}</th>
                  <th>{headers.ComputerNumber} </th>
                  <th>{headers.AreaName}</th>
                  <th>{headers.TechnicianName}</th>
                  <th>{headers.ContractType}</th>
                  <th>{headers.EndDate}</th>
                  <th>{headers.MachineModel}</th>
                  <th>{headers.LastVisitDate}</th>
                  <th>{headers.LastCounter1}</th>
                </tr>
              </thead>
              <tbody>
                {finishedContractData.map((info, idx) => (
                  <tr key={idx}>
                    <td>{info.ClientName}</td>
                    <td>{info.ComputerNumber}</td>
                    <td>{info.AreaName}</td>
                    <td>{info.TechnicianName}</td>
                    <td>{info.ContractType}</td>
                    <td>{info.EndDate}</td>
                    <td>{info.MachineModel}</td>
                    <td>{info.LastVisitDate}</td>
                    <td>{info.LastCounter1}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default FinishedContractsPrintOut;
