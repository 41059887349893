import React from "react";
import { Card, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import "./Settings.scss";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";

export default function Settings() {
  const router = useHistory();

  const settingsList = [
    {
      header: resources.pages.settings.users,
      icon: "users",
      meta: [
        resources.pages.user.addNewUser,
        resources.pages.user.editUser,
        resources.pages.user.changePassword,
        resources.pages.user.deactiveUser,
      ],
      url: "/settings/users/usersList",
      color: "blue",
      isAuthorized: isAuthorized(Perm.viewUsers),
    },
    {
      header: resources.pages.permissions.groupPermissions,
      icon: "hand paper",
      meta: [
        resources.pages.permissions.addGroup,
        resources.pages.permissions.editGroup,
        resources.pages.permissions.deleteGroup,
      ],
      url: "/groupPermissions",
      color: "blue",
      isAuthorized: isAuthorized(Perm.viewGroupPermissions),
    },
    {
      header: resources.pages.settings.clientBranches,
      icon: "building",
      meta: [
        resources.pages.clients.addClient,
        resources.pages.clients.editClient,
        resources.pages.branch.addBranch,
        resources.pages.branch.editBranch,
        resources.pages.branch.branchList,
      ],
      url: "/ClientBranches/ClientBranchesList",
      isAuthorized: isAuthorized(Perm.viewClientBranch),
    },
    {
      header: resources.pages.settings.machines,
      icon: "computer",
      meta: [
        resources.pages.machines.addMachine,
        resources.pages.machines.pageTitle,
      ],
      url: "/machines/MachinesList",
      isAuthorized: isAuthorized(Perm.viewMachines),
    },
    {
      header: resources.pages.settings.contracts,
      icon: "file alternate",
      meta: [
        resources.pages.contracts.addContract,
        resources.pages.contracts.pageTitle,
      ],
      url: "/contracts",
      isAuthorized: isAuthorized(Perm.viewContract),
    },
    {
      header: resources.pages.settings.technicians,
      icon: "group",
      meta: [
        resources.pages.technicians.addTechnician,
        resources.pages.technicians.pageTitle,
      ],
      url: "/technicians",
      isAuthorized: isAuthorized(Perm.viewTech),
    },
    {
      header: resources.pages.settings.techniciansArea,
      icon: "map",
      meta: [resources.pages.technicianAreas.pageTitle],
      url: "/technicians/areas",
      isAuthorized: isAuthorized(Perm.viewtTechAreas),
    },
    {
      header: resources.pages.settings.UserLeaves,
      icon: "time",
      meta: [
        resources.pages.leave.addLeave,
        resources.pages.leave.editLeave,
        resources.pages.leave.leaveList,
      ],
      url: "/settings/Leave/LeaveList",
      isAuthorized: isAuthorized(Perm.viewLeaves),
    },
    {
      header: resources.pages.settings.lookup,
      icon: "star",
      meta: [resources.pages.lookup.subTitle],
      url: "/settings/lookUp",
      isAuthorized: isAuthorized(Perm.viewLookups),
    },
    {
      header: resources.pages.settings.tablets,
      icon: "tablet alternate",
      meta: [
        resources.pages.tablet.addTablets,
        resources.pages.tablet.manageTablets,
      ],
      url: "/settings/tabletsList",
      isAuthorized: isAuthorized(Perm.viewTablets),
    },
  ];
  return (
    <Card.Group className="setting-card-groups">
      {settingsList
        .filter((f) => f.isAuthorized)
        .map((row, idx) => (
          <Card
            key={idx}
            link
            color="blue"
            onClick={() => router.push(row.url)}
            className="setting-card"
          >
            <div className="card-icon">
              <Icon name={row.icon} />
            </div>
            <Card.Header className="card-header">{row.header}</Card.Header>
            <Card.Meta className="card-meta">{row.meta.join(" ، ")}</Card.Meta>
          </Card>
        ))}
    </Card.Group>
  );
}
