import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dropdown,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import validateValues from "./user.validator";
import useDebounce from "../../components/helpers/useDebounce";

const ManageUser = ({ location }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialvalues, setInitialvalues] = useState(null);
  const [isExternalUser, setIsExternalUser] = useState(false);

  const [clients, setClients] = useState([]);
  const [clientNameIsLoading, setClientNameIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientID, setClientID] = useState(0);
  const debouncedClientNameSearch = useDebounce(clientName, 1000);

  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);

  const [machineModels, setMachineModels] = useState([]);
  const [selectedMachineModels, setSelectedMachineModels] = useState([]);

  const router = useHistory();

  const getUserInfo = async (userID) => {
    try {
      const res = await serviceApi.service("users").get(userID);
      if (res) {
        setInitialvalues(res);
        if (res.isExternalUser) {
          fetchClientById(res.clientId);
          setIsExternalUser(res.isExternalUser);
          setClientID(res.clientId);
          setSelectedBranches(
            res.userBranches.includes("all")
              ? ["all"]
              : res.userBranches.split(",").map((item) => parseInt(item))
          );
          setSelectedMachineModels(
            res.userMachineModels.includes("all")
              ? ["all"]
              : res.userMachineModels.split(",").map((item) => parseInt(item))
          );
        }
      } else setInitialvalues(null);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchClients = async () => {
    try {
      setClientNameIsLoading(true);
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "clientName"],
          clientName: { $like: `%${clientName}%` },
          $limit: 25,
        },
      });

      if (Boolean(res && res.data && res.data.length > 0))
        setClients(
          res.data.map((c) => {
            return { key: c.ClientID, text: c.clientName, value: c.ClientID };
          })
        );
      else setClients([]);

      setClientNameIsLoading(false);
    } catch (e) {
      setClientNameIsLoading(false);
      console.log(e);
    }
  };
  const fetchBranches = async () => {
    try {
      var res = await serviceApi.service("queries").find({
        query: {
          model: "ClientBranch",
          attributes: ["branchId", "branchName"],
          where: { clientID: clientID },
        },
      });

      const branchesOptions = res.data.map((item) => ({
        key: item.branchId,
        text: item.branchName,
        value: item.branchId,
      }));
      setBranches(branchesOptions);
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };
  const fetchMachineModels = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 22,
          $select: ["LookupID", "LookupName"],
        },
      });

      const ModelOptions = res.data.map((item) => ({
        key: item.LookupID,
        text: item.LookupName,
        value: item.LookupID,
      }));
      setMachineModels(ModelOptions);
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };
  const fetchClientById = async (id) => {
    try {
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "clientName"],
          clientId: id,
        },
      });
      if (Boolean(res && res.data && res.data.length > 0))
        setClients(
          res.data.map((c) => {
            return { key: c.ClientID, text: c.clientName, value: c.ClientID };
          })
        );
      else setClients([]);
    } catch (error) {
      console.log(error);
      setClients([]);
    }
  };

  useEffect(() => {
    fetchBranches();
    fetchMachineModels();
  }, [clientID]);

  useEffect(() => {
    if (Boolean(clientName && clientName.length > 2)) {
      fetchClients();
    } else {
      setClientName("");
      setClientID(0);
    }
  }, [debouncedClientNameSearch]);

  const onClientSelected = (e, { value }) => {
    setClientID(value);
    setSelectedBranches([]);
    setSelectedMachineModels([]);
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.userId))
      getUserInfo(+location.state.userId);
  }, [location]);

  const onSubmit = async (values) => {
    values = {
      ...values,
      isExternalUser: isExternalUser,
      clientId: clientID,
      userBranches: selectedBranches.join(","),
      userMachineModels: selectedMachineModels
        .filter((model) => !isNaN(model))
        .join(","),
    };
    setIsSubmitting(true);
    try {
      if (Boolean(initialvalues) && Boolean(initialvalues.userId))
        await serviceApi.service("users").patch(+initialvalues.userId, values);
      else await serviceApi.service("users").create(values);
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === 400) {
        setErrorMessage(resources.pages.user.errors.duplicateUser);
      }
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    router.replace("/settings/users/usersList");
  };

  useEffect(() => {
    selectedBranches.includes("all") ? setBranches([]) : fetchBranches();
  }, [selectedBranches]);

  useEffect(() => {
    selectedMachineModels.includes("all")
      ? setMachineModels([])
      : fetchMachineModels();
  }, [selectedMachineModels]);

  return (
    <Grid className="form-container">
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <Grid.Column>
        <PageHeadeTitle
          content={
            Boolean(initialvalues)
              ? resources.pages.user.editUser
              : resources.pages.user.addNewUser
          }
          icon="user"
        />
        <Segment>
          <FinalForm
            onSubmit={onSubmit}
            initialValues={initialvalues}
            validate={(v) => validateValues(v, Boolean(initialvalues))}
            render={({ handleSubmit }) => (
              <Form
                className="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                loading={isSubmitting}
              >
                <Grid columns={2} stackable>
                  <Grid.Column>
                    <p>{resources.pages.user.username}</p>
                    <Field name="userName">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          icon="user"
                          placeholder={resources.pages.user.username}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.user.fullname}</p>
                    <Field name="fullName">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          icon="user"
                          placeholder={resources.pages.user.fullname}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  {!Boolean(initialvalues) && (
                    <>
                      <Grid.Column>
                        <p>{resources.pages.user.password}</p>
                        <Field name="password">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="lock"
                              type="password"
                              placeholder={resources.pages.user.password}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.user.confirmPassword}</p>
                        <Field name="confirmPassword">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="lock"
                              type="password"
                              placeholder={resources.pages.user.confirmPassword}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </>
                  )}
                  <Grid.Column>
                    <p>{resources.pages.user.email}</p>
                    <Field name="email">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          icon="at"
                          placeholder={resources.pages.user.email}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.user.phone}</p>
                    <Field name="phone">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          icon="phone"
                          placeholder={resources.pages.user.phone}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p style={{ marginLeft: "1rem", paddingTop: "0.5rem" }}>
                      {resources.pages.user.externalUser}
                    </p>
                    <Field name="externalUser">
                      {({ input, meta }) => (
                        <Form.Checkbox
                          toggle
                          checked={isExternalUser}
                          onChange={() => setIsExternalUser(!isExternalUser)}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                </Grid>
                <Grid columns={3} stackable>
                  {isExternalUser && (
                    <>
                      <Grid.Column>
                        <p>{resources.pages.clients.clientName}</p>
                        <Field name="client">
                          {({ input, meta }) => (
                            <Form.Dropdown
                              {...input}
                              error={meta.touched && meta.error}
                              options={clients}
                              selection
                              noResultsMessage={resources.common.noData}
                              search
                              value={clientID}
                              onSearchChange={(e) =>
                                setClientName(e.target.value.trim())
                              }
                              loading={clientNameIsLoading}
                              onChange={onClientSelected}
                              className={`${
                                Boolean(clients.length)
                                  ? ""
                                  : "without-down-arrow"
                              }`}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.branch.branchName}</p>
                        <Field name="branches">
                          {({ input, meta }) => (
                            <Form.Dropdown
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              multiple
                              selection
                              options={[
                                {
                                  key: "all",
                                  text: "جميع الفروع",
                                  value: "all",
                                },
                                ...branches,
                              ]}
                              noResultsMessage={resources.common.noData}
                              value={selectedBranches}
                              onChange={(e, { value }) => {
                                value.includes("all")
                                  ? setSelectedBranches(["all"])
                                  : setSelectedBranches(value);
                              }}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.machines.MachineModel}</p>
                        <Field name="machineModel">
                          {({ input, meta }) => (
                            <Form.Dropdown
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              multiple
                              search
                              selection
                              options={[
                                {
                                  key: "all",
                                  text: "جميع الموديلات",
                                  value: "all",
                                },
                                ...machineModels,
                              ]}
                              noResultsMessage={resources.common.noData}
                              onChange={(e, { value }) => {
                                value.includes("all")
                                  ? setSelectedMachineModels(["all"])
                                  : setSelectedMachineModels(value);
                              }}
                              value={selectedMachineModels}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </>
                  )}
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  className="save-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {resources.common.save}
                </Button>
                <Button size="large" type="button" onClick={handleConfirm}>
                  {resources.common.back}
                </Button>
                {errorVisible.visible && (
                  <Message
                    negative
                    compact
                    className="error-message"
                    content={
                      errorMessage || resources.common.errors.genericServerError
                    }
                  />
                )}
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default ManageUser;
