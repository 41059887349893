import Axios from "axios";
const routingPath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ROUTING_PATH
    : `${window.location.protocol}//${window.location.host}`;
const RouterServiceApi = {
  route: (points) => {
    let pointsParams = "";
    points.forEach((point) => {
      pointsParams += "&point=" + point.latitude + "," + point.longitude;
    });
    return Axios.get(
      `${routingPath}/route?type=json&locale=ar${pointsParams}`,
      {
        crossdomain: true,
      }
    );
  },
};

export default RouterServiceApi;
