import { userActions } from "../actions";
import serviceApi from "../../api";

const initialState = {
  user: {},
  accessToken: null,
  attempts: 0,
  loggedOut: false,
  lang: localStorage.getItem("lang") ?? "ar",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.LOGIN_REQUEST_SUCCESS:
      return action.payload.accessToken ? action.payload : state;
    case userActions.LOGIN_REQUEST_FAILED:
      return { ...initialState, attempts: state.attempts + 1 };
    case userActions.LOGOUT:
      serviceApi.logout();
      return { ...initialState, loggedOut: true };
    case userActions.LANGUAGE_CHANGED:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
