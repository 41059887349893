import React, { useState, useEffect } from "react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import { Loader } from "semantic-ui-react";
import serviceApi from "../../api";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import "./MachinesContarctTechniciansPrintOut.scss";
import moment from "moment";

const MachinesContarctTechniciansPrintOut = () => {
  const [machinesContractTech, setmachinesContractTech] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfVisits, setNumberOfVisits] = useState(1);

  const location = useLocation();
  const { from, to, TechnicianID } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const headers = {
    TechnicianName: "الفني",
    ComputerNumber: "رقم الكمبيوتر",
    machineModel: "موديل الالة",
    SerialNumber: "الرقم التسلسلي",
    ClientName: "اسم العميل",
    AreaName: "المنطقة",
    phone: "الهاتف",
    ContractNumber: "رقم العقد",
    EndDate: "تاريخ نهاية العقد",
    counterNumber: "رقم العداد",
    nextVisist: "تاريخ الصيانة القادم",
    ContractMachineNotes: "الملاحظات",
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          TechnicianID: TechnicianID,
          type: 8,
        },
      });
      let numOfVisits = 0;
      const data = res.map((row) => {
        return {
          ...row,
          TechnicianName: row.TechnicianName ? row.TechnicianName : null,
          ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
          machineModel: row.machineModel ? row.machineModel : null,
          SerialNumber: row.SerialNumber ? row.SerialNumber : null,
          ClientName: row.ClientName ? row.ClientName : null,
          AreaName: row.AreaName ? row.AreaName : null,
          Phone: row.Phone ? row.Phone : null,
          ContractNumber: row.ContractNumber ? row.ContractNumber : null,
          EndDate: row.EndDate
            ? moment(row.EndDate).format("YYYY-MM-DD")
            : null,
          counterNumber: row.counterNumber ? row.counterNumber : 0,
          nextVisist: row.nextVisist
            ? moment(row.nextVisist).format("YYYY-MM-DD")
            : null,
          isLate: row.isLate > 0 ? true : false,
          numOfVisits: row.isLate > 0 ? numOfVisits : ++numOfVisits,
          ContractMachineNotes: row.ContractMachineNotes
            ? row.ContractMachineNotes
            : null,
        };
      });
      setmachinesContractTech(data);
      setNumberOfVisits(data[data.length - 1].numOfVisits);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setmachinesContractTech([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="machines-contract-tech-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(machinesContractTech.length) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"الالات التي لها عقود حسب الفني  "}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
            data={machinesContractTech}
            headers={headers}
            //TODO: check model, phone number and end date ... it returns no data in export to excel
          />
          <div className="machines-contract-tech-table-container">
            <table>
              <thead>
                <tr>
                  <th className="machines-contract-table-cell-group-small">
                    الفني
                  </th>
                  <th className="machines-contract-table-cell-group-small">
                    رقم الكمبيوتر
                  </th>
                  <th className="machines-contract-table-cell-group-small">
                    موديل الالة
                  </th>
                  <th>الرقم التسلسلي</th>
                  <th className="machines-contract-table-cell-group-big">
                    اسم العميل
                  </th>
                  <th className="machines-contract-table-cell-group-small">
                    المنطقة
                  </th>
                  <th> الهاتف</th>
                  <th>رقم العقد</th>
                  <th className="machines-contract-table-cell-group-big">
                    {" "}
                    تاريخ نهاية العقد
                  </th>
                  <th>رقم العداد</th>
                  <th className="machines-contract-table-cell-group-big">
                    تاريخ الصيانة القادم
                  </th>
                  <th className="machines-contract-table-cell-group-big">
                    الملاحظات
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {machinesContractTech.map((info, idx) => (
                  <tr key={idx}>
                    <th>{info.TechnicianName}</th>
                    <th>{info.ComputerNumber}</th>
                    <th>{info.machineModel}</th>
                    <th>{info.SerialNumber}</th>
                    <th>{info.ClientName}</th>
                    <th>{info.AreaName}</th>
                    <th>{info.Phone}</th>
                    <th>{info.ContractNumber}</th>
                    <th>{moment(info.EndDate).format("YYYY-MM-DD")}</th>
                    <th>{info.counterNumber}</th>
                    <th>{info.nextVisist}</th>
                    <th>{info.ContractMachineNotes}</th>
                    <th>
                      {info.isLate > 0 ? (
                        <p>x</p>
                      ) : (
                        <>
                          <p>&#10003;</p>
                          {}
                        </>
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              <p>عدد الالات: {machinesContractTech.length}</p>
              <p>عدد المزار: {numberOfVisits}</p>
              <p>
                عدد الغير مزار: {machinesContractTech.length - numberOfVisits}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};
export default MachinesContarctTechniciansPrintOut;
