import qs from "qs";

const ReportExecute = (reportID, options) => {
  if (reportID === "periodEndedContracts") {
    window.open(
      "/PeriodEndedContractsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "notDeservedContracts") {
    window.open(
      "/NotDeservedContractsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "techAreasAndMachinesNum") {
    window.open(
      "/TechAreasAndMachinesNumPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "generalMachineInfo") {
    window.open(
      "/GeneralInfoOnMachinesPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "machinesThatHaveContractWithVisits") {
    window.open(
      "/MachinesThatHaveContractwithVisitsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (
    reportID === "machinesThatHaveContractwithVisitsWithContractDataPrintOut"
  ) {
    window.open(
      "/MachinesThatHaveContractwithVisitsWithContractDataPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "machinesModelClient") {
    window.open(
      "/MachinesModelClientPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "machinesContarctTechnician") {
    window.open(
      "/MachinesContarctTechniciansPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "numberOfTechVisits") {
    window.open(
      "/NumberOfTechVisitsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "finishedContracts") {
    window.open(
      "/FinishedContractsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "lateTickets") {
    window.open(
      "/LateTicketsPrintOut" + qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "repeatedTickets") {
    window.open(
      "/RepeatedTicketsPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "periodicalVisits") {
    window.open(
      "/MachineCardForPeriodicalVisits" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "invoicesReport") {
    window.open(
      "/invoicesReport" + qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "maintenanceCard") {
    window.open(
      "/MaintenanceCard" + qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  } else if (reportID === "partsMovementReportPrintOut") {
    window.open(
      "/PartsMovementReportPrintOut" +
        qs.stringify(options, { addQueryPrefix: true }),
      "_blank"
    );
  }
};
export default ReportExecute;
