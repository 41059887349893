import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

export default function AreasList() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [areaID, setAreaID] = useState(null);
  const [areas, setAreas] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const headers = {
    areaName: resources.pages.areaList.areaName,
    createdBy: resources.common.createdBy,
    createdAt: resources.common.createdDate,
    CreationMethodType: resources.pages.area.creationWay,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  useEffect(() => {
    Boolean(searching && activePage) && loadAreas();
  }, [activePage, searching]);

  const fillSearchData = async () => {
    setSearchData({
      areaName: {
        type: "text",
        label: resources.pages.areaList.areaName,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "areas"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { [colID]: searching.term };
    else return {};
  };

  const loadAreas = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("area").find({
        query: {
          ...whereSearch("areaName"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });
      const editedData = res.data.map((row) => {
        return {
          ...row,
          createdAt: moment(row.createdAt).format("DD/MM/YYYY"),
        };
      });
      setAreas(editedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/areas/area", {
        areaID: row.areaID,
      });
    } else if (type === "remove") {
      setAreaID(row.areaID);
      setIsDelete(true);
    }
  };

  const deleteArea = async () => {
    try {
      await serviceApi
        .service("area")
        .remove(areaID)
        .then((res) => {
          setIsDelete(false);
          loadAreas();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteArea()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.areaList.pageTitle}
            icon="map"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addArea) && (
              <Button
                basic
                color="blue"
                content={resources.pages.areaList.addArea}
                onClick={() => router.push("/areas/area")}
              />
            )}
            {isAuthorized(Perm.viewGeofences) && (
              <ExportDataToExcel
                data={areas}
                headers={headers}
                title={resources.common.excelExport.areas}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="areas"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              headers={headers}
              data={areas}
              edit={isAuthorized(Perm.editArea) ? true : null}
              remove={isAuthorized(Perm.deleteArea) ? true : null}
              isLoading={isLoading}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              onCellClick={(row, type) => onCellClick(row, type)}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
