import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  GridRow,
  Icon,
  Divider,
  GridColumn,
  Dropdown,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import "./ticket.scss";
import MapModal from "../../components/Map/MapModal";
import wkt from "terraformer-wkt-parser";
import useDebounce from "../../components/helpers/useDebounce";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

export default function ManageTicket({ location }) {
  const user = useSelector((state) => state.user).user;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [clientID, setClientID] = useState(null);

  const [isSLA, setIsSLA] = useState(false);
  const [dueHours, setDueHours] = useState(0);

  const [branchID, setBranchID] = useState(null);
  const [contractMachineID, setContractMachineID] = useState(null);

  const [branches, setBranches] = useState([]);
  const [machines, setMachines] = useState([]);
  const [clients, setClients] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [levels, setLevels] = useState([]);
  const [callTypes, setCallTypes] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [branchDetails, setBranchDetails] = useState([]);
  const [machineDetails, setMachineDetails] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const [areaID, setAreaID] = useState(null);
  const [techniciansListIsLoading, setTechniciansListIsLoading] =
    useState(false);
  const router = useHistory();
  const [isViewArea, setIsViewArea] = useState(false);
  const [areaGeometry, setAreaGeometry] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [
    infosComesFromSelectedMachineNumber,
    setInfosComesFromSelectedMachineNumber,
  ] = useState(false);

  const [clientName, setClientName] = useState("");
  const [clientNameIsLoading, setClientNameIsLoading] = useState(false);
  const debouncedClientNameSearch = useDebounce(clientName, 1000);

  const [branchesNamesAreLoading, setBranchesNamesAreLoading] = useState(false);

  const [machineNumber, setMachineNumber] = useState("");
  const [machinesNumbersAreLoading, setMachinesNumbersAreLoading] =
    useState(false);
  const debouncedMachineNumberSearch = useDebounce(machineNumber, 1000);

  const [clientDetailsIsLoading, setClientDetailsIsLoading] = useState(false);
  const [branchDetailsIsLoading, setBranchDetailsIsLoading] = useState(false);
  const [machineDetailsIsLoading, setMachineDetailsIsLoading] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState(1);

  const [technicianNumber, setTechnicianNumber] = useState("");

  const ticketDueHours = [
    {
      key: 24,
      text: resources.pages.ticket.insideAmman,
      value: 24,
    },
    {
      key: 48,
      text: resources.pages.ticket.outsideAmman,
      value: 48,
    },
  ];

  const getTechNumber = async (id) => {
    const techNum = await serviceApi.service("queries").get(id, {
      query: {
        model: "Technicians",
        attributes: ["Mobile"],
        where: { TechnicianID: id },
      },
    });

    setTechnicianNumber(techNum);
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const newValues = {
        ...values,
        branchID: branchID,
        clientID: clientID,
        contractMachineID: contractMachineID,
        ticketDueHours: !isSLA ? values.ticketDueHours : null,
      };
      if (initialValues && initialValues.ticketID) {
        await serviceApi
          .service("tickets")
          .patch(+initialValues.ticketID, newValues);
      } else
        await serviceApi
          .service("tickets")
          .create({ ...newValues, statusID: 97 });

      //check if this technician has a phone number ... if yes then send SMS to this technician ... if no ignore and co

      //TODO: get username, password and userID from tesco
      if (technicianNumber.length > 0) {
      }
      setIsSubmitting(false);

      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const fillEditData = async (ticketId) => {
    try {
      const res = await serviceApi.service("queries").get(ticketId, {
        query: {
          model: "Tickets",
          include: [{ model: "ClientBranch", attributes: ["clientID"] }],
          where: { ticketID: ticketId },
        },
      });

      if (res) {
        const clientId = res.ClientBranch.clientID;
        const branchId = res.branchID;

        if (res.contractMachineID) {
          fillMachines(clientId, branchId);
          setContractMachineID(res.contractMachineID);
        } else {
          setClientID(clientId);
          fillClients(clientId);
          setBranchID(branchId);
        }

        setInitialValues({ ...res, clientID: clientId });
      }
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };
  useEffect(() => {
    if (Boolean(location && location.state && location.state.ticketID))
      fillEditData(+location.state.ticketID);
  }, [location]);

  const fillClients = async (clientID = null) => {
    try {
      setClientNameIsLoading(true);
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "ClientName"],
          ClientName: { $like: `%${clientName}%` },
          ...(clientID ? { ClientID: clientID } : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setClients(
          [
            { key: null, text: resources.common.notSpecified, value: null },
          ].concat(
            res.data.map((c) => {
              return { key: c.ClientID, text: c.ClientName, value: c.ClientID };
            })
          )
        );
      else setClients([]);

      setClientNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setClientNameIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(clientName && clientName.length > 2)) fillClients();
  }, [debouncedClientNameSearch]);

  const fillBranches = async (clientID = null) => {
    try {
      setBranchesNamesAreLoading(true);
      const res = await serviceApi.service("ClientBranch").find({
        query: {
          $select: ["BranchName", "BranchID"],
          ...(clientID ? { clientID: clientID } : {}),
          $limit: 200,
        },
      });

      if (res && res.data && res.data.length > 0) {
        setBranches(
          [
            { key: null, text: resources.common.notSpecified, value: null },
          ].concat(
            res.data.map((c) => {
              return {
                key: c.BranchID,
                text: c.BranchName,
                value: c.BranchID,
              };
            })
          )
        );
      } else setBranches([]);

      setBranchesNamesAreLoading(false);
    } catch (error) {
      // TODO: Show error message
      setBranchesNamesAreLoading(false);
      console.log(error);
    }
  };

  const fillMachines = async (cID = null, bID = null) => {
    try {
      setMachinesNumbersAreLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          attributes: ["ContractMachineID"],
          include: [
            {
              model: "Machine",
              attributes: ["machineId", "computerNumber", "serialNumber"],
              where: {
                ...(selectedSearchOption === 2
                  ? { serialNumber: { like: `%${machineNumber}%` } }
                  : {}),
              },
            },
            {
              model: "Contract",
              attributes: ["ClientID"],
              ...(cID ? { where: { clientID: cID } } : {}),
            },
          ],
          where: {
            contractIsActive: 1,
            ...(selectedSearchOption === 1
              ? { machineId: { like: `%${machineNumber}%` } }
              : {}),

            ...(bID ? { branchID: bID } : {}),
          },
          limit: 10,
          order: [["machineId", "ASC"]],
        },
      });

      if (res && res.data && res.data.length) {
        setMachines(
          res.data.map((c) => {
            return {
              key: c.ContractMachineID,
              text:
                selectedSearchOption === 1
                  ? c.Machine.computerNumber
                  : c.Machine.serialNumber,
              value: c.ContractMachineID,
            };
          })
        );
      } else setMachines([]);
      setMachineDetails([]);

      setMachinesNumbersAreLoading(false);
    } catch (e) {
      setMachinesNumbersAreLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(machineNumber && clientID))
      fillMachines(clientID, branchID ? branchID : null);
    else if (Boolean(machineNumber)) fillMachines();
  }, [debouncedMachineNumberSearch]);

  useEffect(() => {
    setMachines([]);
    setMachineDetails([]);
  }, [machineNumber]);

  const fillCallTypes = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 25,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setCallTypes(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setCallTypes([]);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillReasons = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: {
            $in: [29, 22300], // if developemnt change second cell in array to 22275, if production 22300
          },
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setReasons(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setReasons([]);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };
  const fillLevels = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 90,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setLevels(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setLevels([]);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillTechs = async (id) => {
    try {
      setTechniciansListIsLoading(true);
      if (id) {
        const res = await serviceApi.service("technicians").find({
          query: {
            $select: ["technicianID", "TechnicianName"],
          },
        });

        if (res && res.data && res.data.length > 0) {
          const resAlternative = await serviceApi.service("queries").find({
            query: {
              model: "TechnicianAlternative",
              include: [
                {
                  model: "Technicians",
                  attributes: ["technicianID", "TechnicianName"],
                },
              ],
              where: { PrimaryTechnicianID: id },
            },
          });

          let mainTech = res.data.map((t) => {
            if (t.technicianID === id)
              return {
                key: t.technicianID,
                text: resources.pages.ticket.main + t.TechnicianName,
                value: t.technicianID,
                type: 1,
              };
            else
              return {
                key: t.technicianID,
                text: t.TechnicianName,
                value: t.technicianID,
                type: 3,
              };
          });

          if (
            resAlternative &&
            resAlternative.data &&
            resAlternative.data.length > 0
          ) {
            const alternatives = resAlternative.data.reduce(
              (technician, alterTech) => {
                return {
                  ...technician,
                  [alterTech.Technician.technicianID]: {
                    key: alterTech.Technician.technicianID,
                    text:
                      resources.pages.ticket.alter +
                      alterTech.Technician.TechnicianName,
                    value: alterTech.Technician.technicianID,
                    type: 2,
                  },
                };
              },
              {}
            );

            mainTech = mainTech.filter((t) => !Boolean(alternatives[t.value]));
            mainTech = mainTech
              .concat(Object.values(alternatives))
              .sort((a, b) => a.type - b.type);

            setTechnicians(mainTech);
          } else
            setTechnicians(
              Object.values(mainTech).sort((a, b) => a.type - b.type)
            );
        }
      } else if (areaID) {
        const res = await serviceApi.service("queries").find({
          query: {
            model: "TechnicianArea",
            attributes: ["TechnicianID"],
            include: [
              {
                model: "Technicians",
                attributes: ["technicianID", "TechnicianName"],
              },
            ],
            where: { areaID: areaID },
          },
        });
        if (res && res.data && res.data.length > 0) {
          const techs = res.data.filter((f) => f.TechnicianID !== null);
          setTechnicians(
            Array.from(
              new Set(techs.map((c) => c.Technician.technicianID))
            ).map((c) => {
              return {
                key: c,
                text: techs.find((s) => s.Technician.technicianID === c)
                  .Technician.TechnicianName,
                value: c,
              };
            })
          );
        } else setTechnicians([]);
      } else {
        const res = await serviceApi.service("technicians").find({
          query: {
            $select: ["technicianID", "TechnicianName"],
          },
        });

        if (res && res.data && res.data.length > 0) {
          setTechnicians(
            res.data.map((row) => {
              return {
                key: row.technicianID,
                text: row.TechnicianName,
                value: row.technicianID,
              };
            })
          );
        } else setTechnicians([]);
      }
      setTechniciansListIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setTechniciansListIsLoading(false);
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fillCallTypes();
    fillReasons();
    fillTechs();
    fillLevels();
  }, []);

  const getClientDetails = async (clientId) => {
    try {
      setClientDetailsIsLoading(true);

      const res = await serviceApi.service("client").get(clientId);

      if (Boolean(res))
        setClientDetails([
          {
            label: resources.pages.clients.clientName,
            value: res.clientName,
          },
          {
            label: resources.pages.clients.clientSituation,
            value: res.clientSituation
              ? res.clientSituation
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.clients.vip,
            value: res.vip ? resources.common.yes : resources.common.no,
          },
          {
            label: resources.common.notes,
            value: res.notes ? res.notes : resources.common.noNotes,
          },
        ]);
      else setClientDetails([]);
      setClientDetailsIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setClientDetailsIsLoading(false);
      console.log(error);
    }
  };

  const viewArea = async (AreaID) => {
    if (Boolean(AreaID)) {
      try {
        const res = await serviceApi.service("area").get(AreaID);
        if (res && res.length > 0 && res[0].geometry) {
          setAreaGeometry(wkt.parse(res[0].geometry));
          setIsViewArea(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const getBranchDetails = async (branchId) => {
    try {
      setBranchDetailsIsLoading(true);
      const data = await serviceApi.service("queries").find({
        query: {
          model: "ClientBranch",
          include: [{ model: "Area", attributes: ["areaName", "areaID"] }],
          where: { branchID: branchId },
        },
      });

      if (data && data.data && data.data.length > 0) {
        const res = data.data[0];

        setBranchDetails([
          {
            label: resources.pages.branch.branchName,
            value: res.branchName,
          },
          {
            label: resources.pages.user.email,
            value: res.email ? res.email : resources.common.notSpecified,
          },
          {
            label: resources.pages.branch.address,
            value: res.address,
          },
          {
            label: resources.pages.area.areaName,
            value: res.Area ? res.Area.areaName : resources.common.notSpecified,
            icon: (
              <Icon
                name="eye"
                style={{ cursor: "pointer" }}
                onClick={() => viewArea(res.Area.areaID)}
              />
            ),
          },
        ]);
        setAreaID(res.Area.areaID);

        if (!clientID || clientID !== res.ClientID) setClientID(res.ClientID);
      } else {
        setBranchDetails([]);
        setAreaID(null);
      }
      setBranchDetailsIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setBranchDetailsIsLoading(false);
      console.log(error);
    }
  };

  const getMachineDetails = async () => {
    try {
      setMachineDetailsIsLoading(true);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          attributes: ["withSLA", "dueHours", "BranchID", "TechnicianID"],
          include: [
            {
              model: "Machine",
              include: [
                {
                  model: "Lookup",
                  as: "MachineType",
                  attributes: ["LookupName"],
                },
                {
                  model: "Lookup",
                  as: "MachineModel",
                  attributes: ["LookupName"],
                },
              ],
            },
            {
              model: "Contract",
              include: [
                {
                  model: "Lookup",
                  as: "ContractType",
                  attributes: ["LookupName"],
                },
              ],
              attributes: ["contractNumber"],
            },
          ],
          where: { contractMachineID: contractMachineID },
        },
      });

      if (Boolean(res && res.data && res.data.length > 0)) {
        const machine = res.data[0].Machine;
        const contract = res.data[0].Contract;

        if (res.data[0].withSLA) {
          setIsSLA(true);
          setDueHours(res.data[0].dueHours);
        } else setIsSLA(false);

        setMachineDetails([
          {
            label: resources.pages.machines.serialNumber,
            value: machine.serialNumber,
          },
          {
            label: resources.pages.machines.MachineModelID,
            value: machine.MachineModel
              ? machine.MachineModel.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.MachineTypeID,
            value: machine.MachineType
              ? machine.MachineType.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.addContract.contractNumber,
            value: contract.contractNumber
              ? contract.contractNumber
              : resources.common.notSpecified,
          },
          {
            label: `${resources.pages.addContract.dueHours} (${resources.common.inHours})`,
            value: res.data[0].dueHours
              ? res.data[0].dueHours
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.addContract.contractType,
            value: contract.ContractType
              ? contract.ContractType.LookupName
              : resources.common.notSpecified,
          },
        ]);

        if (!branchID) {
          setInfosComesFromSelectedMachineNumber(true);
          setBranchID(res.data[0].BranchID);
        }

        if (res.data[0].TechnicianID) {
          fillTechs(res.data[0].TechnicianID);
        } else {
          fillTechs(null);
        }
      } else setMachineDetails([]);

      setMachineDetailsIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachineDetailsIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(clientID)) {
      fillBranches(clientID);

      if (infosComesFromSelectedMachineNumber) {
        fillClients(clientID);
        setInfosComesFromSelectedMachineNumber(false);
      } else {
        setBranchID(null);
        setContractMachineID(null);
        setMachines([]);
      }

      getClientDetails(clientID);
    } else {
      setClientDetails([]);
      setBranchID(null);
      setContractMachineID(null);
      setMachines([]);
    }
  }, [clientID]);

  useEffect(() => {
    if (branchID) {
      if (!infosComesFromSelectedMachineNumber) {
        setContractMachineID(null);
        setMachines([]);
      }

      getBranchDetails(branchID);
    } else {
      setBranchDetails([]);
      setContractMachineID(null);
    }
  }, [branchID]);

  useEffect(() => {
    if (contractMachineID) getMachineDetails();
    else {
      setIsSLA(false);
      setMachineDetails([]);
    }
  }, [contractMachineID]);

  const handleConfirm = () => {
    router.replace("/ticket/ticketsList");
  };

  const getDetails = () => {
    return (
      <>
        <GridRow className="ticket-detail-row" columns={3}>
          <GridColumn>
            {clientDetailsIsLoading ? (
              <Loader active={clientDetailsIsLoading} />
            ) : (
              Boolean(clientDetails.length) && (
                <Message info className="row-message">
                  <Message.Header>
                    {resources.pages.ticket.clientDetails}
                    <Divider style={{ margin: "5px 0px" }} />
                  </Message.Header>
                  {clientDetails.map((row, idx) => (
                    <p key={idx}>
                      {row.label} : {row.value}
                    </p>
                  ))}
                </Message>
              )
            )}
          </GridColumn>
          <GridColumn>
            {branchDetailsIsLoading ? (
              <Loader active={branchDetailsIsLoading} />
            ) : (
              Boolean(branchDetails.length) && (
                <Message info className="row-message">
                  <Message.Header>
                    {resources.pages.ticket.branchDetails}
                    <Divider style={{ margin: "5px 0px" }} />
                  </Message.Header>
                  {branchDetails.map((row, idx) => (
                    <p key={idx}>
                      {row.label} : {row.value}
                      {Boolean(row.icon) && row.icon}
                    </p>
                  ))}
                </Message>
              )
            )}
          </GridColumn>
          <GridColumn>
            {machineDetailsIsLoading ? (
              <Loader active={machineDetailsIsLoading} />
            ) : (
              Boolean(machineDetails.length) && (
                <Message info className="row-message">
                  <Message.Header>
                    {resources.pages.ticket.machineDetails}
                    <Divider style={{ margin: "5px 0px" }} />
                  </Message.Header>
                  {machineDetails.map((row, idx) => (
                    <p
                      key={idx}
                      style={
                        row.label === resources.pages.addContract.contractType
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {row.label} : {row.value}
                    </p>
                  ))}
                </Message>
              )
            )}
          </GridColumn>
        </GridRow>

        {isSLA && (
          <div className="SLA-warningMessage-container">
            <Message negative>
              {resources.pages.ticket.warningSLA +
                dueHours +
                resources.pages.addContract.hours}
            </Message>
          </div>
        )}
      </>
    );
  };

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!clientID) {
      errors.clientID = requiredField;
    }
    if (!branchID) {
      errors.branchID = requiredField;
    }

    if (!values.typeID) {
      errors.typeID = requiredField;
    }
    if (!values.calltypeID) {
      errors.calltypeID = requiredField;
    }

    if (!values.levelID) {
      errors.levelID = requiredField;
    }
    if (!values.technicianID) {
      errors.technicianID = requiredField;
    }
    // if (!values.contactName) {
    //   errors.contactName = requiredField;
    // }
    // if (!values.contactPhone) errors.contactPhone = requiredField;
    // else if (
    //   !(
    //     values.contactPhone.startsWith("079") ||
    //     values.contactPhone.startsWith("078") ||
    //     values.contactPhone.startsWith("077")
    //   )
    // ) {
    //   errors.contactPhone = resources.pages.user.errors.phone;
    // } else if (values.contactPhone.length !== 10)
    //   errors.contactPhone = resources.pages.user.errors.phoneLength;
    if (!values.ticketDueHours && !isSLA) errors.ticketDueHours = requiredField;

    return errors;
  };

  const searchOptions = [
    { key: 1, text: "رقم الكمبيوتر", value: 1 },
    { key: 2, text: "الرقم التسلسلي", value: 2 },
  ];

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <MapModal
        isOpen={isViewArea}
        jsonData={areaGeometry}
        onCancel={() => setIsViewArea(false)}
      />
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              initialValues && initialValues.ticketID
                ? resources.pages.ticket.editPageTitle
                : resources.pages.ticket.addPageTitle
            }
            icon="user"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              validate={checkValidation}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid stackable>
                    <GridRow columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.clientName}</p>
                        <Field name="clientID">
                          {({ input, meta }) => (
                            <Form.Select
                              name={input.name}
                              value={clientID}
                              options={clients}
                              placeholder={resources.pages.ticket.clientName}
                              noResultsMessage={resources.common.noData}
                              search={(e) => e}
                              onSearchChange={(e) =>
                                setClientName(e.target.value.trim())
                              }
                              loading={clientNameIsLoading}
                              onChange={(e, { value }) => {
                                setClientID(value);
                                input.onChange(value);
                              }}
                              error={meta.touched && meta.error}
                              disabled={Boolean(
                                location &&
                                  location.state &&
                                  location.state.ticketID
                              )}
                              className={`${
                                Boolean(clients.length > 0)
                                  ? ""
                                  : "without-down-arrow"
                              }`}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.branchName}</p>
                        <Field name="branchID">
                          {({ input, meta }) => (
                            <Form.Select
                              name={input.name}
                              value={branchID}
                              options={branches}
                              placeholder={resources.pages.ticket.branchName}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(branches.length > 0)}
                              loading={branchesNamesAreLoading}
                              onChange={(e, { value }) => {
                                setBranchID(value);
                                input.onChange(value);
                              }}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Dropdown
                          options={searchOptions}
                          onChange={(e, { value }) => {
                            setMachineNumber("");
                            setSelectedSearchOption(value);
                          }}
                          value={selectedSearchOption}
                          className="machines-search-options-ddl"
                        />
                        <Field name="contractMachineID">
                          {({ input, meta }) => (
                            <Form.Select
                              name="contractMachineID"
                              value={contractMachineID}
                              options={machines}
                              placeholder={
                                selectedSearchOption === 1
                                  ? resources.pages.machines.computerNumber
                                  : resources.pages.machines.serialNumber
                              }
                              onChange={(e, { value }) => {
                                setContractMachineID(value);
                                input.onChange(value);
                              }}
                              noResultsMessage={resources.common.noData}
                              search={(e) => e}
                              onSearchChange={(e) =>
                                setMachineNumber(e.target.value.trim())
                              }
                              loading={machinesNumbersAreLoading}
                              error={meta.touched && meta.error}
                              className={`${
                                Boolean(machines.length > 0)
                                  ? ""
                                  : "without-down-arrow"
                              }`}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    {getDetails()}
                    <GridRow columns={2}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.CustomerSerial}</p>
                        <Field name="customerSerialNumber">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="building"
                              placeholder={
                                resources.pages.ticket.CustomerSerial
                              }
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.addContractMachine.address}</p>
                        <Field name="address">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="map pin"
                              placeholder={
                                resources.pages.addContractMachine.address
                              }
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    <GridRow columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.callType}</p>
                        <Field name="calltypeID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={callTypes}
                              placeholder={resources.pages.ticket.callType}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(callTypes.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.reason}</p>
                        <Field name="typeID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={reasons}
                              placeholder={resources.pages.ticket.reason}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(reasons.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.ticketLevel}</p>
                        <Field name="levelID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={levels}
                              placeholder={resources.pages.ticket.ticketLevel}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(levels.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    <GridRow columns={4}>
                      <Grid.Column>
                        <p>{resources.pages.technicians.technicianName}</p>
                        <Field name="technicianID">
                          {({ input, meta }) => (
                            <Form.Select
                              name={input.name}
                              value={input.value ? input.value : null}
                              options={technicians}
                              placeholder={
                                resources.pages.technicians.technicianName
                              }
                              onChange={(e, { value }) => {
                                input.onChange(value);
                                getTechNumber(value);
                              }}
                              loading={techniciansListIsLoading}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(technicians.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.contactName}</p>
                        <Field name="contactName">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="male"
                              placeholder={resources.pages.ticket.contactName}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.contactPhone}</p>
                        <Field name="contactPhone">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="call"
                              placeholder={resources.pages.ticket.contactPhone}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.common.email}</p>
                        <Field name="contactEmail">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="at"
                              placeholder={resources.common.email}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    <GridRow columns={!isSLA ? 3 : 1}>
                      {!isSLA && (
                        <Grid.Column>
                          <p>{`${resources.pages.addContract.dueHours} ( ${resources.common.inHours} )`}</p>
                          <Field name="ticketDueHours">
                            {({ input, meta }) => (
                              <Form.Select
                                name={input.name}
                                value={input.value ? input.value : null}
                                options={ticketDueHours}
                                placeholder={
                                  resources.pages.addContract.dueHours
                                }
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                                noResultsMessage={resources.common.noData}
                                error={meta.touched && meta.error}
                              />
                            )}
                          </Field>
                        </Grid.Column>
                      )}
                      <Grid.Column>
                        <p>{resources.pages.ticket.notes}</p>
                        <Field name="notes">
                          {({ input, meta }) => (
                            <Form.TextArea
                              {...input}
                              error={meta.touched && meta.error}
                              placeholder={resources.pages.ticket.notes}
                              icon="sticky note outline"
                              onChange={(e, { value }) => input.onChange(value)}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button size="large" type="button" onClick={handleConfirm}>
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
