import feathers from "@feathersjs/client";
import axios from "axios";

const serverPath = `${window.location.protocol}//${window.location.hostname}`;
const serverPort =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SERVER_PORT_DEV || 3030
    : process.env.REACT_APP_SERVER_PORT_PROD;

const host = `${serverPath}:${serverPort}`;

export const app = feathers();
app.configure(feathers.rest(host).axios(axios));
app.configure(feathers.authentication());

export { host };
export default app;
