import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Segment,
  Button,
  Icon,
  Modal,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import DeleteModal from "../../components/DeleteModal";
import SuccessModal from "../../components/SuccessModal";
import SearchBy from "../../components/SearchBy/SearchBy";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

export default function Technicians() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [technicianID, setTechnicianID] = useState(null);
  const [technicians, setTechnicians] = useState();
  const [alternateTech, setAlternateTech] = useState([]);
  const [alternateTechID, setAlternateTechID] = useState([]);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [successVisible, setSuccessVisible] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const headers = {
    technicianName: resources.pages.technicians.technicianName,
    mobile: resources.pages.ManageTechnicians.phone,
    email: resources.pages.ManageTechnicians.email,
    createdAt: resources.common.createdDate,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/Technicians/add", {
        technicianID: row.technicianId,
      });
    } else if (type === "remove") {
      setTechnicianID(row.technicianId);
      setIsDelete(true);
    } else if (type === "alterTech") {
      setTechnicianID(row.technicianId);
      setOpenItemModal(true);
    }
  };

  const deleteTechnician = async () => {
    try {
      await serviceApi
        .service("Technicians")
        .remove(technicianID)
        .then((res) => {
          setIsDelete(false);
          loadTechnicians();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  const fillSearchData = async () => {
    setSearchData({
      technicianName: {
        type: "text",
        label: resources.pages.technicians.technicianName,
      },
      mobile: {
        type: "text",
        label: resources.pages.ManageTechnicians.phone,
      },
      email: {
        type: "text",
        label: resources.pages.ManageTechnicians.email,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "technicians"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadTechnicians = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Technicians",
          attributes: [
            "technicianId",
            "technicianName",
            "mobile",
            "email",
            // "machineIdNotRequiredToCloseTicket",
            "createdAt",
          ],
          ...whereLikeSearch("technicianName"),
          ...whereLikeSearch("mobile"),
          ...whereLikeSearch("email"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });
      const newData = res.data.map((row) => {
        return {
          ...row,
          createdAt: moment(row.createdAt).format("DD/MM/YYYY"),
          mobile: Boolean(row.mobile)
            ? row.mobile
            : resources.common.notSpecified,
          email: Boolean(row.email) ? row.email : resources.common.notSpecified,
        };
      });
      setTechnicians(newData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillTechnicians = async () => {
    try {
      const res = await serviceApi.service("technicians").find({
        query: {
          $select: ["technicianId", "TechnicianName"],
        },
      });

      setAlternateTech(
        res.data
          .filter((item) => item.technicianId !== technicianID)
          .map((c) => {
            return {
              key: c.technicianId,
              text: c.TechnicianName,
              value: c.technicianId,
            };
          })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTechnicians();
  }, [searching, activePage]);

  useEffect(() => {
    if (Boolean(alternateTech && technicianID)) {
      const getSelectedIds = async () => {
        const ids = await serviceApi.service("TechnicianAlternative").find({
          query: {
            PrimaryTechnicianID: technicianID,
            $select: ["AlternativeTechnicianID"],
          },
        });

        setAlternateTechID(ids.data.map((alt) => alt.AlternativeTechnicianID));
      };

      getSelectedIds();
    }
  }, [alternateTech]);

  useEffect(() => {
    Boolean(openItemModal) && fillTechnicians();
  }, [openItemModal]);

  const saveAlternateTech = async () => {
    try {
      await serviceApi.service("TechnicianAlternative").remove(null, {
        query: {
          PrimaryTechnicianID: technicianID,
        },
      });

      if (alternateTechID.length > 0) {
        await serviceApi.service("TechnicianAlternative").create(
          alternateTechID.map((item) => ({
            PrimaryTechnicianID: technicianID,
            AlternativeTechnicianID: item,
          }))
        );
      }

      setAlternateTechID([]);
      setOpenItemModal(false);
      setSuccessVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlterTech = (event, { value }) => {
    setAlternateTechID(value);
  };
  const handleConfirm = () => {
    setSuccessVisible(false);
  };
  return (
    <>
      <DeleteModal
        onConfirm={() => deleteTechnician()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <Modal size="tiny" open={openItemModal}>
        <Modal.Header>
          {resources.pages.technicians.chooseAlternate}
        </Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder={resources.pages.technicians.chooseAlternate}
            fluid
            multiple
            search
            value={alternateTechID}
            selection
            options={alternateTech}
            onChange={handleAlterTech}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="modal-button"
            onClick={saveAlternateTech}
            positive
            floated="right"
          >
            {resources.common.save}
          </Button>
          <Button
            className="modal-button"
            onClick={() => setOpenItemModal(false)}
            negative
            floated="right"
          >
            {resources.common.cancel}
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.technicians.pageTitle}
            icon="users"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addNewTechnician) && (
              <Button
                basic
                color="blue"
                content={resources.pages.technicians.addTechnician}
                onClick={() => router.push("/technicians/add")}
              />
            )}
            {isAuthorized(Perm.viewTech) && (
              <ExportDataToExcel
                data={technicians}
                headers={headers}
                title={resources.common.excelExport.technicians}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="technicians"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={technicians}
              edit={isAuthorized(Perm.editTechnician) ? true : null}
              remove={isAuthorized(Perm.deleteTechnician) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              extraCells={[
                ...(isAuthorized(Perm.TechnicianAlternative)
                  ? [
                      {
                        key: "alterTech",
                        children: (
                          <Popup
                            size="mini"
                            content={resources.common.alterTechs}
                            trigger={
                              <Icon name="file alternate" color="blue" />
                            }
                          />
                        ),
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
