import React, { useState, useEffect } from "react";
import "./Area.scss";
import { resources } from "../../assets/LocalizationResources";
import {
  Grid,
  Segment,
  Loader,
  Dimmer,
  Select,
  Divider,
} from "semantic-ui-react";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import AreasMap from "../../components/Areas/AreasMap";
import ManageArea from "../../components/Areas/ManageArea";
import serviceApi from "../../api";
import AdministrativeRegions from "../../components/Areas/creationGeoMethods/AdministrativeRegions";
import LandMarks from "../../components/Areas/creationGeoMethods/LandMarks.js";
import ManageCreationMethodsAreas from "../../components/Areas/ManageCreationMethodsAreas";

export default function Area({ location }) {
  const [geomResult, setGeomResult] = useState({ geom: null, saved: false });
  const [area, setArea] = useState({ areaName: "", areaID: -1, geometry: "" });
  const [loading, setloading] = useState(true);
  const [creationMethodType, setCreationMethodType] = useState([]);
  const [creationMethodTypeID, setCreationMethodTypeID] = useState(null);

  const getAreaInfo = async (areaID) => {
    const res = await serviceApi.service("area").get(areaID);

    if (res && res.length > 0) {
      setCreationMethodTypeID(res[0].CreationMethodTypeID);
      setArea({ ...res[0], areaID: areaID });
    } else {
      setCreationMethodTypeID(null);
      setArea({ areaName: "", areaID: -1, geometry: "" });
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.areaID))
      getAreaInfo(+location.state.areaID);
    else {
      setArea({ areaName: "", areaID: -1, geometry: "" });
      setCreationMethodTypeID(null);
    }
  }, [location]);

  useEffect(() => {
    setloading(false);
  }, [area]);

  const handleAdministrativeRegionChange = (result) => {
    setGeomResult(result);
    setArea({ ...area, geometry: result.geom });
  };

  const getCreationGeoMethods = async () => {
    const res = await serviceApi.service("lookup").find({
      query: {
        ParentLookupID: 130,
        $select: ["LookupID", "LookupName"],
        $limit: 3,
      },
    });

    if (res && res.data && res.data.length > 0)
      setCreationMethodType(
        res.data
          .map((method) => {
            return {
              key: method.LookupID,
              text: method.LookupName,
              value: method.LookupID,
            };
          })
          .sort((a, b) => a.key - b.key)
      );
  };

  useEffect(() => {
    getCreationGeoMethods();
  }, []);

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <Grid>
        <Grid.Column>
          <PageHeadeTitle
            content={
              Boolean(location && location.state && location.state.areaID)
                ? resources.pages.area.editGeoArea
                : resources.pages.area.pageTitle
            }
            icon="map"
          />
          <Segment className="area-container">
            <Grid className="grid" divided="vertically">
              <Grid.Column width={6}>
                <div className="creation-method-container">
                  <div className="creation-method-select-list-container">
                    <label>{resources.pages.area.creationWay}</label>
                    <Select
                      options={creationMethodType}
                      // search={Boolean(creationMethodType.length)}
                      value={creationMethodTypeID}
                      onChange={(e, { value }) => {
                        setCreationMethodTypeID(+value);
                        setGeomResult({ geom: null, saved: false });
                        setArea({ ...area, geometry: "" });
                      }}
                      className="creation-method-select-list"
                      placeholder={resources.pages.area.creationMethod}
                    />
                  </div>
                  {+creationMethodTypeID === 132 && (
                    <AdministrativeRegions
                      onGeomSelected={handleAdministrativeRegionChange}
                    />
                  )}
                  {+creationMethodTypeID === 133 && (
                    <LandMarks
                      onGeomSelected={handleAdministrativeRegionChange}
                    />
                  )}
                  <Divider />
                  <ManageArea
                    geometryResult={geomResult}
                    area={{ areaName: area.areaName, areaID: area.areaID }}
                    creationMethodTypeID={creationMethodTypeID}
                  />
                </div>
              </Grid.Column>

              <Grid.Column width={10} className="area-map-container">
                {+creationMethodTypeID === 131 ? (
                  <AreasMap
                    onGeometryChange={(result) => setGeomResult(result)}
                    geometry={area.geometry}
                  />
                ) : (
                  <ManageCreationMethodsAreas geometry={area.geometry} />
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
