import Axios from "axios";
const searchPath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_SEARCH_PATH
    : `${window.location.protocol}//${window.location.host}`;
const SearchServiceApi = {
  search: (term, type) => {
    return Axios.get(
      `${searchPath}/GCESearchService/api/Search?term=${term}&type=${type}`,
      {
        headers: { "Access-Control-Allow-Origin": "*" },
      }
    );
  },
};

export default SearchServiceApi;
