import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Icon,
  Segment,
  Popup,
  Checkbox,
  Modal,
  Button,
} from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import TableTemplate from "../../components/Table/TableTemplate";
import serviceApi from "../../api";
import { resources } from "../../assets/LocalizationResources";
import { useHistory } from "react-router-dom";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import SearchBy from "../../components/SearchBy/SearchBy";

const ContractMachinesEditRequests = () => {
  const router = useHistory();

  const headers = {
    clientName: resources.pages.maintenanceReport.clientName,
    branchName: resources.pages.branch.branchName,
    machineId: resources.pages.maintenanceReport.computerNumber,
    editRequestNote: resources.pages.contractMachineEditRequests.editRequest,
    createdBy: resources.pages.machines.technician,
  };

  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );
  const searchState = useSelector((state) => state.cacheSearch);

  const [contractMachineEditRequestID, setContractMachineEditRequestID] =
    useState("");

  const [editRequests, setEditRequests] = useState([]);
  const [getEditRequestsIsLoading, setGetEditRequestsIsLoading] =
    useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});

  const [openCloseEditRequestModal, setOpenCloseEditRequestModal] =
    useState(false);

  const [showClosedEditRequests, setShowClosedEditRequests] = useState(false);

  const onPaging = (page) => setActivePage(page);

  const fillSearchData = async () => {
    setSearchData({
      clientName: {
        type: "text",
        label: resources.pages.ticket.clientName,
      },
      branchName: {
        type: "text",
        label: resources.pages.ticket.branchName,
      },
      machineId: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "contractMachineEditRequest"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadEditRequests = async () => {
    try {
      setGetEditRequestsIsLoading(true);

      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "ContractMachine",
          attributes: ["ContractMachineID"],
          include: [
            {
              innerJoin: true,
              model: "Machine",
              attributes: ["machineId"],
              ...whereLikeSearch("machineId"),
            },
            {
              innerJoin: true,
              model: "ClientBranch",
              attributes: ["BranchName", "BranchID", "ClientID"],
              include: [
                {
                  innerJoin: true,
                  model: "Client",
                  attributes: ["ClientID", "ClientName"],
                  ...whereLikeSearch("clientName"),
                },
              ],
              ...whereLikeSearch("branchName"),
            },
          ],
        },
        {
          innerJoin: true,
          model: "Users",
          attributes: ["FullName"],
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachineEditRequests",
          attributes: [
            "ContractMachineEditRequestID",
            "EditRequestNote",
            "IsClosed",
            "CreatedBy",
          ],
          jsonInclude: jsonInclude,
          where: {
            ...(CurrentUserID !== 1 && Boolean(CurrentTechnicianID)
              ? { technicianID: CurrentTechnicianID }
              : {}),
            ...(showClosedEditRequests ? { isClosed: 1 } : { isClosed: 0 }),
          },
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const requests = res.data.map((r) => {
        return {
          editRequestId: r.ContractMachineEditRequestID,
          machineId: r.ContractMachine.Machine.machineId,
          clientName: r.ContractMachine.ClientBranch.Client.ClientName,
          clientId: r.ContractMachine.ClientBranch.Client.ClientID,
          branchName: r.ContractMachine.ClientBranch.BranchName,
          editRequestNote: r.EditRequestNote,
          createdBy: r.User.FullName ? r.User.FullName : null,
          isClosed: r.IsClosed,
          rowColor: r.IsClosed ? "#d4d4d5" : "#FFFFFF",
        };
      });

      setEditRequests(requests);

      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);

      setGetEditRequestsIsLoading(false);
    } catch (error) {
      setGetEditRequestsIsLoading(false);
      console.log(error);
    }
  };

  const onCellClick = (row, type) => {
    if (type === "editMachineInfo") {
      router.push("/machines/ManageMachine", {
        machineID: row.machineId,
      });
    } else if (type === "editClientInfo") {
      router.push("/ClientBranches/ManageClient", {
        clientID: row.clientId,
      });
    } else if (type === "closeEditRequest") {
      setContractMachineEditRequestID(row.editRequestId);
      setOpenCloseEditRequestModal(true);
    }
  };

  const onCloseEditRequest = async () => {
    try {
      await serviceApi
        .service("contractMachineEditRequests")
        .patch(contractMachineEditRequestID, {
          isClosed: 1,
        });
      loadEditRequests();
      setOpenCloseEditRequestModal(false);
    } catch (err) {
      //TODO: add error message
      setOpenCloseEditRequestModal(false);
      console.log(err);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  useEffect(() => {
    if (!!activePage) loadEditRequests();
  }, [activePage, searching, showClosedEditRequests]);

  return (
    <Grid>
      <Grid.Column>
        <PageHeaderTitle
          content={resources.pages.contractMachineEditRequests.editRequests}
          icon="edit"
        />
        <Segment>
          <SearchBy
            pageName="contractMachineEditRequest"
            searchTypes={searchData}
            getSearchResults={getSearchResults}
            extraFields={
              <div className="not-associated-checkbox-container">
                <Checkbox
                  label={
                    resources.pages.contractMachineEditRequests
                      .showClosedEditRequests
                  }
                  checked={showClosedEditRequests}
                  onChange={(evt, elm) =>
                    setShowClosedEditRequests(elm.checked)
                  }
                />
              </div>
            }
          />
          <TableTemplate
            isLoading={getEditRequestsIsLoading}
            headers={headers}
            data={editRequests}
            extraCells={[
              ...(isAuthorized(Perm.editClient)
                ? [
                    {
                      key: "closeEditRequest",
                      children: (
                        <Popup
                          size="mini"
                          content={
                            resources.pages.contractMachineEditRequests
                              .closeEditRequest
                          }
                          trigger={<Icon name="close" color="grey" />}
                        />
                      ),
                      values: [true],
                      valuesID: "isClosed",
                      isCliCkable: true,
                    },
                  ]
                : []),
              ...(isAuthorized(Perm.editMachine)
                ? [
                    {
                      key: "editMachineInfo",
                      children: (
                        <Popup
                          size="mini"
                          content={
                            resources.pages.contractMachineEditRequests
                              .editMachineInfo
                          }
                          trigger={<Icon name="computer" color="blue" />}
                        />
                      ),
                      values: [true],
                      valuesID: "isClosed",
                      isCliCkable: true,
                    },
                  ]
                : []),
              ...(isAuthorized(Perm.editClient)
                ? [
                    {
                      key: "editClientInfo",
                      children: (
                        <Popup
                          size="mini"
                          content={
                            resources.pages.contractMachineEditRequests
                              .editClientInfo
                          }
                          trigger={<Icon name="user" color="blue" />}
                        />
                      ),
                      values: [true],
                      valuesID: "isClosed",
                      isCliCkable: true,
                    },
                  ]
                : []),
            ]}
            onCellClick={(row, type) => onCellClick(row, type)}
            paging={pagination}
            activePage={activePage}
            onPaging={onPaging}
          />
        </Segment>
      </Grid.Column>

      <Modal size="tiny" open={openCloseEditRequestModal}>
        <Modal.Header>
          {resources.pages.contractMachineEditRequests.closeEditRequest}
        </Modal.Header>
        <Modal.Content>
          {resources.pages.contractMachineEditRequests.closeEditRequestMessage}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseEditRequest} color="blue">
            {resources.common.yes}
          </Button>
          <Button onClick={() => setOpenCloseEditRequestModal(false)}>
            {resources.common.no}
          </Button>
        </Modal.Actions>
      </Modal>
    </Grid>
  );
};

export default ContractMachinesEditRequests;
