import React, { useState } from "react";
import { Modal, Form, TextArea, Button, Select } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import serviceApi from "../../api";
import { useSelector } from "react-redux";

export default function TicketActionsModal({
  ticketStatus,
  isOpen,
  ticketID,
  closeChangeTicketModal,
  contractMachineID = null,
  currentTicketStatus,
}) {
  const user = useSelector((state) => state.user.user);

  const [ticketNote, setTicketNote] = useState("");
  const [statusID, setStatusID] = useState(null);
  const [openNoteSection, setOpenNoteSection] = useState(false);
  const [emptyStatusError, setEmptyStatusError] = useState(false);
  const changeTicketStatus = async () => {
    try {
      if (Boolean(statusID) && !user.isExternalUser) {
        let values = {};
        if (statusID === 99)
          values = {
            closedDate: new Date(),
            statusID: statusID,
          };
        else
          values = {
            statusID: statusID,
          };

        if (Boolean(contractMachineID)) {
          values = { ...values, contractMachineID: contractMachineID };
        }

        const res = await serviceApi.service("tickets").patch(ticketID, values);
        if (res) {
          await serviceApi.service("ticket-status").create({
            TicketID: ticketID,
            note: ticketNote,
            StatusID: statusID == 22274 ? currentTicketStatus : statusID,
            isExternalUser: 0,
          });
        }
        closeChangeTicketModal(true);
        setOpenNoteSection(false);
      } else if (user.isExternalUser) {
        try {
          await serviceApi.service("ticket-status").create({
            TicketID: ticketID,
            note: ticketNote,
            StatusID: currentTicketStatus,
            isExternalUser: 1,
          });
          closeChangeTicketModal(true);
          setOpenNoteSection(false);
        } catch (error) {}
      } else setEmptyStatusError(true);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return (
    <Modal size="tiny" open={isOpen}>
      <Modal.Header>اتخاذ إجراء للتذكرة</Modal.Header>
      <Modal.Content>
        <Form>
          <Select
            fluid
            placeholder={resources.pages.ticket.ticketStatus}
            options={ticketStatus}
            search
            noResultsMessage="لا يوجد بيانات "
            onChange={(e, { value }) => {
              setStatusID(value);
              setOpenNoteSection(true);
              setEmptyStatusError(false);
            }}
            error={emptyStatusError}
          />
          {emptyStatusError && (
            <div style={{ color: "red", marginTop: "5px" }}>
              {resources.pages.ticket.emptyStatusError}
            </div>
          )}
          {statusID && openNoteSection && (
            <TextArea
              style={{ marginTop: 10 }}
              placeholder={resources.pages.ticket.ticketNote}
              onChange={(e, { value }) => setTicketNote(value.toString())}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={changeTicketStatus} positive>
          {resources.common.save}
        </Button>
        <Button
          onClick={() => {
            closeChangeTicketModal(false);
            setOpenNoteSection(false);
          }}
          negative
        >
          {resources.common.no}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
