import XLSX from "xlsx";
import moment from "moment";
import { resources } from "../../assets/LocalizationResources";

export const excelExport = (data, headers, title) => {
  const res = [Object.entries(headers).map((v) => v[1])].concat(
    data.map((d) => Object.keys(headers).map((key) => d[key]))
  );

  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet([...res]);
  XLSX.utils.book_append_sheet(workbook, ws, "Results");
  XLSX.writeFile(
    workbook,
    `${title} ، ${resources.common.date} ${moment(moment().toDate()).format(
      "YYYY-MM-DD"
    )}.xlsx`,
    { type: "file" }
  );
};
