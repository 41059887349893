import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  GridRow,
  Icon,
  Divider,
  GridColumn,
  Dropdown,
} from "semantic-ui-react";
import { resources } from "../../../assets/LocalizationResources";
import PageHeaderTitle from "../../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../../api";
import SuccessModal from "../../../components/SuccessModal";
import { useHistory } from "react-router-dom";
import "../ticket.scss";
import MapModal from "../../../components/Map/MapModal";
import wkt from "terraformer-wkt-parser";
import useDebounce from "../../../components/helpers/useDebounce";
import { useSelector } from "react-redux";

export default function ManageExternalTicket({ location }) {
  const user = useSelector((state) => state.user.user);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [clientID, setClientID] = useState(null);

  const [isSLA, setIsSLA] = useState(false);
  const [dueHours, setDueHours] = useState(0);

  const [branchID, setBranchID] = useState(null);
  const [contractMachineID, setContractMachineID] = useState(null);

  const [branches, setBranches] = useState([]);
  const [machines, setMachines] = useState([]);
  const [clients, setClients] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [levels, setLevels] = useState([]);
  const [callTypes, setCallTypes] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [branchDetails, setBranchDetails] = useState([]);
  const [machineDetails, setMachineDetails] = useState([]);

  const [initialValues, setInitialValues] = useState(null);
  const [areaID, setAreaID] = useState(null);
  const router = useHistory();
  const [isViewArea, setIsViewArea] = useState(false);
  const [areaGeometry, setAreaGeometry] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [
    infosComesFromSelectedMachineNumber,
    setInfosComesFromSelectedMachineNumber,
  ] = useState(false);

  const [clientName, setClientName] = useState("");
  const [clientNameIsLoading, setClientNameIsLoading] = useState(false);
  const debouncedClientNameSearch = useDebounce(clientName, 1000);

  const [branchesNamesAreLoading, setBranchesNamesAreLoading] = useState(false);

  const [machineNumber, setMachineNumber] = useState("");
  const [machinesNumbersAreLoading, setMachinesNumbersAreLoading] =
    useState(false);
  const debouncedMachineNumberSearch = useDebounce(machineNumber, 1000);

  const [clientDetailsIsLoading, setClientDetailsIsLoading] = useState(false);
  const [branchDetailsIsLoading, setBranchDetailsIsLoading] = useState(false);
  const [machineDetailsIsLoading, setMachineDetailsIsLoading] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState(1);

  const [technicianNumber, setTechnicianNumber] = useState("");

  const ticketDueHours = [
    {
      key: 24,
      text: resources.pages.ticket.insideAmman,
      value: 24,
    },
    {
      key: 48,
      text: resources.pages.ticket.outsideAmman,
      value: 48,
    },
  ];

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const newValues = {
        ...values,
        branchID: branchID,
        clientID: clientID,
        contractMachineID: contractMachineID,
        technicianID: 91, // مستخدم خارجي
        calltypeID: 22273, // من خلال العميل
        ticketDueHours: 48, // outside amman
        levelID: 93, // متوسطة
      };
      if (initialValues && initialValues.ticketID) {
        await serviceApi
          .service("tickets")
          .patch(+initialValues.ticketID, newValues);
      } else
        await serviceApi
          .service("tickets")
          .create({ ...newValues, statusID: 97 });

      //check if this technician has a phone number ... if yes then send SMS to this technician ... if no ignore and co

      //TODO: get username, password and userID from tesco
      if (technicianNumber.length > 0) {
      }
      setIsSubmitting(false);

      setSuccessVisible(true);
    } catch (error) {
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const fillEditData = async (ticketId) => {
    try {
      const res = await serviceApi.service("queries").get(ticketId, {
        query: {
          model: "Tickets",
          include: [{ model: "ClientBranch", attributes: ["clientID"] }],
          where: { ticketID: ticketId },
        },
      });

      if (res) {
        const clientId = res.ClientBranch.clientID;
        const branchId = res.branchID;

        if (res.contractMachineID) {
          fillMachines(clientId, branchId);
          setContractMachineID(res.contractMachineID);
        } else {
          setClientID(clientId);
          fillClients(clientId);
          setBranchID(branchId);
        }

        setInitialValues({ ...res, clientID: clientId });
      }
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };
  useEffect(() => {
    if (Boolean(location && location.state && location.state.ticketID))
      fillEditData(+location.state.ticketID);
  }, [location]);

  const fillClients = async (clientID = null) => {
    try {
      setClientNameIsLoading(true);
      const res = await serviceApi.service("client").find({
        query: {
          $select: ["ClientID", "ClientName"],
          ClientName: { $like: `%${clientName}%` },
          ...(clientID ? { ClientID: clientID } : {}),
          $limit: 25,
        },
      });

      if (res && res.data && res.data.length > 0)
        setClients(
          [
            { key: null, text: resources.common.notSpecified, value: null },
          ].concat(
            res.data.map((c) => {
              return { key: c.ClientID, text: c.ClientName, value: c.ClientID };
            })
          )
        );
      else setClients([]);

      setClientNameIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setClientNameIsLoading(false);
      console.log(error);
    }
  };

  const fillBranches = async (clientID = null) => {
    try {
      setBranchesNamesAreLoading(true);
      const userBranches =
        user.userBranches === "all"
          ? "all"
          : user.userBranches.split(",").map((item) => parseInt(item));
      const res = await serviceApi.service("ClientBranch").find({
        query: {
          $select: ["BranchName", "BranchID"],
          clientID: clientID,
          ...(userBranches === "all"
            ? {}
            : {
                BranchID: {
                  $in: userBranches,
                },
              }),
          $limit: 200,
        },
      });
      if (res && res.data && res.data.length > 0) {
        setBranches(
          res.data.map((c) => {
            return {
              key: c.BranchID,
              text: c.BranchName,
              value: c.BranchID,
            };
          })
        );
      } else setBranches([]);

      setBranchesNamesAreLoading(false);
    } catch (error) {
      // TODO: Show error message
      setBranchesNamesAreLoading(false);
      console.log(error);
    }
  };

  const fillMachines = async (cID = null, bID = null) => {
    try {
      setMachinesNumbersAreLoading(true);
      const userMachines =
        user.userMachineModels === "all"
          ? "all"
          : user.userMachineModels.split(",").map((item) => parseInt(item));
      const userBranches =
        user.userBranches === "all"
          ? "all"
          : user.userBranches.split(",").map((item) => parseInt(item));
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          attributes: ["ContractMachineID"],
          include: [
            {
              model: "Machine",
              attributes: [
                "machineId",
                "computerNumber",
                "serialNumber",
                "MachineTypeID",
              ],
              where: {
                ...(userMachines === "all"
                  ? {}
                  : {
                      MachineModelID: {
                        in: userMachines,
                      },
                    }),
                ...(selectedSearchOption === 1
                  ? { serialNumber: { like: `%${machineNumber}%` } }
                  : {}),
              },
            },
            {
              model: "Contract",
              attributes: ["ClientID"],
              ...(cID ? { where: { clientID: cID } } : {}),
            },
          ],
          where: {
            ...(userBranches === "all"
              ? {}
              : {
                  BranchID: {
                    in: userBranches,
                  },
                }),
            contractIsActive: 1,
            ...(selectedSearchOption === 2
              ? { machineId: { like: `%${machineNumber}%` } }
              : {}),

            ...(bID ? { branchID: bID } : {}),
          },
          limit: 10,
          order: [["machineId", "ASC"]],
        },
      });

      if (res && res.data && res.data.length) {
        setMachines(
          res.data.map((c) => {
            return {
              key: c.ContractMachineID,
              text:
                selectedSearchOption === 1
                  ? c.Machine.serialNumber
                  : c.Machine.computerNumber,
              value: c.ContractMachineID,
            };
          })
        );
      } else setMachines([]);
      setMachineDetails([]);

      setMachinesNumbersAreLoading(false);
    } catch (e) {
      setMachinesNumbersAreLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(machineNumber && clientID))
      fillMachines(clientID, branchID ? branchID : null);
    else if (Boolean(machineNumber)) fillMachines();
  }, [debouncedMachineNumberSearch]);

  useEffect(() => {
    setMachines([]);
    setMachineDetails([]);
  }, [machineNumber]);

  const fillCallTypes = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 25,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setCallTypes(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setCallTypes([]);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillReasons = async () => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 22300, // if developemnt 22275, if production 22300
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        setReasons(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setReasons([]);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const fillClient = async () => {
    try {
      setClientID(user.clientId);
      const res = await serviceApi.service("Client").find({
        query: {
          $select: ["ClientID", "ClientName"],
          clientID: user.clientId,
          $limit: 1,
        },
      });
      setClientName(res.data[0].ClientName);
    } catch (error) {}
  };

  useEffect(() => {
    setIsLoading(true);
    fillClient();
    fillCallTypes();
    fillReasons();
  }, []);

  const getMachineDetails = async () => {
    try {
      setMachineDetailsIsLoading(true);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          attributes: ["withSLA", "dueHours", "BranchID", "TechnicianID"],
          include: [
            {
              model: "Machine",
              include: [
                {
                  model: "Lookup",
                  as: "MachineType",
                  attributes: ["LookupName"],
                },
                {
                  model: "Lookup",
                  as: "MachineModel",
                  attributes: ["LookupName"],
                },
              ],
            },
            {
              model: "Contract",
              include: [
                {
                  model: "Lookup",
                  as: "ContractType",
                  attributes: ["LookupName"],
                },
              ],
              attributes: ["contractNumber"],
            },
          ],
          where: { contractMachineID: contractMachineID },
        },
      });

      if (Boolean(res && res.data && res.data.length > 0)) {
        const machine = res.data[0].Machine;
        const contract = res.data[0].Contract;

        if (res.data[0].withSLA) {
          setIsSLA(true);
          setDueHours(res.data[0].dueHours);
        } else setIsSLA(false);

        setMachineDetails([
          {
            label: resources.pages.machines.serialNumber,
            value: machine.serialNumber,
          },
          {
            label: resources.pages.machines.MachineModelID,
            value: machine.MachineModel
              ? machine.MachineModel.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.machines.MachineTypeID,
            value: machine.MachineType
              ? machine.MachineType.LookupName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.addContract.contractNumber,
            value: contract.contractNumber
              ? contract.contractNumber
              : resources.common.notSpecified,
          },
        ]);

        if (!branchID) {
          setInfosComesFromSelectedMachineNumber(true);
          setBranchID(res.data[0].BranchID);
        }
      } else setMachineDetails([]);

      setMachineDetailsIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setMachineDetailsIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(clientID)) {
      fillBranches(clientID);

      if (infosComesFromSelectedMachineNumber) {
        fillClients(clientID);
        setInfosComesFromSelectedMachineNumber(false);
      } else {
        setBranchID(null);
        setContractMachineID(null);
        setMachines([]);
      }
    } else {
      setClientDetails([]);
      setBranchID(null);
      setContractMachineID(null);
      setMachines([]);
    }
  }, [clientID]);

  useEffect(() => {
    if (branchID) {
      if (!infosComesFromSelectedMachineNumber) {
        setContractMachineID(null);
        setMachines([]);
      }
    } else {
      setBranchDetails([]);
      setContractMachineID(null);
    }
  }, [branchID]);

  useEffect(() => {
    if (contractMachineID) getMachineDetails();
    else {
      setIsSLA(false);
      setMachineDetails([]);
    }
  }, [contractMachineID]);

  const handleConfirm = () => {
    router.replace("/ExternalTicketsList");
  };

  const getDetails = () => {
    return (
      <>
        <GridRow className="ticket-detail-row" columns={3}>
          <GridColumn></GridColumn>
          <GridColumn></GridColumn>
          <GridColumn>
            {machineDetailsIsLoading ? (
              <Loader active={machineDetailsIsLoading} />
            ) : (
              Boolean(machineDetails.length) && (
                <Message info className="row-message">
                  <Message.Header>
                    {resources.pages.ticket.machineDetails}
                    <Divider style={{ margin: "5px 0px" }} />
                  </Message.Header>
                  {machineDetails.map((row, idx) => (
                    <p
                      key={idx}
                      style={
                        row.label === resources.pages.addContract.contractType
                          ? { color: "red" }
                          : {}
                      }
                    >
                      {row.label} : {row.value}
                    </p>
                  ))}
                </Message>
              )
            )}
          </GridColumn>
        </GridRow>

        {isSLA && (
          <div className="SLA-warningMessage-container">
            <Message negative>
              {resources.pages.ticket.warningSLA +
                dueHours +
                resources.pages.addContract.hours}
            </Message>
          </div>
        )}
      </>
    );
  };

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!clientID) {
      errors.clientID = requiredField;
    }
    if (!branchID) {
      errors.branchID = requiredField;
    }

    if (!values.typeID) {
      errors.typeID = requiredField;
    }

    // if (!values.levelID) {
    //   errors.levelID = requiredField;
    // }
    // if (!values.ticketDueHours && !isSLA) errors.ticketDueHours = requiredField;

    return errors;
  };

  const searchOptions = [
    { key: 1, text: "الرقم التسلسلي", value: 1 },
    { key: 2, text: "رقم الكمبيوتر", value: 2 },
  ];

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <MapModal
        isOpen={isViewArea}
        jsonData={areaGeometry}
        onCancel={() => setIsViewArea(false)}
      />
      <Grid className="form-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeaderTitle
            content={
              initialValues && initialValues.ticketID
                ? resources.pages.ticket.editPageTitle
                : resources.pages.ticket.addPageTitle
            }
            icon="ticket"
          />
          <Segment>
            <FinalForm
              onSubmit={onSubmit}
              validate={checkValidation}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <Form
                  className="form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  loading={isSubmitting}
                >
                  <Grid stackable>
                    <GridRow columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.clientName}</p>
                        <Field name="clientID">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              placeholder={resources.pages.ticket.clientName}
                              value={clientName}
                              readOnly
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.branchName}</p>
                        <Field name="branchID">
                          {({ input, meta }) => (
                            <Form.Select
                              name={input.name}
                              value={branchID}
                              options={branches}
                              clearable
                              placeholder={resources.pages.ticket.branchName}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(branches.length > 0)}
                              loading={branchesNamesAreLoading}
                              onChange={(e, { value }) => {
                                setBranchID(value);
                                input.onChange(value);
                              }}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Dropdown
                          options={searchOptions}
                          onChange={(e, { value }) => {
                            setMachineNumber("");
                            setSelectedSearchOption(value);
                          }}
                          value={selectedSearchOption}
                          className="machines-search-options-ddl"
                        />
                        <Field name="contractMachineID">
                          {({ input, meta }) => (
                            <Form.Select
                              name="contractMachineID"
                              value={contractMachineID}
                              options={machines}
                              placeholder={
                                selectedSearchOption === 1
                                  ? resources.pages.machines.serialNumber
                                  : resources.pages.machines.computerNumber
                              }
                              onChange={(e, { value }) => {
                                setContractMachineID(value);
                                input.onChange(value);
                              }}
                              noResultsMessage={resources.common.noData}
                              search={(e) => e}
                              onSearchChange={(e) =>
                                setMachineNumber(e.target.value.trim())
                              }
                              loading={machinesNumbersAreLoading}
                              error={meta.touched && meta.error}
                              className={`${
                                Boolean(machines.length > 0)
                                  ? ""
                                  : "without-down-arrow"
                              }`}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    {getDetails()}
                    <GridRow columns={2}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.CustomerSerial}</p>
                        <Field name="customerSerialNumber">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="building"
                              placeholder={
                                resources.pages.ticket.CustomerSerial
                              }
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.addContractMachine.address}</p>
                        <Field name="address">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="map pin"
                              placeholder={
                                resources.pages.addContractMachine.address
                              }
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    <GridRow columns={3}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.contactName}</p>
                        <Field name="contactName">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="male"
                              placeholder={resources.pages.ticket.contactName}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.pages.ticket.contactPhone}</p>
                        <Field name="contactPhone">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="call"
                              placeholder={resources.pages.ticket.contactPhone}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <p>{resources.common.email}</p>
                        <Field name="contactEmail">
                          {({ input, meta }) => (
                            <Form.Input
                              {...input}
                              error={meta.touched && meta.error}
                              fluid
                              icon="at"
                              placeholder={resources.common.email}
                              autoComplete="off"
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                    <GridRow columns={1}>
                      {/* <Grid.Column>
                        <p>{`${resources.pages.addContract.dueHours} ( ${resources.common.inHours} )`}</p>
                        <Field name="ticketDueHours">
                          {({ input, meta }) => (
                            <Form.Select
                              name={input.name}
                              value={input.value ? input.value : null}
                              options={ticketDueHours}
                              placeholder={resources.pages.addContract.dueHours}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column> */}
                      <Grid.Column>
                        <p>{resources.pages.ticket.reason}</p>
                        <Field name="typeID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={reasons}
                              placeholder={resources.pages.ticket.reason}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(reasons.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      {/* <Grid.Column>
                        <p>{resources.pages.ticket.ticketLevel}</p>
                        <Field name="levelID">
                          {({ input, meta }) => (
                            <Form.Select
                              {...input}
                              options={levels}
                              placeholder={resources.pages.ticket.ticketLevel}
                              onChange={(e, { value }) => input.onChange(value)}
                              noResultsMessage={resources.common.noData}
                              search={Boolean(levels.length)}
                              error={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Grid.Column> */}
                    </GridRow>
                    <GridRow columns={1}>
                      <Grid.Column>
                        <p>{resources.pages.ticket.notes}</p>
                        <Field name="notes">
                          {({ input, meta }) => (
                            <Form.TextArea
                              {...input}
                              error={meta.touched && meta.error}
                              placeholder={
                                resources.pages.ticket.externalTicketNotes
                              }
                              icon="sticky note outline"
                              onChange={(e, { value }) => input.onChange(value)}
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </GridRow>
                  </Grid>
                  <Button
                    color="blue"
                    size="large"
                    className="save-btn"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {resources.common.save}
                  </Button>
                  <Button size="large" type="button" onClick={handleConfirm}>
                    {resources.common.back}
                  </Button>
                  {errorVisible.visible && (
                    <Message
                      negative
                      compact
                      className="error-message"
                      content={
                        errorMessage ||
                        resources.common.errors.genericServerError
                      }
                    />
                  )}
                </Form>
              )}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
