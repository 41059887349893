import React, { useState, useEffect, memo } from "react";
import {
  Segment,
  Form,
  Icon,
  Modal,
  Loader,
  Button,
  Input,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import { Field } from "react-final-form";
import serviceApi from "../../api";
import TakePicture from "../../components/Webcam/TakePicture";
import DTPicker from "../../components/DTPicker/DTPicker";
import { resources } from "../../assets/LocalizationResources";
import { host } from "../../api/index";
import moment from "moment";

const Counters = ({
  contractMachineId,
  setLastCounters,
  counterImgs,
  setCounterImgs,
  HasPDF,
  contractID,
  openMisMatchCountersModal,
  setOpenMisMatchCountersModal,
}) => {
  const [lastCounter1, setLastCounter1] = useState(0);
  const [lastCounter2, setLastCounter2] = useState(0);
  const [counter1Difference, setCounter1Difference] = useState(0);
  const [counter2Difference, setCounter2Difference] = useState(0);
  const [isOpenImageModal, setIsOpenImageModal] = useState(false);
  const [counter1Img, setCounter1Img] = useState(counterImgs.counter1Img);
  const [counter2Img, setCounter2Img] = useState(counterImgs.counter2Img);
  const [counterNumber, setCounterNumber] = useState(null);
  const [isLastCountersLoading, setIsLastCountersLoading] = useState(false);
  const [pasted, setPasted] = useState(false);
  const [isOpenConfirmTypeModal, setConfirmTypeModal] = useState(false);
  const [formInputs, setFrmInputs] = useState();
  const [counter1, setCounter1] = useState(0);
  const [confirmCounter1, setConfirmCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [confirmCounter2, setConfirmCounter2] = useState(0);

  const getLastCounter = async () => {
    try {
      setIsLastCountersLoading(true);
      const data = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReport",
          limit: 1,
          attributes: ["counter1", "counter2"],
          order: [["StartDate", "DESC"]],
          where: { ContractMachineID: contractMachineId },
        },
      });
      let counter1Value = 0;
      let counter2Value = 0;
      if (Boolean(data && data.data.length > 0)) {
        counter1Value = data.data[0].counter1 ? data.data[0].counter1 : 0;
        counter2Value = data.data[0].counter2 ? data.data[0].counter2 : 0;
        setLastCounters({
          lastCounter1: counter1Value,
          lastCounter2: counter2Value,
        });
        setLastCounter1(counter1Value);
        setLastCounter2(counter2Value);
      } else {
        const res2 = await serviceApi.service("queries").find({
          query: {
            model: "ContractMachine",
            limit: 1,
            attributes: ["LastCounter1", "LastCounter2"],
            order: [["createdAt", "desc"]],
            where: { ContractMachineID: contractMachineId },
          },
        });

        if (res2 && res2.data && res2.data.length > 0) {
          counter1Value = res2.data[0].LastCounter1
            ? res2.data[0].LastCounter1
            : 0;
          counter2Value = res2.data[0].LastCounter2
            ? res2.data[0].LastCounter2
            : 0;
        }
        setLastCounters({
          lastCounter1: counter1Value,
          lastCounter2: counter2Value,
        });
        setLastCounter1(counter1Value);
        setLastCounter2(counter2Value);
      }

      setIsLastCountersLoading(false);
    } catch (e) {
      setIsLastCountersLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    Boolean(contractMachineId) && getLastCounter();
  }, [contractMachineId]);

  const setImagePath = (path) => {
    if (counterNumber == 1) {
      const counter1ImagePath = Boolean(path) ? path : counter1Img;
      setCounter1Img(counter1ImagePath);
      setCounterImgs({
        ...counterImgs,
        counter1Img: counter1ImagePath,
      });
    } else {
      const counter2ImagePath = Boolean(path) ? path : counter2Img;
      setCounter2Img(counter2ImagePath);
      setCounterImgs({
        ...counterImgs,
        counter2Img: counter2ImagePath,
      });
    }
    setIsOpenImageModal(false);
  };

  const visitTypeOptions = [
    {
      key: 2,
      text: resources.pages.maintenanceReport.request,
      value: 2,
    },
    {
      key: 3,
      text: resources.pages.maintenanceReport.cyclic,
      value: 3,
    },
    {
      key: 4,
      text: resources.pages.maintenanceReport.delivery,
      value: 4,
    },
    {
      key: 157,
      text: resources.pages.maintenanceReport.operator,
      value: 157,
    },
    {
      key: 158,
      text: resources.pages.maintenanceReport.requestAndPeriodic,
      value: 158,
    },
    {
      key: 159,
      text: resources.pages.maintenanceReport.scattered,
      value: 159,
    },
    {
      key: 5,
      text: resources.pages.maintenanceReport.other,
      value: 5,
    },
  ];
  const openContractPDF = () => {
    window.open(`${host}/uploads/${contractID}.pdf`);
  };

  const handlePaste = () => {
    setPasted(true);
  };

  const handleCounterConfirm = (e, { value }) => {
    if (!pasted) {
      setConfirmCounter1(value);
    }
    setPasted(false);
  };

  const handleCounter2Confirm = (e, { value }) => {
    if (!pasted) {
      setConfirmCounter2(value);
    }
    setPasted(false);
  };

  return (
    <>
      <Modal open={isOpenConfirmTypeModal} size="small">
        <Modal.Header>
          {resources.pages.maintenanceReport.maintenanceType +
            ": " +
            resources.pages.maintenanceReport.rent}
        </Modal.Header>
        <Modal.Content style={{ color: "red" }}>
          {resources.pages.maintenanceReport.confirmMaintenanceType}
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => setConfirmTypeModal(!isOpenConfirmTypeModal)}
          >
            {resources.common.close}
          </Button>
          <Button
            primary
            onClick={() => {
              formInputs.onChange(9);
              setConfirmTypeModal(!isOpenConfirmTypeModal);
            }}
          >
            {resources.common.save}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={isOpenImageModal}
        size="small"
        content={<TakePicture setImagePath={setImagePath} />}
      />
      <Modal
        open={openMisMatchCountersModal}
        onClose={() => setOpenMisMatchCountersModal(!openMisMatchCountersModal)}
        size="tiny"
      >
        <Modal.Header>
          {resources.pages.maintenanceReport.wrongCounters}
        </Modal.Header>
        <Modal.Content style={{ color: "red" }}>
          {resources.pages.maintenanceReport.countersDontMatch}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() =>
              setOpenMisMatchCountersModal(!openMisMatchCountersModal)
            }
          >
            {resources.common.close}
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment className="counters-maintenance-type-container">
        <div className="header-container">
          <div className="icon-label-container">
            <Icon name="camera" />
            <label>{resources.pages.maintenanceReport.counters}</label>
          </div>
        </div>

        {HasPDF ? (
          <div
            style={{ position: "absolute", left: "1rem", width: "fit-content" }}
          >
            <Button
              type="button"
              icon="file alternate outline"
              content="عرض صورة العقد"
              labelPosition="left"
              primary
              onClick={openContractPDF}
            />
          </div>
        ) : (
          <></>
        )}
        <Segment.Group horizontal className="visit-maintenance-type-container">
          <Segment.Group horizontal className="visit-type-container">
            <Segment className="left-border visit-type-label">
              {resources.pages.maintenanceReport.visitType}
            </Segment>

            <Field name="VisitTypeID">
              {({ input, meta }) => (
                <div className="visit-type-select-list-container">
                  <Form.Select
                    {...input}
                    options={visitTypeOptions}
                    placeholder={resources.pages.maintenanceReport.visitType}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                </div>
              )}
            </Field>
          </Segment.Group>
          <Segment.Group horizontal className="maintenance-type-container">
            <Segment className="left-border maintenance-type-label">
              {resources.pages.maintenanceReport.maintenanceType}
            </Segment>

            <Field name="MaintenanceTypeID">
              {({ input, meta }) => (
                <div className="maintenance-type-radio-buttons-container">
                  <Form.Checkbox
                    radio
                    onChange={(e, v) => input.onChange(v.value)}
                    checked={input.value === 7}
                    label={resources.pages.maintenanceReport.contract}
                    name="maintenanceTypeRadioGroup"
                    value={7}
                  />
                  <Form.Checkbox
                    radio
                    onChange={(e, v) => input.onChange(v.value)}
                    checked={input.value === 8}
                    label={resources.pages.maintenanceReport.free}
                    name="maintenanceTypeRadioGroup"
                    value={8}
                  />
                  <Form.Checkbox
                    radio
                    onChange={(e, v) => {
                      setFrmInputs(input);
                      setConfirmTypeModal(true);
                    }}
                    checked={input.value === 9}
                    label={resources.pages.maintenanceReport.rent}
                    name="maintenanceTypeRadioGroup"
                    value={9}
                  />
                </div>
              )}
            </Field>
          </Segment.Group>
        </Segment.Group>

        <div className="counters-container">
          <Segment.Group horizontal className="counters-row">
            <Segment className="left-border segment-fifty">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.lastCounter1}
              </label>
              {isLastCountersLoading ? (
                <Loader active={isLastCountersLoading} size="tiny" />
              ) : (
                <label>{lastCounter1 ? lastCounter1 : " 0 "}</label>
              )}
            </Segment>
            <Segment className="left-border segment-fifty">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.lastCounter2}
              </label>
              {isLastCountersLoading ? (
                <Loader active={isLastCountersLoading} size="tiny" />
              ) : (
                <label>{lastCounter2 ? lastCounter2 : " 0 "}</label>
              )}
            </Segment>
          </Segment.Group>
          <Segment.Group horizontal className="counters-row">
            <Segment className="counter-input-container left-border">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter1}
              </label>
              <Field name="counter1">
                {({ input, meta }) => (
                  <Form.Input
                    {...input}
                    className="counter-input"
                    placeholder={
                      resources.pages.maintenanceReport.enterCounterValue
                    }
                    value={input.value ? input.value : 0}
                    onChange={(e, { value }) => {
                      input.onChange(value);
                      setCounter1(value);
                      setCounter1Difference(+value - lastCounter1);
                    }}
                    type="number"
                    autoComplete="off"
                  />
                )}
              </Field>

              <div className="counter-img-container">
                {counter1Img ? (
                  <img
                    src={counter1Img}
                    className="counter-img"
                    onClick={() => {
                      setCounterNumber(1);
                      setIsOpenImageModal(true);
                    }}
                  />
                ) : (
                  <Icon
                    name="camera"
                    circular
                    color="blue"
                    link
                    onClick={() => {
                      setCounterNumber(1);
                      setIsOpenImageModal(true);
                    }}
                  />
                )}
              </div>
            </Segment>

            <Segment className="counter-input-container">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter2}
              </label>
              <Field name="counter2">
                {({ input, meta }) => (
                  <Form.Input
                    {...input}
                    value={input.value ? input.value : 0}
                    className="counter-input"
                    placeholder={
                      resources.pages.maintenanceReport.enterCounterValue
                    }
                    type="number"
                    onChange={(e, { value }) => {
                      input.onChange(value);
                      setCounter2(value);
                      setCounter2Difference(+value - lastCounter2);
                    }}
                    autoComplete="off"
                  />
                )}
              </Field>

              <div className="counter-img-container">
                {counter2Img ? (
                  <img
                    src={counter2Img}
                    className="counter-img"
                    onClick={() => {
                      setCounterNumber(2);
                      setIsOpenImageModal(true);
                    }}
                  />
                ) : (
                  <Icon
                    name="camera"
                    circular
                    color="blue"
                    link
                    onClick={() => {
                      setCounterNumber(2);
                      setIsOpenImageModal(true);
                    }}
                  />
                )}
              </div>
            </Segment>
          </Segment.Group>

          <Segment.Group horizontal className="counters-row">
            <Segment className="counter-input-container left-border">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter1Confirm}
              </label>
              <Field name="counter1Confirm">
                {({ input, meta }) => (
                  <Form.Input
                    value={input.value ? input.value : 0}
                    onPaste={handlePaste}
                    onChange={(e, { value }) => {
                      if (!pasted) {
                        setConfirmCounter1(value);
                        input.onChange(value);
                      }
                      setPasted(false);
                    }}
                    style={{ height: "fit-content" }}
                  />
                )}
              </Field>
            </Segment>

            <Segment className="counter-input-container">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter2Confirm}
              </label>
              <Field name="counter2Confirm">
                {({ input, meta }) => (
                  <Form.Input
                    value={input.value ? input.value : 0}
                    onChange={(e, { value }) => {
                      if (!pasted) {
                        setConfirmCounter2(value);
                        input.onChange(value);
                      }
                      setPasted(false);
                    }}
                    onPaste={handlePaste}
                    style={{ height: "fit-content" }}
                  />
                )}
              </Field>
            </Segment>
          </Segment.Group>

          <Segment.Group horizontal>
            <Segment className="left-border segment-fifty counet-difference-segment">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter1Difference}
                {counter1Difference}
              </label>
            </Segment>
            <Segment className="segment-fifty counet-difference-segment">
              <label className="maintenance-label">
                {resources.pages.maintenanceReport.counter2Difference}
                {counter2Difference}
              </label>
            </Segment>
          </Segment.Group>
        </div>

        <Segment.Group horizontal>
          <Segment className="left-border segment-fifty maintenance-div">
            <label className="maintenance-label">
              {resources.pages.maintenanceReport.startTime} :
            </label>
            <div className="maintenance-datePicker">
              <Field name="startDate">
                {({ input, meta }) => (
                  <DTPicker
                    selectedValue={input.value}
                    isTrigger
                    setSelectedValue={(v) => input.onChange(v)}
                    popperPlacement="top-end"
                  />
                )}
              </Field>
            </div>
          </Segment>
          <Segment className="segment-fifty maintenance-div">
            <label className="maintenance-label">
              {resources.pages.maintenanceReport.endTime} :
            </label>
            <div className="maintenance-datePicker">
              <Field name="endDate">
                {({ input, meta }) => (
                  <DTPicker
                    selectedValue={input.value}
                    isTrigger
                    popperPlacement="top-end"
                    isReadOnly
                    setSelectedValue={(v) => input.onChange(v)}
                  />
                )}
              </Field>
            </div>
          </Segment>
        </Segment.Group>
      </Segment>
    </>
  );
};

function countersPropsAreEqual(prevState, nextState) {
  return true;
}

export default memo(Counters, countersPropsAreEqual);
