import React, { useState, useEffect } from "react";
import { Select } from "semantic-ui-react";
import serviceApi from "../../api";
import { useDispatch, useSelector, useLocation } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import useDebounce from "../../components/helpers/useDebounce";
import { reportsActionCreator } from "../../redux/actions";

const ReportMachineModelPicker = () => {
  const [machinesModel, setMachinesModel] = useState([]);
  const [machinesNameIsLoading, setMachinesModelIsLoading] = useState(false);
  const [machinesName, setMachinesName] = useState("");
  const [machinesID, SetMachinesId] = useState("");
  const debouncedClientModeSearch = useDebounce(machinesName, 1000);
  const dispatch = useDispatch();

  const fillMachineModels = async (machineModelID = null) => {
    try {
      setMachinesModelIsLoading(true);
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: 22,
          $select: ["LookupID", "LookupName"],
          LookupName: { $like: `%${machinesName}%` },
          ...(machineModelID ? { LookupID: machineModelID } : {}),
          $limit: 25,
        },
      });
      if (res && res.data && res.data.length > 0)
        setMachinesModel(
          res.data.map((c) => {
            return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
          })
        );
      else setMachinesModel([]);
      setMachinesModelIsLoading(false);
    } catch (err) {
      setMachinesModelIsLoading(false);
      console.log(err);
    }
  };
  const onModelSelected = (e, { value }) => {
    SetMachinesId(value);
    dispatch(
      reportsActionCreator.setReportOptions({
        model: value,
      })
    );
  };

  useEffect(() => {
    if (Boolean(machinesName && machinesName.length > 2)) {
      fillMachineModels();
    } else {
      setMachinesName("");
      SetMachinesId("");
    }
  }, [debouncedClientModeSearch]);

  return (
    <div>
      <p>{resources.pages.maintenanceReport.machineModel} :</p>
      <Select
        options={machinesModel}
        placeholder={resources.pages.maintenanceReport.machineModel}
        noResultsMessage={resources.common.noData}
        search
        value={machinesID}
        onSearchChange={(e) => setMachinesName(e.target.value.trim())}
        loading={machinesNameIsLoading}
        onChange={onModelSelected}
        className={`${
          Boolean(machinesModel.length) ? "" : "without-down-arrow"
        }`}
      />
    </div>
  );
};

export default ReportMachineModelPicker;
