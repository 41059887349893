import { cacheSearchActions } from "../actions";

const initialState = {
  term: "",
  searchBy: "",
  pageName: "",
  extraOptions: {},
};

const cacheSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case cacheSearchActions.SET_CACHE_SEARCH:
      return {
        ...state,
        term: action.payload.term,
        searchBy: action.payload.searchBy,
        pageName: action.payload.pageName,
      };
    case cacheSearchActions.CLEAR_CACHE_SEARCH:
      return {
        ...initialState,
      };
    case cacheSearchActions.SET_EXTRA_OPTIONS:
      return {
        ...state,
        extraOptions: { ...action.extraOptions },
      };
    default:
      return state;
  }
};

export default cacheSearchReducer;
