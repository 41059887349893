import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { reportsActionCreator } from "../../redux/actions";

const ComputerNumberInput = () => {
  const dispatch = useDispatch();
  const [computerNumber, setComputerNumber] = useState("");

  useEffect(() => {
    dispatch(reportsActionCreator.setReportOptions({ computerNumber }));
  }, [computerNumber]);

  return (
    <>
      <div>
        <Input
          placeholder="رقم الكمبيوتر"
          onChange={(e) => {
            setComputerNumber(e.target.value.trim());
          }}
        />
      </div>
    </>
  );
};

export default ComputerNumberInput;
