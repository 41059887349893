import { reportsActions } from "../actions";

const initialState = {
  reportid: "",
  reportName: "",
  interval: {},
  options: {},
  clear: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportsActions.SET_REPORT_NAME_ID:
      return {
        ...state,
        options: initialState.options,
        reportName: action.payload.reportName,
        reportid: action.payload.reportid,
        clear: false,
      };
    case reportsActions.SET_REPORT_INTERVAL:
      return { ...state, interval: action.payload.interval, clear: false };
    case reportsActions.SET_REPORT_OPTIONS:
      return {
        ...state,
        options: { ...state.options, ...action.payload.options },
        clear: false,
      };
    case reportsActions.CLEAR_REPORT_DATA:
      return { ...initialState, clear: action.payload.clear };

    default:
      return state;
  }
};

export default reportsReducer;
