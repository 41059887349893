import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Form,
  Icon,
  Image,
  Modal,
  Button,
  Message,
} from "semantic-ui-react";
import { Field } from "react-final-form";
import { resources } from "../../assets/LocalizationResources";
import TakePicture from "../../components/Webcam/TakePicture";
import SignatureIndicatorImg from "../../assets/images/signature-indecator.png";
import SignatureCanvas from "react-signature-canvas";

const ClientInformations = ({
  clientSig,
  setClientSig,
  clientCardPhoto,
  setClientCardPhoto,
}) => {
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [clientSignature, setClientSignature] = useState(clientSig);
  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);
  const [clientCardImg, setClientCardImg] = useState(clientCardPhoto);
  const [isOpenImageModal, setIsOpenImageModal] = useState(false);
  const [openIsEmptyMessage, setOpenIsEmptyMessage] = useState(false);
  const clientSigRef = useRef(null);

  const addSignature = () => {
    if (clientSigRef.current.getTrimmedCanvas().width > 1) {
      const clientSignatureDataPath = clientSigRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setClientSignature(clientSignatureDataPath);
      setClientSig(clientSignatureDataPath);
      setOpenSignatureModal(false);
      setOpenIsEmptyMessage(false);
    } else setOpenIsEmptyMessage(true);
  };

  useEffect(() => {
    const parent = document.getElementsByClassName(
      "signature-canvas-container"
    );
    setCanvasHeight(parent[0] ? parent[0].clientHeight : null);
    setCanvasWidth(parent[0] ? parent[0].clientWidth : null);
  }, [openSignatureModal]);

  const setImagePath = (path) => {
    const clientCardImgPath = Boolean(path) ? path : clientCardImg;
    setClientCardImg(clientCardImgPath);
    setClientCardPhoto(clientCardImgPath);
    setIsOpenImageModal(false);
  };

  return (
    <>
      <Modal
        open={isOpenImageModal}
        size="small"
        content={<TakePicture setImagePath={setImagePath} />}
      />
      <Segment className="client-information-container">
        <div className="header-container">
          <div className="icon-label-container">
            <Icon name="handshake" />
            <label>{resources.pages.maintenanceReport.clientInfo}</label>
          </div>
          <div
            className="client-card-img-container"
            onClick={() => setIsOpenImageModal(true)}
          >
            <label>
              {clientCardImg
                ? resources.pages.maintenanceReport.editClientCardPhoto
                : resources.pages.maintenanceReport.takeClientCardPhoto}
            </label>
            <div>
              {clientCardImg ? (
                <img src={clientCardImg} className="client-card-img" />
              ) : (
                <Icon name="camera" circular color="blue" link />
              )}
            </div>
          </div>
        </div>

        <Segment.Group
          horizontal
          className="client-informations-section-container"
        >
          <Segment className="client-informations-segment left-border">
            <label>{`${resources.pages.maintenanceReport.employeeName} : `}</label>
            <Field name="clientEmployeeName">
              {({ input, meta }) => (
                <Form.Input
                  {...input}
                  transparent
                  placeholder={resources.pages.maintenanceReport.employeeName}
                  className="client-informations-fields-input"
                  autoComplete="off"
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
          </Segment>

          <Segment className="client-informations-segment left-border">
            <label>{`${resources.pages.maintenanceReport.job} : `}</label>
            <Field name="clientEmployeeJob">
              {({ input, meta }) => (
                <Form.Input
                  {...input}
                  transparent
                  placeholder={resources.pages.maintenanceReport.job}
                  className="client-informations-fields-input"
                  autoComplete="off"
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
          </Segment>

          <Segment>
            <div className="maintenance-status-segment-container">
              <label>{`${resources.pages.maintenanceReport.reportDelivery} : `}</label>
              <Field name="DeliverStatusID">
                {({ input, meta }) => (
                  <div className="maintenance-status-radio-container">
                    <Form.Checkbox
                      radio
                      onChange={(e, v) => input.onChange(v.value)}
                      checked={input.value === 8044}
                      label={resources.pages.maintenanceReport.sendByEmail}
                      name="maintenanceStatusRadioGroup"
                      value={8044}
                    />
                    <Form.Checkbox
                      radio
                      onChange={(e, v) => input.onChange(v.value)}
                      checked={input.value === 8045}
                      label={resources.pages.maintenanceReport.giveByHand}
                      name="maintenanceStatusRadioGroup"
                      value={8045}
                    />
                  </div>
                )}
              </Field>
            </div>
          </Segment>
        </Segment.Group>

        <label>{`${resources.pages.maintenanceReport.clientSignature} : `}</label>
        <div
          className="signature-preview-container"
          onClick={() => setOpenSignatureModal(true)}
        >
          <Image
            src={clientSignature ? clientSignature : SignatureIndicatorImg}
            size="small"
            centered
          />
        </div>

        <Modal
          size="fullscreen"
          open={openSignatureModal}
          className="signature-modal"
        >
          <Modal.Header>
            {resources.pages.maintenanceReport.clientSignature}
          </Modal.Header>
          <Modal.Content className="signature-canvas-container">
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: canvasWidth, height: canvasHeight }}
              ref={clientSigRef}
            />
          </Modal.Content>
          <Modal.Actions className="signature-modal-actions">
            <Button
              type="button"
              content={resources.common.save}
              color="blue"
              onClick={() => addSignature()}
            />
            <Button
              type="button"
              content={resources.common.cancel}
              onClick={() => {
                setOpenSignatureModal(false);
                setOpenIsEmptyMessage(false);
              }}
            />
            {openIsEmptyMessage && (
              <Message
                negative
                compact
                content={resources.pages.maintenanceReport.emptySigError}
              />
            )}
          </Modal.Actions>
        </Modal>
      </Segment>
    </>
  );
};

export default ClientInformations;
