import React, { useState, useEffect } from "react";
import "./AddContract.scss";
import {
  Grid,
  Segment,
  Form,
  Button,
  Message,
  Icon,
  Dimmer,
  Loader,
  Tab,
  Popup,
} from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import TableTemplate from "../../components/Table/TableTemplate";
import DeleteModal from "../../components/DeleteModal";
import getDeleteError from "../../components/helpers/getDeleteError";
import SearchBy from "../../components/SearchBy/SearchBy";
import useDebounce from "../../components/helpers/useDebounce";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import DTPicker from "../../components/DTPicker/DTPicker";

export default function AddContractMachine({ location }) {
  const router = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [contractMachines, setContractMachines] = useState([]);
  const [contractMachineID, setContractMachineID] = useState(-1);
  const [contractID, setContractID] = useState(null);
  const [contractEndDate, setcontractEndDate] = useState(null);
  const [machines, setMachines] = useState([]);
  const [branches, setBranches] = useState([]);
  const [clientID, setClientID] = useState();
  const [contractDetails, setContractDetails] = useState([]);
  const [isLoadingContractMachine, setIsLoadingContractMachine] =
    useState(false);
  const [contractType, setContractType] = useState(-1);
  const [isContractEnd, setIsContractEnd] = useState(false);
  const [branchAreaTechnicians, setBranchAreaTechnicians] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(1);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isSLA, setIsSLA] = useState(false);
  const [techniciansListIsLoading, setTechniciansListIsLoading] =
    useState(false);
  const [machineNumber, setMachineNumber] = useState("");
  const [machinesNumbersAreLoading, setMachinesNumbersAreLoading] =
    useState(false);
  const debouncedMachineNumberSearch = useDebounce(machineNumber, 1000);

  const headers = {
    computerNumber: resources.pages.machines.computerNumber,
    branchName: resources.pages.branch.branchName,
    machineType: resources.pages.machines.MachineType,
    machineModel: resources.pages.machines.MachineModel,
    serialNumber: resources.pages.machines.serialNumber,
    createdDate: resources.common.createdDate,
  };

  const [machinesErrorMessage, setMachineErrorMessage] = useState({
    openMachinesErrorMessage: false,
    errorMessage: "",
  });

  const onPaging = (page) => setActivePage(page);

  const onCellClick = (row, type) => {
    setContractMachineID(row.contractMachineId);
    if (type === "edit") {
      getEdditedBranchAreaID(row.BranchID);
      setInitialValues(row);
      getMachines(row.MachineID);
      setActiveIndex(0);
    } else if (type === "remove") {
      setIsDelete(true);
    }
  };

  const getOptions = async (parentID) => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: parentID,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0) {
        return res.data.map((item) => {
          return {
            key: item.LookupID,
            text: item.LookupName,
            value: item.LookupID,
          };
        });
      } else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fillSearchData = async () => {
    setSearchData({
      computerNumber: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
      serialNumber: {
        type: "text",
        label: resources.pages.machines.serialNumber,
      },
      MachineType: {
        type: "ddl",
        label: resources.pages.machines.MachineType,
        options: await getOptions(19),
      },
      MachineModel: {
        type: "ddl",
        label: resources.pages.machines.MachineModel,
        options: await getOptions(22),
      },
    });
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  const getMachines = async (MachineID, isForAddition = false) => {
    try {
      setMachinesNumbersAreLoading(true);
      if (Boolean(MachineID && !isForAddition)) {
        const res = await serviceApi.service("machine").find({
          query: {
            $select: ["machineId", "serialNumber", "computerNumber"],
            MachineID: MachineID,
            $limit: 1,
          },
        });

        if (res && res.data && res.data.length > 0)
          setMachines(
            res.data.map((c) => {
              return {
                key: c.machineId,
                text: c.computerNumber,
                value: c.machineId,
              };
            })
          );
        else setMachines([]);
      } else {
        const machineContracts = await serviceApi.service("queries").find({
          query: {
            model: "ContractMachine",
            attributes: ["MachineID"],
            include: [
              {
                innerJoin: true,
                model: "Contract",
                attributes: ["endDate"],
              },
            ],
            $limit: 1000000,
          },
        });

        if (
          machineContracts &&
          machineContracts.data &&
          machineContracts.data.length > 0
        ) {
          const associatedMachinesIDs = machineContracts.data
            .filter(
              (c) =>
                new Date().getTime() < new Date(c.Contract.endDate).getTime()
            )
            .map((i) => i.MachineID);

          if (Boolean(associatedMachinesIDs.includes(+MachineID)))
            setMachineErrorMessage({
              openMachinesErrorMessage: true,
              errorMessage:
                resources.pages.addContractMachine
                  .associatedMachineErrorMessage,
            });
          else {
            const notAssociatedMachines = await serviceApi
              .service("machine")
              .find({
                query: {
                  $select: ["machineId", "serialNumber", "computerNumber"],
                  MachineID: MachineID,
                  $limit: 25,
                },
              });
            if (
              notAssociatedMachines &&
              notAssociatedMachines.data &&
              notAssociatedMachines.data.length > 0
            ) {
              setMachines(
                notAssociatedMachines.data.map((c) => {
                  return {
                    key: c.machineId,
                    text: c.computerNumber,
                    value: c.machineId,
                  };
                })
              );
              setMachineErrorMessage({
                openMachinesErrorMessage: false,
                errorMessage: "",
              });
            } else {
              setMachineErrorMessage({
                openMachinesErrorMessage: true,
                errorMessage:
                  resources.pages.addContractMachine
                    .pleaseCheckTheEnteredMachineNumber,
              });
              setMachines([]);
            }
          }
        } else setMachines([]);
      }
      setMachinesNumbersAreLoading(false);
    } catch (error) {
      setMachinesNumbersAreLoading(false);
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    if (Boolean(machineNumber)) getMachines(machineNumber, true);
  }, [debouncedMachineNumberSearch]);

  const fillContractMachine = async (contractID) => {
    try {
      setIsLoadingContractMachine(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          where: { contractID: contractID },
          include: [
            {
              model: "Machine",
              attributes: ["machineId", "SerialNumber", "computerNumber"],
              include: [
                {
                  model: "Lookup",
                  as: "MachineType",
                  attributes: ["LookupName"],
                  ...whereSearch("MachineType", "LookupID"),
                },
                {
                  model: "Lookup",
                  as: "MachineModel",
                  attributes: ["LookupName"],
                  ...whereSearch("MachineModel", "LookupID"),
                },
              ],
              ...whereLikeSearch("serialNumber"),
              ...whereLikeSearch("computerNumber"),
            },
            {
              model: "ClientBranch",
              attributes: ["BranchName"],
            },
          ],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      if (res && res.data && res.data.length) {
        setContractMachines(
          res.data
            .filter((r) => r.Machine !== null)
            .map((row) => {
              return {
                ...row,
                computerNumber: row.Machine.computerNumber,
                serialNumber: row.Machine.SerialNumber,
                machineModel: Boolean(
                  row.Machine.MachineModel &&
                    row.Machine.MachineModel.LookupName
                )
                  ? row.Machine.MachineModel.LookupName
                  : resources.common.notSpecified,
                machineType: Boolean(
                  row.Machine.MachineType && row.Machine.MachineType.LookupName
                )
                  ? row.Machine.MachineType.LookupName
                  : resources.common.notSpecified,
                branchName: Boolean(
                  row.ClientBranch && row.ClientBranch.BranchName
                )
                  ? row.ClientBranch.BranchName
                  : resources.common.notSpecified,
                createdDate: moment(row.createdAt).format("DD/MM/YYYY"),
              };
            })
        );
      } else setContractMachines([]);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoadingContractMachine(false);
    } catch (error) {
      setIsLoadingContractMachine(false);
      console.log(error);
    }
  };

  const deleteMachine = async () => {
    try {
      await serviceApi
        .service("contractMachine")
        .remove(contractMachineID)
        .then((res) => {
          setIsDelete(false);
          fillContractMachine(contractID);
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  const fillBranchAreaTechnicians = async (areaID = null) => {
    try {
      setTechniciansListIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "TechnicianArea",
          attributes: ["TechnicianID"],
          include: [
            {
              model: "Technicians",
              attributes: ["technicianName"],
            },
            {
              model: "Area",
              attributes: ["areaName"],
              ...(areaID ? { where: { AreaID: areaID } } : {}),
            },
          ],
        },
      });

      if (res && res.data && res.data.length > 0) {
        const branchTechs = [];
        const flags = [];
        for (let i = 0; i < res.data.length; i++) {
          if (flags[res.data[i].TechnicianID]) continue;
          flags[res.data[i].TechnicianID] = true;
          if (
            res.data[i].Technician &&
            res.data[i].Area &&
            res.data[i].TechnicianID
          )
            branchTechs.push({
              key: res.data[i].TechnicianID,
              text: res.data[i].Technician.technicianName,
              value: res.data[i].TechnicianID,
            });
        }
        setBranchAreaTechnicians(branchTechs);
      } else setBranchAreaTechnicians([]);
      setTechniciansListIsLoading(false);
    } catch (error) {
      setTechniciansListIsLoading(false);
      console.log(error);
    }
  };

  const fillContractDetails = async (contractID) => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Contract",
          attributes: [
            "ContractNumber",
            "ClientID",
            "startDate",
            "EndDate",
            "ContractTypeID",
          ],
          include: [
            {
              model: "Lookup",
              attributes: ["LookupName", "LookupID"],
              as: "ContractType",
            },
            {
              model: "Client",
              attributes: ["ClientName"],
            },
          ],
          where: { contractId: contractID },
        },
      });

      if (res && res.data && res.data.length) {
        const contract = res.data[0];
        setContractType(contract.ContractTypeID);
        setClientID(contract.ClientID);
        setContractDetails([
          {
            label: resources.pages.addContract.contractNumber,
            value: contract.ContractNumber,
          },
          {
            label: resources.pages.addContract.client,
            value: contract.Client
              ? contract.Client.ClientName
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.addContract.contractType,
            value: contract.ContractType.LookupName,
          },
          {
            label: resources.pages.addContract.startDate,
            value: contract.startDate
              ? moment(contract.startDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
          },
          {
            label: resources.pages.addContract.endDate,
            value: contract.EndDate
              ? moment(contract.EndDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
          },
        ]);
        setcontractEndDate(contract.EndDate); // used for the icon that calls contract payment
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const unActivatePreviousMachineContracts = async (m) => {
    try {
      await serviceApi.service("contractMachine").patch(m.ContractMachineID, {
        contractIsActive: false,
      });
    } catch (err) {
      console.log(err);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (contractMachineID !== -1)
        await serviceApi.service("contractMachine").patch(contractMachineID, {
          ...values,
          ContractID: contractID,
        });
      else {
        const lastContractMachine = await serviceApi.service("queries").find({
          query: {
            model: "ContractMachine",
            limit: 1,
            attributes: ["ContractMachineID"],
            order: [["createdAt", "DESC"]],
            where: { MachineID: values.MachineID },
          },
        });

        let counters = {};
        if (
          lastContractMachine &&
          lastContractMachine.data &&
          lastContractMachine.data.length > 0
        ) {
          counters = await serviceApi.service("queries").find({
            query: {
              model: "MaintenanceReport",
              limit: 1,
              attributes: ["counter1", "counter2"],
              order: [["LastVisitDate", "DESC"]],
              where: {
                ContractMachineID:
                  lastContractMachine.data[0].ContractMachineID,
              },
            },
          });
          if (!counters || !counters.data || counters.data.length <= 0) {
            counters = await serviceApi.service("queries").find({
              query: {
                model: "ContractMachine",
                limit: 1,
                attributes: ["LastCounter1", "LastCounter2"],
                order: [["createdAt", "desc"]],
                where: {
                  ContractMachineID:
                    lastContractMachine.data[0].ContractMachineID,
                },
              },
            });
          }
        } else {
          counters = {
            lastCounter1: 0,
            lastCounter2: 0,
          };
        }

        const { data: previousAssociatedMachineContractsIds } = await serviceApi
          .service("contractMachine")
          .find({
            query: {
              $select: ["ContractMachineID"],
              MachineID: values.MachineID,
            },
          });

        if (
          !!previousAssociatedMachineContractsIds &&
          !!previousAssociatedMachineContractsIds.length
        )
          previousAssociatedMachineContractsIds.forEach(
            unActivatePreviousMachineContracts
          );

        if (counters && counters.data && counters.data.length > 0) {
          values = {
            ...values,
            lastCounter1: counters.data[0].counter1,
            lastCounter2: counters.data[0].counter2,
          };
        }
        await serviceApi
          .service("contractMachine")
          .create({ ...values, ContractID: contractID });
      }
      setIsSubmitting(false);
      setSuccessVisible(true);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  const getEdditedBranchAreaID = async (branchID) => {
    const res = await serviceApi.service("clientBranch").find({
      query: {
        $select: ["AreaID"],
        branchId: branchID,
        $limit: 30,
      },
    });
    if (res) fillBranchAreaTechnicians(res.data[0].AreaID);
  };

  const fillBranches = async () => {
    const res = await serviceApi.service("clientBranch").find({
      query: {
        $select: ["branchId", "branchName", "AreaID"],
        ClientID: clientID,
        $limit: 1000,
      },
    });

    if (Boolean(res && res.data && res.data.length > 0))
      setBranches(
        res.data.map((c) => {
          return {
            key: c.branchId,
            text: c.branchName,
            value: c.branchId,
            areaid: c.AreaID,
          };
        })
      );
    else setBranches([]);
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  useEffect(() => {
    if (clientID) {
      fillBranches();
      // fillBranchAreaTechnicians();
    }
  }, [clientID]);

  useEffect(() => {
    if (searching && contractID) fillContractMachine(contractID);
  }, [searching]);

  useEffect(() => {
    if (activePage) {
      if (Boolean(location && location.state && location.state.contractId)) {
        setIsLoading(true);
        if (
          location.state.endContract === resources.pages.addContract.endContract
        )
          setIsContractEnd(true);
        else setIsContractEnd(false);
        setContractID(+location.state.contractId);
        fillContractDetails(+location.state.contractId);
        fillContractMachine(+location.state.contractId);
      }
    }
  }, [activePage]);

  const checkValidation = (values) => {
    const errors = {};
    const requiredField = resources.common.errors.requiredField;
    if (!values.BranchID) {
      errors.BranchID = requiredField;
    }
    if (!values.MachineID) {
      errors.MachineID = requiredField;
    }
    if (!values.TechnicianID) {
      errors.TechnicianID = requiredField;
    }
    if (!values.minimumNumberOfCopies && contractType === 43) {
      errors.minimumNumberOfCopies = requiredField;
    }
    if (!values.dueHours && isSLA) errors.dueHours = requiredField;

    return errors;
  };

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    setInitialValues(null);
    fillContractMachine(contractID);
    setContractMachineID(-1);
    if (!!machines.length) setMachines([]);
    if (!!machineNumber) setMachineNumber("");
    setSuccessVisible(false);
    setActiveIndex(1);
  };

  const handlePayments = () => {
    router.push("/contracts/contractPayments", {
      contractId: contractID,
      endContract: contractEndDate,
    });
  };

  const addContractMachine = () => {
    return (
      !isContractEnd && (
        <Segment>
          <FinalForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={checkValidation}
            render={({ handleSubmit }) => (
              <Form
                className="contract-form"
                autoComplete="off"
                onSubmit={handleSubmit}
                loading={isSubmitting}
              >
                <Grid columns={3} stackable>
                  <Grid.Column>
                    <p>{resources.pages.branch.branchName}</p>
                    <Field name="BranchID">
                      {({ input, meta }) => (
                        <Form.Select
                          options={branches}
                          placeholder={resources.pages.branch.branchName}
                          noResultsMessage={resources.common.noData}
                          search={Boolean(branches.length > 0)}
                          name={input.name}
                          value={input.value}
                          onChange={(e, { value, options }) => {
                            input.onChange(value);
                            const result = options.find((b) => b.key === value);
                            if (result)
                              fillBranchAreaTechnicians(result.areaid);
                          }}
                          error={meta.touched && meta.error}
                          // disabled={Boolean(
                          //   initialValues && initialValues.BranchID
                          // )}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.machines.computerNumber}</p>
                    <Field name="MachineID">
                      {({ input, meta }) => (
                        <Form.Select
                          options={machines}
                          placeholder={resources.pages.machines.computerNumber}
                          noResultsMessage={resources.common.noData}
                          search
                          name={input.name}
                          value={input.value}
                          onSearchChange={(e) =>
                            setMachineNumber(e.target.value)
                          }
                          type="number"
                          loading={machinesNumbersAreLoading}
                          onChange={(e, { value }) => input.onChange(value)}
                          error={meta.touched && meta.error}
                          className={`${
                            Boolean(machines.length > 0)
                              ? ""
                              : "without-down-arrow"
                          }`}
                          disabled={Boolean(
                            initialValues && initialValues.MachineID
                          )}
                        />
                      )}
                    </Field>
                    {machinesErrorMessage.openMachinesErrorMessage && (
                      <Message
                        negative
                        className="align-text-center"
                        content={machinesErrorMessage.errorMessage}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.machines.technician}</p>
                    <Field name="TechnicianID">
                      {({ input, meta }) => (
                        <Form.Select
                          options={branchAreaTechnicians}
                          placeholder={resources.pages.machines.technician}
                          noResultsMessage={resources.common.noData}
                          search={Boolean(branchAreaTechnicians.length > 0)}
                          name={input.name}
                          value={input.value}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                          }}
                          error={meta.touched && meta.error}
                          loading={techniciansListIsLoading}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  {Boolean(contractType === 42 || contractType === 43) && (
                    <Grid.Column>
                      <p>
                        {resources.pages.addContractMachine.maxAllowedCopies}
                      </p>
                      <Field name="maxAllowedCopies">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="sort numeric up"
                            type="number"
                            placeholder={
                              resources.pages.addContractMachine
                                .maxAllowedCopies
                            }
                            autoComplete="off"
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}
                  {Boolean(
                    contractType == 41 ||
                      contractType == 42 ||
                      contractType == 43
                  ) && (
                    <Grid.Column>
                      <p>
                        {
                          resources.pages.addContractMachine
                            .copyPriceBlackAndWhite
                        }
                      </p>
                      <Field name="copyPrice">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            type="number"
                            icon="dollar sign"
                            placeholder={
                              resources.pages.addContractMachine
                                .copyPriceBlackAndWhite
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}

                  {Boolean(
                    contractType == 41 ||
                      contractType == 42 ||
                      contractType == 43
                  ) && (
                    <Grid.Column>
                      <p>
                        {resources.pages.addContractMachine.copyPriceColorful}
                      </p>
                      <Field name="copyPriceColorful">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            type="number"
                            icon="dollar sign"
                            placeholder={
                              resources.pages.addContractMachine
                                .copyPriceColorful
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}
                  {contractType == 41 && (
                    <Grid.Column>
                      <p>{resources.pages.addContractMachine.monthlyFees}</p>
                      <Field name="monthlyFees">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="dollar sign"
                            type="number"
                            placeholder={
                              resources.pages.addContractMachine.monthlyFees
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}
                  {contractType == 42 && (
                    <Grid.Column>
                      <p>
                        {resources.pages.addContractMachine.numberOfFreeCopies}
                      </p>
                      <Field name="numberOfFreeCopies">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="sort numeric up"
                            type="number"
                            placeholder={
                              resources.pages.addContractMachine
                                .numberOfFreeCopies
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}
                  {contractType == 43 && (
                    <Grid.Column>
                      <p>
                        {
                          resources.pages.addContractMachine
                            .minimumNumberOfCopies
                        }
                      </p>
                      <Field name="minimumNumberOfCopies">
                        {({ input, meta }) => (
                          <Form.Input
                            {...input}
                            error={meta.touched && meta.error}
                            fluid
                            icon="sort numeric up"
                            type="number"
                            placeholder={
                              resources.pages.addContractMachine
                                .minimumNumberOfCopies
                            }
                          />
                        )}
                      </Field>
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <p>{resources.pages.addContract.SLA}</p>
                    <Field name="withSLA">
                      {({ input, meta }) => (
                        <Form.Checkbox
                          onChange={(e, v) => {
                            input.onChange(v.checked);
                            if (Boolean(v.checked)) setIsSLA(true);
                            else setIsSLA(false);
                          }}
                          checked={Boolean(input.value)}
                          name={input.name}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{`${resources.pages.addContract.dueHours} ( ${resources.common.inHours} )`}</p>
                    <Field name="dueHours">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          placeholder={resources.pages.addContract.dueHours}
                          type="number"
                          disabled={!isSLA}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.machines.firstInstallationDate}</p>
                    <Field name="installationDate">
                      {({ input, meta }) => (
                        <DTPicker
                          selectedValue={input.value}
                          isTrigger
                          setSelectedValue={(v) => input.onChange(v)}
                          format="date"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.common.notes}</p>
                    <Field name="contractMachineNotes">
                      {({ input, meta }) => (
                        <Form.TextArea
                          {...input}
                          placeholder={resources.common.notes}
                          icon="sticky note outline"
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  className="contract-save-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {resources.common.save}
                </Button>
                {errorVisible.visible && (
                  <Message
                    negative
                    compact
                    className="error-message"
                    content={
                      errorMessage || resources.common.errors.genericServerError
                    }
                  />
                )}
              </Form>
            )}
          />
        </Segment>
      )
    );
  };

  const contractMachinesTable = () => {
    return (
      <Segment>
        <SearchBy
          searchTypes={searchData}
          getSearchResults={getSearchResults}
        />
        <TableTemplate
          headers={headers}
          data={contractMachines}
          edit={!isContractEnd}
          remove={!isContractEnd}
          onCellClick={onCellClick}
          paging={pagination}
          isLoading={isLoadingContractMachine}
          activePage={activePage}
          onPaging={onPaging}
        />
      </Segment>
    );
  };

  const panes = [
    !isContractEnd && {
      menuItem: initialValues
        ? resources.pages.addContractMachine.editContractMachine
        : resources.pages.addContractMachine.addMachineToContract,
      render: () => addContractMachine(),
    },
    {
      menuItem: resources.pages.addContractMachine.pageTitle,
      render: () => contractMachinesTable(),
    },
  ];

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge">{resources.common.loading}</Loader>
      </Dimmer>
      <DeleteModal
        onConfirm={() => deleteMachine()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid className="add-contract-page-container">
        <SuccessModal
          visible={successVisible}
          onConfirm={handleConfirm}
          header={resources.common.savedSuccessfully}
        />
        <Grid.Column>
          <PageHeadeTitle
            content={resources.pages.addContractMachine.pageTitle}
            icon="file alternate"
          />
          <Message info>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {contractDetails.map((row, idx) => (
                <div key={idx}>
                  <Icon name="info circle" />
                  <label style={{ paddingRight: 2 }}>
                    {row.label} : {row.value}
                  </label>
                </div>
              ))}
              {isAuthorized(Perm.contractRenewal) && (
                <div
                  className="renew-container-container"
                  onClick={handlePayments}
                >
                  <Icon
                    name="payment"
                    color="blue"
                    onClick={handlePayments}
                    className="renew-container-container"
                  />
                  <label
                    style={{ paddingRight: 2 }}
                    onClick={handlePayments}
                    className="renew-container-container"
                  >
                    {resources.pages.contractPayment.contractPayments}
                  </label>
                </div>
              )}
            </div>
          </Message>
          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) => setActiveIndex(+activeIndex)}
            renderActiveOnly={true}
          />
        </Grid.Column>
      </Grid>
    </>
  );
}
