import React, { useState, useEffect } from "react";
import { Select } from "semantic-ui-react";
import serviceApi from "../../api";
import { useDispatch, useSelector, useLocation } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import useDebounce from "../../components/helpers/useDebounce";
import { reportsActionCreator } from "../../redux/actions";

const ReportAreaPicker = () => {
  const [areas, setAreas] = useState([]);
  const [areaNameIsLoading, setAreaNameIsLoading] = useState(false);
  const [areaName, setAreaName] = useState("");
  const [areaID, setAreaID] = useState("");
  const debouncedAreaNameSearch = useDebounce(areaName, 1000);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      setAreaNameIsLoading(true);
      const res = await serviceApi.service("Area").find({
        query: {
          areaName: `%${areaName}%`,
          limit: 10,
          skip: 0,
        },
      });
      if (Boolean(res && res.data && res.data.length > 0))
        setAreas(
          res.data.map((a) => {
            return { key: a.areaID, text: a.areaName, value: a.areaID };
          })
        );
      else setAreas([]);

      setAreaNameIsLoading(false);
    } catch (e) {
      setAreaNameIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(areaName && areaName.length > 2)) {
      fetchData();
    } else {
      setAreaName("");
      setAreaID("");
    }
  }, [debouncedAreaNameSearch]);

  return (
    <div>
      <p>{resources.pages.technicianAreas.area} :</p>
      <Select
        options={areas}
        placeholder={resources.pages.maintenanceReport.area}
        noResultsMessage={resources.common.noData}
        search
        value={areaID}
        onSearchChange={(e) => setAreaName(e.target.value.trim())}
        loading={areaNameIsLoading}
        onChange={(e, { value }) => {
          setAreaID(value);
          dispatch(
            reportsActionCreator.setReportOptions({
              areaID: value,
            })
          );
        }}
        className={`${Boolean(areas.length) ? "" : "without-down-arrow"}`}
      />
    </div>
  );
};

export default ReportAreaPicker;
