import React, { useState, useEffect } from "react";
import { Icon, Input, Segment } from "semantic-ui-react";
import useDebounce from "../../helpers/useDebounce";
import SearchServiceApi from "../../../api/search-service.api";
import { resources } from "../../../assets/LocalizationResources";
import * as turf from "@turf/turf";
import wkt from "terraformer-wkt-parser";
import "./CreationGeoMethods.scss";

const LandMarks = ({ onGeomSelected }) => {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [term, setTerm] = useState("");
  const [inputValue, setInputValue] = useState({ WKT: "", Label: "" });
  const [radiousValue, setRadiousValue] = useState(1);
  const debouncedSearch = useDebounce(term, 1000);

  const onSearch = (e, result) => {
    setInputValue({ ...inputValue, Label: result.value });

    if (result.value) {
      setSearching(true);
      setTerm(result.value);
    } else {
      setResults([]);
      setSearching(false);
    }
  };

  const getResults = async () => {
    const res = await SearchServiceApi.search(term, "8");
    if (res && res.data && res.data.length > 0) setResults(res.data);
    else setResults([]);
    setSearching(false);
  };

  useEffect(() => {
    if (Boolean(term) && searching) {
      getResults();
    }
  }, [debouncedSearch]);

  const handleSelect = (row) => {
    setInputValue(row);
    setTerm("");
    setResults([]);
  };

  useEffect(() => {
    if (inputValue.WKT) {
      const points = wkt.parse(inputValue.WKT);
      const center = [points.coordinates[0], points.coordinates[1]];
      const radius = radiousValue;
      const circle = turf.circle(center, radius, {
        steps: 20,
        units: "kilometers",
      });
      onGeomSelected({ geom: wkt.convert(circle.geometry), saved: true });
    }
  }, [inputValue, radiousValue]);

  return (
    <div className="land-marks-container">
      <div className="land-marks-map-container">
        <label className="label-text">{resources.pages.area.landMarks}</label>
        <div className="land-marks-map-search-container">
          <div className="land-marks-map-search-input-container">
            <Input
              className="land-marks-search-box"
              placeholder={resources.pages.area.searchLandmarks}
              value={inputValue.Label}
              loading={searching}
              onChange={onSearch}
            />
            <Icon
              className="land-marks-icon-close"
              name="close"
              onClick={() => setInputValue({ WKT: "", Label: "" })}
            />
          </div>
          <div>
            {Boolean(term) &&
              Boolean(inputValue.Label) &&
              (results.length === 0 && searching == false ? (
                <Segment className="result-segment">
                  {resources.common.noResultFor}
                  <span style={{ color: "red" }}>{inputValue.Label}</span>
                </Segment>
              ) : (
                <Segment className="map-search-results result-segment">
                  {results.map((r, idx) => (
                    <p
                      className="map-search-option"
                      onClick={() => handleSelect(r)}
                      key={idx}
                    >
                      {r.Label}
                    </p>
                  ))}
                </Segment>
              ))}
          </div>
        </div>
      </div>
      <div className="map-search-radius">
        <label className="label-text">{resources.pages.area.radius}</label>

        <Input
          value={radiousValue}
          onChange={(e, v) => {
            setRadiousValue(+v.value);
          }}
          type="number"
          min="1"
        />
      </div>
    </div>
  );
};

export default LandMarks;
