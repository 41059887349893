import React, { useState, useEffect } from "react";
import { Button, Icon, Input, Message, Modal } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import "./ClientBranches.scss";

const AddContractPersons = ({
  isOpen,
  setCotnactPersons,
  closeModal,
  contactPersons,
  contactPersonsModalHeader,
}) => {
  const [contactPersonInfo, setContactPersonInfo] = useState(contactPersons);
  const [errorMessage, setErrorMessage] = useState({
    index: null,
    fieldName: "",
  });
  const [openIsEmptyMessage, setOpenIsEmptyMessage] = useState(false);

  useEffect(() => {
    if (contactPersons) setContactPersonInfo(contactPersons);
  }, [contactPersons]);

  const handleChange = (i, e) => {
    const values = [...contactPersonInfo];
    values[i][e.target.name] = e.target.value;
    setContactPersonInfo(values);
  };

  const validator = (i, f) => {
    if (!f.personName) {
      setErrorMessage({
        index: i,
        fieldName: "personName",
      });
      return false;
    } else if (!f.personPhoneNumber) {
      setErrorMessage({
        index: i,
        fieldName: "personPhoneNumber",
      });
      return false;
    } else {
      setErrorMessage({
        index: null,
        fieldName: "",
      });
      return true;
    }
  };

  const addContactPerson = (index, inputField) => {
    if (validator(index, inputField)) {
      setContactPersonInfo([
        ...contactPersonInfo,
        { personName: "", personPhoneNumber: "", personEmail: "" },
      ]);
      setOpenIsEmptyMessage(false);
    }
  };

  const removeContactPerson = (index) => {
    const values = [...contactPersonInfo];
    values.splice(index, 1);
    setContactPersonInfo(values);
  };
  return (
    <Modal open={isOpen} className="contact-persons-main-container">
      <Modal.Header>{contactPersonsModalHeader}</Modal.Header>
      <Modal.Content className="contact-persons-container">
        {contactPersonInfo.map((inputField, index) => (
          <div key={index} className="contact-person-field-container">
            <div className="contact-person-input-fields-conteiner">
              <Input
                placeholder={resources.pages.branch.personName}
                icon="user"
                name="personName"
                value={inputField.personName}
                onChange={(event) => {
                  handleChange(index, event);
                }}
                error={
                  Boolean(errorMessage.index === index) &&
                  Boolean(errorMessage.fieldName === "personName")
                    ? true
                    : false
                }
                autoComplete="off"
              />
              <Input
                placeholder={resources.pages.branch.phone}
                icon="phone square"
                name="personPhoneNumber"
                value={inputField.personPhoneNumber}
                onChange={(event) => {
                  handleChange(index, event);
                }}
                error={
                  Boolean(errorMessage.index === index) &&
                  Boolean(errorMessage.fieldName === "personPhoneNumber")
                    ? true
                    : false
                }
                autoComplete="off"
              />
              <Input
                placeholder={resources.pages.branch.personEmail}
                icon="mail"
                name="personEmail"
                value={inputField.personEmail}
                onChange={(event) => {
                  handleChange(index, event);
                }}
                autoComplete="off"
              />
            </div>

            <div>
              <Icon
                name="plus"
                color="green"
                className="contact-person-icon"
                onClick={() => addContactPerson(index, inputField)}
              />
              <Icon
                name="minus"
                color="red"
                disabled={index === 0 ? true : false}
                className="contact-person-icon"
                onClick={() => removeContactPerson(index)}
              />
            </div>
          </div>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          onClick={() => {
            if (
              Boolean(
                contactPersonInfo.every(
                  (p) => p.personName && p.personPhoneNumber
                )
              )
            ) {
              setCotnactPersons(contactPersonInfo);
              setOpenIsEmptyMessage(false);
              closeModal();
            } else setOpenIsEmptyMessage(true);
          }}
        >
          {resources.common.save}
        </Button>
        <Button
          onClick={() => {
            setContactPersonInfo(contactPersons);
            setOpenIsEmptyMessage(false);
            closeModal();
          }}
        >
          {resources.common.cancel}
        </Button>
        {openIsEmptyMessage && (
          <Message
            negative
            compact
            content={resources.pages.branch.fillAllFieldsError}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default AddContractPersons;
