import React, { useState, useEffect } from "react";
import technicianImg from "../../assets/images/techIcon.svg";
import branchesImg from "../../assets/images/routing/route-end.png";
import areasImg from "../../assets/images/map/areas.png";
//import ticketsImg from "../../assets/images/ticketImg.png";
import { icons } from "./helpers/MapIcons";
import { Divider } from "semantic-ui-react";
import wkt from "terraformer-wkt-parser";
import serviceApi from "../../api";
import L from "leaflet";
import { Marker, Polygon, Popup } from "react-leaflet";
import { resources } from "../../assets/LocalizationResources";
import moment from "moment";

export default function MapLegend({ isOpen, setLegendMarkers }) {
  const [areas, setAreas] = useState(false);
  const [techs, setTechs] = useState(false);
  const [branches, setBranches] = useState(false);
  //const [tickets, setTickets] = useState(false);

  const [areaPolygons, setAreaPolygons] = useState([]);
  const [techMarkers, setTechMarkers] = useState("");
  const [branchesMarkers, setBranchesMarkers] = useState("");
  //const [ticketsMarkers, setTicketsMarkers] = useState("");

  useEffect(() => {
    loadTechnicians();
    //loadAreas();
    //loadTickets();
    loadBranches();
  }, []);

  useEffect(() => {
    let markers = [];
    if (techs) markers.push(techMarkers);
    if (branches) markers.push(branchesMarkers);
    //if (tickets) markers.push(ticketsMarkers);
    if (areas) markers.push(areaPolygons);

    setLegendMarkers(markers);
  }, [techs, branches, areas]);

  useEffect(() => {
    if (techMarkers) setTechs(true);
  }, [techMarkers]);

  useEffect(() => {
    if (branchesMarkers) setBranches(true);
  }, [branchesMarkers]);

  const loadTechnicians = async () => {
    try {
      var res = await serviceApi.service("queries").find({
        query: {
          model: "Users",
          attributes: ["userID", "fullName", "phone"],
          include: [
            {
              model: "Locations",
              attributes: ["address", "longitude", "latitude", "gpsTime"],
            },
          ],
        },
      });
      if (res && res.data) {
        setTechMarkers(
          getMarkers(
            res.data
              .filter(
                (e) => e.Location && e.Location.longitude && e.Location.latitude
              )
              .map((row) => {
                return {
                  longitude: row.Location.longitude,
                  latitude: row.Location.latitude,
                  header: row.fullName,
                  icon: icons.TechnicianIcon,
                  details: {
                    التاريخ: moment
                      .utc(row.Location.gpsTime)
                      .format("DD/MM/YYYY"),
                    الساعة: moment.utc(row.Location.gpsTime).format("hh:mm a"),
                  },
                };
              })
          )
        );
      }
    } catch (error) {
      ////TODO: handle error
      console.log(error);
    }
  };

  // const loadTickets = async () => {
  //   const res = await serviceApi.service("queries").find({
  //     query: {
  //       model: "Tickets",
  //       include: [
  //         {
  //           innerJoin: true,
  //           model: "ClientBranch",
  //           attributes: ["BranchID", "BranchName", "longitude", "latitude"],
  //           include: [
  //             {
  //               innerJoin: true,
  //               model: "Client",
  //               attributes: ["ClientName"],
  //             },
  //           ],
  //         },
  //         {
  //           innerJoin: true,
  //           model: "Lookup",
  //           as: "status",
  //           attributes: ["LookupName"],
  //           where: { LookupID: { ne: 99 } },
  //         },
  //       ],
  //     },
  //   });

  //   if (res && res.data && res.data.length > 0) {
  //     setTicketsMarkers(
  //       getMarkers(
  //         res.data.map((row) => {
  //           return {
  //             longitude: row.ClientBranch.longitude,
  //             latitude: row.ClientBranch.latitude,
  //             header: row.ClientBranch.Client.ClientName,
  //             icon: icons.TicketIcon,
  //             details: {
  //               "إسم المتصل": row.contactName,
  //               "الرقم ": row.contactPhone,
  //             },
  //           };
  //         })
  //       )
  //     );
  //   }
  // };

  const loadBranches = async () => {
    try {
      const res = await serviceApi.service("clientBranch").find({
        query: {
          $select: ["longitude", "latitude", "branchName", "address"],
          $limit: 15,
        },
      });
      if (res && res.data) {
        setBranchesMarkers(
          getMarkers(
            res.data.map((row) => {
              return {
                longitude: row.longitude,
                latitude: row.latitude,
                header: row.branchName,
                icon: icons.RouteEnd,
                details: {
                  // "رقم الفرع": row.branchNo,
                  العنوان: row.address,
                },
              };
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGeom = (wkt_string) => {
    var geo = wkt.parse(wkt_string);
    var polygon = geo.coordinates[0].map(
      (point) => new L.latLng(point[1], point[0])
    );

    return polygon;
  };

  const getAreaPolygons = (data) => {
    return data.map((row) => (
      <Polygon
        key={row.key}
        positions={getGeom(row.geom)}
        color={"#" + ((Math.random() * 16777215) | 0).toString(16)}
      >
        <Popup>{row.text}</Popup>
      </Polygon>
    ));
  };

  const loadAreas = async () => {
    try {
      const res = await serviceApi.service("area").find();

      if (res && res.data && res.data.length > 0)
        setAreaPolygons(
          getAreaPolygons(
            res.data.map((c) => {
              return {
                key: c.areaID,
                text: "إسم المنطقة: " + c.areaName,
                value: c.areaID,
                geom: c.geometry,
              };
            })
          )
        );
      else setAreaPolygons([]);
    } catch (error) {
      // TODO: Show error message
      console.log(error);
    }
  };

  const getMarkers = (data) => {
    return data.map((row, idx) => (
      <Marker
        key={idx}
        position={{
          lng: row.longitude,
          lat: row.latitude,
        }}
        icon={row.icon}
      >
        <Popup>
          <div className="popup-container">
            <label className="popup-header">{row.header}</label>
            <Divider style={{ margin: "5px" }} />
            <div>
              {Object.keys(row.details).map((key, idx) => (
                <label key={idx} className="popup-label">
                  {key} :{row.details[key]}
                </label>
              ))}
            </div>
          </div>
        </Popup>
      </Marker>
    ));
  };

  return (
    isOpen && (
      <div className="map-legend-container">
        <div className="map-legend">
          <div
            onClick={() => setBranches(!branches)}
            className={branches ? "selected" : ""}
          >
            <img src={branchesImg} />
            <label>{resources.models.clientBranches}</label>
          </div>
          {/* <div
            onClick={() => setAreas(!areas)}
            className={areas ? "selected" : ""}
          >
            <img src={areasImg} />
            <label>المناطق الجغرافية</label>
          </div> */}
          <div
            onClick={() => setTechs(!techs)}
            className={techs ? "selected" : ""}
          >
            <img src={technicianImg} />
            <label>{resources.models.Technicians}</label>
          </div>
          {/* <div
            onClick={() => setTickets(!tickets)}
            className={tickets ? "selected" : ""}
          >
            <img src={ticketsImg} />
            <label>التذاكر</label>
          </div> */}
        </div>
      </div>
    )
  );
}
