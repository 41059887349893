import React, { useEffect, useState } from "react";
import "./TechniciansMovePoints.scss";
import serviceApi from "../../api";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import {
  Button,
  Dimmer,
  Divider,
  Input,
  Loader,
  Message,
  Select,
} from "semantic-ui-react";
import DTPicker from "../../components/DTPicker/DTPicker";
import { resources } from "../../assets/LocalizationResources";
import PlayBackMap from "./PlayBackMap";
import moment from "moment";

const emptySelectedLocationInfo = {
  locationDate: null,
  locationHour: null,
  lnglat: {
    lng: null,
    lat: null,
  },
  locationId: null,
  index: 0,
};

const TechMovePoints = () => {
  const [technicianID, setTechnicianID] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [fromTechMovingPointsDate, setFromTechMovingPointsDate] =
    useState(null);
  const [toTechMovingPointsDate, setToTechMovingPointsDate] = useState(null);
  const [userID, setUserID] = useState(null);
  const [techMovePointsLocations, setTechMovePointsLocations] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [technicianInformation, setTechnicianInformation] = useState({});
  const [technicianAreas, setTechnicianAreas] = useState([]);
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(
    emptySelectedLocationInfo
  );

  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

  const loadTechnicians = async () => {
    try {
      const res = await serviceApi.service("Technicians").find({
        query: {
          $select: ["technicianId", "TechnicianName", "UserID"],
          UserID: { $ne: null },
        },
      });

      if (res && res.data && res.data.length > 0) {
        setTechnicians(
          res.data.map((tech) => {
            return {
              key: tech.technicianId,
              text: tech.TechnicianName,
              value: tech.technicianId,
              userid: tech.UserID,
            };
          })
        );
      } else setTechnicians([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTechInformation = async () => {
    try {
      if (userID) {
        const res = await serviceApi.service("Technicians").find({
          query: {
            $select: ["TechnicianName", "mobile", "email"],
            userID: userID,
            $limit: 1,
          },
        });

        if (res && res.data && res.data.length > 0) {
          setTechnicianInformation({
            techName: res.data[0].TechnicianName,
            techMobile: res.data[0].mobile
              ? res.data[0].mobile
              : resources.common.notSpecified,
            techEmail: res.data[0].email
              ? res.data[0].email
              : resources.common.notSpecified,
          });
        } else setTechnicianInformation({});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTechLocations = async () => {
    try {
      setIsLoading(true);

      let fromDate = fromTechMovingPointsDate
        ? moment(fromTechMovingPointsDate).format("YYYY-MM-DD HH:mm")
        : moment().format("YYYY-MM-DD HH:mm");
      let toDate = toTechMovingPointsDate
        ? moment(toTechMovingPointsDate).format("YYYY-MM-DD HH:mm")
        : moment().format("YYYY-MM-DD HH:mm");

      if (userID) {
        const res = await serviceApi.service("locations").find({
          query: {
            $select: [
              "longitude",
              "latitude",
              "locationId",
              "gpsTime",
              "address",
            ],
            UserID: userID,
            GPSTime: {
              $gte: fromDate,
              $lte: toDate,
            },
            $sort: {
              gpsTime: 1,
            },
          },
        });

        if (res && res.data && res.data.length > 0) {
          setTechMovePointsLocations(
            res.data.map((point) => {
              return {
                lng: point.longitude,
                lat: point.latitude,
                locationDate: moment.utc(point.gpsTime).format("DD/MM/YYYY"),
                locationHour: moment.utc(point.gpsTime).format("HH:mm:ss"),
                locationID: +point.locationId,
              };
            })
          );
          getTechAreas();
          getTechInformation();
          setIsErrorMessageVisible(false);
        } else {
          setTechMovePointsLocations([]);
          setTechnicianAreas([]);
          setTechnicianInformation({});
          setIsErrorMessageVisible(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getTechAreas = async () => {
    try {
      if (technicianID) {
        const res = await serviceApi.service("queries").find({
          query: {
            model: "TechnicianArea",
            attributes: ["TechnicianID"],
            include: [
              {
                model: "Area",
                attributes: ["areaName", "AreaID"],
              },
            ],
            where: { TechnicianID: technicianID },
          },
        });
        if (res && res.data && res.data.length > 0) {
          setTechnicianAreas(
            res.data.map((area) => {
              return {
                areaID: area.Area.AreaID,
                areaName: area.Area.areaName,
              };
            })
          );
        } else setTechnicianAreas([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadTechnicians();
  }, []);

  useEffect(() => {
    if (locationId) {
      let index = 0;
      const result = techMovePointsLocations.find((t, i) => {
        if (t.locationID === locationId) {
          index = i;
          return true;
        }
      });

      if (result)
        setSelectedLocationInfo({
          locationDate: result.locationDate,
          locationHour: result.locationHour,
          lnglat: {
            lng: result.lng,
            lat: result.lat,
          },
          locationId: locationId,
          index: index,
        });
      else setSelectedLocationInfo(emptySelectedLocationInfo);
    } else setSelectedLocationInfo(emptySelectedLocationInfo);
  }, [locationId]);

  return (
    <div className="technicians-play-back-map-container">
      <div className="technicians-move-points-container">
        <div className="header-container">
          <PageHeaderTitle
            content={resources.pages.playBackMap.techsMovePoints}
            icon="road"
          />
        </div>
        <div className="technicians-list-dates-container">
          <div className="technicians-list-container">
            <label>{resources.pages.technicians.technicianName}</label>
            <Select
              value={technicianID}
              placeholder={resources.pages.playBackMap.chooseTech}
              options={technicians}
              className="technicians-list"
              search={Boolean(technicians.length > 0)}
              onChange={(e, { value, options }) => {
                setTechnicianID(value);
                const result = options.find((t) => t.key === value);
                if (result) setUserID(result.userid);
              }}
              error={isEmpty}
            />
          </div>

          <Divider className="divider" />

          <div className="from-to-dates-container">
            <div className="date-container from-date">
              <label>{resources.common.from}</label>
              <DTPicker
                selectedValue={fromTechMovingPointsDate}
                isTrigger
                setSelectedValue={(v) => setFromTechMovingPointsDate(v)}
                isForTechMovePoints={true}
              />
            </div>

            <div className="date-container">
              <label>{resources.common.to}</label>
              <DTPicker
                selectedValue={toTechMovingPointsDate}
                isTrigger
                setSelectedValue={(v) => setToTechMovingPointsDate(v)}
                isForTechMovePoints={true}
              />
            </div>
          </div>
        </div>
        <div className="submit-button-container">
          <Button
            size="large"
            color="blue"
            onClick={() => {
              if (technicianID) {
                setTechMovePointsLocations([]);
                setTechnicianAreas([]);
                setTechnicianInformation({});
                setIsEmpty(false);
                getTechLocations();
              } else setIsEmpty(true);
            }}
          >
            {resources.pages.playBackMap.implementation}
          </Button>
          <Dimmer active={isLoading}>
            <Loader />
          </Dimmer>
        </div>
        {isErrorMessageVisible && (
          <Message
            negative
            compact
            content={resources.pages.playBackMap.noPointErrorMessage}
            className="no-points-error-message"
          />
        )}
        <div className="location-slider-container">
          {!isErrorMessageVisible &&
            techMovePointsLocations &&
            Boolean(techMovePointsLocations.length > 0) && (
              <>
                <Input
                  type="range"
                  value={locationId}
                  min={techMovePointsLocations[0].locationID}
                  max={
                    techMovePointsLocations[techMovePointsLocations.length - 1]
                      .locationID
                  }
                  onChange={(e, { value }) => setLocationId(+value)}
                  className="location-slider"
                />
                <div className="location-time-container">
                  <label>{`${resources.pages.playBackMap.date} ${
                    Boolean(selectedLocationInfo.locationDate)
                      ? selectedLocationInfo.locationDate
                      : techMovePointsLocations[0].locationDate
                  } `}</label>
                  <label>،</label>
                  <label>{`${resources.pages.playBackMap.hour} ${
                    Boolean(selectedLocationInfo.locationHour)
                      ? selectedLocationInfo.locationHour
                      : techMovePointsLocations[0].locationHour
                  } `}</label>
                </div>
              </>
            )}
        </div>
      </div>
      <div className="play-back-map-container">
        <PlayBackMap
          techMovePointsLocations={techMovePointsLocations}
          selectedLocationInfo={selectedLocationInfo}
          technicianInformation={technicianInformation}
          technicianAreas={technicianAreas}
        />
      </div>
    </div>
  );
};
export default TechMovePoints;
