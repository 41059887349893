import React, { useState, useEffect } from "react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import TableTemplate from "../../components/Table/TableTemplate";
import { resources } from "../../assets/LocalizationResources";
import {
  Grid,
  Segment,
  Popup,
  Icon,
  Modal,
  Button,
  Input,
  Message,
} from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import "./machineInfo.scss";
import serviceApi from "../../api";
import moment from "moment";
import { jsPDF } from "jspdf";
import SuccessModal from "../../components/SuccessModal";

export default function MachineInfo() {
  const machineExtraDevicesHeaders = {
    machineExtraDevices: resources.pages.machines.extraDevice,
    codeNo: resources.pages.machines.codeNo,
  };
  const machinePartsHeaders = {
    partName: resources.pages.machines.partName,
    partNumber: resources.pages.machines.partNumber,
    quantity: resources.pages.machines.quantity,
    price: resources.pages.machines.price,
  };

  const machineContractsHeaders = {
    contractNumber: resources.pages.addContract.contractNumber,
    contractType: resources.pages.addContract.contractType,
    responsibleTech: resources.pages.machines.technician,
    contractValue: resources.pages.addContract.contractValue,
    counter1: resources.pages.machines.counter1,
    counter2: resources.pages.machines.counter2,
    countersDate: resources.pages.machines.countersDate,
    // maintenanceDays: resources.pages.addContractMachine.maintenanceDays,
    paymantMethod: resources.pages.addContract.PaymentMethod,
    startDate: resources.pages.addContract.startDate,
    endDate: resources.pages.addContract.endDate,
    endContract: resources.pages.addContract.contractStatus,
  };

  const machineBranchAndClientHeaders = {
    //branchNo: resources.pages.branch.branchNo,
    branchName: resources.pages.branch.branchName,
    contactPerson: resources.pages.branch.contacts,
    phone: resources.pages.branch.branchPhoneNumber,
    fax: resources.pages.branch.fax,
    poBox: resources.pages.branch.POBox,
    address: resources.pages.branch.address,
    email: resources.pages.user.email,
    clientName: resources.pages.clients.clientName,
    vip: resources.pages.clients.VIP,
  };

  const location = useLocation();
  const [machineDetails, setMachineDetails] = useState(null);
  const [machineExtraDevices, setMachineExtraDevices] = useState([]);
  const [machineParts, setMachineParts] = useState([]);
  const [machineContracts, setMachineContracts] = useState({});
  const [machineBranchAndClient, setMachineBranchAndClient] = useState([]);
  const [openContractMachinesNotesModal, setOpenContractMachinesNotesModal] =
    useState(false);
  const [contractMachinesNotes, setContractMachinesNotes] = useState("");

  const [contractMachinesPDF, setContractMachinesPDF] = useState("");

  const [isLoadingMachineExtraDevices, setIsLoadingMachineExtraDevices] =
    useState(false);
  const [isLoadingMachineParts, setIsLoadingMachineParts] = useState(false);
  const [isLoadingMachineContracts, setIsLoadingMachineContracts] =
    useState(false);
  const [isLoadingMachineBranchAndClient, setIsLoadingMachineBranchAndClient] =
    useState(false);
  const [selectedContractMachineId, setSelectedContractMachineId] = useState(0);
  const [openEditCountersModal, setOpenEditCountersModal] = useState(false);
  const [newCountersForm, setNewCountersForm] = useState({
    counter1: 0,
    counter2: 0,
  });
  const [newCountersLoading, setNewCountersLoading] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [newCountersErrorVisibile, setNewCountersErrorVisibile] =
    useState(false);

  const getMachineParts = async (machineID) => {
    try {
      setIsLoadingMachineParts(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MachinePart",
          where: { machineId: machineID },
          include: [{ model: "Lookup", attributes: ["LookupName"] }],
        },
      });
      if (res && res.data && res.data.length) {
        setMachineParts(
          res.data.map((row) => {
            return {
              ...row,
              partNumber: row.partNumber
                ? row.partNumber
                : resources.common.notSpecified,
              quantity: row.quantity
                ? row.quantity
                : resources.common.notSpecified,
              price: row.price ? row.price : resources.common.notSpecified,
              partName: row.Lookup.LookupName,
            };
          })
        );
      } else setMachineParts([]);
      setIsLoadingMachineParts(false);
    } catch (error) {
      setIsLoadingMachineParts(false);
      // TODO: Show error message
      console.log(error);
    }
  };

  const getCountersValues = async (contractMachineId) => {
    let counters = {
      counter1: resources.common.notSpecified,
      counter2: resources.common.notSpecified,
      countersDate: resources.common.notSpecified,
    };
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReport",
          limit: 1,
          attributes: ["counter1", "counter2", "StartDate"],
          order: [["StartDate", "desc"]],
          where: { ContractMachineID: contractMachineId },
        },
      });

      if (res && res.data && res.data.length > 0) {
        return {
          counter1: res.data[0].counter1 ? res.data[0].counter1 : 0,
          counter2: res.data[0].counter2 ? res.data[0].counter2 : 0,
          countersDate: res.data[0].StartDate
            ? moment(res.data[0].StartDate).format("DD/MM/YYYY")
            : resources.common.notSpecified,
        };
      } else {
        const res2 = await serviceApi.service("queries").find({
          query: {
            model: "ContractMachine",
            limit: 1,
            attributes: ["LastCounter1", "LastCounter2", "createdAt"],
            order: [["createdAt", "desc"]],
            where: { ContractMachineID: contractMachineId },
          },
        });

        if (res2 && res2.data && res2.data.length > 0) {
          return {
            counter1: res2.data[0].LastCounter1 ? res2.data[0].LastCounter1 : 0,
            counter2: res2.data[0].LastCounter2 ? res2.data[0].LastCounter2 : 0,
            countersDate: res2.data[0].createdAt
              ? moment(res2.data[0].createdAt).format("DD/MM/YYYY")
              : resources.common.notSpecified,
          };
        }
      }
    } catch (e) {
      console.log(e);
    }
    return counters;
  };
  const getMachineContracts = async (machineID) => {
    try {
      setIsLoadingMachineContracts(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          include: [
            {
              model: "Contract",
              include: [
                {
                  model: "Lookup",
                  as: "ContractType",
                  attributes: ["LookupName"],
                },
                {
                  model: "Lookup",
                  as: "PaymentMethod",
                  attributes: ["LookupName", "LookupID"],
                },
              ],
              attributes: [
                "contractNumber",
                "contractDate",
                "contractValue",
                "otherPayMethodNote",
                "startDate",
                "endDate",
                "contractDate",
                "hasPDF",
              ],
            },
            {
              model: "Technicians",
              attributes: ["technicianName"],
            },
          ],
          where: { MachineID: machineID },
        },
      });

      if (res && res.data && res.data.length) {
        let counters = [];
        var { data: maintenanceReportRes } = await serviceApi
          .service("queries")
          .find({
            query: {
              model: "MaintenanceReport",
              attributes: ["MaintenanceReportID"],
              limit: 1,
              where: { ContractMachineID: res.data[0].contractMachineId },
            },
          });

        for (let i = 0; i < res.data.length; i++) {
          counters.push(await getCountersValues(res.data[i].contractMachineId));
        }
        setMachineContracts(
          res.data.map((item, idx) => ({
            ...item,
            contractNumber: (
              <label style={{ margin: "0px 5px" }}>
                {item.Contract.contractNumber}
              </label>
            ),
            contractType: item.Contract.ContractType.LookupName,
            contractValue: item.Contract.contractValue
              ? item.Contract.contractValue
              : resources.common.notSpecified,
            responsibleTech: item.Technician
              ? item.Technician.technicianName
              : resources.common.notSpecified,
            paymantMethod: item.Contract.PaymantMethod
              ? item.Contract.PaymantMethod.LookupID === 59
                ? item.Contract.otherPayMethodNote
                : item.Contract.PaymantMethod.LookupName
              : resources.common.notSpecified,
            startDate: item.Contract.startDate
              ? moment(item.Contract.startDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
            endDate: item.Contract.endDate
              ? moment(item.Contract.endDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
            endContract: item.Contract.endDate
              ? new Date().getTime() > new Date(item.Contract.endDate).getTime()
                ? resources.pages.addContract.endContract
                : resources.pages.addContract.notEndContract
              : resources.common.notSpecified,
            ...counters[idx],
          }))
        );
      } else setMachineContracts([]);
      setIsLoadingMachineContracts(false);
    } catch (error) {
      setIsLoadingMachineContracts(false);
      console.log(error);
    }
  };

  const getMachineExtraDevices = async (machineID) => {
    try {
      setIsLoadingMachineExtraDevices(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MachineExtraDevices",
          where: { machineId: machineID },
          include: [{ model: "Lookup", attributes: ["LookupName"] }],
        },
      });

      if (res && res.data && res.data.length) {
        setMachineExtraDevices(
          res.data.map((item) => ({
            ...item,
            machineExtraDevices: item.Lookup.LookupName,
          }))
        );
      }
      setIsLoadingMachineExtraDevices(false);
    } catch (error) {
      setIsLoadingMachineExtraDevices(false);
      console.log(error);
    }
  };

  const getMachineDetails = async (machineID) => {
    try {
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Machine",
          attributes: [
            "machineId",
            "serialNumber",
            "computerNumber",
            "maintenanceDays",
          ],
          where: { machineId: machineID },
          include: [
            { model: "Lookup", as: "MachineType", attributes: ["LookupName"] },
            { model: "Lookup", as: "MachineModel", attributes: ["LookupName"] },
            {
              model: "Lookup",
              as: "ManufacturingCompany",
              attributes: ["LookupName"],
            },
          ],
        },
      });

      if (res && res.data && res.data.length) {
        const flattenedData = res.data.map((row) => ({
          machineId: row.machineId,
          computerNumber: row.computerNumber,
          MachineModel: row.MachineModel
            ? row.MachineModel.LookupName
            : resources.common.notSpecified,
          MachineType: row.MachineType
            ? row.MachineType.LookupName
            : resources.common.notSpecified,
          ManufacturingCompany: row.ManufacturingCompany
            ? row.ManufacturingCompany.LookupName
            : resources.common.notSpecified,
          serialNumber: row.serialNumber,
          maintenanceDays: row.maintenanceDays
            ? row.maintenanceDays
            : resources.common.notSpecified,
        }));
        setMachineDetails(flattenedData[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMachineBranchAndClient = async (machineID) => {
    try {
      setIsLoadingMachineBranchAndClient(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "ContractMachine",
          include: [
            {
              innerJoin: true,
              model: "ClientBranch",
              attributes: [
                "BranchName",
                "BranchID",
                "Phone",
                "email",
                "ContactPerson",
                "Fax",
                "POBox",
                "Address",
              ],
              include: [
                {
                  innerJoin: true,
                  model: "Client",
                  attributes: ["ClientID", "ClientName", "VIP"],
                },
              ],
            },
          ],
          where: { MachineID: machineID },
        },
      });

      if (res && res.data && res.data.length) {
        setMachineBranchAndClient(
          res.data.map((row) => ({
            ...row,
            branchName: row.ClientBranch.BranchName
              ? row.ClientBranch.BranchName
              : resources.common.notSpecified,
            contactPerson: row.ClientBranch.ContactPerson ? (
              <div className="contact-persons-information-container">
                {JSON.parse(row.ClientBranch.ContactPerson).map(
                  (person, index) => {
                    return (
                      <div key={index} className="contact-persons-information">
                        <label>
                          <span>{resources.common.name + " : "}</span>
                          {person.personName && person.personName.length > 0
                            ? person.personName
                            : resources.common.notSpecified}
                        </label>
                        <span>،</span>
                        <label>
                          <span>{resources.common.phoneNum + " : "}</span>
                          {person.personPhoneNumber &&
                          person.personPhoneNumber.length > 0
                            ? person.personPhoneNumber
                            : resources.common.notSpecified}
                        </label>
                        <span>،</span>
                        <label>
                          <span>{resources.common.email + " : "}</span>
                          {person.personEmail && person.personEmail.length > 0
                            ? person.personEmail
                            : resources.common.notSpecified}
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              resources.common.notSpecified
            ),
            phone: row.ClientBranch.Phone
              ? row.ClientBranch.Phone
              : resources.common.notSpecified,
            fax: row.ClientBranch.Fax
              ? row.ClientBranch.Fax
              : resources.common.notSpecified,
            poBox: row.ClientBranch.POBox
              ? row.ClientBranch.POBox
              : resources.common.notSpecified,
            address: row.ClientBranch.Address
              ? row.ClientBranch.Address
              : resources.common.notSpecified,
            email: row.ClientBranch.email
              ? row.ClientBranch.email
              : resources.common.notSpecified,
            clientName: row.ClientBranch.Client.ClientName
              ? row.ClientBranch.Client.ClientName
              : resources.common.notSpecified,
            vip: row.ClientBranch.Client.VIP
              ? resources.common.yes
              : resources.common.no,
          }))
        );
      } else setMachineBranchAndClient([]);
      setIsLoadingMachineBranchAndClient(false);
    } catch (error) {
      setIsLoadingMachineBranchAndClient(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.machineID)) {
      getMachineDetails(location.state.machineID);
      getMachineExtraDevices(location.state.machineID);
      getMachineParts(location.state.machineID);
      getMachineContracts(location.state.machineID);
      getMachineBranchAndClient(location.state.machineID);
    }
  }, [location]);

  const onCellClick = (row, type) => {
    if (type === "contractMachinesNotes") {
      setContractMachinesNotes(
        row.contractMachineNotes
          ? row.contractMachineNotes
          : resources.common.noNotes
      );
      setOpenContractMachinesNotesModal(true);
    } else if (type === "contractPDF") {
      // if (Boolean(row.Contract.contractPDF)) {
      window.open(`/uploads/${row.ContractID}.pdf`);
      // }
    } else if (type === "editCounter") {
      setSelectedContractMachineId(row.contractMachineId);
      setOpenEditCountersModal(true);
    }
  };

  const handleEditCounters = async () => {
    try {
      setNewCountersLoading(true);
      setNewCountersErrorVisibile(false);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReport",
          limit: 1,
          attributes: [
            "maintenanceReportId",
            "counter1",
            "counter2",
            "StartDate",
          ],
          order: [["StartDate", "desc"]],
          where: { ContractMachineID: selectedContractMachineId },
        },
      });

      if (res && res.data && res.data.length > 0) {
        await serviceApi
          .service("MaintenanceReport")
          .patch(res.data[0].maintenanceReportId, newCountersForm);
      } else {
        await serviceApi
          .service("contractMachine")
          .patch(selectedContractMachineId, {
            lastCounter1: newCountersForm.counter1,
            lastCounter2: newCountersForm.counter2,
          });
      }
      getMachineContracts(location.state.machineID);
      setSuccessVisible(true);
      setNewCountersLoading(false);
    } catch (error) {
      console.log(error);
      setNewCountersLoading(false);
      setNewCountersErrorVisibile(true);
    }
  };

  const handleConfirm = () => {
    setSuccessVisible(false);
  };

  return (
    <Grid>
      <Grid.Column>
        <PageHeaderTitle
          content={resources.pages.machines.machineInfo}
          icon="info"
        />
        <div className="machineInfoSegment-container">
          <p className="machineInfo-tableTitles">
            {resources.pages.ticket.machineDetails}
          </p>
          <Segment.Group horizontal>
            <Segment className="left-border segment-quarter machineInfo-center-text">
              {resources.pages.machines.computerNumber}
            </Segment>
            <Segment className="segment-quarter machineInfo-center-text">
              {resources.pages.machines.serialNumber}
            </Segment>
            <Segment className="segment-quarter machineInfo-center-text">
              {resources.pages.machines.MachineType}
            </Segment>
            <Segment className="segment-quarter machineInfo-center-text">
              {resources.pages.machines.MachineModel}
            </Segment>
            <Segment className="segment-quarter machineInfo-center-text">
              {resources.pages.machines.ManufacturingCompany}
            </Segment>
            <Segment className="segment-quarter machineInfo-center-text">
              {resources.pages.machines.numberOfMaintenanceDays}
            </Segment>
          </Segment.Group>
          {machineDetails && (
            <Segment.Group horizontal>
              <Segment className="left-border segment-quarter machineInfo-center-text">
                {machineDetails.computerNumber}
              </Segment>
              <Segment className="segment-quarter machineInfo-center-text">
                {machineDetails.serialNumber}
              </Segment>
              <Segment className="segment-quarter machineInfo-center-text">
                {machineDetails.MachineType}
              </Segment>
              <Segment className="segment-quarter machineInfo-center-text">
                {machineDetails.MachineModel}
              </Segment>
              <Segment className="segment-quarter machineInfo-center-text">
                {machineDetails.ManufacturingCompany}
              </Segment>
              <Segment className="segment-quarter machineInfo-center-text">
                {machineDetails.maintenanceDays}
              </Segment>
            </Segment.Group>
          )}
          <Grid.Row>
            <p className="machineInfo-tableTitles margin-titleTop">
              {resources.pages.machines.machineBranchAndClientDetails}
            </p>
            <TableTemplate
              headers={machineBranchAndClientHeaders}
              data={machineBranchAndClient}
              isLoading={isLoadingMachineBranchAndClient}
            />
          </Grid.Row>
          <p className="machineInfo-tableTitles margin-titleTop">
            {resources.pages.machines.machineContract}
          </p>
          <TableTemplate
            headers={machineContractsHeaders}
            data={machineContracts}
            isLoading={isLoadingMachineContracts}
            extraCells={[
              {
                key: "contractPDF",
                children: <Icon name="download" color="blue" />,
                isCliCkable: true,
                tooltip: "صورة العقد",
                renderCondition: (row) => {
                  return row.Contract.hasPDF;
                },
              },
              {
                key: "editCounter",
                children: <Icon name="edit outline" color="blue" />,
                isCliCkable: true,
                tooltip: "تعديل العدادات",
              },
              {
                key: "contractMachinesNotes",
                children: <Icon name="sticky note outline" color="blue" />,
                isCliCkable: true,
                tooltip: resources.pages.machines.machineNote,
              },
            ]}
            onCellClick={(row, type) => onCellClick(row, type)}
          />
          <Grid.Row>
            <Grid columns={2}>
              <Grid.Column>
                <p className="machineInfo-tableTitles margin-titleTop">
                  {resources.pages.machines.machineExtraDevices}
                </p>
                <TableTemplate
                  headers={machineExtraDevicesHeaders}
                  data={machineExtraDevices}
                  isLoading={isLoadingMachineExtraDevices}
                />
              </Grid.Column>

              <Grid.Column>
                <p className="machineInfo-tableTitles margin-titleTop">
                  {resources.pages.machines.MachineParts}
                </p>
                <TableTemplate
                  headers={machinePartsHeaders}
                  data={machineParts}
                  isLoading={isLoadingMachineParts}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </div>
      </Grid.Column>
      <Modal size="mini" open={openContractMachinesNotesModal}>
        <Modal.Header>
          {resources.pages.machines.contractMachineNotes}
        </Modal.Header>
        <Modal.Content>
          <div className="contract-machines-notes-container">
            <p>{contractMachinesNotes}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setContractMachinesNotes("");
              setOpenContractMachinesNotesModal(false);
            }}
          >
            {resources.common.close}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="large"
        open={openEditCountersModal}
        onClose={() => setOpenEditCountersModal(!openEditCountersModal)}
      >
        <Modal.Header>{resources.pages.machines.editCounters}</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <label>{resources.pages.maintenanceReport.counter1}</label>
              <Input
                value={newCountersForm.counter1}
                onChange={(e, { value }) => {
                  setNewCountersForm({
                    ...newCountersForm,
                    counter1: value,
                  });
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDiraction: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <label>{resources.pages.maintenanceReport.counter2}</label>
              <Input
                value={newCountersForm.counter2}
                onChange={(e, { value }) => {
                  setNewCountersForm({
                    ...newCountersForm,
                    counter2: value,
                  });
                }}
              />
            </div>
          </div>
          {newCountersErrorVisibile && (
            <Message error size="small">
              <Message.Header>
                {resources.common.errors.genericServerError}
              </Message.Header>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            labelPosition="left"
            onClick={handleEditCounters}
            content={resources.common.confirm}
            icon="check"
            loading={newCountersLoading}
          />
          <Button
            content={resources.common.cancel}
            labelPosition="left"
            onClick={() => {
              setNewCountersForm({
                counter1: 0,
                counter2: 0,
              });
              setOpenEditCountersModal(false);
              setNewCountersErrorVisibile(false);
            }}
            icon={"close"}
            loading={newCountersLoading}
          />
        </Modal.Actions>
      </Modal>

      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
    </Grid>
  );
}
