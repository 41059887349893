import React from 'react';
import { Modal } from 'semantic-ui-react';
import { resources } from '../assets/LocalizationResources';

const SuccessModal = ({ visible, onConfirm, content = '', header = '', size = 'mini' }) => {
	return (
		<Modal
			open={visible}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			{...(header ? { header: header } : {})}
			{...(content ? { content: content } : {})}
			size={size}
			centered
			actions={[
				{
					content: resources.common.ok,
					key: 'ok',
					color: 'green',
					icon: 'check',
					labelPosition: 'left',
					onClick: onConfirm,
				},
			]}
		/>
	);
};

export default SuccessModal;
