import LocalizedStrings from "react-localization";

export let resources = new LocalizedStrings({
  ar: {
    tesco: "تيسكو",
    systemSubtitle: "نظام الصيانة",
    hello: "مرحبًا ،",
    pages: {
      user: {
        addNewUser: "إضافة مستخدم جديد",
        editUser: "تعديل بيانات مستخدم ",
        deactiveUser: "تعطيل مستخدم",
        userSituation: "تحديث وضع المستخدم",
        userList: "إدارة المستخدمين",
        changePassword: "تغيير كلمة المرور",
        username: "اسم الدخول",
        password: "كلمة المرور",
        confirmPassword: "تأكيد كلمة المرور",
        newPassword: "كلمة المرور الجديدة",
        confirmNewPassword: "تأكيد كلمة المرور الجديدة",
        fullname: "الاسم الكامل",
        phone: "الهاتف",
        email: "البريد الالكتروني",
        userPermission: "صلاحيات المستخدم",
        permissionChoose: "اختر مجموعة الصلاحيات",
        disableUser: "تعطيل المستخدم",
        disableUserMessage:
          "هذا الإجراء سوف يقوم بتعطيل هذا المستخدم، هل أنت متأكد من ذلك؟",
        activateUser: "تفعيل المستخدم",
        activateUserMessage:
          "هذا الإجراء سوف يقوم بتنشيط هذا المستخدم، هل أنت متأكد من ذلك؟",
        leavesType: "نوع المغادرة أو الإجازة",
        showUnActiveUsers: "إظهار المستخدمين المُعطَّلين",
        externalUser: "مستخدم خارجي",
        errors: {
          passConfirm: "كلمة المرور غير مطابقة",
          passLength: "كلمة المرور قصيرة",
          phone: "رقم الهاتف يجب ان يبدء ب 96277/96278/96279",
          phoneLength: "الرقم يجب أن يتكون من 12 خانات",
          duplicateUser: "اسم الدخول مستخدم مسبقاً",
        },
      },
      machines: {
        pageTitle: "إدارة الآلات",
        addMachine: "إضافة آلة جديدة",
        machineContract: "تفاصيل العقد الخاص بالآلة",
        editMachine: "تعديل بيانات الآلة",
        machineExtraDevices: "الأجهزة الإضافية الخاصة بالآلة",
        machineInfo: "معلومات الآلة",
        serialNumber: "الرقم التسلسلي",
        computerNumber: "رقم الكمبيوتر ",
        deviceCode: "الكود الخاص بالجهاز",
        enterDeviceCode: "يرجى إدخال الكود الخاص بالجهاز",
        cardNo: "رقم البطاقة",
        notes: "ملاحظات",
        extraDevice: "الجهاز الإضافي",
        codeNo: "الكود الخاص بالجهاز",
        addDevices: "الأجهزة الإضافية",
        invoiceNumber: "رقم الفاتورة",
        invoiceDate: "تاريخ الفاتورة",
        invoiceValue: "قيمة الفاتورة",
        lastMaintenaceDate: "تاريخ اخر صيانة",
        lastCounter1: "العداد الأول",
        lastCounter2: "العداد الثاني",
        serviceTime: "وقت الخدمة",
        MachineModelID: "موديل الآلة",
        MachineTypeID: "نوع الآلة",
        firstInstallationDate: "تاريخ التركيب ",
        seller: "البائع",
        warrantyEndDate: "تاريخ إنتهاء الكفالة",
        MachineModel: "موديل الآلة",
        MachineType: "نوع الآلة",
        ManufacturingCompany: "الشركة المصنعة",
        technician: "الفني المسؤول",
        MachineParts: "قطع الآلة",
        part: "القطعة",
        partName: "إسم القطعة",
        partNumber: "رقم القطعة",
        price: "السعر",
        quantity: "الكمية",
        chooseExtraDevice: "يرجى إختيار الجهاز الإضافي",
        machineBranchAndClientDetails: "تفاصيل الفرع والعميل المرتبطين بالآلة",
        contractMachineNotes: "ملاحظات العقد الخاص بهذه الآلة",
        machineNote: "ملاحظات الآلة",
        counter1: "عداد (أبيض وأسود) الحالي",
        counter2: "عداد (ملون) الحالي",
        numberOfMaintenanceDays: "عدد أيام الصيانة",
        countersDate: "تاريخ قراءة العدادات",
        notConnectedWithClient: "غير مرتبط بعميل",
        showNotAssociatedMachines: "عرض الآلات الغير مرتبطة بعميل",
        editCounters: "تعديل العدادات",
        createInvoice: "إصدار فاتورة",
        error: {
          duplicateMachine: "الرقم التسلسلي موجود مسبقا",
        },
      },
      contracts: {
        pageTitle: "إدارة العقود",
        addContract: "إضافة عقد جديد",
        duplicateContract: "العقد موجود مسبقاً",
        notSubjectToSLA: "لا يخضع لنظام الSLA",
        contractRenewal: "تمديد العقد",
        extends: "تمديد",
        attention: "تحذير",
        associatedMachineAttentionMessage:
          "يحتوي هذا العقد على الاّلات موجودة في عقود اخرى (او غير فعالة), يمكنك الاطلاع عليها بالجدول",
      },
      addContract: {
        pageTitle: "إضافة عقد جديد",
        contractNumber: "رقم العقد",
        SLA: "يخضع لنظام الSLA",
        endContract: "منتهي",
        notEndContract: "جاري",
        contractStatus: "حالة العقد",
        every: "كل",
        hours: " ساعات ",
        dueHours: "موعد تلبية الإتصال",
        startDate: "تاريخ البداية",
        endDate: "تاريخ النهاية",
        contractDate: "تاريخ العقد",
        contractValue: "قيمة العقد",
        client: "العميل",
        contractType: "نوع العقد",
        PaymentMethod: "طريقة الدفع",
        notes: "ملاحظات",
        duplicateContractNumber: "رقم العقد مستخدم مسبقا",
        otherPayMethodInfo: "يرجى إدخال طريقة الدفع الأخرى",
      },
      editContract: {
        pageTitle: "تعديل معلومات عقد",
        edit: "تعديل",
      },
      addContractMachine: {
        pageTitle: "آلات العقد",
        installationDate: "تاريخ التحميل",
        address: "الموقع",
        addressDetails: "تفاصيل الموقع",
        copyPrice: "سعر النسخة",
        copyPriceBlackAndWhite: "سعر النسخة (ابيض + اسود) بالفلس",
        copyPriceColorful: " سعر النسخة (ملون) بالفلس",
        startCounter: "العداد الاولي",
        addMachine: "إضافة آلة",
        contractMachines: "الآت العقد ",
        MachineID: "رقم الآلة",
        invoiceNumber: "رقم الفاتورة",
        invoiceDate: "تاريخ الفاتورة",
        invoiceValue: "قيمة الفاتورة",
        maintenanceDays: "ايام الصيانة",
        maintenanceCopies: "عدد النسخ",
        maxAllowedDays: "عدد الايام المسموح بها",
        maxAllowedCopies: "عدد النسخ المسموح بها",
        lastMaintenaceDate: "تاريخ اخر صيانة",
        lastCounter1: "العداد الأول",
        lastCounter2: "العداد الثاني",
        monthlyFees: "الاجرة الشهرية",
        minimumNumberOfCopies: "الحد الأدنى من التصوير",
        numberOfFreeCopies: "عدد النسخ المجانية",
        editContractMachine: "تعديل آلة العقد",
        addMachineToContract: "إضافة آلة للعقد",
        associatedMachineErrorMessage: "هذه الآلة تنتمي إلى عقد آخر فعّال",
        pleaseCheckTheEnteredMachineNumber:
          "يرجى التأكد من رقم الكمبيوتر المُدخل",
      },
      clients: {
        pageTitle: "إدارة الفروع والعملاء",
        addClient: "إضافة عميل جديد",
        editClient: "تعديل عميل ",
        clientName: "إسم العميل",
        clientSituation: "حالة العميل",
        vip: "عميل خاص",
        VIP: "عميل مميز",
      },
      technicians: {
        pageTitle: "إدارة الفنيين ",
        addTechnician: "إضافة فني جديد",
        technicianName: "إسم الفني",
        chooseAlternate: "يرجى اختيار الفني/الفنيين البدلاء",
      },
      technicianAreas: {
        pageTitle: "إضافة مناطق عمل الفني",
        technician: "الفني",
        area: "المنطقة",
        addArea: "إضافة منطقة",
        areas: "المناطق المحددة",
        addRemoveAreas: "إضافة/حذف مناطق",
        doYouWantToremove: ":هل أنت متأكد من حذف المناطق التالية",
        doYouWantToAdd: ":هل أنت متأكد من إضافة المناطق التالية",
      },
      technicianAreasList: {
        pageTitle: "مناطق عمل الفنيين",
        addTechAreas: "ربط فني بمناطق العمل",
        notAvailable: "غير متوفر",
      },
      ManageTechnicians: {
        pageTitle: "إضافة فني جديد",
        technicianName: "اسم الفني",
        phone: "رقم الهاتف",
        email: "البريد الالكتروني",
        machineIdNotRequiredToCloseTicket: "رقم الجهاز غير مطلوب لاغلاق التكت",
        chooseName: "يرجي اختيار اسم المستخدم",
        userName: "اسم المستخدم",
        notAssociatedWithUser: "غير مرتبط بمستخدم",
        requiredForCloseTicket: "مطلوب",
        editTechInfo: "تعديل معلومات الفني",
        notRequiredForCloseTicket: "غير مطلوب",
        sorryUserIsTech: "عذراً ولكن هذا المستخدم هو بالفعل فني!",
        salesmanID: "رقم المندوب",
      },
      area: {
        pageTitle: "إضافة منطقة جغرافية",
        areaName: "اسم المنطقة الجغرافية",
        duplicateArea: "اسم المنطقة الجغرافية مستخدم مسبقاً",
        drawArea: "الرجاء رسم المنطقة المراد حفظها",
        creationMethod: "يرجى إختيار طريقة الإنشاء",
        choosePlease: "يرجى الإختيار",
        borders: "حدود",
        landMarks: "معالم",
        searchLandmarks: "إبحث عن معلم ...",
        creationWay: "طريقة الإنشاء",
        radius: "نصف القطر (كم)",
        editGeoArea: "تعديل منطقة جغرافية",
      },
      areaList: {
        pageTitle: " المناطق الجغرافية",
        addArea: "إضافة منطقة جغرافية جديدة",
        areaName: "المنطقة الجغرافية",
      },
      ticket: {
        CustomerSerial: "الرقم التسلسلي للعميل",
        externalTicketNotes:
          "في حال عدم وجود الرقم التسلسلي يرجى إضافة الرقم في هذه الخانة",
        additionalInfo: "معلومات إضافية",
        addPageTitle: "إضافة تذكرة جديدة",
        editPageTitle: "تعديل تذكرة ",
        lateForDueHours: "التأخر عن موعد تلبية الإتصال",
        contactName: "اسم المتصل  ",
        showSLA: "إظهار SLA",
        orderByDistance: "ترتيب حسب المسافات",
        km: " كم ",
        distance: "المسافة ",
        showClosed: "إظهار المغلق",
        showLate: "إظهار المتأخر",
        ticketDate: "تاريخ التذكرة",
        main: "رئيسي - ",
        notLate: "غير متأخر",
        late: "متأخر",
        alter: "بديل - ",
        contactPhone: "هاتف المتصل",
        notes: "ملاحظات التذكرة",
        clientName: "اسم العميل",
        machineSerialNumber: "الرقم التسلسي للآلة",
        ticketID: "رقم التذكرة",
        ticketsList: "قائمة التذاكر",
        branchName: "اسم الفرع",
        activeTickets: "التذاكر الفعالة",
        lastVisit: "آخر زيارة دورية : ",
        NextVisit: "الزيارة القادمة ",
        callCenter: "كشف الإتصالات/التذاكر",
        serialNumber: "رقم الآلة",
        warningSLA:
          "   تحذير : هذا العقد خاضع لنظام الSLA حيث يجب مراجعته خلال  ",
        clientDetails: "تفاصيل العميل ",
        machineDetails: "تفاصيل الالة",
        branchDetails: "تفاصيل الفرع",
        contractDetails: "تفاصيل العقد",
        callType: "نوع الاتصال",
        reason: "نوع المشكلة",
        openSince: "مؤقت فتح التذكرة",
        ticketStatus: "حالة التذكرة",
        userType: "نوع المستخدم",
        ticketLevel: "مستوى  خطورة التذكرة",
        level: "المستوى",
        closeTicket: "إغلاق التذكرة",
        changeTech: "تحويل لفني جديد",
        notSpecifiedBranch: "فرع غير محدد",
        notSpecifiedClient: "عميل غير محدد",
        notSpecifiedMachNumber: "رقم الآلة غير محدد",
        notSpecifiedProblem: "نوع المشكلة غير محدد",
        showOnMap: "عرض على الخريطة",
        determineDirections: "تحديد إتجاهات المسار",
        determineDirectionsUsingGoogle: "تحديد الإتجاهات بإستخدام جوجل",
        takeAction: "إتخاذ إجراء للتذكرة",
        ticketNote: "ملاحظات الحالة الخاصة بالتذكرة",
        emptyStatusError: "يجب عليك أولاً إختيار حالة للتذكرة",
        showMap: "إظهار الخريطة",
        dueHourseWithoutSLA:
          "هذا العقد غير خاضع لنظام الSLA حيث يجب تلبيته خلال",
        insideAmman: "داخل عمان (24) ساعة",
        outsideAmman: "خارج عمان (48) ساعة",
        ticketsCalls: "التذاكر/الإتصالات",
        periodicVisits: "الزيارات الدورية",
      },
      branch: {
        addBranch: "إضافة فرع جديد",
        editBranch: "تعديل الفرع",
        main: "رئيسي",
        branchList: "قائمة فروع العملاء",
        branchNo: "رقم الفرع",
        branchName: "اسم الفرع",
        clientName: "اسم العميل",
        contactPerson: "التواصل مع",
        phone: "رقم الهاتف",
        address: "العنوان",
        fax: "الفاكس",
        POBox: "صندوق البريد",
        zipCode: "الرمز البريدي",
        city: "المدينة",
        branchPhoneNumber: "رقم الهاتف الخاص بالفرع",
        editContactPersons: "تعديل جهات التواصل",
        addContactPersons: "إضافة جهات التواصل",
        personName: "إسم الشخص",
        contacts: "جهات التواصل",
        noContactBranchData: "عذراً، ولكن لا يوجد جهات تواصل لهذا الفرع.",
        fillAllFieldsError: "يجب إدخال جميع الحقول.",
        personEmail: "إيميل الشخص",
        updateBranchCoords: "تحديث إحداثيات موقع الفرع",
      },
      leave: {
        leaveList: "قائمة المغادرات والإجازات",
        addLeave: "إضافة مغادرة أو إجازة",
        editLeave: "تعديل مغادرة أو إجازة",
      },
      lookup: {
        subTitle: "إضافة رموز النظام",
        addLookup: "أضف رمز نظام جديد",
        lookUpTableTitle: "إسم رمز النظام",
        chooseLookUp: "يرجى إختيار رمز النظام",
      },
      tablet: {
        addTablets: "إضافة أجهزة لوحية",
        manageTablets: " إدارة الأجهزة اللوحية ",
        addNewTablet: "إضافة جهاز جديد",
        tabletUserName: "إسم المستخدم",
        imeiNumber: "رقم الIMEI",
        phoneNumber: "رقم الهاتف",
        notAssociatedWithaUser: "غير مرتبط بمستخدم",
        chooseUserName: "يرجي اختيار اسم المستخدم",
        dublicateIMEI: "عذراً، رقم الIMEI موجود مسبقاً",
        editTabletInfo: "تعديل معلومات الجهاز",
        errors: {
          IMEILength: "رقم الIMEI يجب أن يتكون من 15 خانة",
        },
      },
      sidbar: {
        home: "الرئيسية",
        callCenter: "كشف الإتصالات /التذاكر",
        ticketsList: "التذاكر",
        maintenanceReport: "تقارير الصيانة",
        areasList: "المناطق الجغرافية",
        settings: "الإعدادات",
        reports: "التقارير",
      },
      permissions: {
        groupPermissions: "إدارة  صلاحيات المستخدمين",
        addGroup: "إضافة مجموعة صلاحيات",
        editGroup: "تعديل بيانات مجموعة الصلاحيات",
        deleteGroup: "حذف مجموعة صلاحيات",
        addUserGroups: "إضافة مجموعة صلاحيات للمستخدمين",
        groupName: "اسم المجموعة",
        permissions: "الصلاحيات",
        errorSave: "يجب اختيار صلاحية واحدة على الأقل ",
      },
      settings: {
        users: "إدارة المستخدمين",
        clientBranches: "إدارة فروع العملاء",
        technicians: "إدارة الفنيين ",
        machines: "إدارة الآلات ",
        techniciansArea: "إدارة مناطق عمل الفنيين ",
        contracts: "إدارة  العقود  ",
        UserLeaves: "إدارة  الاجازات والمغادرات  ",
        lookup: "إدارة رموز النظام ",
        tablets: "إدارة الأجهزة اللوحية",
      },
      maintenanceReport: {
        pleaseAddMaintenanceReportId: "الرجاء ادخال رقم تقرير الصيانة",
        theDifferenceBetweenCounter1AndLastCounter1:
          "الفرق بين عداد (أبيض وأسود) السابق والحالي:",
        withoutInvoice: "من دون فاتورة",
        youCanNotCreateInvoiceWithoutConfirmInvoiceCounters:
          "ليتم انشاء فاتورة, يرجى الضغط عى إصدار الفاتورة والتأكيد على العدادات السابقة المدخلة",
        thereIsPartsYouAddedDosentHavePartNumberAndDescription:
          "لإصدار فاتورة قطع، يجب أن تحتوي جميع القطع المختارة على وصف ورقم القطعة، و أن لا يكونا مدخلين بشكل يدوي",
        pleaseChoseBetweenTheCreateItemMaintenanceInvoiceAndStatisticalOptions:
          " الرجاء الاختيار بين اصدار فاتورة قطع او احصائي ",
        onlyPartNumberAndPartDetailsOptional:
          "فقط حقل القطعة يمكن ان يكون خالياً",
        onlyPartNumberAndPartDescriptionOptional:
          "فقط حقل رقم القطعة و حقل وصف القطعة ان يكون خالياً",
        createItemMaintenanceInvoice: "اصدار فاتورة قطع",
        statistical: "احصائي",
        itemAlreadySelected: "القطعة مختارة مسبقاً",
        pleaseSelectAnotherItem: "القطعة مختارة مسبقاً, يرجى اختيار قطعة آخرى",
        machinePartInvoiceNumber: "رقم فاتورة القطع",
        machinePartInvoice: "فاتورة قطع",
        invoiceAmount: "قيمة الفاتورة",
        editInvoice: "تعديل الفاتورة",
        goToA4MaintenanceReport: "الذهاب إلى صفحة تقرير A4",
        goToZebraMaintenanceReport: "الذهاب إلى صفحة تقرير Zebra",
        zeroCopyPriceWarningModalBody:
          "يرجى الانتباه أن سعر الصورة صفر لذلك في حال كنت تريد إصدار فاتورة يرجى مراجعة مدير النظام لتغيير القيمة",
        countersMismatchValidationError:
          "عدد الفاتورة السابق المدخل أكبر او يساوي العداد الحالي يرجى مراجعة المدخلات",
        "alpha-error-technician-not-linked":
          "الفني غير مرتبط  مع نظام Alpha بشكل صحيح و لذلك لن يتم إنشاء تقرير صيانة حتى تحل المشكلة , يرجى مراجعة مدير النظام لحلها ",
        "alpha-error-machine-barcode-not-defined":
          "ال barcode للألة غير معرف في نظام Alpha ولذلك لن يتم إنشاء تقرير صيانة حتى تحل المشكلة , يرجى مراجعة مدير النظام لحلها",
        "alpha-error-machine-model":
          "سعر الصورة للألة غير معرف في نظام Alpha ولذلك لن يتم إنشاء تقرير صيانة حتى تحل المشكلة , يرجى مراجعة مدير النظام لحلها",
        counterWarningModalBody:
          " العداد الحالي المدخل أصغر أو يساوي العداد السابق الموجود في أخر تقرير يرجى مراجعة المدخلات",
        countersDontMatch: "العدادات غير متطابقة",
        maintenanceReports: "تقارير الصيانة",
        maintenanceReport: "تقرير كلفة وصيانة",
        maintenanceReportID: "رقم تقرير الصيانة",
        oldMaintenanceReportNum: "رقم التقرير القديم",
        manualReportNumber: "رقم التقرير اليدوي",
        machineInfo: "معلومات الآلة",
        machineID: "رقم الآلة",
        counters: "العدادات",
        machinePartsTable: "جدول القطع",
        damageDetails: "تفاصيل العطل",
        clientInfo: "معلومات العميل",
        wrongCounters: "خطأ في العدادات",
        confirmMaintenanceType:
          "هذا الاختيار سيؤدي إلى إنشاء فاتورة لاحقا, هل أنت متأكد من هذا الخيار؟",
        computerNumber: "رقم الكمبيوتر",
        machineModel: "موديل الآلة",
        machineType: "نوع الآلة",
        serialNumber: "الرقم المتسلسل",
        location: "موقع الاستخدام",
        area: "المنطقة",
        visitType: "نوع الزيارة",
        maintenanceType: "نوع الصيانة",
        reportDelivery: "تسليم التقرير",
        reportStatus: "حالة التقرير",
        firstCounter: "العداد(1)",
        secondCounter: "العداد (2)",
        enterCounterValue: "يرجى ادخال قيمة العداد",
        startTime: "ساعة البداية",
        endTime: "ساعة النهاية",
        addNewItem: "اضافة عنصر جديد",
        item: "العنصر",
        quantity: "الكمية",
        description: "الوصف",
        add: "اضافة",
        cancel: "الغاء",
        damage: "العطل",
        enterDamage: "يرجى ادخال العطل",
        cause: "السبب",
        enterCause: "يرجى ادخال السبب",
        action: "الاجراء",
        enterAction: "يرجى ادخال الاجراء",
        technicianNotes: "ملاحظات الفني",
        enterTechnicianNotes: "يرجى ادخال ملاحظات الفني",
        userNotes: "ملاحظات المستخدم",
        enterUserNotes: "يرجى ادخال ملاحظات المستخدم",
        employeeName: "اسم الموظف/العميل",
        enterEmployeeName: "يرجى ادخال اسم الموظف/العميل",
        job: "الوظيفة",
        enterJob: "يرجى ادخال الوظيفة",
        maintenanceReportSatus: "حالة تقرير الصيانة",
        next: "التالي",
        close: "اغلاق",
        save: "حفظ",
        search: "بحث",
        clientName: "اسم العميل",
        maintenanceDate: "تاريخ الصيانة",
        enteranceDate: "تاريخ الادخال",
        manualReportNumber: "رقم التقرير اليدوي",
        technicianName: "اسم الفني",
        branchName: "إسم الفرع",
        SerialNumber: "الرقم المتسلسل",
        printOrSendMaintenanceReport: "طباعة/إرسال تقرير الصيانة",
        printMaintenanceReport: "طباعة تقرير الصيانة",
        emailMaintenanceReport: "ارسال تقرير الصيانة عبر الايميل",
        printMaintenanceInvoice: "طباعة فاتورة الصيانة",
        invoice: "فاتورة الصيانة",
        lastCounter1: "عداد (أبيض وأسود) السابق : ",
        counter1: "عداد (أبيض وأسود) الحالي : ",
        counter1Confirm: " تأكيد عداد (أبيض وأسود) : ",
        lastCounter2: "عداد (ملون) السابق : ",
        counter2: "عداد (ملون) الحالي : ",
        counter2Confirm: "عداد (ملون) السابق :",
        counter1Difference: "الفرق بين عداد (أبيض وأسود) السابق والحالي :",
        counter2Difference: "الفرق بين عداد (ملون) السابق والحالي :",
        blackAndWhiteCounter: "عداد (أبيض وأسود)",
        colorfulCounter: "عداد (ملون)",
        currentCounterRead: "قراءة العداد الحالية",
        previousCounterRead: "قراءة العداد السابقة",
        siteArea: "موقع الإستخدام",
        request: "طلب",
        cyclic: "دوري",
        other: "أخرى",
        reportNumber: "رقم التقرير",
        date: "التاريخ",
        delivery: "تسليم",
        contract: "عقد",
        free: "مجاني",
        rent: "تأجير/إصدار فاتورة",
        startHour: "ساعة البداية",
        endHour: "ساعة النهاية",
        partsMaterialTable: "جدول القطع/المواد",

        issue: "العطل",
        reason: "السبب",
        procedure: "الإجراء",
        empName: "إسم الموظف",
        signature: "التوقيع",
        machineReportNeeds:
          "سيتم تزويدكم بعرض سعر اذا اشار التقرير الى حاجة الآلة لقطع ومستلزمات ، وحسب طلبكم",
        stampAndSignature: "الختم والتوقيع",
        reportValidity: "صلاحية التقرير أسبوع",
        machineReceived:
          "إستلمت الآلة بحالة جيدة مع توابعها بحضور الفني المختص",
        costReport:
          "إستلمت تقرير التكلفة مع العلم بأن التقرير أعلاه مبدئي بكلفة الإصلاح، والتي قد تزيد أو تقل عند القيام بعملية الإصلاح الفعلية",
        acheivedCopies: "عدد النسخ المنجزة",
        maintenanceReportCost: "تقرير الكلفة والصيانة",
        contractIsEndedAttentionMessage:
          "يرجى الإنتباه بأن هذه الآلة تنتمي إلى عقد منتهي.",
        emptySigError: "يجب أن توقع أولاً ",
        clientSignature: "توقيع العميل",
        takeClientCardPhoto: "إلتقاط صورة لبطاقة العميل",
        editClientCardPhoto: "تعديل صورة بطاقة العميل",
        contractNumber: "رقم العقد",
        contractType: "نوع العقد",
        operator: "المشغل",
        scattered: "متفرقة",
        requestAndPeriodic: "طلب + دوري",
        pleaseChoosePart: "يرجى إختيار قطعة",
        numberNotFoundErrorMessage: "الرقم غير موجود",
        pleaseCheckTheEnteredNumber: "الرجاء التحقق من الرقم المدخل !",
        sendByEmail: "عبر البريد الالكتروني",
        giveByHand: "باليد",
        printOrSendITMaintenanceReport: "طباعة/إرسال تقرير الصيانة نسخة الIT",
        manualReportInfo: "معلومات التقرير اليدوي",
        invoices: "الفواتير",
      },
      createMaintenanceReport: {
        createMaintenanceReport: "انشاء تقرير صيانة",
        reportsWithInvoice: "جدول الفواتير",
      },
      contractPayment: {
        addPayment: "إضافة دفعة",
        editPayment: "تعديل دفعة",
        paymentDate: "تاريخ الدفعة",
        paymentAmount: "المبلغ",
        deservedDate: "تاريخ الفاتورة",
        invoiceNum: "رقم الفاتورة",
        isDeserved: "إستُحِق",
        contractPayments: "دفعات العقد",
      },
      playBackMap: {
        techsMovePoints: "نقاط تحرك الفنيين",
        implementation: "تنفيذ",
        chooseTech: "يرجى إختيار الفني",
        noPointErrorMessage:
          "عذراً، ولكن لا توجد نقاط تحرك لهذا الفني بين هذه التواريخ.",
        address: "العنوان",
        date: "التاريخ : ",
        techMovePoints: "معلومات نقاط التحرك الخاصة بالفني",
        hour: "الساعة : ",
        workAreaName: "إسم منطقة العمل : ",
        techName: "إسم الفني : ",
        techMobile: "رقم الهاتف : ",
        techEmail: "الإيميل الخاص بالفني : ",
        showAreaOnMap: "عرض المنطقة على الخريطة",
      },
      loginPage: {
        userName: "إسم المستخدم",
        password: "كلمة السر",
        signIn: "تسجيل الدخول",
        maintenanceContractSystem: "نظام عقود الصيانة",
        loginErrorMessage: "إسم المستخدم أو كلمة السر غير صحيحة",
      },
      maintenanceReportDetails: {
        maintenanceReportNumber: "تقرير كلفة وصيانة رقم",
        manualReportNumber: "رقم التقرير اليدوي",
        reportInfo: "معلـومات التقرير",
        reportCreator: "منشئ التقرير",
        maintenanceReportCreatedAt: "تاريخ إنشاء التقرير",
        visitType: "نوع الزيارة",
        maintenanceType: "نوع الصيانة",
        clientInfo: "معلـومات العميل",
        client: "العميل",
        clientNameJob: "إسم الموظف/العميل",
        clientEmployeeJob: "الوظيفية",
        machineInfo: "معلـومات الآلة",
        respTech: "الفني المسؤول",
        computerNumber: "رقم الكمبيوتر",
        machineType: "نوع الآلة",
        machineModel: "موديل الآلة",
        serialNumber: "الرقم التسلسلي",
        branchName: "إسم الفرع",
        branchArea: "المنطقة",
        images: "الصور",
        blackAndWhiteCounter: "العداد الأبيض والأسود",
        colorfulCounter: "العداد الملون",
        clientCardImage: "بطاقة العميل",
        maintenanceInfo: "معلـومات الصيانة",
        counterDetails: "تفاصيل العدادات",
        startHour: "ساعة البداية",
        endHour: "ساعة النهاية",
        damage: "العطل",
        reason: "السبب",
        action: "الإجراء",
        contractInfo: "معلـومات العقد",
        contractNumber: "رقم العقد",
        contractType: "نوع العقد",
        startDate: "تاريخ البداية",
        endDate: "تاريخ النهاية",
        contractSituation: "حالة العقد",
        clientSignature: "توقيع العقد",
        manualReportImg: "صورة العقد اليدوي",
      },
      contractMachineEditRequests: {
        editRequest: "طلب التعديل",
        editRequests: "طلبات التعديل",
        showClosedEditRequests: "إظهار طلبات التعديل المغلقة",
        closeEditRequest: "إغلاق طلب التعديل",
        editMachineInfo: "تعديل معلومات الآلة",
        editClientInfo: "تعديل معلومات العميل",
        closeEditRequestMessage: "هل أنت متأكد من إغلاق هذا الطلب ؟",
        editRequestSentSuccessfully: "تم إرسال طلب التعديل بنجاح",
        pleaseEnterRequiredModifications:
          "يرجى كتابة التعديلات المطلوب تعديلها",
      },
      reports: {
        partsMovementReport: "بطاقة حركة القطع على الآلة",
        maintenanceCard: "بطاقة صيانه",
        periodEndedContracts: "العقود التي ستنتهي خلال فترة محددة",
        notDeservedContracts: "العقود ومدة المطالبة (غير مستحقة)",
        techAreasAndMachinesNum: "مناطق الفني وعدد الآلآت التي لها عقود",
        techMachinesWithContracts: "آلات الفني التي لها عقود",
        clientMachinesInfo: "المعلومات العامة عن آلات العميل",
        machinesModelClient: "تقرير عام عن الآلات حسب الموديل/العميل",
        generalMachineInfo: "تقرير عام عن الآلات",
        machinesThatHaveContractWithVisits:
          "الالات التي لها عقود حسب الفني مع الزيارات",
        machinesThatHaveContractWithVisitsBasedOnTech:
          "معلومات الالات التي لها عقود حسب الفني والزيارات",
        machinesContarctTechnician: "الالات التي لها عقود حسب الفني",
        numberOfTechVisits: "عدد زيارات الفني",
        finishedContracts: "العقود المنتهية التي لم تجدد",
        lateTickets: "الإتصالات المتأخرة",
        repeatedTickets: "الإتصالات المتكررة",
        periodicalVisits: "بطاقة الالة للزيارات الدورية",
        invoicesReport: "تقرير الفواتير",
        description: {
          partsMovementReportDesc:
            "يعرض التقرير جميع حركات قطع الغيار على الآلة",
          maintenanceCardDesc:
            "يعرض التقرير جميع عمليات الصيانة والمحاسبه على الالة",
          periodEndedContractsDesc:
            "يعرض التقرير تفاصيل جميع العقود التي سنتهي خلال فترة محددة",
          notDeservedContractsDesc:
            "يعرض التقرير تفاصيل جميع العقود الغير مستحقة",
          techAreasAndMachinesNumDesc:
            "يعرض التقرير جميع مناطق الفني التي لها آلات مرتبطة بعقود مع عدد الآلات لكل منطقة",
          techMachinesWithContractsDesc:
            "يعرض التقرير تفاصيل جميع آلات الفني المرتبطة بعقود",
          machinesModelClientDesc:
            "يعرض هذا التقرير جميع الالات لعميل محدد او موديل محدد",
          clientMachinesInfoDesc:
            "يعرض التقرير تفاصيل جميع الآلات المرتبطة بعميل معين",
          machinesModelDesc:
            " يعرض هذا التقرير تفاصيل جميع الآلات التي تنتمي إلى موديل معين او عميل معين",
          generalMachineInfoDesc: "يعرض هذا التقرير تفاصيل معلومات عن الاّلاّت",
          machinesThatHaveContractWithVisitsDesc:
            "يعرض هذا التقرير تفاصيل الالات حسب الفني مع تفاصيل الزيارات للسنة الحالية",
          machinesThatHaveContractWithVisitsDescBasedOnTech:
            "يعرض هذا التقرير تفاصيل الالات حسب الفني مع تفاصيل الزيارات للسنة الحالية مع بعض معلومات العقد",
          machinesContarctTechnicianDesc:
            "يعرض هذا التقرير تفاصيل الالات حسب الفني ",
          numberOfTechVisitsDesc: "يعرض هذا التقرير عدد زيارات الفني لشهر معين",
          finishedContractsDesc: "يعرض هذا التقرير عدد زيارات الفني لشهر معين",
          lateTicketsDesc: "يعرص هذا التقرير الاتصالات المتأخرة",
          repeatedTicketsDesc: "يعرص هذا التقرير الاتصالات المتكررة",
          periodicalVisitsDesc:
            "يعرص هذا التقرير بطاقة الزيارات الدورية لرقم الة معينة او موديل معين",
          invoicesReportDesc:
            "يعرض هذا التقرير جميع الفواتير المصدرة من النظام",
        },
        contractEndDate: "تاريخ نهاية العقد",
        systemReports: "تقارير النظام",
        searchReports: "ابحث عن اسم التقرير",
        invoiceDate: "تاريخ الفاتورة",
      },
    },
    models: {
      Users: " المستخدمين",
      clients: "العملاء",
      ContractInvoice: "فواتير العقد",
      clientBranches: " فروع العملاء",
      Technicians: " الفنيين ",
      Groups: "مجموعات الصلاحيات",
      Machine: " الآلات ",
      ContractMachine: "الات العقد",
      Contract: "  العقود  ",
      Lookup: " رموز النظام ",
      Tablet: " الأجهزة اللوحية",
      MachinePart: "قطع الآلة",
      Tickets: "التذاكر",
      machineExtraDevices: "الأجهزة الإضافية",
      UserLeaves: "الإجازات والمغادرات",
      Locations: "المواقع",
      UserGroups: "صلاحيات المستخدم",
      TicketStatus: "حالة تذكرة",
      GroupPermissions: "مجموعة الصلاحيات",
      MaintenanceReport: "تقرير صيانة",
      ContractPayments: "دفعات العقد",
      MaintenanceReportMachinePart: "تقرير الصيانة لقطع الآلة",
    },
    ticketInformation: {
      ticketStatusRecord: "سجل حالات التذكرة",
      noTicketStatusRecord: "عذراً ولكن لا يوجد سجل حالات لهذه التذكرة.",
    },
    common: {
      continue: "إستمرار",
      logout: "تسجيل الخروج",
      ok: "حسناً",
      cancel: "إلغاء",
      save: "حفظ",
      yes: "نعم",
      no: "لا",
      close: "إغلاق",
      open: "فتح",
      confirm: "تأكيد",
      delete: "حذف",
      add: "إضافة",
      alterTechs: "فنيين بدلاء",
      deleteNotification: "تنبيه حذف",
      deleteMessage: " هل أنت متأكد من الحذف ؟",
      savedSuccessfully: "تم الحفظ بنجاح",
      sentSuccessfully: "تم الإرسال بنجاح",
      noChanges: "لم يتم عمل أي تعديلات",
      createdBy: "المنشئ",
      createdDate: "تاريخ الإنشاء",
      from: "من تاريخ",
      to: "إلى تاريخ",
      updatedDate: "تاريخ آخر التعديل",
      updatedBy: "عدل بواسطة",
      isDeleted: "محذوف",
      pleaseSelect: "يرجى الاختيار",
      pleaseWait: "يرجى الانتظار",
      name: "الإسم",
      phoneNum: "رقم الهاتف",
      goToSettingPage: "الذهاب إلى صفحة الإعدادات",
      email: "الإيميل",
      contractImg: "صورة العقد",
      contractPDFDownload: "تحميل صورة العقد من الجهاز",
      options: "خيارات",
      language: "اللغة",
      errors: {
        requiredField: "هذا الحقل اجباري",
        invalidEntry: "ادخال غير صحيح",
        accessDenied: "غير مصرح, انت لا تملك الصلاحيات لعرض المحتوى المطلوب",
        genericServerError: "حدث خطأ, يرجى المحاولة مجدداً",
        ops: "عــــــــــذراً!",
        error404: "خطأ 404 - الصفحة غير موجودة",
        pageRegError: "الصفحة التي قمت بطلبها لا يمكن إيجادها",
        emailNotSent: "لم يتم إرسال البريد الالكتروني",
        warning: "يرجى النتباه !!",
        anErrorOccur: "حدث خطأ",
      },
      excelExport: {
        areas: "المناطق الجغرافية",
        contracts: "العقود",
        machines: "الآلات",
        maintenanceReports: "تقارير الصيانة",
        tablets: "الأجهزة اللوحية",
        techAreas: "مناطق عمل الفنيين",
        technicians: "الفنيين",
        tickets: "التذاكر",
        userLeaves: "المغادرات والإجازات",
        users: "مستخدمين النظام",
        callCenter: "كشف الإتصالات",
      },
      noData: "لا توجد بيانات",
      notes: "الملاحظات",
      edit: "تعديل",
      info: "معلومات تفصيلية",
      notSpecified: "غير محدد",
      searchBy: "البحث حسب ",
      noNotes: "لا توجد ملاحظات",
      noResultFor: "لا توجد نتائج مطابقة ل ",
      inHours: "بالساعات",
      errorDeletingMessage: "لا يمكن حذفها مرتبطة بـ ",
      previous: "السابق",
      loading: "جار التحميل",
      search: "بحث",
      searchDots: "إبحث...",
      back: "الرجوع",
      noPreviousNum: "لا يوجد رقم",
      noCoords: "عـــذراً، ولكن ليس هنالك إحداثيات لهذا الفرع.",
      warning: "تنـبيه !",
      exportDataToExcel: "تصدير البيانات إلى ملف إكسل",
      date: "التاريخ",
      send: "إرسال",
    },
  },
  en: {
    tesco: "TESCO",
    systemSubtitle: "Maintenance System",
    hello: ",Hello ",
    pages: {
      user: {
        addNewUser: "Create New User",
        editUser: "Edit user info",
        userList: "User list",
        deactiveUser: "Deactive User",
        userSituation: "User Situation",
        changePassword: "Change User password",
        username: "Username",
        password: "Password",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        confirmNewPassword: "Confirm new password",
        fullname: "Full Name",
        phone: "Phone",
        email: "Email",
        userPermission: "User permissions",
        permissionChoose: "Choose Permission/s Group",
        disableUser: "Disable User Model",
        disableUserMessage:
          "This action will disable the user, are you sure from that?",
        activateUser: "Activate User Modal",
        activateUserMessage:
          "This action will activate the user, are you sure from that?",
        leavesType: "Leaves Type",
        externalUser: "External user",
        errors: {
          passConfirm: "Password Mismatch",
          passLength: "Password is too short",
          phone: "Phone should start with 96277/96278/96279",
          phoneLength: "Phone number should consist 12 digits",
          duplicateUser: "Username is already used by another user",
        },
        showUnActiveUsers: "Show unactive Users",
      },
      machines: {
        pageTitle: "Machines",
        machineExtraDevices: "Machine Extra Devices",
        machineContract: "Machine Contract Details",
        addMachine: "Add New Machine",
        editMachine: "Edit machine info",
        serialNumber: "Serial Number",
        computerNumber: "computer number ",
        cardNo: "Card Number",
        notes: "Notes",
        extraDevice: "Extra Device",
        machineInfo: "Machine Information",
        codeNo: "Device Code Number",
        serviceTime: "Service time",
        invoiceNumber: "Invoice Number",
        invoiceDate: "Invoice Date",
        invoiceValue: "Invoice Value",
        firstInstallationDate: "First Installation Date",
        seller: "seller",
        addDevices: "Extra Devices",
        lastMaintenaceDate: "Last Maintainance Date",
        lastCounter1: "First counter",
        lastCounter2: "Second counter",
        warrantyEndDate: "Warranty End Date",
        MachineModelID: "Machine Model",
        MachineModel: "Machine Model",
        deviceCode: "Device Code",
        enterDeviceCode: "Enter Device Code",
        MachineTypeID: "Machine Type",
        MachineType: "Machine Type",
        ManufacturingCompany: "Manufacturing Company",
        technician: "technician",
        chooseExtraDevice: "Choose Extra Device",
        MachineParts: "Manage machine part",
        part: "Part",
        partName: "Part Name",
        partNumber: "Part Number",
        price: "Price",
        quantity: "Quantity",
        machineBranchAndClientDetails: "Machine Branche And Client Detailes",
        contractMachineNotes: "Contract Machine Note",
        machineNote: "Machine Note",
        counter1: "Current (Black & White) Counter",
        counter1Confirm: "Confirm Current (Black & White) Counter",
        counter2: "Current (Colorful) Counter",
        counter2Confirm: "Confirm Current (Colorful) Counter",
        numberOfMaintenanceDays: "Number Of Maintenance Days",
        countersDate: "Counters Read Date",
        notConnectedWithClient: "Not Connected With Client",
        showNotAssociatedMachines:
          "Show machines that are not related to a customer",
        createInvoice: "Create Invoice",
        editCounters: "Edit Counters",
        error: {
          duplicateMachine: "duplicate machine",
        },
      },
      contracts: {
        pageTitle: "Contracts",
        addContract: "Add New Contract",
        duplicateContract: "This contract already found",
        notSubjectToSLA: "Not subject to SLA",
        contractRenewal: "Contract Renewal",
        extends: "Extends",
        attention: "Attention",
        associatedMachineAttentionMessage:
          "This contract contains a machine that belongs to another contract (or inactive contract) please check the below table",
      },
      addContract: {
        pageTitle: "Add New Contract",
        contractNumber: "Contract Number",
        every: "Every",
        notEndContract: "Not End",
        contractStatus: "Contract Status",
        hours: " Hours ",
        dueHours: "Due Hours",
        startDate: "Start Date",
        endDate: "End Date",
        endContract: "End",
        contractDate: "Contract Date",
        contractValue: "Contract Value",
        client: "Client",
        contractType: "Contract Type",
        PaymentMethod: "Payment Method",
        notes: "Notes",
        SLA: "Subject to SLA system",
        duplicateContractNumber: "Contract Number used",
        otherPayMethodInfo: "Please enter the other payment way",
      },
      editContract: {
        pageTitle: "Edit Contract Information",
        edit: "Edit",
      },
      addContractMachine: {
        pageTitle: "Contract Machines",
        installationDate: "Instulation ate",
        address: "Address",
        addressDetails: "Address details",
        copyPrice: "Copy price",
        copyPriceBlackAndWhite: "Copy price ( Black + White)",
        copyPriceColorful: "Copy price (Colorful)",
        startCounter: "Start counter",
        addMachine: "Add machine",
        contractMachines: "Contract machines",
        MachineID: "Machine ID",
        invoiceNumber: "Invoice number",
        invoiceDate: "Invoice date",
        invoiceValue: "Invoice value",
        maintenanceDays: "Maintenance days",
        maintenanceCopies: "Mantenance copies",
        maxAllowedDays: "Number of allowed days",
        maxAllowedCopies: "Number of allowed copies",
        lastMaintenaceDate: "Last maintenance date",
        lastCounter1: "First counter",
        lastCounter2: "Second counter",
        monthlyFees: "Monthly fees",
        minimumNumberOfCopies: "Minimum number of copies",
        numberOfFreeCopies: "Number og free copies",
        editContractMachine: "Edit contract machine",
        addMachineToContract: "Add a contract machine",
        associatedMachineErrorMessage:
          "This machine is associated with a valid contract.",
        pleaseCheckTheEnteredMachineNumber:
          "Please check the entered machine number.",
      },
      clients: {
        pageTitle: "Clients",
        addClient: "Add New Client",
        editClient: "Edit client",
        clientName: "Client Name",
        vip: "Special client",
        VIP: " VIP client",

        clientSituation: "Client Situation",
        notes: "Notes",
      },
      technicians: {
        pageTitle: "Technicians",
        addTechnician: "Add New Technician",
        technicianName: "Technician Name",
        chooseAlternate: "Please choose the alternate technician/s",
      },
      technicianAreas: {
        pageTitle: "Add technician work areas",
        technician: "Technician",
        area: "Area",
        addArea: "Add area",
        areas: "Specific areas",
        addRemoveAreas: "Add/Remove areas",
        doYouWantToremove:
          "Are you sure you want to remove the following areas: ",
        doYouWantToAdd: "Are you sure you want to add the following areas: ",
      },
      technicianAreasList: {
        pageTitle: "Technician work areas",
        addTechAreas: "Connects technicians to work areas",
        notAvailable: "Not available",
      },
      ManageTechnicians: {
        pageTitle: "Add New Technician",
        technicianName: "Technician Name",
        phone: "Phone Number",
        email: "Email",
        machineIdNotRequiredToCloseTicket:
          "Machine Number Not Required To Close Ticket",
        chooseName: "Please Choose User Name",
        userName: "User Name",
        notAssociatedWithUser: "Not Associated With A User",
        requiredForCloseTicket: "Required",
        notRequiredForCloseTicket: "Not Required",
        sorryUserIsTech: "Sorry, but this user is already a technician",
        editTechInfo: "Edit Technician Information",
      },
      area: {
        pageTitle: "Add new area",
        areaName: "Area name",
        duplicateArea: "Area name already uesed",
        drawArea: "Please draw the area",
        creationMethod: "Please choose creation method",
        choosePlease: "Choose please",
        borders: "Borders",
        landMarks: "Landmarks",
        searchLandmarks: "Search for landmarks ...",
        creationWay: "Creation Way",
        radius: "Radius (KM)",
        editGeoArea: "Edit Area",
      },
      areaList: {
        pageTitle: "Areas List",
        addArea: "Add new area",
        areaName: "Area Name",
      },
      ticket: {
        CustomerSerial: "Customer Serial Number",
        additionalInfo: "Additional Information",
        externalTicketNotes:
          "In case the serial number isn't found, Please provide it in this field",
        addPageTitle: "Add New Ticket",
        late: "Late",
        showSLA: "Show SLA",
        distance: "Distance",
        orderByDistance: "Order By Distance",
        editPageTitle: "Edit Ticket ",
        notLate: "Not Late",
        km: " km ",
        contactName: "Contact name",
        showClosed: "Show Closed",
        alter: "Alternate - ",
        showLate: "Show Late",
        ticketDate: "Ticket Date",
        contactPhone: "Contact phone",
        main: "Main - ",
        notes: "Ticket notes ",
        lateForDueHours: "Late For Due Date",
        clientName: "Client Name",
        machineSerialNumber: "Machine serial number",
        ticketID: "Ticket number ",
        ticketsList: "Tickets list ",
        branchName: "Branch Name",
        activeTickets: "Active tickets",
        lastVisit: "Last visit",
        NextVisit: "Next visit",
        callCenter: "Call / Ticket List",
        warningSLA:
          "Warning: This contract is subject to SLA and must be reviewed within ",
        serialNumber: "Serial number",
        clientDetails: "client details",
        machineDetails: "machine details ",
        branchDetails: "branch details ",
        contractDetails: "contract details ",
        callType: "Call type",
        reason: "Reason",
        openSince: "open since",
        ticketStatus: "ticket status",
        userType: "user type",
        ticketLevel: "ticketLevel",
        level: "level",
        closeTicket: "close ticket",
        changeTech: "change technician",
        notSpecifiedBranch: "Branch is not specified",
        notSpecifiedClient: "Client is not specified",
        notSpecifiedMachNumber: "Machine Number is not specified",
        notSpecifiedProblem: "Problem Type is not specified",
        showOnMap: "Show On Map",
        determineDirections: "Determine directions",
        determineDirectionsUsingGoogle: "Determine directions using google ",
        takeAction: "Take action for the ticket",
        ticketNote: "Ticket status notes",
        emptyStatusError: "You must first choose the status of the ticket",
        showMap: "Show Map",
        dueHourseWithoutSLA:
          "This contract is not subject to SLA, Where it must be met during ",
        insideAmman: "Inside Amman (24) hrs",
        outsideAmman: "Outside Amman (48) hrs",
        ticketsCalls: "Tickets/Calls",
        periodicVisits: "Periodic Visits",
      },
      branch: {
        addBranch: "Add new branch",
        editBranch: "Edit branch",
        branchList: "Branch list",
        branchNo: "Branch no",
        main: "Main",
        branchName: "Branch name",
        clientName: "Client name",
        contactPerson: "Contact person",
        phone: "contact phone",
        address: "Address",
        fax: "Fax",
        POBox: "POBox",
        zipCode: "Zip code",
        city: "City",
        branchPhoneNumber: "Branch phone number",
        editContactPersons: "Edit Contact Persons",
        addContactPersons: "Add contact Persons",
        personName: "Person Name",
        contacts: "Contacts",
        noContactBranchData: "Sorry, But there is no contacts for this branch",
        fillAllFieldsError: "You must fill all the fields",
        personEmail: "Person Email",
        updateBranchCoords: "Update Branch Coords",
      },
      leave: {
        leaveList: "Leave & vacations List",
        addLeave: "Add new leave or vaction",
        editLeave: "Edit leave or vacation",
      },
      lookup: {
        subTitle: "Add System Lookup",
        addLookup: "Add New Lookup",
        lookUpTableTitle: "Lookup Name",
        chooseLookUp: "Please Choose Lookup",
      },
      tablet: {
        addNewTablet: "Add New Tablet",
        manageTablets: "Manage Tablets",
        addTablets: "Add Tablets",
        tabletUserName: "User Name",
        imeiNumber: "IMEI Number",
        phoneNumber: "Phone Number",
        notAssociatedWithaUser: "Not Associated With A User",
        chooseUserName: "Please Choose User Name",
        dublicateIMEI: "Sorry, But this IMEI number is already exist",
        editTabletInfo: "Edit tablet information",
        errors: {
          IMEILength: "IMEI number should consist 15 digits",
        },
      },
      sidbar: {
        home: "Home",
        callCenter: "Call / Ticket List",
        ticketsList: "Ticket List",
        maintenanceReport: "Maintenance Reports",
        areasList: "Area List",
        settings: "Settings",
        reports: "Reports",
      },
      permissions: {
        groupPermissions: "Manage group permissions",
        addGroup: "Add new group permission",
        editGroup: "edit group permission info",
        deleteGroup: "delete group permission",
        addUserGroups: "add group permission to users ",
        groupName: "group name",
        permissions: "permissions",
        errorSave: "Select one permission at least ",
      },
      settings: {
        users: "User managment ",
        clientBranches: "client and Branches managment",
        technicians: "Technicians managment ",
        machines: "Machines managment ",
        techniciansArea: "Technicians area managment",
        contracts: "Contracts managment ",
        UserLeaves: "User leaves managment",
        lookup: "Lookup System Management",
        tablets: "Tablets Management",
      },
      maintenanceReport: {
        pleaseAddMaintenanceReportId: "Please Add Maintenance Report ID",
        theDifferenceBetweenCounter1AndLastCounter1:
          "The Difference Between Counter1 And LastCounter1:",
        withoutInvoice: "Without Invoice",
        youCanNotCreateInvoiceWithoutConfirmInvoiceCounters:
          "To create an invoice, please click on Generate Invoice and confirm the previously entered readings.",
        thereIsPartsYouAddedDosentHavePartNumberAndDescription:
          "To issue a parts invoice, all selected parts must have a description and part number. Therefore, please delete any parts that do not have a description or part number in order to create the invoice.",
        onlyPartNumberAndPartDetailsOptional:
          "only Part Number And Part Details are Optional",
        onlyPartNumberAndPartDescriptionOptional:
          "only PartNumber And PartDescription are Optional",
        pleaseChoseBetweenTheCreateItemMaintenanceInvoiceAndStatisticalOptions:
          "please Chose Between The Create Item Maintenance Invoice And Statistical Options",
        createItemMaintenanceInvoice: "create Part Maintenance Invoice",
        statistical: "statistical",
        itemAlreadySelected: "Item already selected",
        pleaseSelectAnotherItem:
          "Item already selected, please select another one",
        machinePartInvoice: "Machine Part Invoice",
        editInvoice: "Edit Invoice",
        invoiceAmount: "Invoice Amount",
        goToA4MaintenanceReport: " Go To A4 Maintenance Report page",
        goToZebraMaintenanceReport: "Go To Zebra Maintenance Report Page",
        zeroCopyPriceWarningModalBody:
          "Please note that the price of the image is zero, so if you want to issue an invoice, please change the value.",
        countersMismatchValidationError:
          "Last invoice counter is less than or equal to the current counter, please check the inputs ",
        "alpha-error-technician-not-linked":
          "Technician not linked with alpha system correctly so, the maintanence report will not be created yet, please contact with system administrator",
        "alpha-error-machine-barcode-not-defined":
          "Machine barcode not defined in alpha system so, the maintanence report will not be created yet, please contact with system administrator",
        "alpha-error-machine-model":
          "Machine model type is not defined in alpha system so, the maintanence report will not be created yet, please contact with system administrator",
        counterWarningModalBody:
          "The entered current counter is less than or equal the last counter in the previous maintenance report, please check the inputs",
        maintenanceReports: "Maintenance reports",
        maintenanceReport: "Maintenance report",
        maintenanceReportID: "Maintenance Report ID",
        oldMaintenanceReportNum: "Maintenance Report Old Number",
        manualReportNumber: "Manual Report Number",
        machineID: "Machine ID",
        clientName: "Client Name",
        maintenanceDate: "Maintenance date",
        technicianName: "Technician name",
        branchName: "Branch name",
        SerialNumber: "Serial Number",
        machineModel: "Machine Model",
        machineInfo: "Machine Information",
        machinePartsTable: "Machine parts table",
        damageDetails: "Damage details",
        counters: "Counters",
        clientInfo: "Client information",
        wrongCounters: "Counters Don't Match",
        confirmMaintenanceType:
          "This selection will generate an invoice later, are you sure?",
        computerNumber: "Computer number",
        machineType: "Machine type",
        serialNumber: "Serial number",
        location: "Location",
        area: "area",
        visitType: "Visit type",
        maintenanceType: "Maintenance type",
        firstCounter: "First counter",
        secondCounter: "Second counter",
        enterCounterValue: "Enter counter value",
        startTime: "Start time",
        endTime: "End time",
        addNewItem: "Add new item",
        item: "Item",
        quantity: "Quantity",
        description: "Description",
        add: "Add",
        cancel: "Cancel",
        damage: "Damage",
        enterDamage: "Enter damage",
        cause: "Cause",
        enterCause: "Enter cause",
        action: "Action",
        enterUserNotes: "Enter user notes",
        employeeName: "Employee name",
        enterEmployeeName: "Enter employee name",
        job: "Job",
        enterJob: "Enter job",
        maintenanceReportSatus: "Maintenance report status",
        next: "Next",
        save: "Save",
        printOrSendMaintenanceReport: "Print maintenance report",
        printMaintenanceReport: "Print Maintenance Report",
        printMaintenanceInvoice: "Print maintenance invoice",
        search: "Search",
        close: "Close",
        invoice: "Invoice",
        enterAction: "Enter action",
        technicianNotes: "Technician notes",
        userNotes: "User notes",
        enterTechnicianNotes: "Enter technician notes",
        lastCounter1: "Counter (1) ( Previous ) : ",
        counter1: "Counter (1) ( Current ) : ",
        lastCounter2: "Counter (2) ( Previous ) : ",
        counter2: "Counter (2) ( Current ) : ",
        counter1Difference:
          "Difference between the previous and current counter (1) ",
        counter2Difference:
          "Difference between the previous and current counter (2) ",
        blackAndWhiteCounter: "Counter (Black & White)",
        colorfulCounter: "Counter (Colorful)",
        currentCounterRead: "Counter Current Read",
        previousCounterRead: "Counter Previous Read",
        siteArea: "Site Of Use",
        request: "Request",
        cyclic: "Cyclic",
        other: "Other",
        reportNumber: "Report Number",
        date: "Date",
        delivery: "Delivery",
        contract: "Contract",
        free: "Free",
        rent: "Rent",
        startHour: "Start Hour",
        endHour: "End Hour",
        partsMaterialTable: "Parts/Material Table",
        issue: "Issue",
        reason: "Reason",
        procedure: "Procedure",
        empName: "Employee Name",
        signature: "Signature",
        machineReportNeeds:
          "You will be provided with a price offer if the report indicates the need for the machine for parts and supplies, according to your request",
        stampAndSignature: "Stamp And Signature",
        reportValidity: "The validity of the report is one week",
        machineReceived:
          "I received the machine in good condition, along with its accessories, in the presence of the competent technician",
        costReport:
          " I received the cost report knowing that the above report is preliminary with the cost of the repair, which may increase or decrease when the actual repair is done",
        acheivedCopies: "Number Of Achieved Copies",
        maintenanceReportCost: "Maintenance Report And Cost",
        contractIsEndedAttentionMessage:
          "Please note that this machine is associated with an expired contract",
        emptySigError: "You must sign first",
        clientSignature: "Client Signature",
        takeClientCardPhoto: "Take a photo of the client card",
        editClientCardPhoto: "Edit the photo of the client card",
        contractNumber: "Contract Number",
        contractType: "Contract Type",
        operator: "Operator",
        scattered: "Scattered",
        requestAndPeriodic: "Request + Periodic",
        pleaseChoosePart: "Choose Part",
        numberNotFoundErrorMessage: "Number Not Found",
        pleaseCheckTheEnteredNumber: "Please verify the entered number",
        reportDelivery: "Deliver Report",
        reportStatus: "Report Status",
        printOrSendITMaintenanceReport: "Print Or Send IT Maintenance Report",
        manualReportInfo: "Manual Report Information",
        invoices: "Invoices",
      },
      createMaintenanceReport: {
        createMaintenanceReport: "Create maintenance report",
        reportsWithInvoice: "Invoices table",
      },
      contractPayment: {
        addPayment: "Add Payment",
        editPayment: "Edit Payment",
        deservedDate: "Invoice Date",
        invoiceNum: "Invoice Number",
        isDeserved: "Is Deserved",
        paymentAmount: "Payment Amount",
        paymentDate: "Payment Date",
        contractPayments: "Contract Payments",
      },
      playBackMap: {
        techsMovePoints: "Technicians Move Points",
        implementation: "Implementation",
        chooseTech: "Please choose a technician",
        noPointErrorMessage:
          "Sorry, but there are no moving points for this technician between these dates.",
        address: "Address",
        date: "Date : ",
        techMovePoints: "Technician Move Points",
        hour: "Hour",
        workAreaName: "Work Area Name : ",
        techName: "Technician Name : ",
        techMobile: "Technician Mobile : ",
        techEmail: "Technician Email : ",
        showAreaOnMap: "Show Area On Map",
      },
      loginPage: {
        userName: "User Name",
        password: "Password",
        signIn: "Sign In",
        maintenanceContractSystem: "Maintenance Contract System",
        loginErrorMessage: "The username or password is incorrect",
      },
      maintenanceReportDetails: {
        maintenanceReportNumber: "Maintenance Report Number",
        manualReportNumber: "Manual Report Number",
        reportInfo: "Report Information",
        reportCreator: "Report Creator",
        maintenanceReportCreatedAt: "Maintenance Report Created Date",
        visitType: "Visit Type",
        maintenanceType: "Maintenance Type",
        clientInfo: "Client Information",
        client: "Client",
        clientNameJob: "Client/Employee Name",
        clientEmployeeJob: "Job",
        machineInfo: "Machine Information",
        respTech: "Responsible Technician",
        computerNumber: "Computer Number",
        machineType: "Machine Type",
        machineModel: "Machine Model",
        serialNumber: "Serial Number",
        branchName: "Branch Name",
        branchArea: "Branch Area",
        images: "Images",
        blackAndWhiteCounter: "Black And White Counter",
        colorfulCounter: "Colorful Counter",
        clientCardImage: "Client Card",
        maintenanceInfo: "Maintenance Information",
        counterDetails: "Counters Details",
        startHour: "Start Hour",
        endHour: "End Hour",
        damage: "Damage",
        reason: "Reason",
        action: "Action",
        contractInfo: "Contract Information",
        contractNumber: "Contract Number",
        contractType: "Contract Type",
        startDate: "Start Date",
        endDate: "End Date",
        contractSituation: "Contract Situation",
        clientSignature: "Client Signature",
        manualReportImg: "Manual Report Image",
      },
      contractMachineEditRequests: {
        editRequest: "Edit Request",
        editRequests: "Edit Requests",
        showClosedEditRequests: "Show Closed Edit Requests",
        closeEditRequest: "Close Edit Request",
        editMachineInfo: "Edit Machine Information",
        editClientInfo: "Edit Contract Information",
        closeEditRequestMessage:
          "Are you sure you want to close this request ?",
        editRequestSentSuccessfully: "Edit request has been sent successfully",
        pleaseEnterRequiredModifications:
          "Please write the modifications to be modified",
      },
      reports: {
        partsMovementReport: "Invoice Movement Card",
        maintenanceCard: "maintenance Card",
        periodEndedContracts:
          "Contracts that will expire within a specified period",
        notDeservedContracts: "Contracts and claim period (not due)",
        techAreasAndMachinesNum:
          "Areas of technicians and the number of machines that have contracts",
        techMachinesWithContracts: "Technician machines that have contracts",
        invoicesReport: "Invoices Report",
        clientMachinesInfo: "General information on customer machines",
        machinesModel: "General report of machines by model",
        machinesModelClient: "General report of machines by model/Client",
        lateTickets: "Late Tickest",
        repeatedTickets: "Repeated Tickest",
        periodicalVisits: "Priodical visits for a machine",
        generalMachineInfo: "General Machines Info Report",
        machinesThatHaveContractWithVisits:
          "Machines with contract grouped by technicians with visits",
        machinesContarctTechnician:
          "Machines with contract grouped by technicians",
        numberOfTechVisits: "Number of Visits for a technician",
        finishedContracts: "Expired contract",
        description: {
          partsMovementReportDesc:
            "The report displays all movements of spare parts on the machine.",
          maintenanceCardDesc:
            "The report displays all maintenance and accounting operations on the machine",
          periodEndedContractsDesc:
            "The report displays the details of all contracts that will expire during a specified period",
          notDeservedContractsDesc:
            "The report displays details of all non-deserved contracts",
          techAreasAndMachinesNumDesc:
            "The report displays all technician areas that have contract-bound machines with the number of machines per region",
          techMachinesWithContractsDesc:
            "The report displays details of all contractual technician machines",
          clientMachinesInfoDesc:
            "The report shows details of all machines associated with a specific customer",
          machinesModelClientDesc:
            "This report shows details of all machines of a particular model",
          lateTicketsDesc: "This report shows details of all the late tickets",
          repeatedTicketsDesc:
            "This report shows details of all the repeated tickets",
          periodicalVisitsDesc:
            "This report shows priodical visits cards for a specific machine or a model",
          generalMachineInfoDesc:
            "This report shows Detailed Information about the machines",
          machinesThatHaveContractWithVisitsDesc:
            "This report shows the all the machines with contract based on technician with the number of vistis",
          machinesContarctTechnicianDesc:
            "This report shows the all the machines with contract based on technician",
          numberOfTechVisitsDesc:
            "This report shows the number of visits that a technician made in a specific time",
          finishedContractsDesc:
            "This report shows the expired contracts that haven't been renewed",
          invoicesReportDesc:
            "This report shows all the invoices created from the system between two dates",
        },
        contractEndDate: "Contract End Date",
        systemReports: "System Reports",
        searchReports: "Search for a report",
        invoiceDate: "Invoice Date",
      },
    },
    models: {
      Users: " Users",
      clients: "clients",
      ContractInvoice: "Contract Invoice",
      clientBranches: "client Branches",
      Technicians: " Technicians ",
      Groups: "Groups",
      Machine: " Machine ",
      ContractMachine: "Contract Machine",
      Contract: "Contract",
      Lookup: " Lookup",
      Tablet: "Tablet",
      MachinePart: "Machine Part ",
      Tickets: "Tickets",
      machineExtraDevices: "Machine Extra Devices",
      UserLeaves: "User leaves",
      Locations: "Locations",
      UserGroups: "User Groups",
      TicketStatus: "Ticket Status",
      GroupPermissions: "Group Permissions",
      MaintenanceReport: "Maintenance Report",
      ContractPayments: "Contract Payments",
      MaintenanceReportMachinePart: "Maintenance Report Machine Part",
    },
    ticketInformation: {
      ticketStatusRecord: "Ticket status record",
      noTicketStatusRecord: "There is no record of the statuses of this ticket",
    },
    common: {
      continue: "Continue",
      logout: "Logout",
      ok: "OK",
      cancel: "Cancel",
      save: "Save",
      yes: "Yes",
      no: "No",
      to: "To",
      from: "From date",
      add: "Add",
      close: "Close",
      open: "Open",
      confirm: "Confirm",
      delete: "delete",
      noChanges: "No changes were made",
      pleaseSelect: "Please select",
      updatedDate: "Date of last modification",
      alterTechs: "Alternative technicians",
      pleaseWait: "Please wait",
      deleteNotification: "Delete notification",
      updatedBy: "Modified by",
      isDeleted: "Deleted",
      deleteMessage: "Are you sure you want to delete?",
      savedSuccessfully: "Saved Successfully",
      sentSuccessfully: "Sent Successfully",
      createdBy: "Created By",
      createdDate: "Created Date",
      name: "Name",
      phoneNum: "Phone Number",
      goToSettingPage: "Go to settings page",
      email: "Email",
      ContractImg: "Upload Contract Image",
      contractPDFDownload: "upload Contract image from device",
      options: "options",
      language: "language",
      errors: {
        requiredField: "This field is required",
        invalidEntry: "Invalid Entry",
        accessDenied:
          "Access Denied, You have no permission to access this resource",
        genericServerError: "Something went wrong, Please try again",
        ops: "Oops!",
        error404: "Error 404 - Page Note Found",
        pageRegError: "The page you requested could not be found",
        emailNotSent: "Email wasn't sent",
        warning: "Warning !!",
        anErrorOccur: "Error occured",
      },
      excelExport: {
        areas: "Areas",
        contracts: "Contracts",
        machines: "Machines",
        maintenanceReports: "Maintenance Reports",
        tablets: "Tablets",
        techAreas: "Technician Areas",
        technicians: "Technicians",
        tickets: "Tickets",
        userLeaves: "Departures and vacations",
        users: "System Users",
        callCenter: "Call Center",
      },
      noData: "No data",
      notes: "Notes",
      edit: "Edit",
      info: "info",
      notSpecified: "Not specified",
      searchBy: "Search by ",
      noNotes: "There are no notes",
      noResultFor: "No results found for ",
      inHours: "In Hours",
      errorDeletingMessage: "Cannot be deleted, Linked to ",
      previous: "Previous",
      loading: "Loading",
      search: "Search",
      searchDots: "Search...",
      back: "Back",
      noPreviousNum: "No Previous Number",
      noCoords: "Sorry, But there's no Coords for this branch.",
      warning: "Warning !",
      exportDataToExcel: "Export Data To Excel",
      date: "Date",
      send: "Send",
    },
  },
});
