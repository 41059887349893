import React, { useEffect, useRef, useState } from "react";
import { Map as Leaflet, Marker } from "react-leaflet";
import MapLayers from "../../components/Map/MapLayers";
import { icons } from "../../components/Map/helpers/MapIcons";
import { Icon, Loader, Popup } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import {
  List as Vlist,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import techImg from "../../assets/images/techIcon.svg";
import techEmail from "../../assets/images/email.svg";
import techPhone from "../../assets/images/call.svg";
import wkt from "terraformer-wkt-parser";
import { addJsonData } from "../../components/Map/helpers/MapHelpers";
import serviceApi from "../../api";
import AddressingServiceApi from "../../api/addressing-service.api";
import { TransitionablePortal } from "semantic-ui-react";
import techLocationAddressImg from "../../assets/images/techLocationAddressImg.svg";
import L from "leaflet";

const PlayBackMap = ({
  techMovePointsLocations,
  selectedLocationInfo,
  technicianInformation,
  technicianAreas,
}) => {
  const mapRef = useRef(null);
  const [techLocationAddress, setTechLocationAddress] = useState(null);
  const [hideTechnicianLocationsInfo, setHideTechnicianLocationInfo] =
    useState(false);

  const locationsListCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    })
  );

  const areasListCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 30,
    })
  );

  useEffect(() => {
    mapRef.current.leafletElement.flyTo({ lng: 36.2384, lat: 30.5852 }, 7);
  }, []);

  const drawTechArea = async (areaId) => {
    const res = await serviceApi.service("area").get(areaId);
    addJsonData({
      map: mapRef.current.leafletElement,
      jsonData: wkt.parse(res[0].geometry),
    });
  };

  const getTechLocaionAddress = async (lng, lat) => {
    const techAddress = await AddressingServiceApi.getAddress(lng, lat);
    if (techAddress) {
      setTechLocationAddress(techAddress);
    } else setTechLocationAddress("عذراً، ولكن لا توجد معلومات لهذا العنوان.");
  };

  useEffect(() => {
    //TODO : fix the on cahnge locations to be cleared
    if (
      Boolean(techMovePointsLocations && techMovePointsLocations.length > 0)
    ) {
      const polyline = new L.Polyline(techMovePointsLocations, {
        color: "blue",
        opacity: 0.5,
        smoothFactor: 1,
      });
      polyline.addTo(mapRef.current.leafletElement);
      mapRef.current.leafletElement.fitBounds(polyline.getBounds());
    }
  }, [techMovePointsLocations]);

  return (
    <>
      <Leaflet
        animate
        ref={mapRef}
        center={{ lng: 36.2384, lat: 30.5852 }}
        zoom={6}
        className="play-back-map"
      >
        {techMovePointsLocations &&
          Boolean(techMovePointsLocations.length > 0) && (
            <Marker
              position={
                !!selectedLocationInfo.lnglat &&
                !!selectedLocationInfo.lnglat.lng &&
                !!selectedLocationInfo.lnglat.lat
                  ? selectedLocationInfo.lnglat
                  : {
                      lng: techMovePointsLocations[0].lng,
                      lat: techMovePointsLocations[0].lat,
                    }
              }
              icon={icons.TechnicianIcon}
            />
          )}
        <MapLayers />
      </Leaflet>

      {techMovePointsLocations &&
        Boolean(techMovePointsLocations.length > 0) &&
        technicianInformation && (
          <div className="technician-locations-info-container">
            <div
              className="technician-locations-info-header"
              onClick={() =>
                setHideTechnicianLocationInfo(!hideTechnicianLocationsInfo)
              }
            >
              <Icon
                name={hideTechnicianLocationsInfo ? "angle up" : "angle down"}
              />
              {resources.pages.playBackMap.techMovePoints}
            </div>
            {!hideTechnicianLocationsInfo && (
              <div className="technician-locations-info-box-container">
                <div className="locations-info-box-container">
                  <div className="locations-info-box-headers-container">
                    <label className="locations-info-box-headers-time">
                      الوقت
                    </label>
                    <label className="locations-info-box-headers-address">
                      العنوان
                    </label>
                  </div>
                  <div className="locations-info-box">
                    <AutoSizer>
                      {({ width, height }) => (
                        <Vlist
                          width={width}
                          height={height}
                          rowHeight={locationsListCache.current.rowHeight}
                          deferredMeasurementCache={locationsListCache.current}
                          rowCount={techMovePointsLocations.length}
                          scrollToIndex={selectedLocationInfo.index}
                          scrollToAlignment="start"
                          rowRenderer={({ key, index, style, parent }) => {
                            const techMovePointInfo =
                              techMovePointsLocations[index];
                            return (
                              <CellMeasurer
                                key={key}
                                cache={locationsListCache.current}
                                parent={parent}
                                rowIndex={index}
                                columnIndex={0}
                              >
                                <div
                                  className={`location-info-row-container ${
                                    Boolean(
                                      techMovePointInfo.locationID ===
                                        selectedLocationInfo.locationId
                                    )
                                      ? "yellow-back-ground"
                                      : ""
                                  }`}
                                  style={style}
                                >
                                  <div className="location-info-row-address">
                                    <TransitionablePortal
                                      closeOnTriggerClick
                                      openOnTriggerClick
                                      onOpen={() =>
                                        getTechLocaionAddress(
                                          techMovePointInfo.lng,
                                          techMovePointInfo.lat
                                        )
                                      }
                                      trigger={
                                        <img
                                          src={techLocationAddressImg}
                                          className="img"
                                        />
                                      }
                                    >
                                      <div className="address-text">
                                        {techLocationAddress ? (
                                          techLocationAddress
                                        ) : (
                                          <Loader active inline="centered" />
                                        )}
                                      </div>
                                    </TransitionablePortal>
                                  </div>
                                  <div className="location-info-row-time">
                                    <label>
                                      {techMovePointInfo.locationDate}
                                    </label>
                                    <label>
                                      {techMovePointInfo.locationHour}
                                    </label>
                                  </div>
                                </div>
                              </CellMeasurer>
                            );
                          }}
                        />
                      )}
                    </AutoSizer>
                  </div>
                </div>
                <div className="tech-areas-container">
                  <div className="tech-areas-header">
                    <label>مناطق عمل الفني</label>
                  </div>
                  <div className="tech-areas-list-container">
                    <AutoSizer>
                      {({ width, height }) => (
                        <Vlist
                          width={width}
                          height={height}
                          rowHeight={areasListCache.current.rowHeight}
                          deferredMeasurementCache={areasListCache.current}
                          rowCount={technicianAreas.length}
                          rowRenderer={({ key, index, style, parent }) => {
                            const techArea = technicianAreas[index];
                            return (
                              <CellMeasurer
                                key={key}
                                cache={areasListCache.current}
                                parent={parent}
                                rowIndex={index}
                                columnIndex={0}
                              >
                                <div style={style} className="tech-area">
                                  <Popup
                                    trigger={
                                      <Icon
                                        name="map"
                                        color="blue"
                                        className="show-area-on-map-icon"
                                        onClick={() =>
                                          drawTechArea(techArea.areaID)
                                        }
                                      />
                                    }
                                    content={
                                      resources.pages.playBackMap.showAreaOnMap
                                    }
                                  />
                                  <div>
                                    <label className="work-area-label-text">
                                      {resources.pages.playBackMap.workAreaName}
                                    </label>
                                    <label>{techArea.areaName}</label>
                                  </div>
                                </div>
                              </CellMeasurer>
                            );
                          }}
                        />
                      )}
                    </AutoSizer>
                  </div>
                </div>
                <div className="tech-info-box-container">
                  <div className="tech-info-box-header-container">
                    <label>معلومات الفني</label>
                  </div>
                  <div className="tech-info-box">
                    <div className="tech-info">
                      <img src={techImg} />
                      <label>{`${resources.pages.playBackMap.techName}${technicianInformation.techName}`}</label>
                    </div>
                    <div className="tech-info">
                      <img src={techPhone} />
                      <label>{`${resources.pages.playBackMap.techMobile}${technicianInformation.techMobile}`}</label>
                    </div>
                    <div className="tech-info">
                      <img src={techEmail} />
                      <label>{`${resources.pages.playBackMap.techEmail}${technicianInformation.techEmail}`}</label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default PlayBackMap;
