import React, { useState, useEffect } from "react";
import { resources } from "../../assets/LocalizationResources";
import serviceApi from "../../api";
import { Button, Loader, Modal, Popup, Segment } from "semantic-ui-react";
import moment from "moment";
import "./TicketInfoModal.scss";

export default function TicketInfoModal({
  ticketID,
  isOpen,
  closeTicketInfoModal,
}) {
  const [ticketInfo, setTicketInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadTicketStatusInfo = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "TicketStatus",
          include: [
            {
              innerJoin: true,
              model: "Lookup",
              as: "status",
              attributes: ["LookupName"],
            },
            {
              innerJoin: true,
              model: "Users",
              attributes: ["FullName"],
            },
          ],
          ...(ticketID ? { where: { TicketID: ticketID } } : {}),
        },
      });

      if (res && res.data && res.data.length) {
        const data = res.data.map((row) => {
          return {
            ...row,
            status: row.status.LookupName,
            createdAt: moment(row.createdAt).format("DD/MM/YYYY"),
            fullName: row.User.FullName,
          };
        });
        setTicketInfo(data);
      } else {
        setTicketInfo([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    loadTicketStatusInfo();
  }, [isOpen]);

  return (
    <Modal size="small" open={isOpen} className="ticketStatusInfo-container">
      <Modal.Header>
        {resources.ticketInformation.ticketStatusRecord}
      </Modal.Header>
      {isLoading ? (
        <Modal.Content>
          <Loader active inline="centered" />
        </Modal.Content>
      ) : (
        <>
          {ticketInfo.length > 0 ? (
            <Modal.Content scrolling>
              <Segment.Group horizontal>
                <Segment className="left-border segment-quarter ticketInfo-center-text">
                  {resources.pages.ticket.userType}
                </Segment>
                <Segment className="segment-quarter ticketInfo-center-text">
                  {resources.pages.ticket.ticketStatus}
                </Segment>
                <Segment className="segment-quarter ticketInfo-center-text">
                  {resources.common.createdBy}
                </Segment>
                <Segment className="segment-quarter ticketInfo-center-text">
                  {resources.common.createdDate}
                </Segment>
                <Segment className="segment-quarter ticketInfo-center-text">
                  {resources.common.notes}
                </Segment>
              </Segment.Group>
              {ticketInfo.map((ticket, idx) => (
                <Segment.Group horizontal key={idx}>
                  <Segment className="left-border segment-quarter ticketInfo-center-text">
                    {!ticket.isExternalUser
                      ? resources.tesco
                      : resources.pages.maintenanceReportDetails.client}
                  </Segment>
                  <Segment className="segment-quarter ticketInfo-center-text">
                    {ticket.status
                      ? ticket.status
                      : resources.common.notSpecified}
                  </Segment>
                  <Segment className="segment-quarter ticketInfo-center-text">
                    {ticket.fullName
                      ? ticket.fullName
                      : resources.common.notSpecified}
                  </Segment>
                  <Segment className="segment-quarter ticketInfo-center-text">
                    {ticket.createdAt
                      ? ticket.createdAt
                      : resources.common.notSpecified}
                  </Segment>
                  <Segment className="segment-quarter ticketInfo-center-text">
                    {Boolean(ticket.note) ? (
                      ticket.note.length > 19 ? (
                        <Popup
                          content={ticket.note}
                          trigger={
                            <span>{ticket.note.substring(0, 16) + "..."}</span>
                          }
                        />
                      ) : (
                        <p>{ticket.note}</p>
                      )
                    ) : (
                      resources.common.noNotes
                    )}
                  </Segment>
                </Segment.Group>
              ))}
            </Modal.Content>
          ) : (
            <div className="noStateData-message">
              {resources.ticketInformation.noTicketStatusRecord}
            </div>
          )}
        </>
      )}
      <Modal.Actions>
        <Button onClick={() => closeTicketInfoModal()} negative>
          {resources.common.close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
