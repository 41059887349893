import reportsReducer from "../reducers/reports.reducer";

export const userActions = {
  LOGIN_REQUEST_START: "LOGIN_REQUEST_START",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_FAILED: "LOGIN_REQUEST_FAILED",
  CONNECTION_ERROR: "CONNECTION_ERROR",
  LOGOUT: "LOGOUT",
  LANGUAGE_CHANGED: "LANGUAGE_CHANGED",
};

export const userActionCreator = {
  loginRequest: (credentials) => ({
    type: userActions.LOGIN_REQUEST_START,
    payload: credentials,
  }),
  login: (data) => ({
    type: userActions.LOGIN_REQUEST_SUCCESS,
    payload: data,
  }),
  loginFailed: () => ({
    type: userActions.LOGIN_REQUEST_FAILED,
  }),
  logout: () => ({
    type: userActions.LOGOUT,
  }),
  languageChanged: (lang) => ({
    type: userActions.LANGUAGE_CHANGED,
    payload: lang,
  }),
};

///////////////// Layout

export const layoutActions = {
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
};

export const layoutActionCreator = {
  toggleSideBar: (isVisible) => ({
    type: layoutActions.TOGGLE_SIDEBAR,
    payload: isVisible,
  }),
};
////////////////Reports Action /////////////////////////////
export const reportsActions = {
  SET_REPORT_NAME_ID: "SET_REPORT_NAME_ID",
  SET_REPORT_INTERVAL: "SET_REPORT_INTERVAL",
  SET_REPORT_OPTIONS: "SET_REPORT_OPTIONS",
  CLEAR_REPORT_DATA: "CLEAR_REPORT_DATA",
};

export const reportsActionCreator = {
  setReportNameID: (name, id) => ({
    type: reportsActions.SET_REPORT_NAME_ID,
    payload: { reportid: id, reportName: name },
  }),

  setReportInterval: (interval) => ({
    type: reportsActions.SET_REPORT_INTERVAL,
    payload: { interval: interval },
  }),

  setReportOptions: (options) => ({
    type: reportsActions.SET_REPORT_OPTIONS,
    payload: { options: options },
  }),
  clearReportData: (clear) => ({
    type: reportsActions.CLEAR_REPORT_DATA,
    payload: { clear: clear },
  }),
};

////////////////Cache Search /////////////////////////////
export const cacheSearchActions = {
  SET_CACHE_SEARCH: "SET_CACHE_SEARCH",
  CLEAR_CACHE_SEARCH: "CLEAR_CACHE_SEARCH",
  SET_EXTRA_OPTIONS: "SET_EXTRA_OPTIONS",
};

export const cacheSearchActionCreator = {
  setCacheSearch: (term, searchBy, page) => ({
    type: cacheSearchActions.SET_CACHE_SEARCH,
    payload: {
      term: term,
      searchBy: searchBy,
      pageName: page,
    },
  }),

  clearCacheSearch: () => ({
    type: cacheSearchActions.CLEAR_CACHE_SEARCH,
  }),

  setExtraOptions: (options) => ({
    type: cacheSearchActions.SET_EXTRA_OPTIONS,
    payload: {
      extraOptions: { ...options },
    },
  }),
};
