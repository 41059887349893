import React, { useEffect, useRef, useState } from "react";

import { Map } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { MarkerClusterGroup } from "leaflet.markercluster";
import MapLayers from "../../components/Map/MapLayers";
import { resources } from "../../assets/LocalizationResources";
import { icons } from "../../components/Map/helpers/MapIcons";

const TicketsMap = ({ tickets, selectedTicket }) => {
  const [points, setPoints] = useState([]);

  const mapRef = useRef(null);

  useEffect(() => {
    if (tickets.length) {
      setPoints(
        tickets.map((ticket) => ({
          coords: {
            lat: ticket.ClientBranch.latitude,
            lng: ticket.ClientBranch.longitude,
          },
          branch: ticket.ClientBranch.BranchName,
          computerNumber: ticket.ContractMachine
            ? ticket.ContractMachine.Machine.computerNumber
            : resources.common.notSpecified,
          contact: ticket.contactName,
          phone: ticket.contactPhone,
          clientName: ticket.ClientBranch.Client.ClientName,
          ticketNumber: ticket.ticketID,
          ticketAddress: ticket.ClientBranch.address,
        }))
      );
    }
  }, [tickets]);

  useEffect(() => {
    if (points.length) {
      const ticket = points.map((point) => ({
        lng: point.coords.lng,
        lat: point.coords.lat,
        ticketDetails: {
          branchName: point.branch,
          computerNumber: point.computerNumber,
          contactName: point.contact,
          phoneNumber: point.phone,
          clientName: point.clientName,
          ticketNumber: point.ticketNumber,
          ticketAddress: point.ticketAddress,
        },
      }));

      var bounds = L.latLngBounds(ticket);
      mapRef.current.leafletElement.flyToBounds(bounds, {
        padding: L.point(36, 36),
        animate: true,
      });

      const markers = new MarkerClusterGroup();
      for (const details of ticket) {
        let ticketInfo = ` 
        <div class='ticket-map-popUp-container'>
          <div class='ticket-map-popUp-Info-details-name'> 
            <label>${resources.pages.ticket.clientName}</label>
            <label>${resources.pages.ticket.branchName}</label>
            <label>${resources.pages.ticket.ticketID}</label>
            <label>${resources.pages.machines.computerNumber}</label>
            <label>${resources.pages.ticket.contactName}</label>
            <label>${resources.pages.ticket.contactPhone}</label>
            <label>${resources.pages.branch.address}</label>
          </div>     
          <div class='ticket-map-popUp-details-Info'>
            <label> <span>:</span> ${
              details.ticketDetails.clientName.length > 45
                ? details.ticketDetails.clientName.substring(0, 44) + "..."
                : details.ticketDetails.clientName
            }</label>
            <label> <span>:</span> ${details.ticketDetails.branchName}</label>
            <label> <span>:</span> ${details.ticketDetails.ticketNumber}</label>
            <label> <span>:</span> ${
              details.ticketDetails.computerNumber
            }</label>
            <label> <span>:</span> ${details.ticketDetails.contactName}</label>
            <label> <span>:</span> ${details.ticketDetails.phoneNumber}</label>
            <label> <span>:</span> ${
              Boolean(details.ticketDetails.ticketAddress)
                ? details.ticketDetails.ticketAddress.length > 45
                  ? details.ticketDetails.ticketAddress.substring(0, 44) + "..."
                  : details.ticketDetails.ticketAddress
                : resources.common.notSpecified
            }</label>
          </div>
      </div>`;

        markers.addLayer(
          L.marker(details, {
            icon: icons.TicketIcon,
          })
            .bindPopup(ticketInfo)
            .openPopup()
        );
      }
      mapRef.current.leafletElement.addLayer(markers);
    }
  }, [points]);

  useEffect(() => {
    if (selectedTicket && mapRef.current)
      mapRef.current.leafletElement.flyTo(
        {
          lat: selectedTicket.ClientBranch.latitude,
          lng: selectedTicket.ClientBranch.longitude,
        },
        15
      );
  }, [selectedTicket]);

  return (
    <Map
      animate
      ref={mapRef}
      center={[30.935654, 35.5565]}
      zoom={8}
      className="tickets-map"
    >
      <MapLayers />
    </Map>
  );
};

export default TicketsMap;
