import React, { useState, useEffect } from "react";
import "./map.scss";
import SearchServiceApi from "../../api/search-service.api";
import serviceApi from "../../api";
import { Grid, Select, Input, Segment, Icon, Button } from "semantic-ui-react";
import useDebounce from "../helpers/useDebounce";
import wkt from "terraformer-wkt-parser";
import { resources } from "../../assets/LocalizationResources";

export default function SearchOnMap({ setSearchData }) {
  const [searching, setSearching] = useState(false);
  const [type, setType] = useState("15");
  const [term, setTerm] = useState("");
  const [results, setResults] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebounce(term, 1000);

  const searchBy = [
    { key: "15", value: "15", text: "الكل" },
    { key: "1", value: "1", text: "الشوارع" },
    { key: "2", value: "2", text: "المدن" },
    { key: "4", value: "4", text: "القرى" },
    { key: "8", value: "8", text: "المعالم" },
    { key: "-1", value: "-1", text: "الاحداثيات" },
    { key: "10", value: "10", text: "المناطق الجغرافية" },
  ];

  const onSearch = () => {
    setSearching(true);
    if (inputValue) setTerm(inputValue.trim());
    else {
      setResults([]);
      setSearching(false);
    }
  };
  useEffect(() => {
    if (Boolean(term) && Boolean(type) && searching) {
      getResults();
    }
  }, [debouncedSearch]);

  const getResults = async () => {
    if (type === "10") {
      const res = await serviceApi.service("area").find({
        query: {
          areaName: term,
        },
      });
      if (res && res.data && res.data.length > 0) {
        var editedData = res.data.map((row) => ({
          Label: row.areaName,
          WKT: row.geometry,
        }));
        setResults(editedData);
      } else setResults([]);
      setSearching(false);
    } else {
      const res = await SearchServiceApi.search(term, type);
      if (res && res.data && res.data.length > 0) setResults(res.data);
      else setResults([]);
      setSearching(false);
    }
  };
  useEffect(() => {
    if (!term) setInputValue("");
    if (Boolean(term) && Boolean(type)) getResults();
  }, [type]);

  useEffect(() => {
    if (!inputValue) {
      setTerm("");
      setResults([]);
    }
  }, [inputValue]);

  const handleSelect = (row) => {
    setInputValue(row.Label);
    setTerm("");
    setResults([]);
    setSearchData(wkt.parse(row.WKT));
  };
  return (
    <div className="map-search-container">
      <Select
        className="search-type"
        placeholder={resources.common.searchBy}
        options={searchBy}
        value={type ? type : "15"}
        onChange={(e, { value }) => setType(value)}
      />
      <Input
        className="search-box"
        placeholder={resources.common.searchDots}
        value={inputValue}
        loading={searching}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Icon
        className="icon-close"
        name="close"
        onClick={() => setInputValue("")}
      />
      <Button
        content={resources.common.search}
        disabled={!Boolean(searchBy && inputValue)}
        color="blue"
        onClick={() => onSearch()}
      />
      {Boolean(term) &&
        Boolean(type) &&
        Boolean(inputValue) &&
        (results.length === 0 && searching === false ? (
          <Segment className=" search-result-segment">
            {resources.common.noResultFor}
            <span style={{ color: "red" }}>{inputValue}</span>
          </Segment>
        ) : (
          <Segment className="map-search-results search-result-segment">
            {results.map((r, idx) => (
              <p
                className="map-search-option"
                onClick={() => handleSelect(r)}
                key={idx}
              >
                {r.Label}
              </p>
            ))}
          </Segment>
        ))}
    </div>
  );
}
