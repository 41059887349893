import React, { useState, useEffect } from "react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import { Loader } from "semantic-ui-react";
import "./MachinesModelClientPrintOut.scss";
import moment from "moment";
const MachinesModelClientPrintOut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [machinesModelClientData, setMachinesModelClientData] = useState([]);
  const location = useLocation();

  const headers = {
    ComputerNumber: "رقم الكمبيوتر",
    SerialNumber: "الرقم التسلسلي",
    ClientName: "اسم العميل",
    machineModel: "موديل الالة",
    machineType: "نوع الالة",
    AreaName: "المنطقة",
    Address: "الموقع",
    EndDate: "تاريخ الانتهاء ",
    FirstInstallationDate: "تاريخ التركيب",
    LastVisitDate: "تاريخ اخر زيارة",
    Counter1: "رقم العداد 1	",
    Counter2: "رقم العداد 2",
  };

  const { clientID, model, clientName, modelName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await serviceApi.service("queries-text").find({
        query: {
          clientID: clientID,
          model: model,
          type: 9,
        },
      });
      const data = res.map((row) => {
        return {
          ...row,
          ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
          SerialNumber: row.SerialNumber ? row.SerialNumber : null,
          machineModel: row.machineModel ? row.machineModel : null,
          ClientName: row.ClientName ? row.ClientName : null,
          ContractNumber: row.ContractNumber ? row.ContractNumber : null,
          AreaName: row.AreaName ? row.AreaName : null,
          Address: row.Address ? row.Address : null,
          EndDate: row.EndDate ? row.EndDate : null,
          FirstInstallationDate: row.FirstInstallationDate
            ? row.FirstInstallationDate
            : null,
          LastVisitDate: row.LastVisitDate ? row.LastVisitDate : null,
          Counter1: row.Counter1 ? row.Counter1 : 0,
          Counter2: row.Counter2 ? row.Counter2 : 0,
          Phone: row.Phone ? row.Phone : "لا يوجد",
        };
      });
      setMachinesModelClientData(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setMachinesModelClientData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="machines-model-client-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(machinesModelClientData.length) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"تقرير عام عن الالات حسب الموديل/العميل"}
            dateTitle={"تاريخ نهاية العقد"}
            hasDate={false}
            model={modelName}
            client={clientName}
            data={machinesModelClientData}
            headers={headers}
          />
          <div className="machines-model-client-table-container">
            <table>
              <thead>
                <tr>
                  <th className="small-cell-grouping">رقم الكمبيوتر</th>
                  <th className="small-cell-grouping">الرقم التسلسلي</th>
                  <th className="small-cell-grouping">موديل الاّلة</th>
                  <th className="small-cell-grouping">اسم العميل</th>
                  <th className="small-cell-grouping">رقم العقد</th>
                  <th className="medium-cell-grouping">المنطقة</th>
                  <th className="big-cell-grouping">الموقع</th>
                  <th className="medium-cell-grouping">تاريخ الانتهاء</th>
                  <th className="medium-cell-grouping">تاريخ التركيب</th>
                  <th className="medium-cell-grouping">تاريخ اخر زيارة</th>
                  <th className="small-cell-grouping">رقم العداد 1</th>
                  <th className="small-cell-grouping">رقم العداد 2</th>
                  <th className="small-cell-grouping">رقم الهاتف</th>
                </tr>
              </thead>
              <tbody>
                {machinesModelClientData.map((info, idx) => (
                  <tr key={idx}>
                    <th>{info.ComputerNumber}</th>
                    <th>{info.SerialNumber}</th>
                    <th>{info.machineModel}</th>
                    <th>{info.ClientName}</th>
                    <th>{info.ContractNumber}</th>
                    <th>{info.AreaName}</th>
                    <th>{info.Address}</th>
                    <th>
                      {info.EndDate
                        ? moment(info.EndDate).format("YYYY-MM-DD")
                        : "لا يوجد"}
                    </th>
                    <th>
                      {info.FirstInstallationDate
                        ? moment(info.FirstInstallationDate).format(
                            "YYYY-MM-DD"
                          )
                        : "لا يوجد"}
                    </th>
                    <th>
                      {info.LastVisitDate
                        ? moment(info.LastVisitDate).format("YYYY-MM-DD")
                        : "لا يوجد"}
                    </th>
                    <th>{info.Counter1}</th>
                    <th>{info.Counter2}</th>
                    <th>{info.Phone}</th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default MachinesModelClientPrintOut;
