import React, { useState, useEffect } from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import { resources } from '../assets/LocalizationResources';

export default function DeleteModal({
	isOpen,
	size = 'mini',
	onCancel = null,
	onConfirm = null,
	header = resources.common.deleteNotification,
	content = resources.common.deleteMessage,
	errorMessage = null,
	loading = false,
}) {
	return (
		<Modal size={size} open={isOpen}>
			<Modal.Header>{header}</Modal.Header>
			<Modal.Content>
				<p>{content}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button
					icon='remove'
					labelPosition='right'
					negative
					content={resources.common.cancel}
					onClick={() => onCancel && onCancel()}
					disabled={loading}
					loading={loading}
				></Button>
				<Button
					positive
					icon='checkmark'
					labelPosition='right'
					content={resources.common.delete}
					onClick={() => onConfirm && onConfirm()}
					disabled={loading}
					loading={loading}
				/>
				{errorMessage && <Message floating negative content={errorMessage} />}
			</Modal.Actions>
		</Modal>
	);
}
