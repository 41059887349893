import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Icon, Popup } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import DeleteModal from "../../components/DeleteModal";
import SearchBy from "../../components/SearchBy/SearchBy";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ContractRenewal from "./ContractRenewal";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

export default function Contracts() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [contractID, setContractID] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, total: 0 });
  const [openContractRenewalModel, setOpenContractRenewalModel] =
    useState(false);
  const [contractMacineIsAssociated, setContractMacineIsAssociated] =
    useState(false);
  const [activePage, setActivePage] = useState(1);

  const searchState = useSelector((state) => state.cacheSearch);
  const [associatedContractMachines, setAssociatedContractMachines] = useState(
    []
  );

  const headers = {
    contractNumber: resources.pages.addContract.contractNumber,
    contractType: resources.pages.addContract.contractType,
    clientName: resources.pages.clients.clientName,
    startDate: resources.pages.addContract.startDate,
    endDate: resources.pages.addContract.endDate,
    endContract: resources.pages.addContract.contractStatus,
  };

  const getOptions = async (parentID) => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: parentID,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        return res.data.map((item) => {
          return {
            key: item.LookupID,
            text: item.LookupName,
            value: item.LookupID,
          };
        });
      else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const fillSearchData = async () => {
    setSearchData({
      contractNumber: {
        type: "text",
        label: resources.pages.addContract.contractNumber,
      },
      contractType: {
        type: "ddl",
        label: resources.pages.addContract.contractType,
        options: await getOptions(38),
      },
      clientName: {
        type: "text",
        label: resources.pages.clients.clientName,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "contracts"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadContracts = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          innerJoin: true,
          model: "Contract",
          include: [
            {
              model: "Lookup",
              as: "ContractType",
              attributes: ["LookupName"],
              ...whereSearch("contractType", "LookupID"),
            },
            {
              model: "Client",
              attributes: ["clientName"],
              ...whereLikeSearch("clientName"),
            },
          ],
          attributes: [
            "contractId",
            "contractNumber",
            "startDate",
            "endDate",
            "contractDate",
            "contractValue",
            "createdAt",
          ],
          ...whereLikeSearch("contractNumber"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
          order: [["startDate", "DESC"]],
        },
      });

      if (res && res.data && res.data.length > 0) {
        const newData = res.data.map((row) => {
          const editedRow = {
            ...row,
            clientName: row.Client
              ? row.Client.clientName
              : resources.common.notSpecified,
            contractNumber: row.contractNumber,
            contractType: row.ContractType.LookupName,
            startDate: Boolean(row.startDate)
              ? moment(row.startDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
            endDate: Boolean(row.endDate)
              ? moment(row.endDate).format("DD/MM/YYYY")
              : resources.common.notSpecified,
            endContract: Boolean(row.endDate)
              ? new Date().getTime() > new Date(row.endDate).getTime()
                ? resources.pages.addContract.endContract
                : resources.pages.addContract.notEndContract
              : resources.common.notSpecified,
          };
          delete editedRow.Client;
          return editedRow;
        });

        setContracts(newData);
      } else setContracts([]);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadContracts();
  }, [searching, activePage]);

  const isContractMachineAssociated = async (id) => {
    const res = await serviceApi.service("ContractMachine").find({
      query: {
        $select: ["contractIsActive", "machineID"],
        ContractID: id,
        ContractIsActive: 0,
      },
    });
    setAssociatedContractMachines(res.data);
    return Boolean(res && res.data && res.data.length > 0);
  };

  const onCellClick = async (row, type) => {
    setContractID(row.contractId);
    if (type === "edit") {
      router.push("/contracts/manageContract", {
        contractID: row.contractId,
      });
    } else if (type === "remove") {
      setIsDelete(true);
    } else if (type === "addMachine") {
      router.push("/contracts/manageContractMachine", {
        contractId: row.contractId,
        endContract: row.endContract,
      });
    } else if (type === "contractRenewal") {
      if (await isContractMachineAssociated(row.contractId))
        setContractMacineIsAssociated(true);
      else setContractMacineIsAssociated(false);

      setOpenContractRenewalModel(true);
    } else if (type === "contractPayments") {
      router.push("/contracts/contractPayments", {
        contractId: row.contractId,
        endContract: row.endContract,
      });
    }
  };

  const deleteContract = async () => {
    try {
      await serviceApi
        .service("contract")
        .remove(contractID)
        .then((res) => {
          setIsDelete(false);
          loadContracts();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteContract()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      {isAuthorized(Perm.contractRenewal) && (
        <ContractRenewal
          isOpen={openContractRenewalModel}
          contractID={contractID}
          contractMacineIsAssociated={contractMacineIsAssociated}
          setContractMacineIsAssociated={setContractMacineIsAssociated}
          associatedContractMachines={associatedContractMachines}
          closeContractRenewalModal={(refreshTable) => {
            if (refreshTable) loadContracts();
            setOpenContractRenewalModel(false);
          }}
        />
      )}

      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.contracts.pageTitle}
            icon="file alternate"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addContract) && (
              <Button
                basic
                color="blue"
                content={resources.pages.contracts.addContract}
                onClick={() => router.push("/contracts/manageContract")}
              />
            )}
            {isAuthorized(Perm.viewContract) && (
              <ExportDataToExcel
                data={contracts}
                headers={headers}
                title={resources.common.excelExport.contracts}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="contracts"
              getSearchResults={getSearchResults}
              searchTypes={searchData}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={contracts}
              edit={
                isAuthorized(Perm.editContract)
                  ? {
                      valuesID: "endContract",
                      values: [resources.pages.addContract.endContract],
                    }
                  : null
              }
              remove={isAuthorized(Perm.deleteContract) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              extraCells={[
                ...(isAuthorized(Perm.addMachinesToContract)
                  ? [
                      {
                        key: "addMachine",
                        children: (
                          <Button
                            style={{ padding: "3px" }}
                            icon="computer"
                            size="tiny"
                            content={
                              <p
                                style={{
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  padding: 3,
                                }}
                              >
                                {
                                  resources.pages.addContractMachine
                                    .contractMachines
                                }
                              </p>
                            }
                            labelPosition="left"
                            basic
                            color="blue"
                          />
                        ),
                        isCliCkable: true,
                        tooltip: resources.pages.addContractMachine.pageTitle,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.contractRenewal)
                  ? [
                      {
                        key: "contractRenewal",
                        tooltip: resources.pages.contracts.contractRenewal,
                        children: <Icon name="history" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.contractPayments)
                  ? [
                      {
                        key: "contractPayments",
                        tooltip:
                          resources.pages.contractPayment.contractPayments,
                        children: <Icon name="payment" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
