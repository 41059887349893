import React, { useState } from "react";
import { resources } from "../../assets/LocalizationResources";
import {
  Input,
  Icon,
  Segment,
  Dropdown,
  Popup,
  Loader,
  Button,
  TextArea,
  Modal,
  Label,
} from "semantic-ui-react";
import locatorImg from "../../assets/images/locator.svg";
import GeoLocationModal from "../TechnicianHomePage/GeoLocationModal";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";

const ComputerInfos = ({ getMachineInfo, machineInfo }) => {
  const searchOptions = [
    { key: 1, text: resources.pages.machines.computerNumber, value: 1 },
    { key: 2, text: resources.pages.machines.serialNumber, value: 2 },
  ];
  const [computerNumber, setComputerNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [branchID, setBranchID] = useState(null);
  const [selectedSearchOption, setSelectedSearchOption] = useState(1);
  const [openGeoLocationModal, setOpenGeoLocationModal] = useState(false);
  const [branchCoords, setBranchCoords] = useState({});
  const [branchCoordsAreLoading, setBranchCoordsAreLoading] = useState(false);

  const [openEditRequestModal, setOpenEditRequestModal] = useState(false);
  const [editNote, setEditNote] = useState("");
  const [sendingEditRequestIsLoading, setSendingEditRequestIsLoading] =
    useState(false);

  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const onSearchClicked = async (e) => {
    if (computerNumber) {
      setIsLoading(true);
      if (selectedSearchOption === 1) await getMachineInfo(computerNumber);
      else await getMachineInfo(computerNumber, true);
    }
  };

  const clearComputerNumberInput = async () => {
    await getMachineInfo(null);
  };

  const getBranchCoords = async (branchId) => {
    try {
      setBranchCoordsAreLoading(true);
      const res = await serviceApi.service("ClientBranch").find({
        query: {
          $select: ["longitude", "latitude"],
          branchID: branchId,
          $limit: 1,
        },
      });

      if (res && res.data && res.data.length > 0) {
        setBranchCoords({
          lat: res.data[0].latitude,
          long: res.data[0].longitude,
        });
      } else setBranchCoords({});

      setBranchCoordsAreLoading(false);
    } catch (e) {
      setBranchCoordsAreLoading(false);
      console.log(e);
    }
  };

  const onEditRequestSend = async () => {
    try {
      if (!Boolean(editNote)) setIsErrorVisible(true);
      else {
        setSendingEditRequestIsLoading(true);

        await serviceApi.service("ContractMachineEditRequests").create({
          ContractMachineID: machineInfo.ContractMachineID,
          editRequestNote: editNote,
        });

        setSendingEditRequestIsLoading(false);
        setEditNote("");
        setSuccessModalVisible(true);
      }
    } catch (err) {
      setSendingEditRequestIsLoading(false);
      setEditNote("");
      console.log(err);
    }
  };

  return (
    <div className="computer-info-section">
      <div className="computer-info-section-content">
        <div className="computer-number-input-container">
          <div className="search-options-container">
            <label>{resources.common.searchBy}</label>
            <Dropdown
              options={searchOptions}
              onChange={(e, { value }) => setSelectedSearchOption(value)}
              value={selectedSearchOption}
            />
          </div>
          <Input
            className="computer-number-input"
            iconPosition="left"
            placeholder={`${resources.pages.maintenanceReport.search}`}
            min={0}
            readOnly={Boolean(machineInfo && machineInfo.computerNumber)}
            loading={isLoading}
            onKeyPress={(e) =>
              Boolean(computerNumber && e.charCode === 13) &&
              document.getElementById("computerInputSearchIcon").click()
            }
            value={
              computerNumber ||
              (machineInfo && machineInfo.computerNumber) ||
              ""
            }
            icon={
              Boolean(machineInfo && machineInfo.computerNumber) ? (
                <Icon
                  name="delete"
                  onClick={clearComputerNumberInput}
                  inverted
                  circular
                  color="red"
                  link
                />
              ) : (
                <Icon
                  name="search"
                  id="computerInputSearchIcon"
                  onClick={onSearchClicked}
                  inverted
                  circular
                  link
                />
              )
            }
            onChange={(e) => setComputerNumber(e.target.value)}
          />
          {!!machineInfo  && (
          <Label className="m-1" color='red' size="medium"  > {`سعر الصورة : ${machineInfo.CopyPrice ?? 0}`}</Label>
        )}
        </div>
        {!!machineInfo && (
          <Button
            type="button"
            icon="edit"
            content="طلب تعديل"
            labelPosition="left"
            primary
            onClick={() => setOpenEditRequestModal(true)}
          />
        )}
      </div>
      {Boolean(machineInfo) && (
        <div className="computer-info">
          <Segment.Group horizontal>
            <Segment className="left-border computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.machineModel} : `}</label>
              <label className="colored-label">
                {machineInfo.MachineModel ? machineInfo.MachineModel : " - "}
              </label>
            </Segment>
            <Segment className="computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.machineType} : `}</label>
              <label className="colored-label">
                {machineInfo.MachineType ? machineInfo.MachineType : " - "}
              </label>
            </Segment>
            <Segment className="computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.serialNumber} : `}</label>
              <label className="colored-label">
                {machineInfo.SerialNumber ? machineInfo.SerialNumber : " - "}
              </label>
            </Segment>
          </Segment.Group>
          <Segment.Group horizontal>
            <Segment className="left-border computer-info-segment">
              <label>
                {`${resources.pages.maintenanceReport.clientName} : `}
              </label>
              <label className="colored-label">
                {machineInfo.ClientName ? machineInfo.ClientName : " - "}
              </label>
            </Segment>
            <Segment className="computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.location} : `}</label>
              <label className="colored-label">
                {machineInfo.BranchName ? machineInfo.BranchName : " - "}
              </label>
            </Segment>
            <Segment className="computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.area} : `}</label>
              <label className="colored-label">
                {machineInfo.AreaName ? machineInfo.AreaName : " - "}
              </label>
            </Segment>
          </Segment.Group>
          <Segment.Group horizontal>
            <Segment className="left-border computer-info-segment">
              <label>{`إحداثيات الموقع : `}</label>
              <div>
                {branchCoordsAreLoading ? (
                  <Loader active={branchCoordsAreLoading} size="tiny" />
                ) : Boolean(branchCoords.lat && branchCoords.long) ? (
                  `(${branchCoords.lat} , ${branchCoords.long})`
                ) : (
                  resources.pages.branch.updateBranchCoords
                )}
              </div>
              <Popup
                content={resources.pages.branch.updateBranchCoords}
                position="bottom right"
                trigger={
                  <img
                    src={locatorImg}
                    className="img-icon"
                    onClick={() => {
                      setBranchID(machineInfo.BranchID);
                      setOpenGeoLocationModal(true);
                    }}
                  />
                }
                color="blue"
              />
            </Segment>
            <Segment className="computer-info-segment">
              <label>
                {`${resources.pages.maintenanceReport.contractNumber} : `}
              </label>
              <label className="colored-label">
                {machineInfo.ContractNumber
                  ? machineInfo.ContractNumber
                  : " - "}
              </label>
            </Segment>
            <Segment className="computer-info-segment">
              <label>{`${resources.pages.maintenanceReport.contractType} : `}</label>
              <label className="colored-label">
                {machineInfo.ContractType ? machineInfo.ContractType : " - "}
              </label>
            </Segment>
          </Segment.Group>

          {machineInfo.contractIsEnd && (
            <div className="contract-is-ended-attention-message-contrainer">
              <label>
                {
                  resources.pages.maintenanceReport
                    .contractIsEndedAttentionMessage
                }
              </label>
            </div>
          )}

          {machineInfo.BranchID && (
            <GeoLocationModal
              isOpen={openGeoLocationModal}
              branchID={branchID}
              closeGeoLocationModal={(reLoadBranchCoords) => {
                setBranchID(null);
                setOpenGeoLocationModal(false);
                if (reLoadBranchCoords) getBranchCoords(machineInfo.BranchID);
              }}
            />
          )}

          <Modal
            size="small"
            open={openEditRequestModal}
            className="edit-request-modal"
          >
            <Modal.Header>
              {resources.pages.contractMachineEditRequests.editRequest}
            </Modal.Header>
            <Modal.Content>
              <TextArea
                style={{ marginTop: 10 }}
                placeholder={
                  resources.pages.contractMachineEditRequests
                    .pleaseEnterRequiredModifications
                }
                onChange={(e, { value }) => setEditNote(value)}
              />
              {isErrorVisible && (
                <div className="error-message-container">
                  <Label
                    basic
                    color="red"
                    pointing
                    content={
                      resources.pages.contractMachineEditRequests
                        .pleaseEnterRequiredModifications
                    }
                  />
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="blue"
                type="button"
                loading={sendingEditRequestIsLoading}
                disabled={sendingEditRequestIsLoading}
                onClick={onEditRequestSend}
                content={resources.common.send}
              />

              <Button
                type="button"
                loading={sendingEditRequestIsLoading}
                disabled={sendingEditRequestIsLoading}
                onClick={() => {
                  setOpenEditRequestModal(false);
                  if (!!editNote) setEditNote("");
                  if (isErrorVisible) setIsErrorVisible(false);
                }}
                content={resources.common.cancel}
              />
            </Modal.Actions>
          </Modal>
        </div>
      )}

      <SuccessModal
        visible={successModalVisible}
        onConfirm={() => {
          setSuccessModalVisible(false);
          setOpenEditRequestModal(false);
          if (isErrorVisible) setIsErrorVisible(false);
        }}
        header={
          resources.pages.contractMachineEditRequests
            .editRequestSentSuccessfully
        }
      />
    </div>
  );
};

export default ComputerInfos;
