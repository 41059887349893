import React, { useState } from "react";
import "./PrintOutHeaderLandscape.scss";
import Date from "../GetDate/GetDate";
import PrintOutdateFilter from "../PrintOutFilters/PrintOutDateFilter";
import PrintOutFilter from "../PrintOutFilters/PrintOutFilter";
import ExportDataToExcel from "../ExportExcelFile/ExportDataToExcel";

const PrintOutHeaderLandscape = ({
  printOutTitle,
  dateTitle,
  startDate,
  endDate,
  hasDate,
  model,
  client,
  data,
  extraHeader,
  headers,
}) => {
  return (
    <div className="printout-header-landscape">
      <div className="printout-main-and-info-container">
        {Boolean(data) ? (
          <div className="export-to-excel-container no-print">
            <ExportDataToExcel
              data={data}
              headers={headers}
              title={printOutTitle}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="printout-main">
          <h1 className="printout-main-name">
            شركة المعدات و التجهيزات الفنية
          </h1>
          <h3 className="printout-main-title"> {printOutTitle}</h3>
        </div>
        <div className="printout-info">
          <Date />
        </div>
      </div>
      <div className="printout-filter">
        {extraHeader && extraHeader}
        {hasDate && model ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <PrintOutdateFilter
              from={startDate}
              to={endDate}
              dateTitle={dateTitle}
            />
            <PrintOutFilter model={model} client={client} />
          </div>
        ) : hasDate ? (
          <PrintOutdateFilter
            from={startDate}
            to={endDate}
            dateTitle={dateTitle}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="divider"></div>
    </div>
  );
};

export default PrintOutHeaderLandscape;
