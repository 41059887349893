import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon, Loader, Segment, Image, Table } from "semantic-ui-react";
import serviceApi from "../../api";
import "./ViewMaintenanceReportDetails.scss";
import { resources } from "../../assets/LocalizationResources";
import noImg from "../../assets/images/no-img.png";
import moment from "moment";
import { generateMachinePartInvoiceNumber } from "./MaintenanceReportsList";

const ViewMaintenanceReportDetails = () => {
  const location = useLocation();

  const [maintenanceReportDetails, setMaintenanceReportDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [imgData, setImgData] = useState("");

  const getMaintenanceReportDetails = async (maintenanceReportID) => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "MaintenanceReport",
          attributes: [
            "MaintenanceReportId",
            "createdat",
            "counter1",
            "counter2",
            "lastCounter1",
            "lastCounter2",
            "damage",
            "causes",
            "action",
            "clientEmployeeName",
            "clientEmployeeJob",
            "startDate",
            "endDate",
            "maintenanceTypeID",
            "visitTypeID",
            "Counter1Image",
            "Counter2Image",
            "ClientSignature",
            "ClientCardImage",
            "manualReportNumber",
            "manualReportImg",
            "machinePartInvoiceNumber",
          ],
          include: [
            {
              innerJoin: true,
              model: "ContractMachine",
              attributes: ["ContractMachineID"],
              include: [
                {
                  innerJoin: true,
                  model: "Contract",
                  include: [
                    {
                      model: "Lookup",
                      as: "ContractType",
                      attributes: ["LookupName"],
                    },
                  ],
                  attributes: ["contractNumber", "startDate", "endDate"],
                },
                {
                  innerJoin: true,
                  model: "Machine",
                  attributes: ["computerNumber", "serialNumber"],
                  include: [
                    {
                      model: "Lookup",
                      as: "MachineType",
                      attributes: ["LookupName"],
                    },
                    {
                      model: "Lookup",
                      as: "MachineModel",
                      attributes: ["LookupName"],
                    },
                  ],
                },
                {
                  innerJoin: true,
                  model: "ClientBranch",
                  attributes: ["BranchName"],
                  include: [
                    {
                      model: "Client",
                      attributes: ["ClientName"],
                    },
                    {
                      model: "Area",
                      attributes: ["AreaName"],
                    },
                  ],
                },
                {
                  model: "Technicians",
                  attributes: ["technicianName"],
                },
              ],
            },
            {
              model: "Lookup",
              as: "VisitType",
              attributes: ["LookupName"],
            },
            {
              model: "Lookup",
              as: "MaintenanceType",
              attributes: ["LookupName"],
            },
          ],
        },
        {
          model: "Technicians",
          attributes: ["technicianName"],
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReportTechnician",
          attributes: ["TechnicianID"],
          jsonInclude: jsonInclude,
          where: { maintenanceReportID: maintenanceReportID },
        },
      });

      const machinePartsRes = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReportMachinePart",
          attributes: [
            "MaintenanceReportMachinePartID",
            "Quantity",
            "Description",
            "ACTID",
            "FCID",
            "PartNumber",
          ],
          include: [
            {
              model: "MachinePart",
              attributes: ["PartNameId"],
              include: [
                {
                  model: "Lookup",
                  attributes: ["LookupName"],
                },
              ],
            },
          ],
          where: { maintenanceReportID: maintenanceReportID },
        },
      });

      if (res && res.data && res.data.length > 0) {
        const flattenedData = res.data.map((d) => ({
          maintenanceReportNumber: d.MaintenanceReport.MaintenanceReportId,
          createdAt: d.MaintenanceReport.createdat,
          reportCreator: d.Technician.technicianName,
          respTech:
            d.MaintenanceReport.ContractMachine.Technician.technicianName,
          computerNumber:
            d.MaintenanceReport.ContractMachine.Machine.computerNumber,
          serialNumber:
            d.MaintenanceReport.ContractMachine.Machine.serialNumber,
          machineType:
            d.MaintenanceReport.ContractMachine.Machine.MachineType.LookupName,
          machineModel:
            d.MaintenanceReport.ContractMachine.Machine.MachineModel.LookupName,
          branchName:
            d.MaintenanceReport.ContractMachine.ClientBranch.BranchName,
          branchArea:
            d.MaintenanceReport.ContractMachine.ClientBranch.Area.AreaName,
          counter1Image: d.MaintenanceReport.Counter1Image
            ? d.MaintenanceReport.Counter1Image
            : noImg,
          counter2Image: d.MaintenanceReport.Counter2Image
            ? d.MaintenanceReport.Counter2Image
            : noImg,
          contractNumber:
            d.MaintenanceReport.ContractMachine.Contract.contractNumber,
          contractType:
            d.MaintenanceReport.ContractMachine.Contract.ContractType
              .LookupName,
          clientName:
            d.MaintenanceReport.ContractMachine.ClientBranch.Client.ClientName,
          contractStartDate: new Date(
            d.MaintenanceReport.ContractMachine.Contract.startDate
          ).toLocaleDateString(),
          contractEndDate: new Date(
            d.MaintenanceReport.ContractMachine.Contract.endDate
          ).toLocaleDateString(),
          endContract:
            new Date().getTime() >
            new Date(
              d.MaintenanceReport.ContractMachine.Contract.endDate
            ).getTime()
              ? resources.pages.addContract.endContract
              : resources.pages.addContract.notEndContract,
          clientSignatureImage: d.MaintenanceReport.ClientSignature,
          clientCardImage: d.MaintenanceReport.ClientCardImage
            ? d.MaintenanceReport.ClientCardImage
            : noImg,
          maintenanceType: d.MaintenanceReport.MaintenanceType
            ? d.MaintenanceReport.MaintenanceType.LookupName
            : resources.common.notSpecified,
          visitType: d.MaintenanceReport.VisitType
            ? d.MaintenanceReport.VisitType.LookupName
            : resources.common.notSpecified,
          counter1: d.MaintenanceReport.counter1,
          counter2: d.MaintenanceReport.counter2,
          lastCounter1: d.MaintenanceReport.lastCounter1,
          lastCounter2: d.MaintenanceReport.lastCounter2,
          clientEmployeeJob: d.MaintenanceReport.clientEmployeeJob
            ? d.MaintenanceReport.clientEmployeeJob
            : resources.common.notSpecified,
          clientEmployeeName: d.MaintenanceReport.clientEmployeeName
            ? d.MaintenanceReport.clientEmployeeName
            : resources.common.notSpecified,
          action: d.MaintenanceReport.action
            ? d.MaintenanceReport.action
            : resources.common.notSpecified,
          damage: d.MaintenanceReport.damage
            ? d.MaintenanceReport.damage
            : resources.common.notSpecified,
          causes: d.MaintenanceReport.causes
            ? d.MaintenanceReport.causes
            : resources.common.notSpecified,
          startHour: moment(d.MaintenanceReport.startDate).format(
            "HH:mm , DD/MM/YYYY"
          ),
          endHour: moment(d.MaintenanceReport.endDate).format(
            "HH:mm , DD/MM/YYYY"
          ),
          counter1AchivedCopies:
            d.MaintenanceReport.counter1 - d.MaintenanceReport.lastCounter1,
          counter2AchivedCopies:
            d.MaintenanceReport.counter2 - d.MaintenanceReport.lastCounter2,
          manualReportNumber: d.MaintenanceReport.manualReportNumber
            ? d.MaintenanceReport.manualReportNumber
            : null,
          manualReportImg: d.MaintenanceReport.manualReportImg
            ? d.MaintenanceReport.manualReportImg
            : null,
          machinePartInvoiceNumber:
            d.MaintenanceReport.machinePartInvoiceNumber,
        }));

        if (
          machinePartsRes &&
          machinePartsRes.data &&
          machinePartsRes.data.length > 0
        ) {
          flattenedData[0].machineParts = machinePartsRes.data;
        }

        setMaintenanceReportDetails(flattenedData[0]);
      } else setMaintenanceReportDetails({});
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (
      Boolean(location && location.state && location.state.maintenanceReportID)
    )
      getMaintenanceReportDetails(location.state.maintenanceReportID);
  }, [location]);

  const DetailsBox = ({
    icon,
    title,
    data,
    isImage,
    noborder,
    isSignature,
  }) => {
    return (
      <div
        className={`details-box-container ${
          Boolean(noborder) ? "no-border" : ""
        } `}
      >
        <div className="details-title-container">
          <Icon name={icon} size="small" />
          <label className="title-label">{title}</label>
        </div>
        {data ? (
          isImage ? (
            <div
              className={`${isSignature ? "signature-img-container" : ""}`}
              onClick={() => {
                setImgData(data);
                setIsImgOpen(true);
              }}
            >
              <Image src={data} className="data-img" />
            </div>
          ) : (
            <div className="details-data-container">
              <label className="data">{data}</label>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="maintenance-report-details-container">
        {isLoading ? (
          <Loader active={isLoading} />
        ) : (
          <>
            <div className="header-container">
              <label className="info-label">
                {`${
                  resources.pages.maintenanceReportDetails
                    .maintenanceReportNumber
                } #${maintenanceReportDetails.maintenanceReportNumber} ${
                  maintenanceReportDetails.manualReportNumber
                    ? ` ، ${resources.pages.maintenanceReportDetails.manualReportNumber} : ${maintenanceReportDetails.manualReportNumber}`
                    : ""
                }`}
              </label>
              <DetailsBox
                icon="user"
                title={resources.pages.maintenanceReportDetails.reportCreator}
                data={maintenanceReportDetails.reportCreator}
              />
            </div>

            <div className="multiple-segments-container">
              <div className="segment-container">
                <label className="info-label">
                  {resources.pages.maintenanceReportDetails.reportInfo}
                </label>
                <Segment className="details-box-segment">
                  <DetailsBox
                    icon="calendar alternate"
                    title={
                      resources.pages.maintenanceReportDetails
                        .maintenanceReportCreatedAt
                    }
                    data={moment(maintenanceReportDetails.createdat).format(
                      "DD/MM/YYYY"
                    )}
                  />
                  <DetailsBox
                    icon="setting"
                    title={resources.pages.maintenanceReportDetails.visitType}
                    data={maintenanceReportDetails.visitType}
                  />
                  <DetailsBox
                    icon="bug"
                    title={
                      resources.pages.maintenanceReportDetails.maintenanceType
                    }
                    data={maintenanceReportDetails.maintenanceType}
                  />
                </Segment>
              </div>

              <div className="segment-container">
                <label className="info-label">
                  {resources.pages.maintenanceReportDetails.clientInfo}
                </label>
                <Segment className="details-box-segment">
                  <DetailsBox
                    icon="star"
                    title={resources.pages.maintenanceReportDetails.client}
                    data={maintenanceReportDetails.clientName}
                  />
                  <DetailsBox
                    icon="user"
                    title={
                      resources.pages.maintenanceReportDetails.clientNameJob
                    }
                    data={maintenanceReportDetails.clientEmployeeName}
                  />
                  <DetailsBox
                    icon="briefcase"
                    title={
                      resources.pages.maintenanceReportDetails.clientEmployeeJob
                    }
                    data={maintenanceReportDetails.clientEmployeeJob}
                  />
                </Segment>
              </div>
            </div>
            <div className="segment-container">
              <label className="info-label">
                {resources.pages.maintenanceReportDetails.machineInfo}
              </label>
              <Segment className="details-box-segment">
                <DetailsBox
                  icon="user circle"
                  title={resources.pages.maintenanceReportDetails.respTech}
                  data={maintenanceReportDetails.respTech}
                />
                <DetailsBox
                  icon="computer"
                  title={
                    resources.pages.maintenanceReportDetails.computerNumber
                  }
                  data={maintenanceReportDetails.computerNumber}
                />
                <DetailsBox
                  icon="th"
                  title={resources.pages.maintenanceReportDetails.machineType}
                  data={maintenanceReportDetails.machineType}
                />
                <DetailsBox
                  icon="typo3"
                  title={resources.pages.maintenanceReportDetails.machineModel}
                  data={maintenanceReportDetails.machineModel}
                />
                <DetailsBox
                  icon="barcode"
                  title={resources.pages.maintenanceReportDetails.serialNumber}
                  data={maintenanceReportDetails.serialNumber}
                />
                <DetailsBox
                  icon="building"
                  title={resources.pages.maintenanceReportDetails.branchName}
                  data={maintenanceReportDetails.branchName}
                />
                <DetailsBox
                  icon="map"
                  title={resources.pages.maintenanceReportDetails.branchArea}
                  data={maintenanceReportDetails.branchArea}
                />
              </Segment>
            </div>

            <div className="segment-container">
              <label className="info-label">
                {resources.pages.maintenanceReportDetails.images}
              </label>
              <Segment className="images-segment">
                <DetailsBox
                  icon="image"
                  title={
                    resources.pages.maintenanceReportDetails
                      .blackAndWhiteCounter
                  }
                  isImage
                  noborder
                  data={maintenanceReportDetails.counter1Image}
                />
                <DetailsBox
                  icon="image"
                  title={
                    resources.pages.maintenanceReportDetails.colorfulCounter
                  }
                  isImage
                  noborder
                  data={maintenanceReportDetails.counter2Image}
                />
                <DetailsBox
                  icon="image"
                  title={
                    resources.pages.maintenanceReportDetails.clientCardImage
                  }
                  isImage
                  noborder
                  data={maintenanceReportDetails.clientCardImage}
                />
              </Segment>
            </div>

            <div className="segment-container">
              <label className="info-label">
                {resources.pages.maintenanceReportDetails.maintenanceInfo}
              </label>
              <Segment>
                <div>
                  <DetailsBox
                    icon="info circle"
                    title={
                      resources.pages.maintenanceReportDetails.counterDetails
                    }
                    noborder
                  />
                  <Table celled unstackable>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell
                          width="5"
                          textAlign="center"
                          className="left-border bottom-border"
                        ></Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="bottom-border"
                        >
                          {
                            resources.pages.maintenanceReport
                              .blackAndWhiteCounter
                          }
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="bottom-border"
                        >
                          {resources.pages.maintenanceReport.colorfulCounter}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell textAlign="center" className="left-border">
                          {
                            resources.pages.maintenanceReport
                              .previousCounterRead
                          }
                        </Table.Cell>
                        <Table.Cell textAlign="center" className="left-border">
                          {maintenanceReportDetails.lastCounter1}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {maintenanceReportDetails.lastCounter2}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell
                          textAlign="center"
                          className="left-border bottom-border"
                        >
                          {resources.pages.maintenanceReport.currentCounterRead}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="bottom-border"
                        >
                          {maintenanceReportDetails.counter1}
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="bottom-border"
                        >
                          {maintenanceReportDetails.counter2}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell textAlign="center" className="left-border">
                          {resources.pages.maintenanceReport.acheivedCopies}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {maintenanceReportDetails.counter1AchivedCopies}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {maintenanceReportDetails.counter2AchivedCopies}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
                <div className="details-box-segment maintenance-info">
                  <DetailsBox
                    icon="hourglass one"
                    title={resources.pages.maintenanceReportDetails.startHour}
                    data={maintenanceReportDetails.startHour}
                  />
                  <DetailsBox
                    icon="hourglass zero"
                    title={resources.pages.maintenanceReportDetails.endHour}
                    data={maintenanceReportDetails.endHour}
                  />
                  <DetailsBox
                    icon="bug"
                    title={resources.pages.maintenanceReportDetails.damage}
                    data={maintenanceReportDetails.damage}
                  />
                  <DetailsBox
                    icon="question circle"
                    title={resources.pages.maintenanceReportDetails.reason}
                    data={maintenanceReportDetails.causes}
                  />
                  <DetailsBox
                    icon="settings"
                    title={resources.pages.maintenanceReportDetails.action}
                    data={maintenanceReportDetails.action}
                  />
                </div>
              </Segment>
            </div>

            {maintenanceReportDetails &&
              maintenanceReportDetails.machineParts &&
              maintenanceReportDetails.machineParts.length && (
                <div className="segment-container">
                  <label className="info-label">
                    {resources.pages.maintenanceReport.partsMaterialTable}
                  </label>
                  <Segment>
                    <div>
                      <DetailsBox
                        icon="hourglass one"
                        title={`${resources.pages.machines.invoiceNumber} : ${
                          maintenanceReportDetails.machinePartInvoiceNumber
                            ? generateMachinePartInvoiceNumber(
                                maintenanceReportDetails.createdAt,
                                maintenanceReportDetails.machinePartInvoiceNumber
                              )
                            : resources.pages.maintenanceReport.withoutInvoice
                        }`}
                      />
                      <Table basic celled unstackable>
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              textAlign="center"
                              className="left-border bottom-border"
                            >
                              Name
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border bottom-border"
                            >
                              QTY
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border bottom-border"
                            >
                              FC
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="left-border bottom-border"
                            >
                              ACT
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="bottom-border"
                            >
                              Part Number
                            </Table.Cell>
                            <Table.Cell
                              textAlign="center"
                              className="bottom-border"
                            >
                              Description
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {maintenanceReportDetails.machineParts.map(
                            (row, index) => {
                              return (
                                <Table.Row key={index}>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.MachinePart.Lookup
                                      ? row.MachinePart.Lookup.LookupName
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.Quantity ? row.Quantity : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.FCID ? row.FCID : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.ACTID ? row.ACTID : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.PartNumber ? row.PartNumber : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    textAlign="center"
                                    className="left-border"
                                  >
                                    {row.Description ? row.Description : "-"}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Segment>
                </div>
              )}

            <div className="segment-container">
              <label className="info-label">
                {resources.pages.maintenanceReportDetails.contractInfo}
              </label>
              <Segment className="details-box-segment">
                <DetailsBox
                  icon="qrcode"
                  title={
                    resources.pages.maintenanceReportDetails.contractNumber
                  }
                  data={maintenanceReportDetails.contractNumber}
                />
                <DetailsBox
                  icon="houzz"
                  title={resources.pages.maintenanceReportDetails.contractType}
                  data={maintenanceReportDetails.contractType}
                />

                <DetailsBox
                  icon="calendar check"
                  title={resources.pages.maintenanceReportDetails.startDate}
                  data={maintenanceReportDetails.contractStartDate}
                />
                <DetailsBox
                  icon="calendar times"
                  title={resources.pages.maintenanceReportDetails.endDate}
                  data={maintenanceReportDetails.contractEndDate}
                />
                <DetailsBox
                  icon="hourglass half"
                  title={
                    resources.pages.maintenanceReportDetails.contractSituation
                  }
                  data={maintenanceReportDetails.endContract}
                />
              </Segment>
            </div>
            {maintenanceReportDetails.clientSignatureImage && (
              <Segment>
                <DetailsBox
                  icon="image"
                  title={
                    resources.pages.maintenanceReportDetails.clientSignature
                  }
                  isImage
                  isSignature
                  noborder
                  data={maintenanceReportDetails.clientSignatureImage}
                />
              </Segment>
            )}
            {maintenanceReportDetails.manualReportImg && (
              <Segment>
                <DetailsBox
                  icon="image"
                  title={
                    resources.pages.maintenanceReportDetails.manualReportImg
                  }
                  isImage
                  noborder
                  data={maintenanceReportDetails.manualReportImg}
                />
              </Segment>
            )}
          </>
        )}
        {isImgOpen && imgData && (
          <div className="full-screen-wrapper">
            <div
              className="close-btn"
              onClick={() => {
                setImgData("");
                setIsImgOpen(false);
              }}
            >
              <Icon name="x" size="large" />
            </div>
            <img src={imgData} />
          </div>
        )}
      </div>
    </>
  );
};

export default ViewMaintenanceReportDetails;
