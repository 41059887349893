import React, { useState, useEffect } from "react";
import PrintOutHeader from "../PrintOutHeader/PrintOutHeader";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import serviceApi from "../../api";
import { Loader } from "semantic-ui-react";
import "./NumberOfTechVisitsPrintOut.scss";
import moment from "moment";

const NumberOfTechVisitsPrintOut = () => {
  const [numberOfVisits, setNumberOfVisits] = useState([]);
  const [totalNumberOfVisits, setTotalNumberOfVisits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { from, to, TechnicianID, TechnicianName } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          TechnicianID: TechnicianID,
          type: 10,
        },
      });
      let totalSum = 0;
      const data = res.map((row) => {
        totalSum += row.count;
        return {
          ...row,
          Month: row.Month ? row.Month : null,
          count: row.count ? row.count : 0,
        };
      });
      setNumberOfVisits(data);
      setTotalNumberOfVisits(totalSum);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="num-of-tech-visits-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(numberOfVisits.length) ? (
        <div>
          <PrintOutHeader
            printOutTitle={"عدد زيارات الفني"}
            from={from}
            to={to}
            hasDate={true}
          />
          <div className="num-of-tech-visits-table-container">
            <div className="num-of-tech-visits-techName-container">
              <h4>{TechnicianName}</h4>
            </div>
            <table className="num-of-tech-visits-table">
              <thead>
                <tr>
                  <th>الشهر</th>
                  <th>عدد الزيارات</th>
                </tr>
              </thead>
              <tbody>
                {numberOfVisits.map((info, idx) => (
                  <tr key={idx}>
                    <td> {info.Month ? info.Month : " "}</td>
                    <td> {info.count ? info.count : "0"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="num-of-tech-visits-divider"></div>
          <div className="num-of-tech-visits-sum-lbl-container">
            <label>{`عدد الاّلاّت: ${totalNumberOfVisits}`}</label>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default NumberOfTechVisitsPrintOut;
