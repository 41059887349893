import React from "react";
import "./PrintOutDateFilter.scss";

const PrintOutdateFilter = ({ from, to, dateTitle }) => {
  return (
    <div className="date-filter-container">
      {Boolean(dateTitle) ? (
        <div>
          <p> {dateTitle} </p>
        </div>
      ) : (
        ""
      )}
      {Boolean(from) ? (
        <div>
          <p> من &emsp; {from} </p>
        </div>
      ) : (
        ""
      )}
      {Boolean(to) ? (
        <div>
          <p> الى &emsp; {to} </p>
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default PrintOutdateFilter;
