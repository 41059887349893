import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import { useSelector } from "react-redux";
import moment from "moment";

export default function LeaveList() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [leaveID, setLeaveID] = useState(null);
  const [data, setData] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const headers = {
    fullName: resources.pages.user.fullname,
    userLeaveType: resources.pages.user.leavesType,
    from: resources.common.from,
    to: resources.common.to,
  };
  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const fillSearchData = async () => {
    setSearchData({
      fullName: {
        type: "text",
        label: resources.pages.user.fullname,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "leaves"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadLeave = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "UserLeave",
          attributes: ["leaveid", "from", "to"],
          include: [
            {
              model: "Users",
              attributes: ["fullName"],
              ...whereLikeSearch("fullName"),
            },
            {
              model: "Lookup",
              as: "UsersLeaves",
              attributes: ["LookupName"],
            },
          ],
          ...(CurrentUserID !== 1 ? { where: { userId: CurrentUserID } } : {}),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const newData = res.data.map((row) => {
        const editedRow = {
          ...row,
          fullName: row.User.fullName,
          from: moment(row.from).format("DD/MM/YYYY"),
          to: moment(row.to).format("DD/MM/YYYY"),
          userLeaveType: row.UsersLeaves
            ? row.UsersLeaves.LookupName
            : resources.common.notSpecified,
        };
        delete editedRow.User;
        return editedRow;
      });
      setData(newData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadLeave();
  }, [searching, activePage]);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/settings/Leave/manageLeave", {
        leaveID: row.leaveid,
        name: row.fullname,
      });
    } else if (type === "remove") {
      setLeaveID(row.leaveid);
      setIsDelete(true);
    }
  };

  const deleteLeave = async () => {
    try {
      await serviceApi
        .service("UserLeave")
        .remove(leaveID)
        .then((res) => {
          setIsDelete(false);
          loadLeave();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteLeave()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.leave.leaveList}
            icon="time"
          />

          <div className="two-buttons-container">
            {isAuthorized(Perm.AddUserLeave) && (
              <Button
                basic
                color="blue"
                content={resources.pages.leave.addLeave}
                onClick={() => router.push("/settings/Leave/manageLeave")}
              />
            )}
            {isAuthorized(Perm.viewLeaves) && (
              <ExportDataToExcel
                data={data}
                headers={headers}
                title={resources.common.excelExport.userLeaves}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="leaves"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={data}
              edit={isAuthorized(Perm.editUserLeave) ? true : null}
              remove={isAuthorized(Perm.deleteUserLeave) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
