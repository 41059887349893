import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Message, Segment } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.scss";
import { userActionCreator } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import tescoLogo from "../../assets/images/tescoNewLogo.png";
import { resources } from "../../assets/LocalizationResources";

export default function Login() {
  const [credentials, setCredentials] = useState({
    userName: "",
    password: "",
  });
  const [touched, setTouched] = useState({ userName: false, password: false });
  const [submitting, setSubmitting] = useState(false);

  const router = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user && user.accessToken && user.user.isExternalUser) {
      router.replace("/ExternalTicketsList");
    } else if (user && user.accessToken && !user.user.isExternalUser) {
      router.replace("/Home");
    } else {
      setSubmitting(false);
    }
  }, [user]);

  const handleChanges = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });

    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = () => {
    if (
      !credentials.userName ||
      !credentials.password ||
      credentials.password.length < 3
    ) {
      setTouched({
        password: true,
        userName: true,
      });
    } else {
      setSubmitting(true);
      dispatch(userActionCreator.loginRequest(credentials));
    }
  };

  return (
    <Grid
      textAlign="center"
      className="login-container clear-margin"
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Form size="large" autoComplete="off">
          <Segment raised size="large" className="login-form-container">
            <div className="login-header-container">
              <div>
                <img src={tescoLogo} />
              </div>
              <p>{resources.pages.loginPage.maintenanceContractSystem}</p>
            </div>
            <p className="input-label">{resources.pages.loginPage.userName}</p>
            <Form.Input
              fluid
              icon="user"
              name="userName"
              onChange={handleChanges}
              error={Boolean(touched.userName && !credentials.userName)}
            />
            <p className="input-label">{resources.pages.loginPage.password}</p>
            <Form.Input
              fluid
              icon="lock"
              type="password"
              name="password"
              onChange={handleChanges}
              error={Boolean(
                touched.password &&
                  (!credentials.password || credentials.password.length < 3)
              )}
            />
            {/* Start with attempts > 1 as we'll initiate the first attempt automatically */}
            {user.attempts > 1 && (
              <Message
                content={resources.pages.loginPage.loginErrorMessage}
                negative
              />
            )}
            <Button
              color="blue"
              fluid
              size="large"
              onClick={handleLogin}
              loading={submitting}
              disabled={submitting}
            >
              {resources.pages.loginPage.signIn}
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}
