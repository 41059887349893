import React, { useEffect } from "react";
import "./scss/App.scss";
import { resources } from "./assets/LocalizationResources";
import { Switch, Route } from "react-router-dom";
import Login from "./containers/Login/Login";
import Layout from "./components/Layout/Layout";
import { useSelector } from "react-redux";
import Home from "./containers/TechnicianHomePage/TechHome";
import MaintenanceReport from "./containers/MaintenanceReport/MaintenanceReport";
import MaintenanceInvoice from "./containers/MaintenanceReport/MaintenanceInvoice/";
import MaintenanceReportsList from "./containers/MaintenanceReport/MaintenanceReportsList";
import Area from "./containers/Areas/Area";
import Technicians from "./containers/Technicians/Technicians";
import ManageTechnicians from "./containers/Technicians/ManageTechnicians";
import ManageUser from "./containers/Users/ManageUser";
import UsersList from "./containers/Users/UsersList";
import AreasList from "./containers/Areas/AreasList";
import Contracts from "./containers/Contracts/Contracts";
import ManageContract from "./containers/Contracts/ManageContract";
import Machines from "./containers/Machines/MachinesList";
import Clients from "./containers/ClientBranches/ClientBranchesList";
import ManageClient from "./containers/ClientBranches/ManageClient";
import TicketsList from "./containers/Tickets/TicketsList";
import ManageTicket from "./containers/Tickets/ManageTicket";
import SystemReports from "./containers/Reports/SystemReports";
import ManageContractMachine from "./containers/Contracts/ManageContractMachines";
import ChangePassword from "./containers/Users/ChangePassword";
import CallCenter from "./containers/CallCenter/CallCenter";
import ManageBranch from "./containers/ClientBranches/ManageBranch";
import LeaveList from "./containers/UserLeave/LeaveList";
import ManageLeave from "./containers/UserLeave/ManageLeave";
import TechHome from "./containers/TechnicianHomePage/TechHome";
import TechTickets from "./containers/TechnicianHomePage/TechTickets";
import TechVisits from "./containers/TechnicianHomePage/TechVisits";
import TechnicianAreas from "./containers/TechnicianAreas/TechnicianAreas";
import TechnicianAreasTable from "./containers/TechnicianAreas/TechnicianAreasTable";
import Settings from "./containers/Settings/Settings";
import ManageMachine from "./containers/Machines/ManageMachine";
import ManageLookUp from "./containers/Lookup/ManageLookUp";
import ManageMachineParts from "./containers/Machines/ManageMachineParts";
import GroupPermissionsList from "./containers/Permissions/GroupPermissionsList";
import ManageGroupPermissions from "./containers/Permissions/ManageGroupPermissions";
import TabletsList from "./containers/TabletsDevices/TabletsList";
import ManageTablets from "./containers/TabletsDevices/ManageTablets";
import ManageMachineExtraDevices from "./containers/Machines/ManageMachineExtraDevices";
import MachineInfo from "./containers/Machines/MachineInfo";
import TechMovePoints from "./containers/TechnicianMovePoints/TechMovePoints";
import ContractPayments from "./containers/Contracts/ContractPayments";
import ViewMaintenanceReportDetails from "./containers/MaintenanceReport/ViewMaintenanceReportDetails";
import Perm, { isAuthorized } from "./components/helpers/Permissions";
import { Loader } from "semantic-ui-react";
import PeriodEndedContractsPrintOut from "./components/PrintOutPages/PeriodEndedContractsPrintOut";
import NotDeservedContractsPrintOut from "./components/PrintOutPages/NotDeservedContractsPrintOut";
import TechAreasAndMachinesNumPrintOut from "./components/PrintOutPages/TechAreasAndMachinesNumPrintOut";
import GeneralInfoOnMachinesPrintOut from "./components/PrintOutPages/GeneralInfoOnMachinesPrintOut";
import MachinesModelClientPrintOut from "./components/PrintOutPages/MachinesModelClientPrintOut";
import MachinesThatHaveContractwithVisitsPrintOut from "./components/PrintOutPages/MachinesThatHaveContractwithVisitsPrintOut";
import MachinesThatHaveContractwithVisitsWithContractDataPrintOut from "./components/PrintOutPages/MachinesThatHaveContractwithVisitsWithContractDataPrintOut";
import MachinesContarctTechniciansPrintOut from "./components/PrintOutPages/MachinesContarctTechniciansPrintOut";
import NumberOfTechVisitsPrintOut from "./components/PrintOutPages/NumberOfTechVisitsPrintOut";
import FinishedContractsPrintOut from "./components/PrintOutPages/FinishedContractsPrintOut";
import LateTicketsPrintOut from "./components/PrintOutPages/LateTicketsPrintOut";
import RepeatedTicketsPrintOut from "./components/PrintOutPages/RepeatedTicketsPrintOut";
import MachineCardForPeriodicalVisits from "./components/PrintOutPages/MachineCardForPeriodicalVisits";
import ContractMachinesEditRequests from "./containers/ContractMachinesEditRequests/ContractMachinesEditRequests";
import ManageExternalTicket from "./containers/Tickets/ExternalTickets/ManageExternalTicket";
import ExternalTicketsList from "./containers/Tickets/ExternalTickets/ExternalTicketsList";
import InvoicesPrintOut from "./components/PrintOutPages/InvoicesPrintOut";
import MaintenanceInvoiceReportsList from "./containers/MaintenanceInvoiceReportsList";
import MaintenanceReportA4PrintView from "./containers/MaintenanceReport/MaintenanceReportA4PrintView";
import MaintenanceCardPrintOutPage from "./components/PrintOutPages/MaintenanceCardPrintOutPage";
import PartsMovementReportPrintOut from "./components/PrintOutPages/PartsMovementReportPrintOut";
import PartInvoiceA4PrintView from "./containers/MaintenanceReport/PartInvoice/PartInvoiceA4PrintView";
window.addEventListener("resize", () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

function App() {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  resources.setLanguage(localStorage.getItem("lang") ?? "ar");

  return (
    <Switch>
      <Route path="/Login" exact>
        <Login />
      </Route>
      {isAuthorized(Perm.printOrSendMaintenanceReports) && (
        <Route
          path="/maintenanceReportPrintView"
          component={MaintenanceReportA4PrintView}
          exact
        />
      )}
      {isAuthorized(Perm.printOrSendMaintenanceReports) && (
        <Route
          path="/PartInvoiceA4PrintView"
          component={PartInvoiceA4PrintView}
          exact
        />
      )}
      <Route path="/MaintenanceInvoice" component={MaintenanceInvoice} exact />
      <Route
        path="/PeriodEndedContractsPrintOut"
        exact
        component={PeriodEndedContractsPrintOut}
      />
      <Route
        path="/q" // For sms service
        exact
        component={PeriodEndedContractsPrintOut}
      />
      <Route
        path="/NotDeservedContractsPrintOut"
        exact
        component={NotDeservedContractsPrintOut}
      />
      <Route
        path="/MaintenanceCard"
        exact
        component={MaintenanceCardPrintOutPage}
      />
      <Route
        path="/partsMovementReportPrintOut"
        exact
        component={PartsMovementReportPrintOut}
      />
      <Route
        path="/FinishedContractsPrintOut"
        exact
        component={FinishedContractsPrintOut}
      />
      <Route
        path="/TechAreasAndMachinesNumPrintOut"
        exact
        component={TechAreasAndMachinesNumPrintOut}
      />
      <Route
        path="/GeneralInfoOnMachinesPrintOut"
        exact
        component={GeneralInfoOnMachinesPrintOut}
      />
      <Route
        path="/MachinesThatHaveContractwithVisitsPrintOut"
        exact
        component={MachinesThatHaveContractwithVisitsPrintOut}
      />
      <Route
        path="/MachinesThatHaveContractwithVisitsWithContractDataPrintOut"
        exact
        component={MachinesThatHaveContractwithVisitsWithContractDataPrintOut}
      />
      <Route
        path="/MachinesModelClientPrintOut"
        exact
        component={MachinesModelClientPrintOut}
      />
      <Route
        path="/MachinesContarctTechniciansPrintOut"
        exact
        component={MachinesContarctTechniciansPrintOut}
      />
      <Route
        path="/NumberOfTechVisitsPrintOut"
        exact
        component={NumberOfTechVisitsPrintOut}
      />
      <Route
        path="/LateTicketsPrintOut"
        exact
        component={LateTicketsPrintOut}
      />
      <Route
        path="/RepeatedTicketsPrintOut"
        exact
        component={RepeatedTicketsPrintOut}
      />
      <Route
        path="/MachineCardForPeriodicalVisits"
        exact
        component={MachineCardForPeriodicalVisits}
      />
      <Route path="/invoicesReport" exact component={InvoicesPrintOut} />
      <Layout>
        <Switch>
          {!user.user.isExternalUser && (
            <Route path={["/", "/Home"]} exact component={Home} />
          )}
          {user.user.isExternalUser && (
            <Route
              path="/manageExternalTicket"
              exact
              component={ManageExternalTicket}
            />
          )}
          {user.user.isExternalUser && (
            <Route
              path="/ExternalTicketsList"
              exact
              component={ExternalTicketsList}
            />
          )}
          <Route path="/settings" exact component={Settings} />
          {isAuthorized(Perm.addNewTechnician, Perm.editTechnician) && (
            <Route
              path="/technicians/add"
              component={ManageTechnicians}
              exact
            />
          )}
          {isAuthorized(Perm.addTablets, Perm.editTablets) && (
            <Route
              path="/tableList/manageTablets"
              exact
              component={ManageTablets}
            />
          )}
          {isAuthorized(Perm.viewTablets) && (
            <Route path="/settings/tabletsList" exact component={TabletsList} />
          )}
          {isAuthorized(Perm.viewGroupPermissions) && (
            <Route
              path="/groupPermissions"
              exact
              component={GroupPermissionsList}
            />
          )}
          {isAuthorized(
            Perm.addNewGroupPermissions,
            Perm.editGroupPermissions
          ) && (
            <Route
              path="/groupPermissions/ManageGroupPermissions"
              exact
              component={ManageGroupPermissions}
            />
          )}
          {isAuthorized(Perm.viewMachineInfo) && (
            <Route path="/machines/machineInfo" component={MachineInfo} exact />
          )}
          {isAuthorized(Perm.viewTech) && (
            <Route path="/technicians" component={Technicians} exact />
          )}
          {isAuthorized(Perm.addTechnicianAreas) && (
            <Route
              path="/technicians/areas/add"
              component={TechnicianAreas}
              exact
            />
          )}
          {isAuthorized(Perm.viewtTechAreas) && (
            <Route
              path="/technicians/areas"
              component={TechnicianAreasTable}
              exact
            />
          )}

          {isAuthorized(
            Perm.addMachinesToContract,
            Perm.editMaintenanceReports
          ) && (
            <Route
              path="/maintenanceReports"
              component={MaintenanceReport}
              exact
            />
          )}

          {isAuthorized(Perm.viewMaintenaceReport) && (
            <Route
              path="/maintenanceReportsList"
              component={MaintenanceReportsList}
              exact
            />
          )}
          {isAuthorized(Perm.viewMaintenaceReport) && (
            <Route
              path="/MaintenanceInvoiceReportsList"
              component={MaintenanceInvoiceReportsList}
              exact
            />
          )}
          {isAuthorized(Perm.addMachinesToContract) && (
            <Route
              path="/contracts/manageContractMachine"
              component={ManageContractMachine}
              exact
            />
          )}
          {isAuthorized(Perm.addContract, Perm.editContract) && (
            <Route
              path="/contracts/manageContract"
              component={ManageContract}
              exact
            />
          )}
          {isAuthorized(Perm.viewContract) && (
            <Route path="/contracts" component={Contracts} exact />
          )}
          {isAuthorized(Perm.AddMachine, Perm.editMachine) && (
            <Route
              path="/machines/manageMachine"
              component={ManageMachine}
              exact
            />
          )}
          {isAuthorized(Perm.viewMachines) && (
            <Route path="/machines/machinesList" component={Machines} exact />
          )}
          {isAuthorized(Perm.machineParts) && (
            <Route
              path="/machines/ManageMachineParts"
              component={ManageMachineParts}
              exact
            />
          )}

          {isAuthorized(Perm.addExtraDevices) && (
            <Route
              path="/machines/ManageMachineExtraDevices"
              component={ManageMachineExtraDevices}
              exact
            />
          )}

          {isAuthorized(Perm.AddNewClient, Perm.editContract) && (
            <Route
              path="/ClientBranches/ManageClient"
              component={ManageClient}
              exact
            />
          )}
          {isAuthorized(Perm.viewClientBranch) && (
            <Route
              path="/ClientBranches/ClientBranchesList"
              component={Clients}
              exact
            />
          )}
          {isAuthorized(Perm.addArea, Perm.editArea) && (
            <Route path="/Areas/Area" component={Area} />
          )}
          {isAuthorized(Perm.viewGeofences) && (
            <Route path="/Areas/AreasList" component={AreasList} />
          )}
          <Route path="/tech/Home" exact component={TechHome} />
          {isAuthorized(Perm.technicianTickets) && (
            <Route path="/tech/tickets" exact component={TechTickets} />
          )}
          {isAuthorized(Perm.technicianVisits) && (
            <Route path="/tech/visits" exact component={TechVisits} />
          )}
          {isAuthorized(Perm.callCenter) && (
            <Route path="/CallCenter" exact component={CallCenter} />
          )}
          {isAuthorized(Perm.AddUser, Perm.editUser) && (
            <Route
              path="/settings/users/manageUser"
              exact
              component={ManageUser}
            />
          )}
          {isAuthorized(Perm.viewLookups) && (
            <Route path="/settings/lookUp" exact component={ManageLookUp} />
          )}
          {isAuthorized(Perm.viewUsers) && (
            <Route
              path="/settings/users/usersList"
              exact
              component={UsersList}
            />
          )}
          {isAuthorized(Perm.changePassw0rd) && (
            <Route
              path="/settings/users/changePassword"
              exact
              component={ChangePassword}
            />
          )}
          {isAuthorized(Perm.viewLeaves) && (
            <Route
              path="/settings/Leave/LeaveList"
              exact
              component={LeaveList}
            />
          )}
          {isAuthorized(Perm.AddUserLeave, Perm.editUserLeave) && (
            <Route
              path="/settings/Leave/manageLeave"
              exact
              component={ManageLeave}
            />
          )}
          {isAuthorized(
            Perm.viewAllReports,
            Perm.reports,
            Perm.viewFinancialReports
          ) && <Route path="/systemReports" exact component={SystemReports} />}
          {isAuthorized(Perm.viewAllTickets, Perm.viewTicktes) && (
            <Route path="/ticket/ticketsList" exact component={TicketsList} />
          )}
          {isAuthorized(Perm.addTicket, Perm.editTicket) && (
            <Route path="/ticket/manageTicket" exact component={ManageTicket} />
          )}
          {isAuthorized(Perm.addBranch, Perm.editBranch) && (
            <Route
              path="/ClientBranches/ManageBranch"
              component={ManageBranch}
            />
          )}
          {isAuthorized(Perm.contractPayments) && (
            <Route
              path="/contracts/contractPayments"
              exact
              component={ContractPayments}
            />
          )}

          {isAuthorized(Perm.techMovePoints) && (
            <Route path="/techMovePoints" exact component={TechMovePoints} />
          )}
          {isAuthorized(Perm.printOrSendMaintenanceReports) && (
            <Route
              path="/MaintenanceReportDetails"
              exact
              component={ViewMaintenanceReportDetails}
            />
          )}
          {isAuthorized(Perm.viewEditRequests) && (
            <Route
              path="/ContractMachinesEditRequests"
              exact
              component={ContractMachinesEditRequests}
            />
          )}
          <Route>
            <Loader active={true} />
          </Route>
        </Switch>
      </Layout>
    </Switch>
  );
}
export default App;
