import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Checkbox } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

export default function TicketsList() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [ticketID, setTicketID] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [showClosed, setShowClosed] = useState(false);

  const headers = {
    ticketID: resources.pages.ticket.ticketID,
    branchName: resources.pages.ticket.branchName,
    contactName: resources.pages.ticket.contactName,
    technicianName: resources.pages.technicians.technicianName,
    clientName: resources.pages.ticket.clientName,
    computerNumber: resources.pages.machines.computerNumber,
    notes: resources.pages.ticket.notes,
    createdat: resources.pages.ticket.ticketDate,
    lateForDueHours: resources.pages.ticket.lateForDueHours,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  const fillSearchData = async () => {
    setSearchData({
      ticketID: { type: "text", label: resources.pages.ticket.ticketID },
      branchName: {
        type: "text",
        label: resources.pages.ticket.branchName,
      },
      contactName: {
        type: "text",
        label: resources.pages.ticket.contactName,
      },
      clientName: {
        type: "text",
        label: resources.pages.ticket.clientName,
      },
      technicianName: {
        type: "text",
        label: resources.pages.technicians.technicianName,
      },
      computerNumber: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "tickets"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const loadTickets = async () => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "ClientBranch",
          attributes: ["BranchName", "BranchID"],
          include: [
            {
              innerJoin: true,
              model: "Client",
              attributes: ["ClientName"],
              ...whereLikeSearch("clientName"),
            },
          ],
          ...whereLikeSearch("branchName"),
        },
        {
          ...(searching && searching.searchBy == "computerNumber"
            ? { innerJoin: true }
            : {}),
          model: "ContractMachine",
          attributes: ["machineID", "withSLA", "dueHours"],
          include: [
            {
              innerJoin: true,
              model: "Machine",
              attributes: ["machineId", "computerNumber"],
              ...whereLikeSearch("computerNumber"),
            },
          ],
        },
        {
          model: "Technicians",
          attributes: ["TechnicianName"],
          ...whereLikeSearch("technicianName"),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "status",
          attributes: ["LookupName"],
          ...(Object.keys(whereSearch("status", "LookupID")).length
            ? whereSearch("status", "LookupID")
            : showClosed
            ? {
                where: { LookupID: 99 },
              }
            : { where: { LookupID: { ne: 99 } } }),
        },
      ]);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Tickets",
          attributes: [
            "ticketID",
            "contactName",
            "notes",
            "createdat",
            "statusID",
            "ticketDueHours",
          ],
          jsonInclude: jsonInclude,
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
          ...whereLikeSearch("ticketID"),
          ...whereLikeSearch("contactName"),
        },
      });

      const flattenedData = res.data.map((row) => {
        const editedRow = {
          ...row,
          branchName: row.ClientBranch
            ? row.ClientBranch.BranchName
            : resources.common.notSpecified,
          contactName: row.contactName
            ? row.contactName
            : resources.common.notSpecified,
          clientName: row.ClientBranch.Client
            ? row.ClientBranch.Client.ClientName
            : resources.common.notSpecified,
          technicianName: row.Technician
            ? row.Technician.TechnicianName
            : resources.common.notSpecified,
          computerNumber: row.ContractMachine
            ? row.ContractMachine.Machine.computerNumber
            : resources.common.notSpecified,
          notes: row.notes ? row.notes : resources.common.noNotes,
          createdat: moment(row.createdat).format("DD/MM/YYYY"),
          lateForDueHours: Boolean(
            row.ContractMachine && row.ContractMachine.dueHours
          )
            ? new Date().getTime() >
              new Date(row.createdat).setHours(
                new Date(row.createdat).getHours() +
                  row.ContractMachine.dueHours
              )
              ? resources.pages.ticket.late
              : resources.pages.ticket.notLate
            : Boolean(row.ticketDueHours) &&
              new Date().getTime() >
                new Date(row.createdat).setHours(
                  new Date(row.createdat).getHours() + row.ticketDueHours
                )
            ? resources.pages.ticket.late
            : resources.pages.ticket.notLate,
        };
        delete editedRow.ClientBranch;
        delete editedRow.ContractMachine;
        return editedRow;
      });
      setTickets(flattenedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTickets();
  }, [searching, activePage, showClosed]);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/ticket/manageTicket", {
        ticketID: row.ticketID,
      });
    } else if (type === "remove") {
      setTicketID(row.ticketID);
      setIsDelete(true);
    }
  };

  const deleteTicket = async () => {
    try {
      await serviceApi
        .service("tickets")
        .remove(ticketID)
        .then((res) => {
          setIsDelete(false);
          loadTickets();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  const SearchBoxes = ({ label, onChange, stateKey }) => {
    return (
      <div className="checkBoxFilter-container">
        <Checkbox label={label} checked={stateKey} onChange={onChange} />
      </div>
    );
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteTicket()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.ticket.ticketsList}
            icon="ticket"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addTicket) && (
              <Button
                basic
                color="blue"
                content={resources.pages.ticket.addPageTitle}
                onClick={() => router.push("/ticket/manageTicket")}
              />
            )}
            {isAuthorized(Perm.viewTicktes) && (
              <ExportDataToExcel
                data={tickets}
                headers={headers}
                title={resources.common.excelExport.ticketsList}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="tickets"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
              extraFields={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <SearchBoxes
                    label={resources.pages.ticket.showClosed}
                    onChange={(evt, elm) => setShowClosed(Boolean(elm.checked))}
                    stateKey={showClosed}
                  />
                </div>
              }
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={tickets}
              edit={
                isAuthorized(Perm.editTicket)
                  ? { valuesID: "statusID", values: [99] }
                  : null
              }
              remove={isAuthorized(Perm.deleteTicket) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
