import React, { useState, useRef, useEffect } from "react";
import { Map as Leaflet, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "./map.scss";
import MapLayers from "./MapLayers";
import { icons } from "./helpers/MapIcons";
import { Icon } from "semantic-ui-react";
import SearchOnMap from "./SearchOnMap";
import { addJsonData, decodePath } from "./helpers/MapHelpers";
import Routing from "./Routing";
import RoutingServiceApi from "../../api/router-service.api";
import mapLegendImg from "../../assets/images/legend.png";
import MapLegend from "./MapLegend";
L.Icon.Default.imagePath = "/images/";

export default function Map({
  Center = { lng: 36.5565, lat: 31.535654 },
  FlyToOptions = null,
  Zoom = 8,
  // Technicians = [],
  // ClientBranches = [],
  addMarkerOnClick = false,
  Clickable = false,
  OnMapClick = null,
  Search = false,
  routingPoints = null,
  Markers = null,
  Legend = false,
  jsonData = null,
}) {
  const mapRef = useRef(null);
  const [locationPicker, setLocationPicker] = useState({
    view: false,
    coords: { lat: 31.535654, lng: 36.5565 },
  });
  const [routingData, setRoutingData] = useState(null);
  const [isOpenLegend, setIsOpenLegend] = useState(false);
  const [legendMarkers, setLegendMarkers] = useState(null);
  useEffect(() => {
    return () => {
      if (mapRef && mapRef.current) mapRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (mapRef && mapRef.current && jsonData) {
      setRoutingData(null);
      addJsonData({
        map: mapRef.current.leafletElement,
        jsonData: [jsonData],
      });
    }
  }, [jsonData]);

  useEffect(() => {
    if (mapRef.current && FlyToOptions) {
      mapRef.current.leafletElement.flyTo(
        FlyToOptions.center,
        FlyToOptions.zoom,
        {
          animate: true,
          duration: 1.5,
        }
      );
    }
  }, [FlyToOptions]);

  useEffect(() => {
    if (Boolean(routingPoints)) getRoutingData(routingPoints);
  }, [routingPoints]);

  const onClickEvent = (e) => {
    if (addMarkerOnClick) {
      if (e && e.latlng)
        setLocationPicker({
          view: true,
          coords: e.latlng,
        });
    }

    if (OnMapClick && typeof OnMapClick === "function") OnMapClick(e.latlng);
  };

  const _setSearchData = (searchData) => {
    if (mapRef && mapRef.current && Search && searchData) {
      setRoutingData(null);
      addJsonData({
        map: mapRef.current.leafletElement,
        jsonData: [searchData],
      });
    }
  };

  const getRoutingData = async () => {
    try {
      const res = await RoutingServiceApi.route(routingPoints);
      if (res && res.data && res.data.paths && res.data.paths.length > 0) {
        const coordinates = decodePath(res.data.paths[0].points);
        setRoutingData({
          instructions: res.data.paths[0].instructions,
          distance: res.data.paths[0].distance,
          time: res.data.paths[0].time,
        });
        if (mapRef && mapRef.current && coordinates.length > 0) {
          addJsonData({
            map: mapRef.current.leafletElement,
            jsonData: [
              {
                type: "Point",
                coordinates: [coordinates[0][0], coordinates[0][1]],
                icon: icons.RouteStart,
              },
              {
                type: "MultiLineString",
                coordinates: [coordinates],
              },
              {
                type: "Point",
                coordinates: [
                  coordinates[coordinates.length - 1][0],
                  coordinates[coordinates.length - 1][1],
                ],
                icon: icons.RouteEnd,
              },
            ],
          });
        }
      }
    } catch (e) {
      console.log("error :", e);
    }
  };

  return (
    <div className="main-map-container">
      <Leaflet
        ref={mapRef}
        {...(Clickable ? { onClick: onClickEvent } : {})}
        center={Center}
        zoom={Zoom}
        className="main-map"
      >
        <MapLayers />
        {locationPicker && locationPicker.view && (
          <Marker position={locationPicker.coords} icon={icons.PinUrl} />
        )}
        {Markers}
        {legendMarkers}
        {/* {Technicians.map((tech, idx) => (
          <Marker
            key={idx}
            position={tech.lastLocation}
            icon={icons.TechnicianIcon}
          >
            <Popup>
              <div className="map-popup">
                <div>
                  <Icon name="user" className="map-popup-icon"></Icon>
                  <label>{tech.technicianName}</label>
                </div>
                <div>
                  <Icon name="phone"></Icon>
                  <label>{tech.phone}</label>
                </div>
                <div>
                  <Icon name="clock"></Icon>
                  <label>{tech.gpsTime}</label>
                </div>
                <div>
                  <Icon name="map marker alternate"></Icon>
                  <label>{tech.address}</label>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
        {ClientBranches.map((branch, idx) => (
          <Marker
            key={idx}
            position={{ lng: branch.longitude, lat: branch.latitude }}
            icon={icons.ClientIcon}
          >
            <Popup>
              <div className="map-popup">
                <div>
                  <Icon name="user" className="map-popup-icon"></Icon>
                  <label>{branch.branchName}</label>
                </div>
                <div>
                  <Icon name="phone"></Icon>
                  <label>{branch.phone}</label>
                </div>
                <div>
                  <Icon name="map marker alternate"></Icon>
                  <label>{branch.address}</label>
                </div>
              </div>
            </Popup>
          </Marker>
        ))} */}
      </Leaflet>
      {Search && <SearchOnMap setSearchData={_setSearchData} />}
      {Boolean(routingData) && <Routing routingData={routingData} />}
      {Legend && (
        <>
          <img
            className="map-markers-legend"
            src={mapLegendImg}
            onClick={() => setIsOpenLegend(!isOpenLegend)}
          />
          <MapLegend
            isOpen={isOpenLegend}
            setLegendMarkers={(markers) => setLegendMarkers(markers)}
          />
        </>
      )}
    </div>
  );
}
