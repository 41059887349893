import React, { useEffect, useState } from "react";
import "./tech-home.scss";
import { icons } from "../../components/Map/helpers/MapIcons";
import "../../components/Map/map.scss";
import { Divider } from "semantic-ui-react";
import { Marker, Popup } from "react-leaflet";
import Map from "../../components/Map/Map";

export default function TechHome({ location }) {
  const [routingPoints, setRoutingPoints] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [flyToOptions, setFlyToOptions] = useState(null);

  const setMapMarker = (ticket) => {
    setMarkers(
      <Marker
        position={{
          lng: +ticket.ClientBranch.longitude,
          lat: +ticket.ClientBranch.latitude,
        }}
        icon={icons.RouteEnd}
      >
        <Popup>
          <div className="popup-container">
            <label className="popup-header">
              {ticket.ClientBranch
                ? ticket.ClientBranch.BranchName
                : "فرع غير محدد"}
            </label>
            <Divider style={{ margin: "5px" }} />
            <div>
              <label className="popup-label">التذكرة :{ticket.ticketID}</label>
              <label className="popup-label">
                العميل :
                {ticket.ClientBranch && ticket.ClientBranch.Client
                  ? ticket.ClientBranch.Client.ClientName
                  : "عميل غير محدد"}
              </label>
              <label className="popup-label">
                للتواصل:
                {ticket.contactName}-{ticket.contactPhone}
              </label>

              <label className="popup-label">
                الآلة:
                {ticket.ContractMachine && ticket.ContractMachine.Machine
                  ? ticket.ContractMachine.Machine.computerNumber
                  : "رقم الآلة غير محدد"}
              </label>
            </div>
          </div>
        </Popup>
      </Marker>
    );
    setFlyToOptions({
      center: {
        lng: +ticket.ClientBranch.longitude,
        lat: +ticket.ClientBranch.latitude,
      },
      zoom: 15,
    });
  };
  const setMaVisitMarker = (machine) => {
    setMarkers(
      <Marker
        position={{
          lng: +machine.longitude,
          lat: +machine.latitude,
        }}
        icon={icons.RouteEnd}
      >
        <Popup>
          <div className="popup-container">
            <label className="popup-header">
              {machine.BranchName ? machine.BranchName : "فرع غير محدد"}
            </label>
            <Divider style={{ margin: "5px" }} />
            <div>
              <label className="popup-label">
                العميل :
                {machine.ClientName ? machine.ClientName : "عميل غير محدد"}
              </label>
              {/* <label className="popup-label">
                للتواصل:
                {machine.contactName}-{machine.ContactPhone}
              </label> */}
              <label className="popup-label">
                الآلة:
                {machine.ComputerNumber
                  ? machine.ComputerNumber
                  : "رقم الآلة غير محدد"}
              </label>
            </div>
          </div>
        </Popup>
      </Marker>
    );
    setFlyToOptions({
      center: {
        lng: +machine.longitude,
        lat: +machine.latitude,
      },
      zoom: 15,
    });
  };

  useEffect(() => {
    if (location && location.state && location.state.isRouting)
      setRoutingPoints(location.state.points);
    else if (
      location &&
      location.state &&
      location.state.showOnMap &&
      Boolean(location.state.ticket)
    ) {
      setMapMarker(location.state.ticket);
    } else if (
      location &&
      location.state &&
      location.state.showOnMap &&
      location.state.machine
    ) {
      setMaVisitMarker(location.state.machine);
    }
  }, [location]);

  return (
    <div className="tech-home-container">
      <Map
        Search
        Legend
        routingPoints={routingPoints}
        Markers={markers}
        FlyToOptions={flyToOptions}
      />
    </div>
  );
}
