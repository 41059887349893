import React, { useRef } from "react";
import { Map as Leaflet, Polygon, Popup } from "react-leaflet";
import L from "leaflet";
import MapLayers from "../../components/Map/MapLayers";
import wkt from "terraformer-wkt-parser";
L.Icon.Default.imagePath = "/images/";

const TechAreasMap = ({
  Center = { lng: 36.5565, lat: 31.535654 },
  Zoom = 7,
  areas,
  selectedArea,
}) => {
  const mapRef = useRef(null);

  const getGeom = (wkt_string) => {
    var geo = wkt.parse(wkt_string);
    var polygon = geo.coordinates[0].map(
      (point) => new L.latLng(point[1], point[0])
    );

    return polygon;
  };

  return (
    <div className="main-map-container">
      <Leaflet ref={mapRef} center={Center} zoom={Zoom} className="main-map">
        <MapLayers />
        {areas.map((area) => (
          <Polygon key={area.key} positions={getGeom(area.geom)}>
            <Popup>{area.text}</Popup>
          </Polygon>
        ))}
        {Boolean(selectedArea.value) && (
          <Polygon
            key={selectedArea.key}
            positions={getGeom(selectedArea.geom)}
            color="red"
          />
        )}
      </Leaflet>
    </div>
  );
};

export default TechAreasMap;
