import React, { useEffect, useState } from "react";
import serviceApi from "../../../api";
import TableTemplate from "../../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Checkbox, Input } from "semantic-ui-react";
import PageHeaderTitle from "../../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../../assets/LocalizationResources";
import SearchBy from "../../../components/SearchBy/SearchBy";
import DeleteModal from "../../../components/DeleteModal";
import Perm, { isAuthorized } from "../../../components/helpers/Permissions";
import getDeleteError from "../../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";
import TicketActionsModal from "../../../components/Tickets/TicketActionsModal";
import TicketInfoModal from "../../../components/Tickets/TicketInfoModal";
import doneImg from "../../../assets/images/done.svg";
import ticketStatusReportImg from "../../../assets/images/ticketStatusHistory.svg";

export default function ExternalTicketsList() {
  const user = useSelector((state) => state.user.user);
  const router = useHistory();

  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [ticketID, setTicketID] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [currentTicketStatus, setCurrentTicketStatus] = useState(0);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [changeTicketModal, setChangeTicketModal] = useState(false);
  const [ticketStatus, setTicketStatus] = useState([
    {
      key: 22274,
      text: "اضافة ملاحظة",
      value: 22274,
    },
  ]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [selectedTicketDateFrom, setSelectedTicketDateFrom] = useState(null);
  const [selectedTicketDateTo, setSelectedTicketDateTo] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(false);

  const headers = {
    ticketID: resources.pages.ticket.ticketID,
    branchName: resources.pages.ticket.branchName,
    contactName: resources.pages.ticket.contactName,
    // technicianName: resources.pages.technicians.technicianName,
    clientName: resources.pages.ticket.clientName,
    serialNumber: resources.pages.machines.serialNumber,
    notes: resources.pages.ticket.notes,
    createdat: resources.pages.ticket.ticketDate,
    customerSerialNumber: resources.pages.ticket.CustomerSerial,
    status: resources.pages.ticket.ticketStatus,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  const fillSearchData = async () => {
    setSearchData({
      ticketID: { type: "text", label: resources.pages.ticket.ticketID },
      branchName: {
        type: "text",
        label: resources.pages.ticket.branchName,
      },
      contactName: {
        type: "text",
        label: resources.pages.ticket.contactName,
      },
      clientName: {
        type: "text",
        label: resources.pages.ticket.clientName,
      },
      // technicianName: {
      //   type: "text",
      //   label: resources.pages.technicians.technicianName,
      // },
      serialNumber: {
        type: "text",
        label: resources.pages.machines.serialNumber,
      },
      customerSerialNumber: {
        type: "text",
        label: resources.pages.ticket.CustomerSerial,
      },
      status: {
        type: "ddl",
        label: resources.pages.ticket.ticketStatus,
        options: await getOptions(96),
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "tickets"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const getOptions = async (parentID) => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: parentID,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        return res.data.map((item) => {
          return {
            key: item.LookupID,
            text: item.LookupName,
            value: item.LookupID,
          };
        });
      else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return { where: {} };
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  useEffect(() => {
    if (reloadTrigger) {
      loadTickets();
      setReloadTrigger(false);
    }
  }, [reloadTrigger]);

  const loadTickets = async () => {
    try {
      setIsLoading(true);
      const userBranches =
        user.userBranches === "all"
          ? "all"
          : user.userBranches.split(",").map((item) => parseInt(item));
      const clientId = user.clientId;
      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "ClientBranch",
          attributes: ["BranchName", "BranchID"],
          include: [
            {
              innerJoin: true,
              model: "Client",
              attributes: ["ClientName"],
              where: {
                ...whereLikeSearch("clientName").where,
                ClientID: clientId,
              },
            },
          ],
          where: {
            ...whereLikeSearch("branchName").where,
          },
        },
        {
          ...(searching && searching.searchBy == "serialNumber"
            ? { innerJoin: true }
            : {}),
          model: "ContractMachine",
          attributes: ["machineID", "withSLA", "dueHours"],
          include: [
            {
              innerJoin: true,
              model: "Machine",
              attributes: ["machineId", "serialNumber"],
              ...whereLikeSearch("serialNumber"),
            },
          ],
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "status",
          attributes: ["LookupName"],
          ...(Object.keys(whereSearch("status", "LookupID")).length
            ? whereSearch("status", "LookupID")
            : showClosed
            ? {
                where: { LookupID: 99 },
              }
            : { where: { LookupID: { ne: 99 } } }),
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "Tickets",
          attributes: [
            "ticketID",
            "contactName",
            "notes",
            "createdat",
            "statusID",
            "ticketDueHours",
            "customerSerialNumber",
          ],
          jsonInclude: jsonInclude,
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
          where: {
            ...whereLikeSearch("ticketID").where,
            ...whereLikeSearch("contactName").where,
            ...whereLikeSearch("customerSerialNumber").where,
            ...(userBranches === "all"
              ? {}
              : {
                  BranchID: {
                    in: userBranches,
                  },
                }),
            ...(Boolean(selectedTicketDateFrom && selectedTicketDateTo)
              ? {
                  createdAt: {
                    between: [
                      moment(selectedTicketDateFrom).format("YYYY-MM-DD") +
                        " 00:00:00",
                      moment(selectedTicketDateTo).format("YYYY-MM-DD") +
                        " 23:59:59",
                    ],
                  },
                }
              : {}),
          },
        },
      });

      const flattenedData = res.data.map((row) => {
        const editedRow = {
          ...row,
          branchName: row.ClientBranch
            ? row.ClientBranch.BranchName
            : resources.common.notSpecified,
          contactName: row.contactName
            ? row.contactName
            : resources.common.notSpecified,
          clientName: row.ClientBranch.Client
            ? row.ClientBranch.Client.ClientName
            : resources.common.notSpecified,
          technicianName: row.Technician
            ? row.Technician.TechnicianName
            : resources.common.notSpecified,
          serialNumber: row.ContractMachine
            ? row.ContractMachine.Machine.serialNumber
            : resources.common.notSpecified,
          customerSerialNumber: row.customerSerialNumber
            ? row.customerSerialNumber
            : resources.common.notSpecified,
          notes: row.notes ? row.notes : resources.common.noNotes,
          createdat: moment(row.createdat).format("DD/MM/YYYY"),
          status: row.status.LookupName
            ? row.status.LookupName
            : resources.common.notSpecified,
          lateForDueHours: Boolean(
            row.ContractMachine && row.ContractMachine.dueHours
          )
            ? new Date().getTime() >
              new Date(row.createdat).setHours(
                new Date(row.createdat).getHours() +
                  row.ContractMachine.dueHours
              )
              ? resources.pages.ticket.late
              : resources.pages.ticket.notLate
            : Boolean(row.ticketDueHours) &&
              new Date().getTime() >
                new Date(row.createdat).setHours(
                  new Date(row.createdat).getHours() + row.ticketDueHours
                )
            ? resources.pages.ticket.late
            : resources.pages.ticket.notLate,
        };
        delete editedRow.ClientBranch;
        delete editedRow.ContractMachine;
        return editedRow;
      });
      setTickets(flattenedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTickets();
  }, [searching, activePage, showClosed]);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/manageExternalTicket", {
        ticketID: row.ticketID,
      });
    } else if (type === "ticketChangeStatusAction") {
      setTicketID(row.ticketID);
      setCurrentTicketStatus(row.statusID);
      setChangeTicketModal(true);
    } else if (type === "ticketStatusRecord") {
      setTicketID(row.ticketID);
      setOpenInfoModal(true);
    }
  };

  const SearchBoxes = ({ label, onChange, stateKey }) => {
    return (
      <div className="checkBoxFilter-container">
        <Checkbox label={label} checked={stateKey} onChange={onChange} />
      </div>
    );
  };

  return (
    <>
      <TicketActionsModal
        ticketStatus={ticketStatus}
        ticketID={ticketID}
        isOpen={changeTicketModal}
        currentTicketStatus={currentTicketStatus}
        closeChangeTicketModal={() => {
          setChangeTicketModal(false);
        }}
      />
      <TicketInfoModal
        ticketID={ticketID}
        isOpen={openInfoModal}
        closeTicketInfoModal={() => {
          setOpenInfoModal(false);
        }}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.ticket.ticketsList}
            icon="ticket"
          />
          <div className="two-buttons-container">
            <Button
              basic
              color="blue"
              content={resources.pages.ticket.addPageTitle}
              onClick={() => router.push("/manageExternalTicket")}
            />
            <ExportDataToExcel
              data={tickets}
              headers={headers}
              title={resources.common.excelExport.ticketsList}
            />
          </div>
          <Segment>
            <SearchBy
              pageName="tickets"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
              withDate
              selectedTicketDateFrom={selectedTicketDateFrom}
              selectedTicketDateTo={selectedTicketDateTo}
              setSelectedTicketDateFrom={setSelectedTicketDateFrom}
              setSelectedTicketDateTo={setSelectedTicketDateTo}
              setReloadTrigger={setReloadTrigger}
              extraFields={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <SearchBoxes
                    label={resources.pages.ticket.showClosed}
                    onChange={(evt, elm) => setShowClosed(Boolean(elm.checked))}
                    stateKey={showClosed}
                  />
                </div>
              }
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={tickets}
              edit={true}
              extraCells={[
                {
                  key: "ticketChangeStatusAction",
                  tooltip: resources.pages.ticket.takeAction,
                  children: <img src={doneImg} className="img-icon" />,
                  header: "",
                  values: [99],
                  valuesID: "statusID",
                  isCliCkable: true,
                },
                {
                  key: "ticketStatusRecord",
                  tooltip: resources.ticketInformation.ticketStatusRecord,
                  children: (
                    <img src={ticketStatusReportImg} className="img-icon" />
                  ),
                  header: "",
                  isCliCkable: true,
                },
              ]}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
