import React from "react";
import { Menu, Icon, Sidebar, Divider, Header, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import routingImg from "../../assets/images/routing/trackingLocationImg.svg";
import tescoLogo from "../../assets/images/tescoNewLogo.png";

const VerticalSidebar = ({ visible, menuItems = [], hideSidebarFn }) => {
  return (
    <Sidebar
      as={Menu}
      animation="push"
      direction="right"
      icon="labeled"
      vertical
      visible={visible}
      width="wide"
    >
      <div className="sidebar-header">
        <div>
          <img src={tescoLogo} />
        </div>
        <p>{resources.pages.loginPage.maintenanceContractSystem}</p>
      </div>
      {menuItems.map((item) => (
        <Link
          key={item.label}
          className="item sidebar-item"
          to={item.path}
          onClick={hideSidebarFn}
        >
          {Boolean(
            item.label === resources.pages.playBackMap.techsMovePoints
          ) ? (
            <img src={routingImg} />
          ) : (
            <Icon name={item.icon} color="blue" />
          )}
          <label>{item.label}</label>
        </Link>
      ))}
    </Sidebar>
  );
};

export default VerticalSidebar;
