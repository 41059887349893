import { takeLatest, put } from "redux-saga/effects";
import { userActions } from "../actions";
import { userActionCreator } from "../actions";
import serviceApi from "../../api";

function* login({ payload }) {
  try {
    const data = yield serviceApi.authenticate({
      strategy: "local",
      ...payload,
    });

    if (data.user.isActive) {
      const userData = yield serviceApi
        .service("queries-text")
        .find({ query: { userID: data.user.userId, type: 1 } });
      if (userData && userData.permissions.length) {
        data.user.permission = userData.permissions.map((m) => m.PermissionID);
      }
      if (userData && userData.technicianID.length)
        data.user.technicianID = userData.technicianID[0].TechnicianID;

      yield put(userActionCreator.login(data));
    } else {
      yield serviceApi.logout();
      yield put(userActionCreator.loginFailed());
    }
  } catch (error) {
    // TODO: dispatch login/connection error instead
    console.log(error);
    yield put(userActionCreator.loginFailed());
  }
}

export function* loginRequested() {
  yield takeLatest(userActions.LOGIN_REQUEST_START, login);
}
