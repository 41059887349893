import store from "../../redux/store/index";

const Perm = {
  users: 2,
  machines: 3,
  AddUser: 4,
  editUser: 5,
  deleteUser: 6,
  changePassw0rd: 7,
  technicians: 8,
  addNewTechnician: 9,
  editTechnician: 10,
  deleteTechnician: 11,
  TechnicianAreas: 13,
  TechnicianAlternative: 15,
  ClientBranches: 16,
  AddNewClient: 17,
  addExtraDevices: 18,
  editClient: 19,
  addBranch: 20,
  editBranch: 21,
  deleteClient: 22,
  deleteBranch: 23,
  AddMachine: 24,
  editMachine: 25,
  deleteMachine: 26,
  machineParts: 27,
  contracts: 28,
  addContract: 29,
  editContract: 30,
  deleteContract: 31,
  addMachinesToContract: 32,
  technicianAreas: 33,
  addTechnicianAreas: 34,
  UserLeaves: 35,
  AddUserLeave: 36,
  editUserLeave: 37,
  deleteUserLeave: 38,
  GroupPermissions: 39,
  addNewGroupPermissions: 40,
  editGroupPermissions: 41,
  deleteGroupPermissions: 42,
  tickets: 43,
  addTicket: 44,
  editTicket: 45,
  deleteTicket: 46,
  MaintenanceReports: 47,
  editMaintenanceReports: 48,
  printOrSendMaintenanceReports: 49,
  printInvoiceReports: 50,
  areas: 51,
  addArea: 52,
  editArea: 53,
  deleteArea: 54,
  callCenter: 59,
  lookups: 60,
  addLookups: 61,
  editLookups: 62,
  deleteLookups: 66,
  reports: 67,
  technicianTickets: 69,
  technicianVisits: 70,
  viewUsers: 71,
  viewMachines: 72,
  viewTech: 73,
  viewClientBranch: 74,
  viewContract: 75,
  viewGeofences: 77,
  viewLeaves: 78,
  viewLookups: 79,
  viewMaintenaceReport: 80,
  viewTicktes: 81,
  viewtTechAreas: 82,
  viewGroupPermissions: 83,
  addUserLeaveAndVacation: 84,
  userPermission: 85,
  tablets: 86,
  addTablets: 87,
  editTablets: 88,
  deleteTablets: 89,
  viewTablets: 90,
  viewMachineInfo: 91,
  contractRenewal: 92,
  contractPayments: 93,
  techMovePoints: 94,
  editRequests: 95,
  viewEditRequests: 96,
  viewAllTickets: 97,
  viewAllReports: 98,
  viewAllMaintenanceReports: 99,
  viewFinancialReports: 100,
  editInvoice: 101,
  editContractNumberAndType: 102,
};

/**
 * @param {Number[]} IDs
 */
export function isAuthorized(...IDs) {
  const { user } = store.getState().user;
  if (user && user.userId) {
    const userId = user.userId;
    const permissions = user.permission;
    if (userId === 1) return true;
    else if (permissions && permissions.length > 0) {
      for (var i = 0; i < IDs.length; i++)
        if (permissions.indexOf(IDs[i]) > -1) return true;
      return false;
    }
  }
}

export default Perm;
