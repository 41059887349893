import React, { useState } from "react";
import { Button, Modal, Segment } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";

const ContactPersonsListModal = ({
  isOpen,
  contactPersons,
  closeContactPersonListModal,
}) => {
  return (
    <Modal size="tiny" open={isOpen} className="contact-person-list-modal">
      <Modal.Header>{resources.pages.branch.contacts}</Modal.Header>
      {contactPersons.length > 0 ? (
        <Modal.Content>
          <Segment.Group horizontal>
            <Segment className="left-border segment-fifty">
              {resources.common.name}
            </Segment>
            <Segment className="segment-fifty">
              {resources.common.phoneNum}
            </Segment>
            <Segment className="segment-fifty">
              {resources.common.email}
            </Segment>
          </Segment.Group>
          {contactPersons.map((person, index) => (
            <Segment.Group horizontal key={index} className="a">
              <Segment className="left-border segment-fifty">
                {person.personName}
              </Segment>
              <Segment className="segment-fifty">
                {person.personPhoneNumber
                  ? person.personPhoneNumber
                  : resources.common.notSpecified}
              </Segment>
              <Segment className="segment-fifty">
                {person.personEmail
                  ? person.personEmail
                  : resources.common.notSpecified}
              </Segment>
            </Segment.Group>
          ))}
        </Modal.Content>
      ) : (
        <div className="noContactBranchData-message">
          {resources.pages.branch.noContactBranchData}
        </div>
      )}
      <Modal.Actions>
        <Button onClick={() => closeContactPersonListModal()} negative>
          {resources.common.close}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ContactPersonsListModal;
