import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Segment,
  Button,
  Icon,
  Popup,
  Modal,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import SuccessModal from "../../components/SuccessModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { cacheSearchActionCreator } from "../../redux/actions";

export default function UsersList() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [userID, setUserID] = useState(null);
  const [users, setUsers] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [openDeActivateOrActivateModal, setOpenDeActivateOrActivateModal] =
    useState(false);
  const [isActivationAction, setIsActivationAction] = useState(false);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupIDs, setGroupIDs] = useState([]);
  const [successVisible, setSuccessVisible] = useState(false);
  const headers = {
    userName: resources.pages.user.username,
    fullName: resources.pages.user.fullname,
    email: resources.pages.user.email,
    phone: resources.pages.user.phone,
    createdat: resources.common.createdDate,
  };
  const [showUnActiveUsers, setShowUnActiveUsers] = useState(false);

  const searchState = useSelector((state) => state.cacheSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    fillSearchData();
  }, []);

  const fillSearchData = async () => {
    setSearchData({
      userName: {
        type: "text",
        label: resources.pages.user.username,
      },
      fullName: {
        type: "text",
        label: resources.pages.user.fullname,
      },
      email: {
        type: "text",
        label: resources.pages.user.email,
      },
      phone: {
        type: "text",
        label: resources.pages.user.phone,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "users"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
    if (
      searchState &&
      searchState.extraOptions &&
      searchState.extraOptions.showUnActiveUsers
    ) {
      console.log(searchState.extraOptions);
      setShowUnActiveUsers(Boolean(searchState.extraOptions.showUnActiveUsers));
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { [colID]: { $like: `%${searching.term}%` } };
    else return {};
  };

  const loadUsers = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("users").find({
        query: {
          $select: [
            "userId",
            "userName",
            "fullName",
            "email",
            "phone",
            "createdat",
            "isActive",
          ],
          ...whereLikeSearch("userName"),
          ...whereLikeSearch("fullName"),
          ...whereLikeSearch("email"),
          ...whereLikeSearch("phone"),
          ...(showUnActiveUsers ? { isActive: 0 } : { isActive: 1 }),
          $limit: pagination.pageSize,
          $skip: pagination.pageSize * (activePage - 1),
        },
      });
      const editedData = res.data.map((row) => {
        return {
          ...row,
          createdat: moment(row.createdat).format("DD/MM/YYYY"),
          email: row.email ? row.email : resources.common.notSpecified,
          phone: row.phone ? row.phone : resources.common.notSpecified,
          rowColor: row.isActive ? null : "#d4d4d5",
        };
      });
      setUsers(editedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const fillGroups = async () => {
    try {
      const res = await serviceApi.service("groups").find({
        query: {
          $select: ["groupId", "groupName"],
        },
      });
      if (res && res.data && res.data.length > 0)
        setGroups(
          res.data.map((item) => {
            return {
              key: item.groupId,
              text: item.groupName,
              value: item.groupId,
            };
          })
        );
      else setGroups([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadUsers();
  }, [searching, activePage, showUnActiveUsers]);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(openItemModal) && fillGroups();
  }, [openItemModal]);

  useEffect(() => {
    if (userID) {
      const getSelectedIds = async () => {
        const ids = await serviceApi.service("user-groups").find({
          query: {
            UserID: userID,
            $select: ["GroupID"],
          },
        });
        if (Boolean(ids && ids.data && ids.data.length > 0))
          setGroupIDs(ids.data.map((item) => item.GroupID));
        else setGroupIDs([]);
      };

      getSelectedIds();
    }
  }, [groups]);

  const SaveUserPermissons = async () => {
    try {
      await serviceApi.service("user-groups").remove(null, {
        query: {
          UserID: userID,
        },
      });

      if (groupIDs.length > 0) {
        await serviceApi.service("user-groups").create(
          groupIDs.map((item) => ({
            UserID: userID,
            GroupID: item,
          }))
        );
      }

      setGroupIDs([]);
      setOpenItemModal(false);
      setSuccessVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onCellClick = (row, type) => {
    setUserID(row.userId);
    if (type === "edit")
      router.push("/settings/users/manageUser", {
        userId: row.userId,
      });
    else if (type === "remove") setIsDelete(true);
    else if (type === "userPermission") setOpenItemModal(true);
    else if (type === "changePassword")
      router.push("/settings/users/changePassword", {
        userId: row.userId,
        fullName: row.fullName,
      });
    else if (type === "addLeave")
      router.push("/settings/Leave/manageLeave", {
        userId: row.userId,
        fullName: row.fullName,
      });
    else if (type === "deActivateOrActivateUser") {
      if (row.isActive) setIsActivationAction(false);
      else setIsActivationAction(true);
      setOpenDeActivateOrActivateModal(true);
    }
  };

  const deleteUser = async () => {
    try {
      await serviceApi
        .service("users")
        .remove(userID)
        .then(() => {
          setIsDelete(false);
          loadUsers();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  const deActivateOrActivateUser = async () => {
    if (isActivationAction)
      await serviceApi.service("users").patch(userID, {
        isActive: 1,
      });
    else
      await serviceApi.service("users").patch(userID, {
        isActive: 0,
      });
    loadUsers();
    setOpenDeActivateOrActivateModal(false);
  };

  const handleConfirm = () => {
    setSuccessVisible(false);
  };
  const handleGroups = (event, { value }) => {
    setGroupIDs(value);
  };
  return (
    <>
      <DeleteModal
        onConfirm={() => deleteUser()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <Modal size="tiny" open={openItemModal}>
        <Modal.Header>{resources.pages.user.permissionChoose}</Modal.Header>
        <Modal.Content>
          <Dropdown
            placeholder={resources.pages.user.permissionChoose}
            fluid
            multiple
            search
            value={groupIDs}
            selection
            options={groups}
            onChange={handleGroups}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={SaveUserPermissons} positive>
            {resources.common.save}
          </Button>
          <Button onClick={() => setOpenItemModal(false)} negative>
            {resources.common.cancel}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={openDeActivateOrActivateModal}>
        <Modal.Header>
          {isActivationAction
            ? resources.pages.user.activateUser
            : resources.pages.user.disableUser}
        </Modal.Header>
        <Modal.Content>
          {isActivationAction
            ? resources.pages.user.activateUserMessage
            : resources.pages.user.disableUserMessage}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={deActivateOrActivateUser} positive>
            {resources.common.yes}
          </Button>
          <Button
            onClick={() => setOpenDeActivateOrActivateModal(false)}
            negative
          >
            {resources.common.no}
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.user.userList}
            icon="users"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.AddUser) && (
              <Button
                basic
                color="blue"
                content={resources.pages.user.addNewUser}
                onClick={() => router.push("/settings/users/manageUser")}
              />
            )}
            {isAuthorized(Perm.viewUsers) && (
              <ExportDataToExcel
                data={users}
                headers={headers}
                title={resources.common.excelExport.users}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              searchState={searchState}
              pageName="users"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
              extraFields={
                <div className="not-associated-checkbox-container">
                  <Checkbox
                    label={resources.pages.user.showUnActiveUsers}
                    checked={showUnActiveUsers}
                    onChange={(evt, elm) => {
                      setShowUnActiveUsers(Boolean(elm.checked));
                      dispatch(
                        cacheSearchActionCreator.setExtraOptions({
                          showUnActiveUsers: !Boolean(elm.checked),
                        })
                      );
                    }}
                  />
                </div>
              }
            />
            <TableTemplate
              headers={headers}
              data={users}
              edit={isAuthorized(Perm.editUser) ? true : null}
              isLoading={isLoading}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              remove={isAuthorized(Perm.deleteUser) ? true : null}
              onCellClick={(row, type) => onCellClick(row, type)}
              extraCells={[
                {
                  key: "deActivateOrActivateUser",
                  tooltip: resources.pages.user.userSituation,
                  children: <Icon name="shield" color="blue" />,
                  header: "",
                  isCliCkable: true,
                },
                ...(isAuthorized(Perm.userPermission)
                  ? [
                      {
                        key: "userPermission",
                        children: (
                          <Popup
                            size="mini"
                            content={resources.pages.user.userPermission}
                            trigger={<Icon name="hand paper" color="blue" />}
                          />
                        ),
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.changePassw0rd)
                  ? [
                      {
                        key: "changePassword",
                        tooltip: resources.pages.user.changePassword,
                        children: <Icon name="key" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.addUserLeaveAndVacation)
                  ? [
                      {
                        key: "addLeave",
                        tooltip: resources.pages.leave.addLeave,
                        children: <Icon name="time" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
