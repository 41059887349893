import React, { useState, useEffect } from "react";
import "./MaintenanceCardPrintOutPage.scss";
import { Loader } from "semantic-ui-react";
import moment from "moment";
import qs from "qs";
import { useLocation } from "react-router-dom";
import serviceApi from "../../api";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import {
  generateInvoiceNumber,
  generateMachinePartInvoiceNumber,
} from "../../containers/MaintenanceReport/MaintenanceReportsList";
const MaintenanceCardPrintOutPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [printOutInfo, setPrintOutInfo] = useState([]);
  const [lastClientName, setLastClientName] = useState("");
  const { from, to, computerNumber } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await serviceApi.service("queries-text").find({
        query: {
          from,
          to,
          computerNumber,
          type: 32,
        },
      });

      const { MachineData, lastClient } = response;

      const processedData = MachineData.map((row) => {
        return {
          ...row,
          invoiceDate: row.createdAt
            ? moment(row.createdAt).format("YYYY-MM-DD")
            : null,
          previousCounter: row.Counter1 ? row.Counter1 : null,
          consumedImages: row.TotalImagesBlack
            ? row.TotalImagesBlack
            : row.Counter1,
          actionsCompleted: row.Action ? row.Action : null,
          maintenanceReportNumber: row.MaintenanceReportID
            ? row.MaintenanceReportID
            : null,
          InvoiceNumber: row.InvoiceNumber
            ? generateInvoiceNumber(row.createdAt, row.InvoiceNumber)
            : null,
          MachinePartInvoiceNumber: row.MachinePartInvoiceNumber
            ? generateMachinePartInvoiceNumber(
                row.createdAt,
                row.MachinePartInvoiceNumber
              )
            : null,
          fils: row.Total ? row.Total.toString().split(".")[1] : null,
          dinar: row.Total ? row.Total.toString().split(".")[0] : null,
          technicianName: row.TechnicianName ? row.TechnicianName : null,
        };
      });

      setLastClientName(lastClient);
      setPrintOutInfo(processedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setPrintOutInfo([]);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [from, to, computerNumber]);

  return (
    <div className="not-deserved-contract-printout-container-Maintenance-Card  ">
      <div>
        {isLoading ? (
          <div className="loader-container-Maintenance-Card ">
            <Loader inline active={isLoading} />
          </div>
        ) : (
          <>
            <div>
              <PrintOutHeaderLandscape
                printOutTitle="بطاقة صيانة ومحاسبة على الآت تصوير الوثائق"
                dateTitle="تاريخ الفاتورة "
                startDate={from}
                endDate={to}
                hasDate={true}
                data={printOutInfo}
                headers={{
                  createdAt: "تاريخ الفاتورة",
                  Counter1: "رقم العداد السابق",
                  TotalImagesBlack: "عدد الصور المستهلكة",
                  Action: "الأعمال المنجزة",
                  MaintenanceReportID: "رقم تقرير الصيانة",
                  InvoiceNumber: "رقم الفاتورة",
                  Total: "قيمة الفاتورة",
                  TechnicianName: "اسم الفني",
                  MachinePartInvoiceNumber: "رقم فاتورة القطع",
                }}
                extraHeader={
                  <div className="mb-1">
                    <div>
                      <h5>رقم الجهاز: {computerNumber}</h5>
                    </div>
                    {lastClientName && (
                      <div>
                        <h5>اسم العميل : {lastClientName}</h5>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            {printOutInfo.length > 0 ? (
              <div className="not-deserved-contract-printout-table-container-Maintenance-Card ">
                <table className="invoice-printout-table-Maintenance-Card ">
                  <thead>
                    <tr>
                      <th>تاريخ الفاتورة</th>
                      <th>رقم العداد</th>
                      <th>عدد الصور المستهلكه</th>
                      <th>الأعمال المنجزه</th>
                      <th>رقم تقرير الصيانه</th>
                      <th>رقم الفاتورة</th>
                      <th>رقم فاتورة القطع</th>
                      <th>فلس</th>
                      <th>دينار</th>
                      <th>اسم الفني</th>
                    </tr>
                  </thead>
                  <tbody>
                    {printOutInfo.map((info, idx) => (
                      <tr key={idx}>
                        <td>
                          {info.createdAt
                            ? moment(info.createdAt).format("YYYY-MM-DD")
                            : " لايوجد بيانات"}
                        </td>
                        <td>{info.Counter1 ? info.Counter1 : "لايوجد"}</td>
                        <td>
                          {info.TotalImagesBlack
                            ? info.TotalImagesBlack
                            : info.Counter1}
                        </td>
                        <td>{info.Action ? info.Action : " لايوجد بيانات"}</td>
                        <td>
                          {info.MaintenanceReportID
                            ? info.MaintenanceReportID
                            : " لايوجد بيانات"}
                        </td>
                        <td>
                          {info.InvoiceNumber
                            ? info.InvoiceNumber
                            : " لايوجد بيانات"}
                        </td>
                        <td>
                          {info.MachinePartInvoiceNumber
                            ? info.MachinePartInvoiceNumber
                            : " لايوجد بيانات"}
                        </td>
                        <td>
                          {info.Total
                            ? info.Total.toString().split(".")[1]
                            : "لايوجد بيانات"}
                        </td>
                        <td>
                          {info.Total
                            ? info.Total.toString().split(".")[0]
                            : "لايوجد بيانات"}
                        </td>
                        <td>
                          {info.TechnicianName
                            ? info.TechnicianName
                            : " لايوجد بيانات"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                style={{
                  marginTop: "10px",
                  fontSize: 30,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                لا توجد بيانات
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MaintenanceCardPrintOutPage;
