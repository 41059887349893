import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { useSelector } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import moment from "moment";
import { generateInvoiceNumber } from "../MaintenanceReport/MaintenanceReportsList";

export default function MaintenanceInvoiceReportsList() {
  const router = useHistory();
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const headers = {
    maintenanceReportId: resources.pages.maintenanceReport.maintenanceReportID,
    maintenanceReportInvoiceId: resources.pages.machines.invoiceNumber,
    technicianName: resources.pages.maintenanceReport.technicianName,
    machineId: resources.pages.maintenanceReport.computerNumber,
    enteranceDate: resources.pages.maintenanceReport.enteranceDate,
  };

  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );
  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const fillSearchData = async () => {
    setSearchData({
      maintenanceReportInvoiceId: {
        type: "text",
        label: resources.pages.machines.invoiceNumber,
      },
      maintenanceReportId: {
        type: "text",
        label: resources.pages.maintenanceReport.maintenanceReportID,
      },
      machineId: {
        type: "text",
        label: resources.pages.maintenanceReport.computerNumber,
      },
      technicianName: {
        type: "text",
        label: resources.pages.maintenanceReport.technicianName,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "maintenanceReports"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadMaintenanceInvoiceReports = async () => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "MaintenanceReport",
          attributes: ["MaintenanceReportId"],
          include: [
            {
              innerJoin: true,
              model: "ContractMachine",
              attributes: ["ContractMachineID"],
              include: [
                {
                  innerJoin: true,
                  model: "Machine",
                  attributes: ["machineId", "computerNumber"],
                },
                {
                  innerJoin: true,
                  model: "Technicians",
                  attributes: ["technicianId", "technicianName"],
                  ...whereLikeSearch("technicianName"),
                },
              ],
              ...whereLikeSearch("machineId"),
            },
          ],

          where: {
            ...(!isAuthorized(Perm.viewAllMaintenanceReports)
              ? { createdBy: CurrentUserID }
              : {}),
          },
          ...whereLikeSearch("maintenanceReportId"),
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "MaintenanceReportInvoice",
          attributes: [
            "InvoiceNumber",
            "MaintenanceReportInvoiceID",
            "MaintenanceReportID",
            "createdAt",
          ],
          jsonInclude: jsonInclude,
          order: [["MaintenanceReportID", "DESC"]],
          ...whereLikeSearch("maintenanceReportInvoiceId"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const flattenedData = res.data.map((row) => {
        var technician = row.MaintenanceReport.ContractMachine.Technician;
        const editedRow = {
          ...row,
          maintenanceReportInvoiceId: generateInvoiceNumber(
            row.createdAt,
            row.InvoiceNumber
          ),
          maintenanceReportId: row.MaintenanceReport.MaintenanceReportId,
          machineId:
            row.MaintenanceReport.ContractMachine.Machine.computerNumber,
          enteranceDate: moment(row.createdAt).format("DD/MM/YYYY"),
          technicianName: Boolean(
            technician.technicianId && technician.technicianName
          )
            ? technician.technicianName
            : resources.common.notSpecified,
        };
        return editedRow;
      });

      setMaintenanceReport(flattenedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadMaintenanceInvoiceReports();
  }, [searching, activePage]);

  const onCellClick = (row, type) => {
    if (type === "print")
      window.open(`/maintenanceReportPrintView?id=${row.maintenanceReportId}`);
    else if (type === "printOrSendITMaintenanceReport")
      window.open(
        `/maintenanceReportPrintView?id=${row.maintenanceReportId}&isITMaintenanceReport=true`
      );
    else if (type === "invoice")
      window.open(`/MaintenanceInvoice?id=${row.maintenanceReportId}`);
    else if (type === "viewMaintenanceReportDetails")
      router.push("/MaintenanceReportDetails", {
        maintenanceReportID: row.maintenanceReportId,
      });
  };

  return (
    <>
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.maintenanceReport.invoices}
            icon="ticket"
          />
          <Segment>
            <SearchBy
              pageName="maintenanceReports"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={maintenanceReport}
              extraCells={[
                ...(isAuthorized(Perm.printOrSendMaintenanceReports)
                  ? [
                      {
                        key: "print",
                        children: <Icon name="print" color="blue" />,
                        isCliCkable: true,
                        tooltip:
                          resources.pages.maintenanceReport
                            .printOrSendMaintenanceReport,
                      },
                      {
                        key: "printOrSendITMaintenanceReport",
                        children: <Icon name="print" color="blue" />,
                        isCliCkable: true,
                        tooltip:
                          resources.pages.maintenanceReport
                            .printOrSendITMaintenanceReport,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.printInvoiceReports)
                  ? [
                      {
                        key: "invoice",
                        values: [7, 8],
                        valuesID: "maintenanceTypeID",
                        children: <Icon name="credit card" color="blue" />,
                        isCliCkable: true,
                        tooltip: resources.pages.maintenanceReport.invoice,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.printOrSendMaintenanceReports)
                  ? [
                      {
                        key: "viewMaintenanceReportDetails",
                        tooltip: "عرض التقرير",
                        children: <Icon name="eye" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
