import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Form,
  Button,
  GridColumn,
  Message,
} from "semantic-ui-react";

import { resources } from "../../assets/LocalizationResources";
import PageHeadeTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { host } from "../../api/index";

export default function ManageTechnicians() {
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
    timeout: null,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [notSuccessfulVisible, setNotSuccessfulVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [technician, setTechnician] = useState({
    technicianName: "",
    technicianId: -1,
    mobile: "",
    email: "",
    // machineIdNotRequiredToCloseTicket: 0,
    salesmanNo: 0,
  });

  const router = useHistory();
  const [users, setUsers] = useState([]);
  const [salesmen, setSalesmen] = useState([]);
  const [editable, setEditAble] = useState(false);
  const usersList = async () => {
    try {
      const res = await serviceApi.service("users").find({
        query: {
          $select: ["userid", "fullname"],
          $limit: 100,
        },
      });
      if (res && res.data && res.data.length > 0)
        setUsers([
          {
            key: -1,
            text: resources.pages.ManageTechnicians.notAssociatedWithUser,
            value: -1,
          },
          ...res.data.map((item) => {
            return {
              key: item.userid,
              text: item.fullname,
              value: item.userid,
            };
          }),
        ]);
      else setUsers([]);
    } catch (error) {
      console.log(error);
    }
  };
  const salesmenList = async () => {
    try {
      await axios.get(`${host}/getSalesmen`).then((res) => {
        if (
          res &&
          res.data &&
          res.data.recordset &&
          res.data.recordset.length > 0
        )
          setSalesmen([
            {
              key: -1,
              text: resources.pages.ManageTechnicians.notAssociatedWithUser,
              value: -1,
            },
            ...res.data.recordset.map((item) => {
              return {
                key: item.SalesmanNo,
                text: `${item.SalesmanNo} - ${item.SalesmanName}`,
                value: item.SalesmanNo,
              };
            }),
          ]);
        else setSalesmen([]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    usersList();
    salesmenList();
  }, []);

  const fillFields = async (value, mutator) => {
    try {
      if (value === -1) {
        setEditAble(false);
        mutator.setInfo({
          technicianName: "",
          mobile: "",
          email: "",
        });
        return;
      }

      const data = await serviceApi.service("users").get(value, {
        query: {
          $select: ["userId", "fullName", "email", "phone"],
        },
      });
      setEditAble(true);
      mutator.setInfo({
        technicianName: data.fullName,
        mobile: data.phone,
        email: data.email ? data.email : resources.common.notSpecified,
      });
    } catch (error) {
      setEditAble(false);
      mutator.setInfo({
        technicianName: "",
        mobile: "",
        email: "",
      });
    }
  };

  const onSubmit = async (values) => {
    try {
      if (technician && technician.technicianId == -1) {
        const preUsers = await serviceApi.service("Technicians").find({
          query: {
            UserID: values.UserID,
            $limit: 1,
          },
        });

        if (preUsers.data.length) {
          setNotSuccessfulVisible(true);
        } else {
          await serviceApi.service("Technicians").create({
            ...values,
            UserID: values.UserID === -1 ? null : values.UserID,
          });
          setSuccessVisible(true);
        }
      } else {
        await serviceApi
          .service("Technicians")
          .patch(+technician.technicianId, {
            ...values,
            UserID: values.UserID === -1 ? null : values.UserID,
            salesmanNo: values.salesmanNo,
          });
        setSuccessVisible(true);
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === 400) {
        setErrorMessage(resources.pages.user.errors.duplicateUser);
      }
      setErrorVisible({
        visible: true,
        timeout: setTimeout(() => {
          setErrorVisible({ visible: false, timeout: null });
          setErrorMessage(null);
        }, 5000),
      });
    }
  };

  const getTechnicianDetails = async (techID) => {
    try {
      const res = await serviceApi.service("Technicians").get(techID);
      if (res) {
        setTechnician({
          ...res,
          technicianId: techID,
        });
        if (res.UserID !== null) setEditAble(true);
      } else
        setTechnician({
          technicianName: "",
          technicianId: -1,
          mobile: "",
          email: "",
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (Boolean(location && location.state && location.state.technicianID))
      getTechnicianDetails(+location.state.technicianID);
  }, [location]);

  useEffect(() => {
    return () => {
      if (errorVisible.timeout) clearTimeout(errorVisible.timeout);
    };
  }, [errorVisible]);

  const handleConfirm = () => {
    if (notSuccessfulVisible) setNotSuccessfulVisible(false);
    else router.replace("/technicians");
  };

  const validateEmail = (email) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

  const validator = (v) => {
    const requiredField = resources.common.errors.requiredField;
    const errors = {};
    if (!v.UserID) errors.UserID = requiredField;
    if (!v.technicianName) errors.technicianName = requiredField;
    if (!v.mobile) {
      errors.mobile = requiredField;
    } else if (
      !(
        v.mobile.startsWith("96279") ||
        v.mobile.startsWith("96278") ||
        v.mobile.startsWith("96277")
      )
    ) {
      errors.mobile = resources.pages.user.errors.phone;
    } else if (v.mobile.length !== 12) {
      errors.mobile = resources.pages.user.errors.phoneLength;
    }
    return errors;
  };

  const setInfoMutator = (args, state, utils) => {
    utils.changeValue(state, "technicianName", () => args[0].technicianName);
    utils.changeValue(state, "mobile", () => args[0].mobile);
    utils.changeValue(state, "email", () => args[0].email);
  };

  return (
    <Grid className="form-container">
      <SuccessModal
        visible={notSuccessfulVisible ? notSuccessfulVisible : successVisible}
        onConfirm={handleConfirm}
        header={
          notSuccessfulVisible
            ? resources.pages.ManageTechnicians.sorryUserIsTech
            : resources.common.savedSuccessfully
        }
      />
      <Grid.Column>
        <PageHeadeTitle
          content={
            Boolean(location && location.state)
              ? resources.pages.ManageTechnicians.editTechInfo
              : resources.pages.ManageTechnicians.pageTitle
          }
          icon="users"
        />
        <Segment>
          <FinalForm
            initialValues={{
              UserID: technician.UserID || -1,
              technicianName: technician.technicianName,
              mobile: technician.mobile,
              email: technician.email,
              // machineIdNotRequiredToCloseTicket:
              //   technician.machineIdNotRequiredToCloseTicket,
              salesmanNo: technician.salesmanNo,
            }}
            mutators={{
              setInfo: setInfoMutator,
            }}
            onSubmit={onSubmit}
            validate={validator}
            render={({ handleSubmit, form }) => (
              <Form
                autoComplete="off"
                onSubmit={handleSubmit}
                loading={isSubmitting}
              >
                <Grid columns={2} stackable>
                  <GridColumn>
                    <p>{resources.pages.ManageTechnicians.userName}</p>
                    <Field name="UserID">
                      {({ input, meta }) => (
                        <Form.Select
                          {...input}
                          options={users}
                          placeholder={
                            resources.pages.ManageTechnicians.chooseName
                          }
                          noResultsMessage={resources.common.noData}
                          search={users.length > 0}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                            fillFields(value, form.mutators);
                          }}
                          error={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </GridColumn>
                  <Grid.Column>
                    <p>{resources.pages.ManageTechnicians.technicianName}</p>
                    <Field name="technicianName">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          icon="user"
                          placeholder={
                            resources.pages.ManageTechnicians.technicianName
                          }
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.ManageTechnicians.phone}</p>
                    <Field name="mobile">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          error={meta.touched && meta.error}
                          fluid
                          type="number"
                          icon="phone volume"
                          placeholder={resources.pages.ManageTechnicians.phone}
                          readOnly={editable === true}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.ManageTechnicians.email}</p>

                    <Field name="email">
                      {({ input, meta }) => (
                        <Form.Input
                          {...input}
                          fluid
                          icon="mail"
                          placeholder={resources.pages.ManageTechnicians.email}
                          readOnly={editable === true}
                          autoComplete="off"
                        />
                      )}
                    </Field>
                  </Grid.Column>
                  <Grid.Column>
                    <p>{resources.pages.ManageTechnicians.salesmanID}</p>
                    <Field name="salesmanNo">
                      {({ input, meta }) => (
                        <Form.Select
                          {...input}
                          options={salesmen}
                          placeholder={
                            resources.pages.ManageTechnicians.chooseName
                          }
                          noResultsMessage={resources.common.noData}
                          search={salesmen.length > 0}
                          onChange={(e, { value }) => {
                            input.onChange(value);
                            // fillFields(value, form.mutators);
                          }}
                          error={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Grid.Column>
                </Grid>
                <Button
                  color="blue"
                  size="large"
                  className="save-btn"
                  disabled={isSubmitting}
                  type="submit"
                >
                  {resources.common.save}
                </Button>
                <Button color="red" size="large" onClick={handleConfirm}>
                  {resources.common.cancel}
                </Button>
                {errorVisible.visible && (
                  <Message
                    negative
                    compact
                    className="error-message"
                    content={
                      errorMessage || resources.common.errors.genericServerError
                    }
                  />
                )}
              </Form>
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
