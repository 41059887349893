import React, { useState, useEffect } from "react";
import { resources } from "../../assets/LocalizationResources";
import ReportIntervals from "./ReportIntervals";
import ReportNamePicker from "./ReportNamePicker";
import ReportClientPicker from "./ReportClientPicker";
import ReportAreaPicker from "./ReportAreaPicker";
import ReportMachineModelPicker from "./ReportMachineModelPicker";
import ComputerNumberInput from "./ComputerNumberInput";
import "./Report.scss";
import PartNumber from "./PartNumber";

export const PeriodEndedContractsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <ReportNamePicker />
    </div>
  );
};

export const NotDeservedContractsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.contractPayment.paymentDate} : `}</label>
      <ReportIntervals />
    </div>
  );
};

export const TechMachinesWithContractsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
    </div>
  );
};

export const TechAreasAndMachinesNumOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.playBackMap.techName} `}</label>
      <ReportNamePicker />
    </div>
  );
};
export const GeneralMachineInfoOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <ReportIntervals />
      <div className="manage-report-operations-content-clientArea-picker">
        <ReportClientPicker />
        <ReportAreaPicker />
      </div>
      <label>{`${resources.pages.playBackMap.techName} `}</label>
      <ReportNamePicker />
    </div>
  );
};

export const MachinesThatHaveContractwithVisitsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <label>{`${resources.pages.playBackMap.techName} `}</label>
      <ReportNamePicker />
    </div>
  );
};

export const MachinesThatHaveContractwithVisitsNewOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <label>{`${resources.pages.playBackMap.techName} `}</label>
      <ReportNamePicker />
    </div>
  );
};

export const MachinesModelClientOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <ReportClientPicker />
      <ReportMachineModelPicker />
    </div>
  );
};

export const MachinesContarctTechnicianOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <ReportNamePicker />
    </div>
  );
};

export const NumberOfTechVisitsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <ReportNamePicker />
    </div>
  );
};

export const FinishedContractsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
    </div>
  );
};
export const RepeatedTicketsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
    </div>
  );
};
export const PeriodicalVisitsOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.contractEndDate} : `}</label>
      <ReportIntervals />
      <ReportMachineModelPicker />
      <ComputerNumberInput />
    </div>
  );
};
export const InvoicesReportOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.invoiceDate} : `}</label>
      <ReportIntervals />
      <ReportNamePicker />
    </div>
  );
};
export const MaintenanceCardOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.invoiceDate} : `}</label>
      <ReportIntervals />
      <ComputerNumberInput />
    </div>
  );
};
export const PartsMovementReportOptions = () => {
  return (
    <div className="manage-report-operations-content">
      <label>{`${resources.pages.reports.invoiceDate} : `}</label>
      <ReportIntervals />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <ComputerNumberInput />
        <PartNumber />
      </div>
    </div>
  );
};
