import React, { useState } from "react";
import Webcam from "react-webcam";
import "../Webcam/TakePicture.scss";
import { Icon } from "semantic-ui-react";

export default function TakePicture({ setImagePath }) {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(null);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment",
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const saveImage = () => {
    setImagePath(imgSrc);
  };

  const cancelImage = () => {
    setImgSrc(null);
  };

  const closeImgCapture = () => {
    setImagePath(null);
  };

  return (
    <div className="cam-body">
      {imgSrc ? (
        <>
          <img src={imgSrc} alt="" className="cam-img" />
          <div className="cam-button">
            <Icon
              name="save"
              circular
              size="large"
              className="icon-button"
              aria-label="save"
              onClick={saveImage}
            />
            <Icon
              name="delete"
              circular
              size="large"
              className="icon-button"
              aria-label="cancel"
              onClick={cancelImage}
            />
          </div>
        </>
      ) : (
        <>
          <Webcam
            className="cam-div"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={(e) => alert(e)}
          />
          <div className="cam-button">
            <Icon
              className="icon-button"
              name="camera"
              circular
              size="large"
              onClick={capture}
            />
            <Icon
              name="close"
              circular
              size="large"
              className="icon-button"
              aria-label="close"
              onClick={closeImgCapture}
            />
          </div>
        </>
      )}
    </div>
  );
}
