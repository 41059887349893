import React, { useState, useEffect } from "react";
import { Select, Icon, Input, Button, Form } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import useDebounce from "../helpers/useDebounce";
import "./SearchBy.scss";
import { useSelector, useDispatch } from "react-redux";
import { cacheSearchActionCreator } from "../../redux/actions";
import DTPicker from "../DTPicker/DTPicker";
import DatePicker from "react-datepicker";
import moment from "moment";

export default function SearchBy({
  searchTypes,
  getSearchResults,
  extraFields = null,
  pageName = "",
  withDate = false,
  setSelectedTicketDateFrom,
  setSelectedTicketDateTo,
  selectedTicketDateFrom,
  selectedTicketDateTo,
  setReloadTrigger,
}) {
  const [searchBy, setSearchBy] = useState(null);
  const [searchByType, setSearchByType] = useState("text");
  const [searchOptions, setSearchOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [term, setTerm] = useState("");
  const debouncedSearch = useDebounce(term, 1500);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.cacheSearch);

  const noSearch = ["contracts", "groupPermissions", "callCenter"];

  const onSearch = () => {
    setLoading(true);
    if (Boolean(searchByType === "text") && inputValue.trim().length > 0) {
      setTerm(inputValue.trim());
      if (!noSearch.includes(pageName)) {
        dispatch(
          cacheSearchActionCreator.setCacheSearch(
            inputValue.trim(),
            searchBy,
            pageName
          )
        );
      }
    } else if (Boolean(searchByType === "ddl")) {
      setTerm(inputValue);
      if (!noSearch.includes(pageName)) {
        dispatch(
          cacheSearchActionCreator.setCacheSearch(
            inputValue,
            searchBy,
            pageName
          )
        );
      }
    } else {
      if (withDate) {
        setReloadTrigger(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === pageName &&
      !noSearch.includes(pageName)
    ) {
      setSearchBy(searchState.searchBy);
      setInputValue(searchState.term);
    }
  }, [searchState]);

  useEffect(() => {
    if (!inputValue) getSearchResults();
  }, [inputValue]);

  useEffect(() => {
    if (Boolean(term) && Boolean(searchBy) && loading) {
      getSearchResults(searchBy, term);
      setLoading(false);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (Boolean(term) && Boolean(searchBy)) {
      getSearchResults();
    }
    if (searchBy) {
      if (searchByType !== searchTypes[searchBy].type) setInputValue("");
      setSearchByType(searchTypes[searchBy].type);
      if (searchTypes[searchBy].type == "ddl")
        setSearchOptions(searchTypes[searchBy].options);
    }
  }, [searchBy]);

  useEffect(() => {
    if (!selectedTicketDateFrom && !selectedTicketDateTo) onSearch();
  }, [selectedTicketDateFrom, selectedTicketDateTo]);

  return (
    <div className="table-search-container">
      <div className="inputs-container">
        <Select
          className="search-type"
          placeholder={resources.common.searchBy}
          value={searchBy}
          options={Object.keys(searchTypes).map((h) => {
            return { key: h, value: h, text: searchTypes[h].label };
          })}
          onChange={(e, { value }) => {
            setSearchBy(value);
            setInputValue("");
          }}
        />
        {searchByType === "text" ? (
          <>
            <Input
              className="search-box"
              placeholder={resources.common.searchDots}
              value={inputValue}
              loading={loading}
              disabled={!Boolean(searchBy)}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) =>
                Boolean(searchBy && e.charCode === 13) &&
                document.getElementById("search-by-btn").click()
              }
            />
            <Icon
              className="icon-close"
              name="close"
              disabled={!Boolean(searchBy)}
              onClick={() => setInputValue("")}
              style={withDate ? { left: "530px" } : {}}
            />
          </>
        ) : (
          <>
            <Select
              className="search-box"
              placeholder={resources.common.searchDots}
              options={searchOptions}
              value={inputValue}
              search
              noResultsMessage={resources.common.noData}
              loading={loading}
              disabled={!Boolean(searchBy)}
              onChange={(e, { value }) => setInputValue(value)}
            />
            <Icon
              className="icon-close"
              name="close"
              disabled={!Boolean(searchBy)}
              onClick={() => setInputValue("")}
              style={withDate ? { left: "530px" } : {}}
            />
          </>
        )}
        {withDate && (
          <Form className="date-time-container">
            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              selected={selectedTicketDateFrom}
              placeholderText={"من تاريخ"}
              onChange={(date) => {
                setSelectedTicketDateFrom(date);
              }}
              popperPlacement="top-end"
              isClearable={true}
            />
            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              selected={selectedTicketDateTo}
              placeholderText={"الى تاريخ"}
              onChange={(date) => {
                setSelectedTicketDateTo(date);
              }}
              popperPlacement="top-end"
              isClearable={true}
            />
          </Form>
        )}
        <Button
          id="search-by-btn"
          content={resources.common.search}
          disabled={!Boolean((searchBy && inputValue) || withDate)}
          className="search-button"
          color="blue"
          onClick={() => onSearch()}
        />
      </div>
      <div className="check-boxes">{Boolean(extraFields) && extraFields}</div>
    </div>
  );
}
