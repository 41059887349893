import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Segment, Label } from "semantic-ui-react";
import app from "../../api";
import { resources } from "../../assets/LocalizationResources";
import SuccessModal from "../../components/SuccessModal";
import "./EditMaintenanceReportModal.scss";

export const EditMaintenanceReportModal = ({
  open,
  onClose,
  maintenanceReportId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceedModalShown, setIsSucceedModalShown] = useState(false);
  const [updateCurrentCounterForm, setUpdateCurrentCounterForm] = useState({
    currentCounter: null,
  });
  const onConfirmUpdateInvoice = async () => {
    try {
      setIsLoading(true);
      await app
        .service("MaintenanceReport")
        .patch(maintenanceReportId, {
          counter1: updateCurrentCounterForm.currentCounter,
        });

      setIsSucceedModalShown(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getMaintenanceReport = async () => {
    try {
      setIsLoading(true);
      const response = await app
        .service("MaintenanceReport")
        .get(maintenanceReportId);

      setUpdateCurrentCounterForm({
        currentCounter: response?.counter1 ? response?.counter1 : 0,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getMaintenanceReport();
  }, [open, maintenanceReportId]);

  const onCloseEditInvoiceModal = () => {
    setIsLoading(false);
    setIsSucceedModalShown(false);
    onClose();
  };

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <SuccessModal
        visible={isSucceedModalShown}
        header={resources.common.savedSuccessfully}
        onConfirm={onCloseEditInvoiceModal}
      />
      <Modal.Header>
        {resources.pages.maintenanceReport.editInvoice}
      </Modal.Header>
      <Modal.Content>
        <Input
          fluid
          className="edit-page-input-style"
          label={resources.pages.maintenanceReport.currentCounterRead}
          type="number"
          onChange={(e) => {
            e.persist();
            setUpdateCurrentCounterForm((prev) => {
              return { currentCounter: e?.target?.value };
            });
          }}
          value={`${updateCurrentCounterForm.currentCounter}`}
          autoComplete="off"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="edit"
          labelPosition="right"
          content={resources.common.confirm}
          onClick={onConfirmUpdateInvoice}
          loading={isLoading}
          disabled={isLoading}
        />
        <Button
          icon="share square outline"
          labelPosition="right"
          content={resources.common.cancel}
          onClick={onCloseEditInvoiceModal}
        />
      </Modal.Actions>
    </Modal>
  );
};
