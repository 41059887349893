import React, { useState, useEffect } from "react";
import DTPicker from "../../components/DTPicker/DTPicker";
import { useDispatch, useSelector } from "react-redux";
import { reportsActionCreator } from "../../redux/actions";
import moment from "moment";
import { Label } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";

const ReportIntervals = () => {
  const dispatch = useDispatch();
  const intervalDates = useSelector((state) => state.reports.options);

  useEffect(() => {
    dispatch(
      reportsActionCreator.setReportOptions({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      })
    );
  }, []);

  return (
    <div className="report-interval-container">
      <div className="report-date-picker-container">
        <Label className="date-label" basic color="blue" pointing="below">
          {resources.common.from}
        </Label>
        <DTPicker
          format="date"
          selectedValue={intervalDates.from}
          setSelectedValue={(d) =>
            dispatch(
              reportsActionCreator.setReportOptions({
                from: moment(d).format("YYYY-MM-DD"),
                to: intervalDates.to,
              })
            )
          }
        />
      </div>
      <div className="report-date-picker-container">
        <Label className="date-label" basic color="blue" pointing="below">
          {resources.common.to}
        </Label>
        <DTPicker
          format="date"
          selectedValue={intervalDates.to}
          setSelectedValue={(d) =>
            dispatch(
              reportsActionCreator.setReportOptions({
                from: intervalDates.from,
                to: moment(d).format("YYYY-MM-DD"),
              })
            )
          }
        />
      </div>
    </div>
  );
};

export default ReportIntervals;
