import Axios from "axios";
const path =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_GEOFENCE_PATH
    : `${window.location.protocol}//${window.location.host}`;

const GeofenceServiceApi = {
  getList: (table) => Axios.get(`${path}/geofence/${table}`),
  getWKT: (table, id) => Axios.get(`${path}/geofence/${table}/${id}`),
};

export default GeofenceServiceApi;
