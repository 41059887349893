import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button, Icon } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { useSelector } from "react-redux";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { EditMaintenanceReportModal } from "./EditMaintenanceReportModal";

export const generateInvoiceNumber = (invoiceDate, counter) => {
  const year = moment(invoiceDate).format("YY");
  const counterString = counter.toString().padStart(5, "0");
  const result = year + "25" + counterString;
  return result;
};

export const generateMachinePartInvoiceNumber = (invoiceDate, counter) => {
  const year = moment(invoiceDate).format("YY");
  const counterString = counter.toString().padStart(5, "0");
  const result = year + "26" + counterString;
  return result;
};

export default function MaintenanceReportsList() {
  const router = useHistory();
  const [maintenanceReport, setMaintenanceReport] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [editMaintenanceReportModalProps, setEditMaintenanceReportModalProps] =
    useState({
      open: false,
      maintenanceReportId: null,
    });

  const headers = {
    maintenanceReportId: resources.pages.maintenanceReport.maintenanceReportID,
    oldMaintenanceReportNum:
      resources.pages.maintenanceReport.oldMaintenanceReportNum,
    manualReportNumber: resources.pages.maintenanceReport.manualReportNumber,
    machineId: resources.pages.maintenanceReport.computerNumber,
    clientName: resources.pages.maintenanceReport.clientName,
    branchName: resources.pages.maintenanceReport.branchName,
    technicianName: resources.pages.maintenanceReport.technicianName,
    maintenanceDate: resources.pages.maintenanceReport.maintenanceDate,
    enteranceDate: resources.pages.maintenanceReport.enteranceDate,
    manualReportNumber: resources.pages.maintenanceReport.manualReportNumber,
    maintenanceReportInvoiceId: resources.pages.machines.invoiceNumber,
    maintenanceReportMachinePartInvoiceId:
      resources.pages.maintenanceReport.machinePartInvoiceNumber,
  };

  const CurrentUserID = useSelector((state) => state.user.user.userId);
  const CurrentTechnicianID = useSelector(
    (state) => state.user.user.technicianID
  );
  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const fillSearchData = async () => {
    setSearchData({
      maintenanceReportId: {
        type: "text",
        label: resources.pages.maintenanceReport.maintenanceReportID,
      },
      oldMaintenanceReportNum: {
        type: "text",
        label: resources.pages.maintenanceReport.oldMaintenanceReportNum,
      },
      manualReportNumber: {
        type: "text",
        label: resources.pages.maintenanceReport.manualReportNumber,
      },
      machineId: {
        type: "text",
        label: resources.pages.maintenanceReport.computerNumber,
      },
      clientName: {
        type: "text",
        label: resources.pages.maintenanceReport.clientName,
      },
      branchName: {
        type: "text",
        label: resources.pages.maintenanceReport.branchName,
      },
      technicianName: {
        type: "text",
        label: resources.pages.maintenanceReport.technicianName,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "maintenanceReports"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadMaintenanceReports = async () => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          innerJoin: true,
          model: "MaintenanceReport",
          attributes: [
            "MaintenanceReportId",
            "createdat",
            "maintenanceTypeID",
            "oldMaintenanceReportNum",
            "manualReportNumber",
            "startdate",
            "machinePartInvoiceNumber",
          ],
          include: [
            {
              innerJoin: true,
              model: "ContractMachine",
              attributes: ["ContractMachineID"],
              include: [
                {
                  innerJoin: true,
                  model: "Machine",
                  attributes: ["machineId", "computerNumber"],
                },
                {
                  innerJoin: true,
                  model: "ClientBranch",
                  attributes: ["BranchName", "BranchID", "ClientID"],
                  ...whereLikeSearch("branchName"),
                  include: [
                    {
                      innerJoin: true,
                      model: "Client",
                      attributes: ["ClientID", "ClientName"],
                      ...whereLikeSearch("clientName"),
                    },
                    {
                      innerJoin: true,
                      model: "Area",
                      attributes: ["AreaName"],
                    },
                  ],
                },
              ],
              ...whereLikeSearch("machineId"),
            },
          ],

          where: {
            ...(!isAuthorized(Perm.viewAllMaintenanceReports)
              ? { createdBy: CurrentUserID }
              : {}),
          },
          ...whereLikeSearch("maintenanceReportId"),
          ...whereLikeSearch("oldMaintenanceReportNum"),
        },
        {
          model: "Technicians",
          attributes: ["TechnicianName"],
          ...whereLikeSearch("technicianName"),
        },
      ]);
      const res = await serviceApi.service("queries").find({
        query: {
          innerJoin: true,
          model: "MaintenanceReportTechnician",
          attributes: ["TechnicianID"],
          jsonInclude: jsonInclude,
          order: [["maintenanceReportID", "DESC"]],
          where: {
            ...(!isAuthorized(Perm.viewAllMaintenanceReports)
              ? { technicianID: CurrentTechnicianID }
              : {}),
          },
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const maintenanceReportInvoiceRes = await serviceApi
        .service("MaintenanceReportInvoice")
        .find({
          query: {
            MaintenanceReportID: {
              $in: res.data.map(
                (row) => row.MaintenanceReport.MaintenanceReportId
              ),
            },
            $select: ["MaintenanceReportID", "InvoiceNumber", "CreatedAt"],
            $limit: pagination.pageSize,
          },
        });

      const flattenedData = res.data.map((row) => {
        const InvoiceNumber = maintenanceReportInvoiceRes.data.find(
          (invoice) =>
            invoice.MaintenanceReportID ===
            row.MaintenanceReport.MaintenanceReportId
        )?.InvoiceNumber;
        const editedRow = {
          ...row,
          maintenanceReportInvoiceId: InvoiceNumber
            ? generateInvoiceNumber(row.createdAt, InvoiceNumber)
            : "",
          maintenanceReportId: row.MaintenanceReport.MaintenanceReportId,
          oldMaintenanceReportNum: row.MaintenanceReport.oldMaintenanceReportNum
            ? row.MaintenanceReport.oldMaintenanceReportNum
            : resources.common.noPreviousNum,
          branchName: Boolean(
            row.MaintenanceReport.ContractMachine &&
              row.MaintenanceReport.ContractMachine.ClientBranch.BranchName
          )
            ? row.MaintenanceReport.ContractMachine.ClientBranch.BranchName
            : resources.common.notSpecified,
          machineId:
            row.MaintenanceReport.ContractMachine.Machine.computerNumber,
          maintenanceDate: moment(row.MaintenanceReport.startdate).format(
            "DD/MM/YYYY"
          ),
          enteranceDate: moment(row.MaintenanceReport.createdat).format(
            "DD/MM/YYYY"
          ),
          clientName: Boolean(
            row.MaintenanceReport.ContractMachine &&
              row.MaintenanceReport.ContractMachine.ClientBranch.Client
                .ClientName
          )
            ? row.MaintenanceReport.ContractMachine.ClientBranch.Client
                .ClientName
            : resources.common.notSpecified,
          technicianName: Boolean(
            row.Technician && row.Technician.TechnicianName
          )
            ? row.Technician.TechnicianName
            : resources.common.notSpecified,
          maintenanceTypeID: row.MaintenanceReport.maintenanceTypeID,
          manualReportNumber: row.MaintenanceReport.manualReportNumber
            ? row.MaintenanceReport.manualReportNumber
            : resources.common.noPreviousNum,
          maintenanceReportMachinePartInvoiceId: row.MaintenanceReport
            .machinePartInvoiceNumber
            ? generateMachinePartInvoiceNumber(
                row.MaintenanceReport.createdat,
                row.MaintenanceReport.machinePartInvoiceNumber
              )
            : " ",
        };
        return editedRow;
      });

      setMaintenanceReport(flattenedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadMaintenanceReports();
  }, [searching, activePage]);

  const onCellClick = (row, type) => {
    if (type === "print")
      window.open(`/maintenanceReportPrintView?id=${row.maintenanceReportId}`);
    else if (type === "printOrSendITMaintenanceReport")
      window.open(
        `/maintenanceReportPrintView?id=${row.maintenanceReportId}&isITMaintenanceReport=true`
      );
    else if (type === "part")
      window.open(`/PartInvoiceA4PrintView?id=${row.maintenanceReportId}`);
    else if (type === "invoice")
      window.open(`/MaintenanceInvoice?id=${row.maintenanceReportId}`);
    else if (type === "viewMaintenanceReportDetails")
      router.push("/MaintenanceReportDetails", {
        maintenanceReportID: row.maintenanceReportId,
      });
    else if (type === "editMaintenanceReportCurrentCounter")
      setEditMaintenanceReportModalProps({
        open: true,
        maintenanceReportId: row.maintenanceReportId,
      });
  };

  const onCloseEditMaintenanceReportModal = () => {
    setEditMaintenanceReportModalProps({
      open: false,
      maintenanceReportId: null,
    });
  };

  return (
    <>
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.maintenanceReport.maintenanceReports}
            icon="ticket"
          />
          <div className="two-buttons-container">
            <div>
              {isAuthorized(Perm.editMaintenanceReports) && (
                <Button
                  basic
                  color="blue"
                  content={
                    resources.pages.createMaintenanceReport
                      .createMaintenanceReport
                  }
                  onClick={() => router.push("/maintenanceReports")}
                />
              )}
              {isAuthorized(Perm.viewMaintenaceReport) && (
                <Button
                  basic
                  color="blue"
                  content={
                    resources.pages.createMaintenanceReport.reportsWithInvoice
                  }
                  onClick={() => router.push("/MaintenanceInvoiceReportsList")}
                />
              )}
            </div>
            {isAuthorized(Perm.viewMaintenaceReport) && (
              <ExportDataToExcel
                data={maintenanceReport}
                headers={headers}
                title={resources.common.excelExport.maintenanceReports}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="maintenanceReports"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={maintenanceReport}
              extraCells={[
                ...(isAuthorized(Perm.printOrSendMaintenanceReports)
                  ? [
                      {
                        key: "print",
                        children: <Icon name="print" color="blue" />,
                        isCliCkable: true,
                        tooltip:
                          resources.pages.maintenanceReport
                            .printOrSendMaintenanceReport,
                      },
                      {
                        key: "printOrSendITMaintenanceReport",
                        children: <Icon name="print" color="blue" />,
                        isCliCkable: true,
                        tooltip:
                          resources.pages.maintenanceReport
                            .printOrSendITMaintenanceReport,
                      },
                      {
                        key: "part",
                        values: [" "],
                        valuesID: "maintenanceReportMachinePartInvoiceId",
                        children: <Icon name="microchip" color="blue" />,
                        isCliCkable: true,
                        tooltip:
                          resources.pages.maintenanceReport.machinePartInvoice,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.printInvoiceReports)
                  ? [
                      {
                        key: "invoice",
                        values: [7, 8],
                        valuesID: "maintenanceTypeID",
                        children: <Icon name="credit card" color="blue" />,
                        isCliCkable: true,
                        tooltip: resources.pages.maintenanceReport.invoice,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.printOrSendMaintenanceReports)
                  ? [
                      {
                        key: "viewMaintenanceReportDetails",
                        tooltip: "عرض التقرير",
                        children: <Icon name="eye" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.editInvoice)
                  ? [
                      {
                        key: "editMaintenanceReportCurrentCounter",
                        tooltip: "تعديل العداد الحالي",
                        children: <Icon name="edit" color="blue" />,
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
            {editMaintenanceReportModalProps.maintenanceReportId && (
              <EditMaintenanceReportModal
                onClose={onCloseEditMaintenanceReportModal}
                open={editMaintenanceReportModalProps.open}
                maintenanceReportId={
                  editMaintenanceReportModalProps.maintenanceReportId
                }
              />
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
