import React, { useState, useEffect } from "react";
import { resources } from "../../assets/LocalizationResources";
import { Form, Button, Message } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import serviceApi from "../../api";
import SuccessModal from "../../components/SuccessModal";
import { useHistory } from "react-router-dom";

export default function ManageArea({
  geometryResult,
  area,
  creationMethodTypeID,
}) {
  const [areaName, setAreaName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorVisible, setErrorVisible] = useState({
    visible: false,
  });
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (area) setAreaName(area.areaName);
  }, [area]);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (Boolean(geometryResult.geom)) {
        if (area && area.areaID !== -1) {
          await serviceApi.service("area").patch(area.areaID, {
            ...values,
            geometry: geometryResult.geom,
            CreationMethodTypeID: creationMethodTypeID,
          });
        } else {
          await serviceApi.service("area").create({
            ...values,
            geometry: geometryResult.geom,
            CreationMethodTypeID: creationMethodTypeID,
          });
        }
        setIsSubmitting(false);
        setSuccessVisible(true);
      } else {
        setIsSubmitting(false);
        setErrorMessage(resources.pages.area.drawArea);
        setErrorVisible({
          visible: true,
        });

        setTimeout(() => {
          setErrorVisible({ visible: false });
          setErrorMessage(null);
        }, 5000);
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.code === 400) {
        setErrorMessage(resources.pages.area.duplicateArea);
      }
      setErrorVisible({
        visible: true,
      });

      setTimeout(() => {
        setErrorVisible({ visible: false });
        setErrorMessage(null);
      }, 5000);
    }
  };

  const handleConfirm = () => {
    router.replace("/areas/areasList");
  };
  return (
    <>
      <SuccessModal
        visible={successVisible}
        onConfirm={handleConfirm}
        header={resources.common.savedSuccessfully}
      />
      <FinalForm
        onSubmit={onSubmit}
        validate={(v) => {
          if (!v.areaName)
            return { areaName: resources.common.errors.requiredField };
        }}
        initialValues={{ areaName: areaName }}
        render={({ handleSubmit }) => (
          <Form
            className="form"
            autoComplete="off"
            onSubmit={handleSubmit}
            loading={isSubmitting}
          >
            <p>{resources.pages.area.areaName}</p>
            <Field name="areaName">
              {({ input, meta }) => (
                <Form.Input
                  {...input}
                  error={meta.touched && meta.error}
                  fluid
                  icon="map"
                  placeholder={resources.pages.area.areaName}
                  autoComplete="off"
                />
              )}
            </Field>
            <Button
              color="blue"
              size="large"
              className="save-btn"
              disabled={isSubmitting || !geometryResult.saved}
              type="submit"
            >
              {resources.common.save}
            </Button>
            {errorVisible.visible && (
              <Message
                negative
                compact
                className="error-message"
                content={
                  errorMessage || resources.common.errors.genericServerError
                }
              />
            )}
          </Form>
        )}
      />
    </>
  );
}
