import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { Grid, Segment, Popup, Icon, Checkbox } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import ticketStatusReportImg from "../../assets/images/ticketStatusHistory.svg";
import { resources } from "../../assets/LocalizationResources";
import Moment from "moment";
import SearchBy from "../../components/SearchBy/SearchBy";
import doneImg from "../../assets/images/done.svg";
import TicketActionsModal from "../../components/Tickets/TicketActionsModal";
import TicketInfoModal from "../../components/Tickets/TicketInfoModal";
import "./CallCenter.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";

export default function CallCenter() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [time, setTime] = useState(Moment());
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [ticketID, setTicketID] = useState(null);
  const [changeTicketModal, setChangeTicketModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showSLA, setShowSLA] = useState(false);
  const [showLateDate, setShowLateDate] = useState(false);
  const [ticketStatus, setTicketStatus] = useState([]);
  const headers = {
    ticketLevel: resources.pages.ticket.level,
    ticketID: resources.pages.ticket.ticketID,
    ClientName: resources.pages.ticket.clientName,
    contactName: resources.pages.ticket.contactName,
    contactPhone: resources.pages.ticket.contactPhone,
    computerNumber: resources.pages.machines.computerNumber,
    ticketDate: resources.pages.ticket.ticketDate,
    technicianName: resources.pages.technicians.technicianName,
    reason: resources.pages.ticket.reason,
    status: resources.pages.ticket.ticketStatus,
    openSince: resources.pages.ticket.openSince,
    lateForDueHours: resources.pages.ticket.lateForDueHours,
    notes: resources.pages.ticket.notes,
  };
  const [currentTicketStatus, setCurrentTicketStatus] = useState(0);

  const searchState = useSelector((state) => state.cacheSearch);

  const getOptions = async (parentID) => {
    try {
      const res = await serviceApi.service("lookup").find({
        query: {
          parentLookupID: parentID,
          $select: ["LookupID", "LookupName"],
        },
      });

      if (res && res.data && res.data.length > 0) {
        if (parentID == 96) {
          setTicketStatus(
            res.data
              .filter((m) => m.LookupID !== 97)
              .map((c) => ({
                key: c.LookupID,
                text: c.LookupName,
                value: c.LookupID,
              }))
          );
        }
        return res.data.map((c) => {
          return { key: c.LookupID, text: c.LookupName, value: c.LookupID };
        });
      } else return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fillSearchData = async () => {
    setSearchData({
      ticketLevel: {
        type: "ddl",
        label: resources.pages.ticket.level,
        options: await getOptions(90),
      },
      ticketID: { type: "text", label: resources.pages.ticket.ticketID },
      ClientName: {
        type: "text",
        label: resources.pages.ticket.clientName,
      },
      computerNumber: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
      technicianName: {
        type: "text",
        label: resources.pages.technicians.technicianName,
      },
      reason: {
        type: "ddl",
        label: resources.pages.ticket.reason,
        options: await getOptions(29),
      },
      status: {
        type: "ddl",
        label: resources.pages.ticket.ticketStatus,
        options: await getOptions(96),
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "callCenter"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  useEffect(() => {
    fillSearchData();
    const interval = setInterval(() => setTime(Moment()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const temp = [...data];
      setData(
        temp.map((e) => {
          return { ...e, openSince: getInterval(time, e.createdAt) };
        })
      );
    }
  }, [time]);

  useEffect(() => {
    Boolean(activePage && searching) && loadData();
  }, [activePage, showLateDate, showClosed, searching, showSLA]);

  const getInterval = (e, d2) => {
    const date1 = Moment();
    const date2 = Moment(d2);
    const duration = date1.diff(date2);
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
      days = Math.floor(duration / (1000 * 60 * 60 * 24));

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return days + "." + hours + ":" + minutes;
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          ...(showSLA || (searching && searching.searchBy == "computerNumber")
            ? { innerJoin: true }
            : {}),
          model: "ContractMachine",
          attributes: ["machineID", "withSLA", "dueHours"],
          include: [
            {
              innerJoin: true,
              model: "Machine",
              attributes: ["machineId", "computerNumber"],
              ...whereLikeSearch("computerNumber"),
            },
          ],
          ...(showSLA ? { where: { withSLA: showSLA } } : {}),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "ticketType",
          attributes: ["LookupName"],
          ...whereSearch("reason", "LookupID"),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "status",
          attributes: ["LookupName"],
          ...(Object.keys(whereSearch("status", "LookupID")).length
            ? whereSearch("status", "LookupID")
            : showClosed
            ? {
                where: { LookupID: 99 },
              }
            : { where: { LookupID: { ne: 99 } } }),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "level",
          attributes: ["LookupName"],
          ...whereSearch("ticketLevel", "LookupID"),
        },
        {
          innerJoin: true,
          model: "ClientBranch",
          attributes: ["BranchID"],
          include: [
            {
              innerJoin: true,
              model: "Client",
              attributes: ["ClientName"],
              ...whereLikeSearch("ClientName"),
            },
          ],
        },
        {
          innerJoin: true,
          model: "Technicians",
          attributes: ["TechnicianName"],
          ...whereLikeSearch("technicianName"),
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "Tickets",
          jsonInclude: jsonInclude,
          ...whereLikeSearch("ticketID"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      if (showLateDate) {
        const tableData = [];
        res.data.forEach((row) => {
          if (
            (Boolean(row.ContractMachine && row.ContractMachine.dueHours) &&
              new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() +
                    row.ContractMachine.dueHours
                )) ||
            (Boolean(row.ticketDueHours) &&
              new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() + row.ticketDueHours
                ))
          ) {
            tableData.push({
              ...row,
              ClientName: row.ClientBranch.Client
                ? row.ClientBranch.Client.ClientName
                : resources.common.notSpecified,
              contactPhone: row.contactPhone,
              contactName: row.contactName,
              computerNumber: row.ContractMachine
                ? row.ContractMachine.Machine.computerNumber
                : resources.common.notSpecified,
              ticketDate: moment(row.createdAt).format("DD/MM/YYYY"),
              ticketLevel: (
                <Popup
                  content={row.level.LookupName}
                  trigger={
                    <Icon
                      size="large"
                      name="exclamation triangle"
                      color={
                        row.statusID == 99
                          ? "grey"
                          : row.levelID === 94
                          ? "red"
                          : row.levelID === 93
                          ? "yellow"
                          : "green"
                      }
                    />
                  }
                />
              ),
              technicianName: row.Technician.TechnicianName,
              reason: row.ticketType.LookupName,
              status: row.status.LookupName,
              openSince: getInterval(time, row.createdAt),
              lateForDueHours: resources.pages.ticket.late,
              rowColor:
                row.statusID == 99
                  ? "#d4d4d5"
                  : row.levelID === 94
                  ? "#ff00002b"
                  : row.levelID === 93
                  ? "#ffff004a"
                  : "#00800033",
              notes: row.notes,
            });
          }
        });
        setData(tableData);
      } else {
        const tableData = res.data.map((row) => {
          const newData = {
            ...row,
            ClientName: row.ClientBranch.Client
              ? row.ClientBranch.Client.ClientName
              : resources.common.notSpecified,
            contactPhone: row.contactPhone,
            contactName: row.contactName,
            computerNumber: row.ContractMachine
              ? row.ContractMachine.Machine.computerNumber
              : resources.common.notSpecified,
            ticketDate: row.createdAt
              ? moment(row.createdAt).format("DD/MM/YYYY")
              : resources.common.notSpecified,
            ticketLevel: (
              <Popup
                content={row.level.LookupName}
                trigger={
                  <Icon
                    size="large"
                    name="exclamation triangle"
                    color={
                      row.statusID == 99
                        ? "grey"
                        : row.levelID === 94
                        ? "red"
                        : row.levelID === 93
                        ? "yellow"
                        : "green"
                    }
                  />
                }
              />
            ),
            technicianName: row.Technician
              ? row.Technician.TechnicianName
              : resources.common.notSpecified,
            reason: row.ticketType.LookupName
              ? row.ticketType.LookupName
              : resources.common.notSpecified,
            status: row.status.LookupName,
            openSince: getInterval(time, row.createdAt),
            lateForDueHours: Boolean(
              row.ContractMachine && row.ContractMachine.dueHours
            )
              ? new Date().getTime() >
                new Date(row.createdAt).setHours(
                  new Date(row.createdAt).getHours() +
                    row.ContractMachine.dueHours
                )
                ? resources.pages.ticket.late
                : resources.pages.ticket.notLate
              : Boolean(row.ticketDueHours) &&
                new Date().getTime() >
                  new Date(row.createdAt).setHours(
                    new Date(row.createdAt).getHours() + row.ticketDueHours
                  )
              ? resources.pages.ticket.late
              : resources.pages.ticket.notLate,
            rowColor:
              row.statusID == 99
                ? "#d4d4d5"
                : row.levelID === 94
                ? "#ff00002b"
                : row.levelID === 93
                ? "#ffff004a"
                : "#00800033",
            notes: row.notes,
          };
          delete newData.ContractMachine;
          delete newData.ticketType;
          delete newData.Technician;
          return newData;
        });
        setData(tableData);
      }
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setData([]);
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  const onCellClick = (row, type) => {
    if (type === "ticketChangeStatusAction") {
      setCurrentTicketStatus(row.statusID);
      setTicketID(row.ticketID);
      setChangeTicketModal(true);
    } else if (type === "ticketStatusRecord") {
      setTicketID(row.ticketID);
      setOpenInfoModal(true);
    }
  };

  const SearchBoxes = ({ label, onChange, stateKey }) => {
    return (
      <div className="checkBoxFilter-container">
        <Checkbox label={label} checked={stateKey} onChange={onChange} />
      </div>
    );
  };

  return (
    <Grid>
      <TicketActionsModal
        ticketStatus={ticketStatus}
        ticketID={ticketID}
        isOpen={changeTicketModal}
        currentTicketStatus={currentTicketStatus}
        closeChangeTicketModal={(reLoadData) => {
          setChangeTicketModal(false);
          if (reLoadData) loadData();
        }}
      />
      {openInfoModal && (
        <TicketInfoModal
          ticketID={ticketID}
          isOpen={openInfoModal}
          closeTicketInfoModal={() => {
            setOpenInfoModal(false);
          }}
        />
      )}
      <Grid.Column>
        <PageHeaderTitle
          content={resources.pages.ticket.callCenter}
          icon="call"
        />
        <div className="two-buttons-container">
          {isAuthorized(Perm.callCenter) && (
            <ExportDataToExcel
              data={data}
              headers={headers}
              title={resources.common.excelExport.callCenter}
            />
          )}
        </div>
        <Segment>
          <SearchBy
            pageName="callCenter"
            searchTypes={searchData}
            getSearchResults={getSearchResults}
            extraFields={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <SearchBoxes
                  label={resources.pages.ticket.showSLA}
                  onChange={(evt, elm) => setShowSLA(Boolean(elm.checked))}
                  stateKey={showSLA}
                />
                <SearchBoxes
                  label={resources.pages.ticket.showClosed}
                  onChange={(evt, elm) => setShowClosed(Boolean(elm.checked))}
                  stateKey={showClosed}
                />
                <SearchBoxes
                  label={resources.pages.ticket.showLate}
                  onChange={(evt, elm) => {
                    setShowLateDate(Boolean(elm.checked));
                  }}
                  stateKey={showLateDate}
                />
              </div>
            }
          />
          <TableTemplate
            isLoading={isLoading}
            headers={headers}
            data={data}
            paging={pagination}
            activePage={activePage}
            onPaging={onPaging}
            onCellClick={onCellClick}
            extraCells={[
              {
                key: "ticketChangeStatusAction",
                tooltip: resources.pages.ticket.takeAction,
                children: <img src={doneImg} className="img-icon" />,
                header: "",
                values: [99],
                valuesID: "statusID",
                isCliCkable: true,
              },
              {
                key: "ticketStatusRecord",
                tooltip: resources.ticketInformation.ticketStatusRecord,
                children: (
                  <img src={ticketStatusReportImg} className="img-icon" />
                ),
                header: "",
                isCliCkable: true,
              },
            ]}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
