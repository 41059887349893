import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Segment,
  Button,
  Icon,
  Popup,
  Checkbox,
} from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

export default function MachinesList() {
  const router = useHistory();
  const [machineID, setMachineID] = useState(null);
  const [machines, setMachines] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showNotAssociatedMachines, setShowNoteAssociatedMachines] =
    useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const searchState = useSelector((state) => state.cacheSearch);

  const headers = {
    machineId: resources.pages.machines.computerNumber,
    serialNumber: resources.pages.machines.serialNumber,
    clientName: resources.pages.clients.clientName,
    MachineType: resources.pages.machines.MachineType,
    MachineModel: resources.pages.machines.MachineModel,
    invoiceDate: resources.pages.machines.invoiceDate,
  };

  const fillSearchData = async () => {
    setSearchData({
      machineId: {
        type: "text",
        label: resources.pages.machines.computerNumber,
      },
      serialNumber: {
        type: "text",
        label: resources.pages.machines.serialNumber,
      },
      clientName: {
        type: "text",
        label: resources.pages.ticket.clientName,
      },
      MachineType: {
        type: "text",
        label: resources.pages.machines.MachineType,
      },
      MachineModel: {
        type: "text",
        label: resources.pages.machines.MachineModel,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "machines"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      if (
        searching.searchBy === "MachineType" ||
        searching.searchBy === "MachineModel"
      )
        return {
          where: {
            ["LookupName"]: { like: `%${searching.term}%` },
            ["ParentLookupID"]: Boolean(searching.searchBy === "MachineType")
              ? 19
              : 22,
          },
        };
      else return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const machineIdentifiersWhereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { [colID]: { like: `%${searching.term}%` } };
    else return {};
  };

  const loadMachines = async () => {
    try {
      setIsLoading(true);
      const jsonInclude = JSON.stringify([
        {
          ...(searching && searching.searchBy == "clientName"
            ? { innerJoin: true }
            : {}),
          model: "ContractMachine",
          attributes: ["machineId"],
          include: [
            {
              innerJoin: true,
              model: "ClientBranch",
              attributes: ["branchId"],
              include: [
                {
                  innerJoin: true,
                  model: "Client",
                  attributes: ["clientName"],
                  ...whereLikeSearch("clientName"),
                },
              ],
            },
          ],
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "MachineType",
          attributes: ["LookupName"],
          ...whereLikeSearch("MachineType"),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "MachineModel",
          attributes: ["LookupName"],
          ...whereLikeSearch("MachineModel"),
        },
        {
          innerJoin: true,
          model: "Lookup",
          as: "ManufacturingCompany",
          attributes: ["LookupName"],
        },
      ]);

      const res = await serviceApi.service("queries").find({
        query: {
          model: "Machine",
          attributes: [
            "machineId",
            "serialNumber",
            "computerNumber",
            "invoiceDate",
          ],
          jsonInclude: jsonInclude,
          where: {
            ...machineIdentifiersWhereLikeSearch("machineId"),
            ...machineIdentifiersWhereLikeSearch("serialNumber"),
            ...(showNotAssociatedMachines && {
              contractMachineID: { is: null },
            }),
          },
          order: [["computerNumber", "DESC"]],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const flattenedData = res.data.map((row) => {
        const editedRow = {
          ...row,
          MachineModel: row.MachineModel
            ? row.MachineModel.LookupName
            : resources.common.notSpecified,
          MachineType: row.MachineType
            ? row.MachineType.LookupName
            : resources.common.notSpecified,
          machineId: row.machineId,
          computerNumber: row.computerNumber,
          clientName:
            row.ContractMachine && row.ContractMachine.ClientBranch
              ? row.ContractMachine.ClientBranch.Client.clientName
              : resources.pages.machines.notConnectedWithClient,
          invoiceDate: Boolean(row.invoiceDate)
            ? moment(row.invoiceDate).format("DD/MM/YYYY")
            : resources.common.notSpecified,
        };
        return editedRow;
      });

      setMachines(flattenedData);
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    if (Boolean(searching && activePage)) {
      if (
        Boolean(searching && searching.searchBy == "clientName") &&
        showNotAssociatedMachines
      )
        setShowNoteAssociatedMachines(false);

      loadMachines();
    }
  }, [searching, activePage, showNotAssociatedMachines]);

  const onCellClick = (row, type) => {
    setMachineID(row.machineId);
    if (type === "edit") {
      router.push("/machines/ManageMachine", {
        machineID: row.machineId,
      });
    }
    // else if (type === "remove") {
    //   setIsDelete(true);
    // }
    else if (type === "addParts") {
      router.push("/machines/ManageMachineParts", {
        machineID: row.machineId,
      });
    } else if (type === "addDevices") {
      router.push("/machines/ManageMachineExtraDevices", {
        machineID: row.machineId,
      });
    } else if (type === "machineInfo") {
      router.push("/machines/machineInfo", {
        machineID: row.machineId,
      });
    }
  };

  // const deleteMachine = async () => {
  //   try {
  //     const res = await serviceApi.service("machine").remove(machineID);
  //     if (res) {
  //       setIsDelete(false);
  //       loadMachines();
  //     }
  //   } catch (e) {
  //     if (e.code == 409) {
  //       setDeleteMessage(getDeleteError(e.data));
  //     } else setDeleteMessage(resources.common.errors.genericServerError);
  //   }
  // };

  return (
    <>
      {/* <DeleteModal
        onConfirm={() => deleteMachine()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      /> */}
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.machines.pageTitle}
            icon="computer"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.AddMachine) && (
              <Button
                basic
                color="blue"
                content={resources.pages.machines.addMachine}
                onClick={() => router.push("/machines/ManageMachine")}
              />
            )}
            {isAuthorized(Perm.viewMachines) && (
              <ExportDataToExcel
                data={machines}
                headers={headers}
                title={resources.common.excelExport.machines}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="machines"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
              extraFields={
                <div className="not-associated-checkbox-container">
                  <Checkbox
                    disabled={Boolean(
                      searching && searching.searchBy == "clientName"
                    )}
                    label={resources.pages.machines.showNotAssociatedMachines}
                    checked={showNotAssociatedMachines}
                    onChange={(evt, elm) =>
                      setShowNoteAssociatedMachines(Boolean(elm.checked))
                    }
                  />
                </div>
              }
            />
            <TableTemplate
              isLoading={isLoading}
              headers={headers}
              data={machines}
              edit={isAuthorized(Perm.editMachine) ? true : null}
              // remove={isAuthorized(Perm.deleteMachine) ? true : null}
              extraCells={[
                ...(isAuthorized(Perm.machineParts)
                  ? [
                      {
                        key: "addParts",
                        children: (
                          <Button
                            style={{ padding: "3px" }}
                            icon="microchip"
                            size="tiny"
                            content={
                              <p
                                style={{
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  padding: 3,
                                }}
                              >
                                {resources.pages.machines.MachineParts}
                              </p>
                            }
                            labelPosition="left"
                            basic
                            color="blue"
                          />
                        ),
                        isCliCkable: true,
                        tooltip: resources.pages.machines.MachineParts,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.addExtraDevices)
                  ? [
                      {
                        key: "addDevices",
                        children: (
                          <Button
                            style={{ padding: "3px" }}
                            icon="computer"
                            size="tiny"
                            content={
                              <p
                                style={{
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  padding: 3,
                                }}
                              >
                                {resources.pages.machines.addDevices}
                              </p>
                            }
                            labelPosition="left"
                            basic
                            color="blue"
                          />
                        ),
                        isCliCkable: true,
                        tooltip: resources.pages.machines.addDevices,
                      },
                    ]
                  : []),
                ...(isAuthorized(Perm.viewMachineInfo)
                  ? [
                      {
                        key: "machineInfo",
                        children: (
                          <Popup
                            size="mini"
                            content={resources.pages.machines.machineInfo}
                            trigger={<Icon name="info" color="blue" />}
                          />
                        ),
                        header: "",
                        isCliCkable: true,
                      },
                    ]
                  : []),
              ]}
              onCellClick={(row, type) => onCellClick(row, type)}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
