import React, { useState, useEffect } from "react";
import { reportsActionCreator } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, Icon, Input } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import ManageReportOptions from "./ManageReportOptions";
import { resources } from "../../assets/LocalizationResources";
import "./Report.scss";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";

const SystemReports = () => {
  const report = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const [inputSearchValue, setInputSearchValue] = useState("");

  const reports = [
    {
      id: "periodEndedContracts",
      name: resources.pages.reports.periodEndedContracts,
      description: resources.pages.reports.description.periodEndedContractsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "notDeservedContracts",
      name: resources.pages.reports.notDeservedContracts,
      description: resources.pages.reports.description.notDeservedContractsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "techAreasAndMachinesNum",
      name: resources.pages.reports.techAreasAndMachinesNum,
      description:
        resources.pages.reports.description.techAreasAndMachinesNumDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    // {
    //   id: "techMachinesWithContracts",
    //   name: resources.pages.reports.techMachinesWithContracts,
    //   description:
    //     resources.pages.reports.description.techMachinesWithContractsDesc,
    // },
    // {
    //   id: "machines",
    //   name: "بطاقة الآلة للزيارات الدورية",
    //   description: "غير محدد",
    // },
    // {
    //   id: "clientMachinesInfo",
    //   name: resources.pages.reports.clientMachinesInfo,
    //   description: resources.pages.reports.description.clientMachinesInfoDesc,
    // },
    {
      id: "machinesModelClient",
      name: resources.pages.reports.machinesModelClient,
      description: resources.pages.reports.description.machinesModelClientDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "generalMachineInfo",
      name: resources.pages.reports.generalMachineInfo,
      description: resources.pages.reports.description.generalMachineInfoDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "machinesThatHaveContractWithVisits",
      name: resources.pages.reports.machinesThatHaveContractWithVisits,
      description:
        resources.pages.reports.description
          .machinesThatHaveContractWithVisitsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "machinesThatHaveContractwithVisitsWithContractDataPrintOut",
      name: resources.pages.reports
        .machinesThatHaveContractWithVisitsBasedOnTech,
      description:
        resources.pages.reports.description
          .machinesThatHaveContractWithVisitsDescBasedOnTech,
      isAuthorized: isAuthorized(
        Perm.viewAllReports,
        Perm.viewFinancialReports
      ),
    },
    {
      id: "machinesContarctTechnician",
      name: resources.pages.reports.machinesContarctTechnician,
      description:
        resources.pages.reports.description.machinesContarctTechnicianDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "numberOfTechVisits",
      name: resources.pages.reports.numberOfTechVisits,
      description: resources.pages.reports.description.numberOfTechVisitsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "finishedContracts",
      name: resources.pages.reports.finishedContracts,
      description: resources.pages.reports.description.finishedContractsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "lateTickets",
      name: resources.pages.reports.lateTickets,
      description: resources.pages.reports.description.lateTicketsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "repeatedTickets",
      name: resources.pages.reports.repeatedTickets,
      description: resources.pages.reports.description.repeatedTicketsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "periodicalVisits",
      name: resources.pages.reports.periodicalVisits,
      description: resources.pages.reports.description.periodicalVisitsDesc,
      isAuthorized: isAuthorized(Perm.viewAllReports),
    },
    {
      id: "invoicesReport",
      name: resources.pages.reports.invoicesReport,
      description: resources.pages.reports.description.invoicesReportDesc,
      isAuthorized: isAuthorized(Perm.viewFinancialReports),
    },
    {
      id: "maintenanceCard",
      name: resources.pages.reports.maintenanceCard,
      description: resources.pages.reports.description.maintenanceCardDesc,
      isAuthorized: isAuthorized(Perm.viewFinancialReports),
    },
    {
      id: "partsMovementReportPrintOut",
      name: resources.pages.reports.partsMovementReport,
      description: resources.pages.reports.description.partsMovementReportDesc,
      isAuthorized: isAuthorized(Perm.viewFinancialReports),
    },
  ];

  const onReportSelected = (report) => () => {
    dispatch(reportsActionCreator.setReportNameID(report.name, report.id));
  };

  return (
    <div className="reports-component-container">
      <PageHeaderTitle
        content={resources.pages.reports.systemReports}
        icon="file alternate"
      />
      <Input
        placeholder={resources.pages.reports.searchReports}
        value={inputSearchValue}
        onChange={(e) => setInputSearchValue(e.target.value)}
      />
      <Card.Group className="reports-cards-group">
        {reports
          .filter((r) => r.name.includes(inputSearchValue))
          .map(
            (report, idx) =>
              report.isAuthorized && (
                <Card
                  key={idx}
                  color="blue"
                  onClick={onReportSelected(report)}
                  className="report-card"
                >
                  <div className="card-icon">
                    <Icon name="file alternate outline" />
                  </div>
                  <Card.Header className="card-header">
                    {report.name}
                  </Card.Header>
                  <Card.Meta className="card-description">
                    {report.description}
                  </Card.Meta>
                </Card>
              )
          )}
      </Card.Group>

      {Boolean(report && report.reportid) && (
        <ManageReportOptions reportID={report.reportid} />
      )}
    </div>
  );
};

export default SystemReports;
