import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { userActionCreator } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Dropdown } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import { useHistory } from "react-router-dom";
import Perm, { isAuthorized } from "../helpers/Permissions";

const UserNavbarMenu = ({ userInfo }) => {
  const user = useSelector((state) => state.user).user;
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(userActionCreator.logout());
  };

  const router = useHistory();

  const goToChangePasswordPage = () => {
    router.push("/settings/users/changePassword", {
      userId: userInfo.userId,
      fullName: userInfo.fullName,
      fromUserMenuList: true,
    });
  };

  const changeLangauge = (e, { value }) => {
    resources.setLanguage(value);
    localStorage.setItem("lang", value);
    dispatch(userActionCreator.languageChanged(value));
  };

  const languages = [
    {
      key: "عربي",
      text: "عربي",
      value: "ar",
    },
    {
      key: "English",
      text: "English",
      value: "en",
    },
  ];

  return (
    <AnimatePresence>
      (
      <motion.div
        initial={{ y: 85, opacity: 0 }}
        exit={{ opacity: 0, transition: { duration: 0.2 } }}
        animate={{ y: 0, opacity: 1 }}
        className="user-menu-list-container"
      >
        {(isAuthorized(Perm.changePassw0rd) || user.isExternalUser) && (
          <div className="menu-list-item" onClick={goToChangePasswordPage}>
            <Icon name="lock" /> <h5>{resources.pages.user.changePassword}</h5>
          </div>
        )}
        <div className="menu-list-item" onClick={logout}>
          <Icon name="sign-out" className="sign-out-icon" />
          <h5>{resources.common.logout}</h5>
        </div>

        <div className="menu-list-item">
          <Dropdown
            style={{ width: "100%" }}
            placeholder={resources.common.language}
            fluid
            options={languages}
            onChange={changeLangauge}
            defaultValue={localStorage.getItem("lang") ?? "ar"}
            pointing="top right"
          />
        </div>
      </motion.div>
      )
    </AnimatePresence>
  );
};

export default UserNavbarMenu;
