import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { resources } from "../../assets/LocalizationResources";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";

export default function BranchesList({ clientID }) {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [branchID, setBranchID] = useState(null);
  const [branches, setBranches] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 5, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const headers = {
    // branchNo: resources.pages.branch.branchNo,
    branchName: resources.pages.branch.branchName,
    areaName: resources.pages.areaList.areaName,
    contactPerson: resources.pages.branch.contacts,
    fax: resources.pages.branch.fax,
    phone: resources.pages.branch.branchPhoneNumber,
  };

  useEffect(() => {
    loadBranches();
  }, [activePage]);

  const loadBranches = async () => {
    try {
      setIsLoading(true);
      var res = await serviceApi.service("queries").find({
        query: {
          model: "ClientBranch",
          include: [{ model: "Area", attributes: ["areaName"] }],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
          attributes: [
            "branchId",
            //"branchNo",
            "areaID",
            "branchName",
            "contactPerson",
            "fax",
            "phone",
          ],
          where: { clientID: clientID },
        },
      });

      setBranches(
        res.data.map((row) => ({
          ...row,
          areaName: row.Area
            ? row.Area.areaName
            : resources.common.notSpecified,
          contactPerson: row.contactPerson ? (
            <div className="contact-persons-information-container">
              {JSON.parse(row.contactPerson).map((person, index) => {
                return (
                  <div key={index} className="contact-persons-information">
                    <label>
                      <span>{resources.common.name + " : "}</span>
                      {person.personName && person.personName.length > 1
                        ? person.personName
                        : resources.common.notSpecified}
                    </label>
                    <span>،</span>
                    <label>
                      <span>{resources.common.phoneNum + " : "}</span>
                      {person.personPhoneNumber &&
                      person.personPhoneNumber.length > 0
                        ? person.personPhoneNumber
                        : resources.common.notSpecified}
                    </label>
                    <span>،</span>
                    <label>
                      <span>{resources.common.email + " : "}</span>
                      {person.personEmail && person.personEmail.length > 0
                        ? person.personEmail
                        : resources.common.notSpecified}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (
            resources.common.notSpecified
          ),
          fax: row.fax ? row.fax : resources.common.notSpecified,
          phone: row.phone ? row.phone : resources.common.notSpecified,
        }))
      );

      setPagination({
        ...pagination,
        total: res.total,
      });
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const onPaging = (page) => setActivePage(page);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/ClientBranches/manageBranch", {
        branchID: row.branchId,
      });
    } else if (type === "remove") {
      setBranchID(row.branchId);
      setIsDelete(true);
    }
  };

  const deleteBranch = async () => {
    try {
      await serviceApi
        .service("ClientBranch")
        .remove(branchID)
        .then((res) => {
          setIsDelete(false);
          loadBranches();
        });
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteBranch()}
        isOpen={isDelete}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        errorMessage={deleteMessage}
      />
      <TableTemplate
        isLoading={isLoading}
        headers={headers}
        data={branches}
        edit={isAuthorized(Perm.editBranch) ? true : null}
        remove={isAuthorized(Perm.deleteBranch) ? true : null}
        onCellClick={(row, type) => onCellClick(row, type)}
        paging={pagination}
        activePage={activePage}
        onPaging={onPaging}
      />
    </>
  );
}
