import React, { useState, useEffect } from "react";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import { Loader } from "semantic-ui-react";
import "./MachinesThatHaveContractwithVisitsPrintOut.scss";
import serviceApi from "../../api";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const MachinesThatHaveContractwithVisitsPrintOut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [machinesThatHaveContractsData, setMachinesThatHaveContractsData] =
    useState([]);
  const location = useLocation();

  const headers = {
    TechnicianName: "اسم الفني",
    ComputerNumber: "رقم الكمبيوتر",
    machineModel: "موديل الالة",
    ClientName: "اسم العميل",
    AreaName: "المنطقة",
    ContractType: "نوع العقد",
    clientType: "تصنيف العميل",
    MaintenanceDays: "أيام الصيانة",
    nextVisist: "تاريخ الصيانة القادمه",
    lateDays: "مدة التأخير",
    neededVistis: "ز.مطلوبة",
    actualVistis: "ز.فعلية",
    failure: "التقصير",
  };

  const { from, TechnicianID, to } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries-text").find({
        query: {
          from: from,
          to: to,
          TechnicianID: TechnicianID,
          type: 6,
        },
      });
      const data = res
        .filter((row) => row.lateDays && row.lateDays > 0)
        .map((row) => {
          return {
            ...row,
            TechnicianName: row.TechnicianName ? row.TechnicianName : null,
            ComputerNumber: row.ComputerNumber ? row.ComputerNumber : null,
            machineModel: row.machineModel ? row.machineModel : null,
            ClientName: row.ClientName ? row.ClientName : null,
            AreaName: row.AreaName ? row.AreaName : null,
            ContractType: row.ContractType ? row.ContractType : null,
            MaintenanceDays: row.MaintenanceDays
              ? row.MaintenanceDays
              : "غير محدد",
            nextVisist: row.nextVisist
              ? moment(row.nextVisist).format("YYYY-MM-DD")
              : null,
            lateDays: row.lateDays,
            neededVistis: row.neededVistis ? row.neededVistis : null,
            actualVistis: row.actualVistis ? row.actualVistis : null,
            clientType: `${row.VIP ? "VIP" : ""} ${
              row.VIP && row.SLA ? " / " : ""
            } ${row.SLA ? "SLA" : ""}`,
            failure:
              row.neededVistis - row.actualVistis > 0
                ? row.neededVistis - row.actualVistis
                : 0,
          };
        });
      setMachinesThatHaveContractsData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      setMachinesThatHaveContractsData([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="machines-contracts-visitis-container">
      {isLoading ? (
        <div className="loader-container">
          <Loader inline active={isLoading} />
        </div>
      ) : Boolean(machinesThatHaveContractsData.length) ? (
        <div>
          <PrintOutHeaderLandscape
            printOutTitle={"الالات التي لها عقود حسب الفني مع الزيارات"}
            dateTitle={"تاريخ نهاية العقد"}
            startDate={from}
            endDate={to}
            hasDate={true}
            data={machinesThatHaveContractsData}
            headers={headers}
          />
          <div className="machines-contracts-visitis-table-container">
            <table>
              <thead>
                <tr>
                  <th>الفني</th>
                  <th>رقم الكمبيوتر</th>
                  <th>موديل الالة</th>
                  <th className="client-name-cell">اسم العميل</th>
                  <th>المنطقة</th>
                  <th className="contract-type-cell">نوع العقد</th>
                  <th>تصنيف العميل</th>
                  <th>أيام الصيانة</th>
                  <th>تاريخ الصيانة القادمة</th>
                  <th>مدة التأخير</th>
                  <th>ز.مطلوبة</th>
                  <th>ز.فعلية</th>
                  <th>التقصير</th>
                </tr>
              </thead>
              <tbody>
                {machinesThatHaveContractsData.map((info, idx) => (
                  <tr key={idx} style={{ textAlign: "right" }}>
                    <th>{info.TechnicianName}</th>
                    <th>{info.ComputerNumber}</th>
                    <th>{info.machineModel}</th>
                    <th>{info.ClientName}</th>
                    <th>{info.AreaName}</th>
                    <th>{info.ContractType}</th>
                    <th>{info.clientType}</th>
                    <th>{info.MaintenanceDays}</th>
                    <th>{info.nextVisist}</th>
                    <th>{info.lateDays}</th>
                    <th>{info.neededVistis}</th>
                    <th>{info.actualVistis}</th>
                    <th>
                      {info.neededVistis - info.actualVistis > 0
                        ? info.neededVistis - info.actualVistis
                        : 0}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontSize: 30,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          لا توجد بيانات
        </div>
      )}
    </div>
  );
};

export default MachinesThatHaveContractwithVisitsPrintOut;
