import React, { useState, useEffect } from "react";
import "./NotDeservedContractsPrintOut.scss";
import { useLocation } from "react-router-dom";
import serviceApi from "../../api";
import moment from "moment";
import { Loader } from "semantic-ui-react";
import qs from "qs";
import PrintOutHeaderLandscape from "../PrintOutHeader/PrintOutHeaderLandscape";
import { generateMachinePartInvoiceNumber } from "../../containers/MaintenanceReport/MaintenanceReportsList";
import { resources } from "../../assets/LocalizationResources";

const PartsMovementReportPrintOut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [printOutInfo, setPrintOutInfo] = useState([]);
  const [partDescription, setPartDescription] = useState("");

  const [lastClientName, setLastClientName] = useState("");
  const { from, to, computerNumber, partNumber } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await serviceApi.service("queries-text").find({
        query: {
          from,
          to,
          computerNumber,
          partNumber,
          type: 34,
        },
      });

      const { MachineData, lastClient } = response;

      if (MachineData) {
        const processedData = MachineData.map((row) => {
          return {
            ...row,
            invoiceDate: row.createdAt
              ? moment(row.createdAt).format("YYYY-MM-DD")
              : null,
            MachinePartInvoiceNumber: row.MachinePartInvoiceNumber
              ? generateMachinePartInvoiceNumber(
                  row.createdAt,
                  row.MachinePartInvoiceNumber
                )
              : null,
            Counter1: row.Counter1,
            Quantity: row.Quantity,
          };
        });

        setPartDescription(MachineData[0].Description);
        setLastClientName(lastClient);
        setPrintOutInfo(processedData);
      } else {
        setPrintOutInfo([]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setPrintOutInfo([]);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [from, to, computerNumber]);

  return (
    <div className="not-deserved-contract-printout-container  ">
      <div>
        {isLoading ? (
          <div className="loader-container">
            <Loader inline active={isLoading} />
          </div>
        ) : (
          <>
            <div>
              <PrintOutHeaderLandscape
                printOutTitle={resources.pages.reports.partsMovementReport}
                dateTitle="تاريخ الفاتورة "
                startDate={from}
                endDate={to}
                hasDate={true}
                data={printOutInfo}
                headers={{
                  createdAt: "تاريخ الفاتورة",
                  MachinePartInvoiceNumber: "رقم فاتورة القطع",
                  LastCounter1: "العداد الحالي",
                  Quantity: "الكمية",
                }}
                extraHeader={
                  <div className="mb-1">
                    {computerNumber && (
                      <div>
                        <h5>رقم الجهاز: {computerNumber}</h5>
                      </div>
                    )}
                    {partNumber && (
                      <div>
                        <h5>رقم القطعة: {partNumber}</h5>
                      </div>
                    )}
                    {partDescription && partNumber && (
                      <div>
                        <h5>وصف القطعة: {partDescription}</h5>
                      </div>
                    )}
                    {lastClientName && (
                      <div>
                        <h5>اسم العميل : {lastClientName}</h5>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            {printOutInfo.length > 0 ? (
              <div className="not-deserved-contract-printout-table-container">
                <table className="invoice-printout-table">
                  <thead>
                    <tr>
                      <th>تاريخ الفاتورة</th>
                      <th>رقم فاتورة القطع</th>
                      <th>العداد الحالي</th>
                      <th>الكمية</th>
                    </tr>
                  </thead>
                  <tbody>
                    {printOutInfo.map((info, idx) => (
                      <tr key={idx}>
                        <td>
                          {info.createdAt
                            ? moment(info.createdAt).format("YYYY-MM-DD")
                            : " لايوجد بيانات"}
                        </td>
                        <td>
                          {info.MachinePartInvoiceNumber
                            ? info.MachinePartInvoiceNumber
                            : " لايوجد بيانات"}
                        </td>
                        <td>{info.Counter1 ? info.Counter1 : "----"}</td>
                        <td>
                          {info.Quantity ? info.Quantity : " لايوجد بيانات"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                style={{
                  marginTop: "10px",
                  fontSize: 30,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                لا توجد بيانات
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PartsMovementReportPrintOut;
