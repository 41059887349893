import React, { useRef, useEffect, useState } from "react";
import { Map as Leaflet, Circle, GeoJSON } from "react-leaflet";
import { Button, Icon } from "semantic-ui-react";
import L from "leaflet";
import "../Map/map.scss";
import MapLayers from "../Map/MapLayers";
import {
  setupDrawControl,
  StopDrawing,
  StartDrawing,
  addGeomtry,
  getGeometry,
  deletefreeDraw,
} from "../Map/DrawControl";
import SearchOnMap from "../../components/Map/SearchOnMap";

L.Icon.Default.imagePath = "/images/";

export default function AreasMap({ onGeometryChange, geometry }) {
  const mapRef = useRef(null);
  const [isDraw, setisDraw] = useState(true);
  const [center, setCenter] = useState({ lng: 36.5565, lat: 31.535654 });
  const [zoom, setZoom] = useState(7);
  useEffect(() => {
    return () => {
      if (mapRef && mapRef.current)
        deletefreeDraw(mapRef.current.leafletElement);
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      setupDrawControl({ _map: mapRef.current.leafletElement });
    }
  }, [mapRef]);

  useEffect(() => {
    if (geometry && mapRef && mapRef.current) {
      addGeomtry(mapRef.current.leafletElement, geometry);
    }
  }, [geometry]);

  const _toggleDrawing = () => {
    isDraw
      ? StopDrawing(mapRef.current.leafletElement)
      : StartDrawing(mapRef.current.leafletElement);
    setisDraw(!isDraw);

    if (typeof onGeometryChange === "function")
      onGeometryChange({
        geom: isDraw ? getGeometry(mapRef.current.leafletElement) : null,
        saved: isDraw,
      });
  };
  const _setSearchData = (searchData) => {
    if (mapRef && mapRef.current && searchData) {
      if (searchData && searchData.type == "Point" && searchData.coordinates) {
        mapRef.current.leafletElement.flyTo(
          [searchData.coordinates[1], searchData.coordinates[0]],
          14
        );
      } else if (
        searchData &&
        searchData.coordinates &&
        searchData.coordinates.length > 0
      ) {
        let center =
          searchData.coordinates[Math.floor(searchData.coordinates.length / 2)];
        mapRef.current.leafletElement.flyTo([center[1], center[0]], 14);
      }
    }
  };
  return (
    <>
      <SearchOnMap setSearchData={_setSearchData} />
      <Leaflet ref={mapRef} center={center} zoom={zoom} className="main-map">
        <MapLayers />
      </Leaflet>
      <div className="map-draw-control-legend" onClick={_toggleDrawing}>
        <Button color={isDraw ? "green" : "blue"} icon>
          {isDraw ? <Icon name="save" /> : <Icon name="edit" />}
        </Button>
      </div>
    </>
  );
}
