import React, { useEffect, useState } from "react";
import serviceApi from "../../api";
import TableTemplate from "../../components/Table/TableTemplate";
import { useHistory } from "react-router-dom";
import { Grid, Segment, Button } from "semantic-ui-react";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import { resources } from "../../assets/LocalizationResources";
import SearchBy from "../../components/SearchBy/SearchBy";
import DeleteModal from "../../components/DeleteModal";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import getDeleteError from "../../components/helpers/getDeleteError";
import moment from "moment";
import { useSelector } from "react-redux";

export default function GroupPermissionsList() {
  const router = useHistory();
  const [isDelete, setIsDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [groupID, setGroupID] = useState(null);
  const [groups, setGroups] = useState(null);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);

  const headers = {
    groupName: resources.pages.permissions.groupName,
    fullName: resources.common.createdBy,
    createdAt: resources.common.createdDate,
  };

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const getOptions = async () => {
    try {
      const res = await serviceApi.service("groups").find({
        query: {
          $select: ["groupId", "groupName"],
        },
      });

      if (res && res.data && res.data.length > 0)
        return res.data.map((item) => {
          return {
            key: item.groupId,
            text: item.groupName,
            value: item.groupId,
          };
        });
      else return [];
    } catch (error) {
      console.log(error);
    }
  };

  const fillSearchData = async () => {
    setSearchData({
      groupName: {
        type: "ddl",
        label: resources.pages.permissions.groupName,
        options: await getOptions(),
      },
      fullName: {
        type: "text",
        label: resources.common.createdBy,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "groupPermissions"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  const whereSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id)
      return { where: { [colID]: searching.term } };
    else return {};
  };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadGroups = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "Groups",
          include: [
            {
              model: "Users",
              attributes: ["fullName"],
              ...whereLikeSearch("fullName"),
            },
          ],
          ...whereSearch("groupName", "groupId"),
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });
      setGroups(
        res.data.map((m) => {
          return {
            ...m,
            fullName: m.User.fullName,
            createdAt: moment(m.createdAt).format("DD/MM/YYYY"),
          };
        })
      );
      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadGroups();
  }, [searching, activePage]);

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/groupPermissions/ManageGroupPermissions", {
        groupID: row.groupId,
        groupName: row.groupName,
      });
    } else if (type === "remove") {
      setGroupID(row.groupId);
      setIsDelete(true);
    }
  };

  const deleteGroup = async () => {
    try {
      const res = await serviceApi.service("group-permissions").remove(null, {
        query: {
          groupID: groupID,
        },
      });
      if (res) {
        await serviceApi
          .service("groups")
          .remove(groupID)
          .then((res) => {
            setIsDelete(false);
            loadGroups();
          });
      }
    } catch (e) {
      if (e.code == 409) {
        setDeleteMessage(getDeleteError(e.data));
      } else setDeleteMessage(resources.common.errors.genericServerError);
    }
  };

  return (
    <>
      <DeleteModal
        onConfirm={() => deleteGroup()}
        onCancel={() => {
          setIsDelete(false);
          setDeleteMessage("");
        }}
        isOpen={isDelete}
        errorMessage={deleteMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.permissions.groupPermissions}
            icon="hand paper"
          />
          {isAuthorized(Perm.addNewGroupPermissions) && (
            <Button
              basic
              color="blue"
              content={resources.pages.permissions.addGroup}
              onClick={() =>
                router.push("/groupPermissions/ManageGroupPermissions")
              }
            />
          )}
          <Segment>
            <SearchBy
              pageName="groupPermissions"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              headers={headers}
              data={groups}
              edit={isAuthorized(Perm.editGroupPermissions) ? true : null}
              remove={isAuthorized(Perm.deleteGroupPermissions) ? true : null}
              isLoading={isLoading}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              onCellClick={(row, type) => onCellClick(row, type)}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
}
