import React, { useState } from "react";
import { Button, Modal, Table } from "semantic-ui-react";
import DTPicker from "../../components/DTPicker/DTPicker";
import serviceApi from "../../api";
import moment from "moment";
import { resources } from "../../assets/LocalizationResources";

const ContractRenewal = ({
  contractID = null,
  isOpen,
  closeContractRenewalModal,
  contractMacineIsAssociated,
  setContractMacineIsAssociated,
  associatedContractMachines,
}) => {
  const [newEndDate, setNewEndDate] = useState(moment().format("YYYY-MM-DD"));

  const renewalContract = async () => {
    try {
      if (Boolean(contractID)) {
        await serviceApi
          .service("contract")
          .patch(contractID, { endDate: newEndDate });
        closeContractRenewalModal(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open={isOpen} className="contract-renewal-container">
      {contractMacineIsAssociated ? (
        <>
          <Modal.Header>
            <label className="attention-label">
              {resources.pages.contracts.attention}
            </label>
          </Modal.Header>
          <Modal.Content
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <label className="associated-machine-message-label">
              {resources.pages.contracts.associatedMachineAttentionMessage}
            </label>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {resources.pages.addContractMachine.MachineID}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {associatedContractMachines.map((item) => (
                  <Table.Row key={item.machineID}>
                    <Table.Cell>{item.machineID}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => setContractMacineIsAssociated(false)}
            >
              {resources.common.continue}
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Header>
            {resources.pages.contracts.contractRenewal}
          </Modal.Header>
          <Modal.Content>
            <DTPicker
              selectedValue={newEndDate}
              setSelectedValue={(v) => setNewEndDate(v)}
              format="date"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={renewalContract} positive>
              {resources.pages.contracts.extends}
            </Button>
            <Button
              negative
              onClick={() => {
                closeContractRenewalModal(false);
              }}
            >
              {resources.common.no}
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default ContractRenewal;
