import React, { useEffect, useState } from "react";
import { resources } from "../../../assets/LocalizationResources";
import { Checkbox, Loader, Select } from "semantic-ui-react";
import "./CreationGeoMethods.scss";
import geofenceApi from "../../../api/geofence-service.api";

const AdministrativeRegions = ({ onGeomSelected }) => {
  const searchBy = [
    { key: "1", text: "محافظات", value: "JORDAN_GOVERNORATES" },
    { key: "2", text: "مناطق عمان", value: "AMMAN_DISTRICT" },
    { key: "3", text: "أحياء عمان", value: "AMMAN_SUBDISTRICT" },
  ];

  const [choosedWay, setChoosedWay] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsID, setSelectedOptionsID] = useState(null);
  const [loading, setLoading] = useState(false);

  const radioButtons = (item) => {
    return item.map((row, idx) => (
      <Checkbox
        radio
        key={idx}
        name="checkboxRadioGroup"
        label={row.text}
        value={row.value}
        checked={row.value === choosedWay ? true : false}
        onChange={(e, elm) => setChoosedWay(elm.value)}
      />
    ));
  };

  useEffect(() => {
    const getWKT = async () => {
      if (selectedOptionsID) {
        setLoading(true);
        const data = await geofenceApi.getWKT(choosedWay, selectedOptionsID);
        if (typeof onGeomSelected === "function" && data.data)
          onGeomSelected({ geom: data.data.wkt, saved: true });
        setLoading(false);
      }
    };

    getWKT();
  }, [selectedOptionsID]);

  const fillOptions = async (value) => {
    if (value) {
      setLoading(true);
      const data = await geofenceApi.getList(value);
      setSelectedOptions(
        data.data.map((row) => ({
          text: row.text,
          value: row.id,
          key: row.id,
        }))
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fillOptions(choosedWay);
  }, [choosedWay]);

  return (
    <div className="administrative-regions-container">
      <label className="label-text">{resources.pages.area.borders}</label>
      <div className="administrative-regions-choices">
        <div className="administrative-regions-choices-checkBoxes">
          {radioButtons(searchBy)}
        </div>

        <Select
          options={selectedOptions}
          className="administrative-regions-choices-select-list"
          value={selectedOptionsID}
          placeholder={resources.pages.area.choosePlease}
          onChange={(e, { value }) => {
            setSelectedOptionsID(value);
          }}
          search
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AdministrativeRegions;
