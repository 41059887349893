import React, { useState, useEffect } from "react";
import { Grid, Segment, Button } from "semantic-ui-react";
import { resources } from "../../assets/LocalizationResources";
import PageHeaderTitle from "../../components/PageHeaderTitle/PageHeaderTitle";
import DeleteModal from "../../components/DeleteModal";
import TableTemplate from "../../components/Table/TableTemplate";
import Perm, { isAuthorized } from "../../components/helpers/Permissions";
import SearchBy from "../../components/SearchBy/SearchBy";
import { useHistory } from "react-router-dom";
import serviceApi from "../../api";
import getDeleteError from "../../components/helpers/getDeleteError";
import ExportDataToExcel from "../../components/ExportExcelFile/ExportDataToExcel";
import moment from "moment";
import { useSelector } from "react-redux";

const TechnicianAreasTable = () => {
  const router = useHistory();
  const headers = {
    technicianName: resources.pages.technicianAreas.technician,
    areaName: resources.pages.technicianAreas.areas,
    createdAt: resources.common.createdDate,
  };

  const [technicians, setTechnicians] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searching, setSearching] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [pagination, setPagination] = useState({ pageSize: 100, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const [deleteModalTechID, setDeleteModalTechID] = useState(null);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(null);

  const searchState = useSelector((state) => state.cacheSearch);

  useEffect(() => {
    fillSearchData();
  }, []);

  const onPaging = (page) => setActivePage(page);

  const fillSearchData = async () => {
    setSearchData({
      technicianName: {
        type: "text",
        label: resources.pages.technicianAreas.technician,
      },
      areaName: {
        type: "text",
        label: resources.pages.technicianAreas.areas,
      },
    });

    if (
      searchState &&
      searchState.searchBy &&
      searchState.term &&
      searchState.pageName === "technicianArea"
    ) {
      setSearching({
        searchBy: searchState.searchBy,
        term: searchState.term,
      });
    }
  };

  // const whereSearch = (id, colID = id) => {
  //   if (searching && searching.searchBy === id)
  //     return { where: { [colID]: searching.term } };
  //   else return {};
  // };

  const whereLikeSearch = (id, colID = id) => {
    if (searching && searching.searchBy === id) {
      return { where: { [colID]: { like: `%${searching.term}%` } } };
    } else return {};
  };

  const loadTechs = async () => {
    try {
      setIsLoading(true);
      const res = await serviceApi.service("queries").find({
        query: {
          model: "TechnicianArea",
          attributes: ["TechnicianID", "createdAt"],
          include: [
            {
              model: "Technicians",
              attributes: ["technicianName"],
              ...whereLikeSearch("technicianName"),
            },
            {
              innerJoin: true,
              model: "Area",
              attributes: ["areaName"],
              ...whereLikeSearch("areaName"),
            },
          ],
          limit: pagination.pageSize,
          skip: pagination.pageSize * (activePage - 1),
        },
      });

      const reduced = res.data.reduce((p, c) => {
        if (p[c.TechnicianID]) {
          p[c.TechnicianID].areaName += `, ${c.Area.areaName}`;
        } else {
          p[c.TechnicianID] = {
            TechnicianID: c.TechnicianID,
            technicianName: Boolean(c.Technician)
              ? c.Technician.technicianName
              : resources.pages.technicianAreasList.notAvailable,
            areaName: c.Area.areaName,
            createdAt: moment(c.createdAt).format("DD/MM/YYYY"),
          };
        }
        return p;
      }, {});

      const reducedArr = Object.values(reduced);
      for (const row of reducedArr) {
        row.areaName =
          row.areaName.length > 50
            ? row.areaName.substring(0, 47) + "..."
            : row.areaName;
      }

      setTechnicians(reducedArr);

      setPagination({
        ...pagination,
        total: res.total,
      });
      if (Math.ceil(res.total / 10) < activePage) setActivePage(1);
      setIsLoading(false);
    } catch (error) {
      // TODO: Show error message
      setIsLoading(false);
      console.log(error);
    }
  };

  const getSearchResults = (searchBy = "", term = "") => {
    setSearching({ term: term, searchBy: searchBy });
  };

  useEffect(() => {
    Boolean(searching && activePage) && loadTechs();
  }, [searching, activePage]);

  const deleteTechAreas = async () => {
    try {
      await serviceApi.service("TechnicianArea").remove(null, {
        query: { TechnicianID: +deleteModalTechID },
      });
      setDeleteModalTechID(null);
      loadTechs();
    } catch (e) {
      if (e.code == 409) {
        setDeleteErrorMessage(getDeleteError(e.data));
      } else setDeleteErrorMessage(resources.common.errors.genericServerError);
    }
  };

  const onCellClick = (row, type) => {
    if (type === "edit") {
      router.push("/technicians/areas/add", {
        technicianID: row.TechnicianID,
      });
    } else if (type === "remove") {
      setDeleteModalTechID(row.TechnicianID);
    }
  };
  return (
    <>
      <DeleteModal
        onConfirm={deleteTechAreas}
        onCancel={() => {
          setDeleteModalTechID(null);
          setDeleteErrorMessage("");
        }}
        isOpen={Boolean(deleteModalTechID)}
        errorMessage={deleteErrorMessage}
      />
      <Grid>
        <Grid.Column>
          <PageHeaderTitle
            content={resources.pages.technicianAreasList.pageTitle}
            icon="map"
          />
          <div className="two-buttons-container">
            {isAuthorized(Perm.addTechnicianAreas) && (
              <Button
                basic
                color="blue"
                content={resources.pages.technicianAreasList.addTechAreas}
                onClick={() => router.push("/technicians/areas/add")}
              />
            )}
            {isAuthorized(Perm.viewtTechAreas) && (
              <ExportDataToExcel
                data={technicians}
                headers={headers}
                title={resources.common.excelExport.techAreas}
              />
            )}
          </div>
          <Segment>
            <SearchBy
              pageName="technicianArea"
              searchTypes={searchData}
              getSearchResults={getSearchResults}
            />
            <TableTemplate
              headers={headers}
              data={technicians}
              edit={isAuthorized(Perm.addTechnicianAreas) ? true : null}
              remove={isAuthorized(Perm.addTechnicianAreas) ? true : null}
              isLoading={isLoading}
              paging={pagination}
              activePage={activePage}
              onPaging={onPaging}
              onCellClick={(row, type) => onCellClick(row, type)}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default TechnicianAreasTable;
