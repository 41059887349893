import { combineReducers } from "redux";
import userReducer from "./users.reducer";
import layoutReducer from "./layout.reducer";
import reportsReducer from "./reports.reducer";
import cacheSearchReducer from "./cacheSearch.reducer";

const rootreducer = combineReducers({
  user: userReducer,
  layout: layoutReducer,
  reports: reportsReducer,
  cacheSearch: cacheSearchReducer,
});

export default rootreducer;
